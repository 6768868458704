import React from "react";
import { Modal } from "reactstrap";

export default function MapModel({ isOpen, toggle ,latitude,
  longitude }) {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={"lg"} className="free-rules-builder-modal" centered>
      <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100 modal-header">
        <h3>Hotel Location On Map</h3>
        <a href="##" onClick={toggle} className="btn-x">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <iframe title="hotel map" className="mt-2" width="100%" height="315"
       src={`https://maps.google.com/?q=${latitude},${longitude}`} 
       frameborder="0" allowfullscreen></iframe>
    </Modal>
  );
}
