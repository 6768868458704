import React from "react";
import { Route } from "react-router-dom";

// React Component
export default function PrivateRoute({ component: Component, ...props }) {


  return (
    <Route
      {...props}
      render={(matchProps) => (
        // <DashboardLayout>
        <Component {...matchProps} />
        // </DashboardLayout>
      )}
    />
  );
}
