import Carousel from 'react-multi-carousel';
import ThemeSevenTourCard from './TourCard';
import { useMemo } from 'react';
import ButtonGroup from 'modules/WebBuilder-V2/shared/CarouselButtonGroup';
import { useSBSState } from 'context/global';


export default function ToursTheme7({
  items,
  title,
  body
}) {

  const { locale } = useSBSState();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1200, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const isMobileView = window.innerWidth < responsive.tablet.breakpoint.max;

  const groupedToursItems = useMemo(() => {
    return isMobileView ? items : (Array.from({ length: Math.ceil(items.length / 3) }, (_, i) => items.slice(i * 3, i * 3 + 3)) || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(items)]);

  const showArrows = groupedToursItems.length > responsive.desktop.items;

  return (
    <section className="theme_tours_section">
      <div className="row d-flex align-items-center">
        <div className="col-xl-3 col-lg-4 mb-4">
          <div className="section_title">

            <h4 className='heading'>{title}</h4>
            <p className="text__content">
              {body}
            </p>
          </div>
        </div>

        <div className="col-xl-9 col-lg-8">
          <div className="section_content">
            <Carousel
              responsive={responsive}
              className={!showArrows ? "pb-0" : " "}
              slidesToSlide={1}
              keyBoardControl={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              customButtonGroup={showArrows ? <ButtonGroup arrowsBesideDots={true} /> : null}
              renderButtonGroupOutside={false}
              showDots={showArrows}
              arrows={false}
              infinite={false}
              rtl={locale === "ar"}
            >
              {isMobileView ?

                groupedToursItems.map((props, index) => {
                  return (
                    <div className="row row_wrapper px-lg-4 px-3 h-100" key={props?.product_uuid}>
                      <div className="col-md-6" key={props.id}>
                        <ThemeSevenTourCard
                          {...props}
                          index={index}
                        />
                      </div>
                    </div>
                  )
                })
                :
                groupedToursItems.map((group, groupIndex) => (
                  <div className="row row_wrapper px-lg-4 px-3 h-100" key={group?.product_uuid}>

                    {group.map((props, index) => {
                      const isFirstItem = index === 0;

                      if (isFirstItem) {
                        return (
                          <div className={`col-md-6 ${isFirstItem ? 'mb-md-0 mb-4' : ''}`} key={props.id}>
                            <ThemeSevenTourCard
                              {...props}
                              index={index}
                              cardStyleType={isFirstItem ? "super" : undefined}
                            />
                          </div>
                        );
                      } else if (index === 1) {
                        return (
                          <div className="col-md-6" key={props.id}>
                            <ThemeSevenTourCard
                              {...props}
                              index={index}
                              className="mb-3"
                            />
                            {group[2] && (
                              <ThemeSevenTourCard
                                {...group[2]}
                                index={index + 1}
                              />
                            )}
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                ))
              }

            </Carousel>
          </div>
        </div>
      </div>
    </section>
  )
}