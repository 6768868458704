import { useSBSState } from 'context/global';
import { ArrowRightIcon } from 'modules/WebBuilder-V2/shared/icons';
import { useState } from 'react';
import Locale from 'translations';

export default function AboutUsThemeSeven({ items }) {
  let media = items?.find(item => item.id.includes("media"));
  let content = items?.find(item => item.id.includes("content"));

  return (
    <div className="theme_about_section custom-container">
      {/* media */}
      <div className="bg_col">
        <AboutMedia details={media} />
      </div>
      {/* content */}
      <AboutContent details={content} />
    </div>
  )
}


const AboutContent = ({ details }) => {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  let { head, body, textAlign = "start" } = details;

  head = [locale] in head ? head[locale] : head
  body = [locale] in body ? body[locale] : body

  let trimBody = body
    ? body?.length > 400
      ? `${body?.substr(0, 400)}...`
      : body
    : "";
  const [isFullContentShown, setIsFullContentShown] = useState(false);

  function toggleContent() {
    setIsFullContentShown(!isFullContentShown);
  }

  return (
    <div className="text_col" style={{ textAlign }}>
      <h3 className='sub_heading'>
        {webBuilder.aboutus}
      </h3>
      <h4 className='main_heading'>
        {head}
      </h4>
      <p className="about__text">
        {!isFullContentShown ? trimBody : body}
      </p>
      {body?.length > 400 ? (
        <div className="cta" onClick={toggleContent}>
          <button className="theme_btn">
            <span className='btn_content'>
              {isFullContentShown
                ? webBuilder.readLessText
                : webBuilder.readMoreText}
              <span className="mx-1"></span>
              <ArrowRightIcon className="mirror-rtl" />
            </span>
          </button>
        </div>
      ) : null}
    </div>
  );
};

const AboutMedia = ({ details }) => {
  const { mediaUrl, mediaType } = details;

  return (
    <>
      {mediaType === "video" ? (
        <iframe
          style={{ maxWidth: "100%" }}
          className="w-100 h-100"
          width="auto"
          height="auto"
          src={`https://www.youtube.com/embed/${mediaUrl}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <img src={mediaUrl} alt="about us" />
      )}
    </>
  );
};