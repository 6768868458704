import React from "react";
import ThemeFourServiceCard from "./ServiceCard";
import { useWebBuilderState } from "context/webBuilder";
import { useSBSState } from "context/global";
// import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";

export default function OurServicesThemeFour() {
  const { ourServices } = useWebBuilderState();
  const { locale } = useSBSState();
  let services = Array.isArray(ourServices?.services)
    ? ourServices?.services
    : [];
  services = services?.filter((service) => service.icon && service.visible);

  return (
    <div className="travel-services-container">
      <div className="travel-services-wrapper custom-container">
        {services.map((service, index) => (
          <ThemeFourServiceCard
            type={service?.type}
            image={service?.icon}
            header={service?.header[locale]}
            body={service?.body[locale]}
            key={service?.type + index}
          />
        ))}
      </div>
    </div>
  );
}
