import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";


function FlightsService({ details }) {
  const { id, body, title, items } = details;



  return (
    <div className="web-builder-content-flights" id={id}>
      <div className="flights-top-section">
        <ServicesContent
          header={title}
          body={body}
        />
      </div>

      <div className="flights-detials">
        {items.map((item, index) => (
          <ServiceCardDetails {...item} itemType="flight" key={item.id} />
        ))}
      </div>
    </div>
  );
}

export default FlightsService;
