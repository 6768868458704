
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import PackageCardThemeFour from "./PackageCard";


export default function OurPackagesThemeFour({ details }) {
  const { id, title, body, items } = details;

  return (
    <section className={`theme_packages_section custom-container`} id={id}>
      <div className="service-top-section">
        <div className="section_heading w-100">
          <ServicesContent header={title} body={body} />
        </div>
      </div>

      <div className="package-container">
        {items?.filter((item) => item && item?.product_uuid)?.map((props, index) => (
          <PackageCardThemeFour {...props} key={index} />
        ))}
      </div>
    </section>
  );
}
