import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { customContainerItems } from "../../../interfaces/blockTypes";
import ParagraphItem from "./ParagraphItem";
import PhotoItem from "./PhotoItem";
import VideoItem from "./VideoItem";
import FAQs from "../FAQs";
import { useState } from "react";
import { useSBSState } from "context/global";

function CustomContainer({ details }) {
  const {locale} = useSBSState();
  const { body, title, items } = details;
  const [faqModal, setFaqModal] = useState(false);




  const handleFaqModal = () => {
    setFaqModal((prev) => !prev);
  };

  const {
    customContainerParagraph,
    customContainerPhoto,
    customContainerVideo,
    customContainerFaqs,
  } = customContainerItems;

  const containerItems = {
    [customContainerParagraph]: {
      component: ParagraphItem,
      componentProps: {},
    },
    [customContainerPhoto]: {
      component: PhotoItem,
      componentProps: {},
    },
    [customContainerVideo]: {
      component: VideoItem,
      componentProps: {},
    },
    [customContainerFaqs]: {
      component: FAQs,
      componentProps: {
        openModal: faqModal,
        handleOpenModal: handleFaqModal,
      },
    },
  };




  return (
    <div className="web-builder-content-custom-container">
      <div className="custom-container-top-section">
        <ServicesContent
          header={title}
          body={body}
        />
      </div>

      <div className="custom-container-detials">
        {items.map((item, index) => {
          const itemDetails = containerItems?.[item?.type];

          const Component = itemDetails?.component;
          const componentProps = itemDetails?.componentProps;

          return (
            <Component {...item} body={item?.body?.[locale]} {...componentProps} key={item.id} />
          );
        })}
      </div>
    </div>
  );
}

export default CustomContainer;
