import React, { useState } from "react";
import disimg from "assets/images/disimg.svg";
import actimg from "assets/images/actimg.svg";
import IconDueAmount from "assets/images/duaIcon.svg";

import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AWSpay from "components/AWSpay";
import Locale from "translations";
import OTPInput from "react-otp-input";
import { sendOtp } from "services/marketplace";
import { DueAmountIcon, VisaCardIcon } from "./Icons";
import { useWebBuilderState } from "context/webBuilder";

function NewPaymentAction({
  currency,
  Price,
  actionType,
  formData,
  canPayLater,
  isOpen,
  toggleModal,
  setActionType,
  onPayment,
  OTP,
}) {
  const { companyCountryId } = useWebBuilderState();
  const { payment, inventory } = Locale;

  const [check, setcheck] = useState("online");
  const [otpNumber, setOtpNumber] = useState("");
  const [paymnet, setPaymnet] = useState({});
  const [paymnetOnline, setpaymnetOnline] = useState({});


  const sendMassage = async () => {
    //
    const res = await sendOtp();
    if (res?.status === 200 || res?.status === 201) {
      setActionType("Otp");
    }
  };

  const formatter = new Intl.NumberFormat("en-US");

  //

  const setNewDeduct = (e) => {
    let Paymt = { ...paymnet };
    if (e.target.checked) {
      Paymt.newDua =
        Paymt?.exchange_rate_price +
        (Paymt?.exchange_rate_price - Paymt.amount) * 0.025 -
        Paymt.amount;
      setPaymnet(Paymt);
    } else {
      Paymt.newDua = null;
      setPaymnet(Paymt);
    }
  };


  return (
    <>
      <Modal className="mt-5 " isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader
          className="align-items-center font-weight-bold payment-title hd-title-model text-center"
          toggle={toggleModal}
        >
          {inventory.messages.ChoosePaymentMethod}
        </ModalHeader>
        <ModalBody>
          {actionType === "form-redirect" ? (
            <AWSpay data={formData} />
          ) : actionType === "Otp" ? (
            <>
              <p className="text-center font-weight-bolder h4 mt-3">
                {inventory.messages.enterVerificationCode}
              </p>
              <div
                className="my-3 d-flex flex-column px-3"
                style={{ minHeight: "10vh", width: "100%" }}
              >
                <OTPInput
                  className="w-100"
                  value={otpNumber}
                  onChange={setOtpNumber}
                  numInputs={4}
                  renderSeparator={<span className="mx-2">-</span>}
                  inputStyle={"w-25 input-OTP"}
                  renderInput={(props) => (
                    <input style={{ width: "25%" }} {...props} />
                  )}
                />
                <a
                  className="btn btn-link h4 mt-2"
                  onClick={() => sendMassage()}
                  href={() => false}
                >
                  {inventory.messages.resendCode}
                </a>
              </div>
              <ModalFooter>
                <Button
                  className=" border-0 px-4 "
                  onClick={() => {
                    toggleModal();
                  }}
                  color="danger"
                >
                  {inventory.messages.cancel}
                </Button>

                <Button
                  className="px-5 w-30 btn-pay-prim-disabled"
                  disabled={
                    !(
                      check === "online" ||
                      check === "debit" ||
                      check === "credit" ||
                      check === "pay_later"
                    )
                  }
                  color="primary"
                  onClick={() => {
                    onPayment(
                      check,
                      // paymnet?.currency,
                      currency,
                      otpNumber,
                      paymnet?.exchange_rate_price
                    );
                  }}
                >
                  {inventory.messages.pay}
                </Button>
              </ModalFooter>
            </>
          ) : (
            <div className="boxs-payment px-1">
              {/* <div className="d-flex align-self-end">
								<img className="mx-2" src={iconNotification} alt="" />
								<div>
									<h5 className="">{inventory.messages.Amount}</h5>
									<h3 style={{ fontWeight: "bold" }} className=" text-success">
										{Price} {currency}
									</h3>
								</div>
							</div> */}
              {canPayLater ? (
                <div
                  className={`item-payment  pay-${check === "pay_later" ? "active" : ""
                    } `}
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="raido-label">
                      <input
                        type="radio"
                        onClick={() => {
                          setcheck("pay_later");
                          setPaymnet({
                            currency: currency,
                            price: Price,
                            exchange_rate_price: Price,
                          });
                        }}
                        className="check-payment"
                        id="pay_later"
                        name="check-payment"
                      />
                      <label htmlFor="pay_later">
                        {inventory.messages.ConfirmNowPayLater}{" "}
                      </label>
                    </div>
                    <img
                      src={check === "pay_later" ? actimg : disimg}
                      alt=""
                      srcset=""
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {/* canPayOnline */}
              {true ? (
                <>
                  <div
                    className={`item-payment  pay-${check === "online" ? "active" : ""
                      } `}
                  >
                    <div className="d-flex align-items-center justify-content-between visa-card-label">
                      <div className="raido-label">
                        <input
                          type="radio"
                          onClick={() => {
                            setcheck("online");
                            setPaymnet(paymnetOnline);
                          }}
                          className="check-payment"
                          id="online"
                          name="check-payment"
                          checked={check === "online"}
                        />
                        <label htmlFor="online">
                          {inventory.messages.PayFromCreditCard}
                        </label>
                      </div>

                      <VisaCardIcon/> 
                    </div>
                  </div>
                  {check === "online" &&
                    paymnet.amount > 0 &&
                    paymnet.amount <
                    paymnet?.exchange_rate_price +
                    paymnet?.exchange_rate_price * 0.025 ? (
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-content-center">
                        <input
                          id="DeductWallet"
                          className="mx-2"
                          type="checkbox"
                          name="DeductWallet"
                          onChange={(e) => setNewDeduct(e)}
                        />
                        <label for="DeductWallet" className="m-0 Deduct-title">
                          Deduct from wallet?
                        </label>
                      </div>
                      <div>
                        <p className="your-Balance">
                          Your Balance:
                          <span>
                            {" "}
                            {paymnet.amount} {paymnet.currency}
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : null}

            
              <div className="d-flex justify-content-start my-4 due-amount">
              <DueAmountIcon/>
                <div>
                  <h5 className="title-pay">{inventory.messages.dueAmount}</h5>
                  <h3 style={{ fontWeight: "bold" }} className="payment-text-price">
                    {check === "online" ? paymnet.newDua
                      ?
                      paymnet.newDua.toFixed(2) :

                      paymnet?.exchange_rate_price ? formatter.format(
                        paymnet?.exchange_rate_price +
                        paymnet?.exchange_rate_price * 0.025
                      ) : <>{ (Price +  Price * 0.025).toFixed(2) } {currency}  </>

                      : formatter.format(paymnet?.exchange_rate_price || "")}{""}
                    {paymnet?.currency}
                  </h3>
                  {check === "online"
                    ? <span className='sub-title-pay'>{inventory.messages.AllPaymentsMadeByCredit}</span>
                    : ""}
                </div>
                {check === "pay_later" && currency && Price ? (
                  <>
                    <div className="px-3"></div>
                    <img className="mx-2" src={IconDueAmount} alt="" />
                    <div className="">
                      <h5 className="title-pay">{inventory.messages.Amount}</h5>
                      <h3
                        style={{ fontWeight: "bold" }}
                        className=" text-success"
                      >
                        {formatter.format(Price)} {currency}
                      </h3>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <button
                className="newpay-btn btn"
                disabled={check === ""}
                onClick={() => {
                  if (OTP && check === "debit") {
                    sendMassage();
                  } else {
                    let checked = paymnet.newDua != null ? "split" : check
                    onPayment(
                      checked,
                      // paymnet?.currency,
                      currency = companyCountryId === 62 ? "IDR" : process.env.REACT_APP_ONLINE_CURRENCY,
                      otpNumber,
                      paymnet?.exchange_rate_price
                    );
                  }
                }}
              >
                {" "}
                {payment.messages.pay}
              </button>
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
}

export default NewPaymentAction;
