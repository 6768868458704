import { useState } from 'react';
import { Collapse } from 'reactstrap';
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import { useSBSState } from 'context/global';

export default function FAQTheme7({
  title,
  faqs,
  image,
}) {
  const { locale } = useSBSState();
  const [collapsedItems, setCollapsedItems] = useState([]);
  return (

    <section className="theme_faq_section" style={{ backgroundImage: `url(${image})` }}>
      <div className="col-xl-6 col-lg-7 col-md-8">
        <div className="theme_faq_card">

          <h3 className="mb-3 font-weight-bold h4">{title}</h3>
          {faqs &&
            faqs?.map((que, index) => {
              const isOpen = collapsedItems.includes(index);
              const openCollapse = () => {
                const updated = [...collapsedItems, index];
                setCollapsedItems(updated);
              };
              const closeCollapse = () =>
                setCollapsedItems(
                  collapsedItems.filter((i) => i !== index)
                );
              return (
                <div className="faqs-question-wrapper" key={que.id}>

                  <div className="faqs-container-question">
                    <span> {que.question[locale]} </span>
                    {isOpen ? (
                      <button
                        className="bg-transparent border-0 faq-btn"
                        onClick={closeCollapse}
                      >
                        <MinusIcon />
                      </button>
                    ) : (
                      <button
                        className="bg-transparent border-0 faq-btn"
                        onClick={openCollapse}
                      >
                        <PlusIcon />
                      </button>
                    )}
                  </div>
                  <Collapse isOpen={isOpen}>
                    <p>{que.body[locale]}</p>
                  </Collapse>
                </div>
              )
            })}
        </div>
      </div>

    </section>

  )
}