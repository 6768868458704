import reservationError from "assets/images/inventory/error.png";
import { useSBSDispatch, useSBSState } from "context/global";
import { Modal, ModalBody } from "reactstrap";
import Locale from "translations";

export default function ErrorModal() {
	const { isError, ErrorMassage } = useSBSState();
	const { commons, inventory } = Locale;

	const dispatch = useSBSDispatch();

	const closeModal = () => {
		dispatch({ type: "setError", payload: false });
	};

	return (
		<>
			<Modal size="md" className="mt-10	" isOpen={isError}>
				<div className="p-3 border-bottom d-flex justify-content-between align-items-center">
					{window.location.href.indexOf("/Hotels/inventory/") > -1 ? (
						<p className="error-model-title ">
							{inventory.messages.EditReservation}
						</p>
					) : (
						<p className="error-model-title ">
							{ErrorMassage.title
								? ErrorMassage.title
								: commons.somethingWentWrong}
						</p>
					)}
					<i className="fas fa-times pointer" onClick={closeModal}></i>
				</div>

				<ModalBody className="payModal">
					<div
						className="container"
						style={{ height: "250px", overflow: "auto" }}
					>
						<div className="text-center" role="alert">
							<img src={reservationError} alt="" />
							<p className="m-0 mt-3 h5 text-gray py-4">
								{ErrorMassage.body
									? ErrorMassage.body
									: commons.somethingWentWrong}
							</p>
						</div>

						<div className="box">
							<p className="title-modal-sm"></p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
