import * as actionTypes from "./actions";

//Define InitialState
const initialState = {
	name: null,
	idNo: null,
	phone: null,
	businessAddress: null,
	mobile1: null,
	mobile2: null,
	discount: null,
	additionalDiscount: null,
	amount: null,
	amountDate: null,
	comment: null,

	passportNo: null,
	nationalId: null,
	firstNameEn: null,
	fatherNameEn: null,
	grandFatherNameEn: null,
	lastNameEn: null,
	national: null,
	gender: null,
	issuedDate: null,
	expiryDate: null,
	jobTitle: null,
	address: null,
	relationMuharram: null,
	muharramName: null,
	firstNameAr: null,
	fatherNameAr: null,
	grandFatherNameAr: null,
	lastNameAr: null,
	visaNo: null,
	visaType: null,
	consular: null,
	passportPhoto: null,
};

// Define Reservation Reducer
export default function reservation_reducer(state = initialState, action) {
	switch (action.type) {
		//Reservation: Reservation Reducer
		case actionTypes.Reservation_App_Owner:
			return {
				...state,
				name: action.name,
				idNo: action.idNo,
				businessAddress: action.businessAddress,
				phone: action.phone,
				mobile1: action.mobile1,
				mobile2: action.mobile2,
			};
		case actionTypes.Reservation_Accounting:
			return {
				...state,
				discount: action.discount,
				additionalDiscount: action.additionalDiscount,
				amount: action.amount,
				amountDate: action.amountDate,
				comment: action.comment,
			};
		case actionTypes.Reservation_Details:
			return {
				...state,
				passportNo: action.passportNo,
				nationalId: action.nationalId,
				firstNameEn: action.firstNameEn,
				fatherNameEn: action.fatherNameEn,
				grandFatherNameEn: action.grandFatherNameEn,
				lastNameEn: action.lastNameEn,
				national: action.national,
				gender: action.gender,
				issuedDate: action.issuedDate,
				expiryDate: action.expiryDate,
				jobTitle: action.jobTitle,
				address: action.address,
				relationMuharram: action.relationMuharram,
				muharramName: action.muharramName,
				firstNameAr: action.firstNameAr,
				fatherNameAr: action.fatherNameAr,
				grandFatherNameAr: action.grandFatherNameAr,
				lastNameAr: action.lastNameAr,
				visaNo: action.visaNo,
				visaType: action.visaType,
				consular: action.consular,
				passportPhoto: action.passportPhoto,
			};
		default:
			return state;
	}
}
