import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import SelectField from 'components/Form/SelectField/SelectField'
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import moment from 'moment';
import React, { useState } from 'react'
import Locale from 'translations';

export default function TourSearchAvailability({ searchData, modify, handleModifySearch }) {
  const { marketPlace, inventory, visa } = Locale;
  const { toursSearch } = useMarketplaceState();
  const dispatch = useMarketplaceDispatch();
  
  const [tourAvailabilityData, setTourAvailabilityData] = useState({
    date_from: moment(searchData?.date_from),
    date_to: moment(searchData?.date_to),
    adults: searchData?.adults,
    childs: searchData?.childs,
    childs_ages: searchData?.childs_ages
  });




  const adultsLookup = [...Array(20).keys()].map(count => {
    return {
      id: count + 1,
      value: count + 1,
      label: (count + 1).toString(),
      name: (count + 1).toString()
    }
  })
  const childsLookup = [...Array(20 + 1).keys()].map(count => {
    return {
      id: count,
      value: count,
      label: (count).toString(),
      name: (count).toString()
    }
  })

  const childsAgesLookup = [...Array(12 + 1).keys()].map(age => {
    return {
      id: age,
      value: age,
      label: (age).toString(),
      name: (age).toString()
    }
  });

  function handleChildsCount(e) {
    const currentChildsCount = tourAvailabilityData?.childs_ages?.length;
    let newChildsAges = tourAvailabilityData?.childs_ages;
    let addedRemovedChildsCount = 0;
    // check if we increase
    if (e.value > currentChildsCount) {
      addedRemovedChildsCount = e.value - currentChildsCount;
      newChildsAges = [...tourAvailabilityData?.childs_ages, ...[...Array(+addedRemovedChildsCount).keys()].map(() => {
        return {
          id: 1,
          value: 1,
          label: "1",
          name: "1"
        }
      })];
    } else if (e.value < currentChildsCount) {
      addedRemovedChildsCount = -(currentChildsCount - e.value)
      newChildsAges = newChildsAges.slice(0, addedRemovedChildsCount)
    }
    setTourAvailabilityData({
      ...tourAvailabilityData,
      childs: e,
      childs_ages: newChildsAges,
    });
  }

  function handleChildAge(e, ageIndex) {
    const ages = [...tourAvailabilityData?.childs_ages];
    ages[ageIndex] = e;
    setTourAvailabilityData({ ...tourAvailabilityData, childs_ages: ages })
  }
  // update tour serach context
  function handleUpdateTourSearch() {
    if (modify) {
      handleModifySearch && handleModifySearch(tourAvailabilityData);
    }
    dispatch({
      type: "saveToursSearch",
      payload: { ...toursSearch, ...tourAvailabilityData }
    });
  }

  return (
    <div className='tour-details-searchavailibality'>
      <h3 className='mb-2 font-weight-bold'>{marketPlace.searchAvailability}</h3>
      <div className='row'>
        {/* date from */}
        <div className="col-md-4 col-6">
          <DatePickerField
            label={marketPlace.From}
            id="date_from"
            name="date_from"
            placeholder="DD/MM/YYYY"
            date={tourAvailabilityData?.date_from}
            onChangeDate={(date) => {
              setTourAvailabilityData({
                ...tourAvailabilityData,
                date_from: date,
                date_to: moment(date) >= moment(tourAvailabilityData?.date_to) ? null : tourAvailabilityData?.date_to,
              });
            }}
            isOutsideRange={(day) =>
              !day.isAfter(moment(new Date()).add(0, "d"), "day")
            }
          />
        </div>
        {/* date to */}
        <div className="col-md-4 col-6">
          <DatePickerField
            label={marketPlace.To}
            id="date_to"
            name="date_to"
            placeholder="DD/MM/YYYY"
            date={tourAvailabilityData?.date_to}
            onChangeDate={(date) => {
              setTourAvailabilityData({
                ...tourAvailabilityData,
                date_to: date,
                date_from: moment(date) <= moment(tourAvailabilityData?.date_from) ? null : tourAvailabilityData?.date_from,
              });
            }}
            isOutsideRange={(day) =>
              !day.isAfter(moment(tourAvailabilityData?.date_from).add(0, "d"), "day")
            }
          />
        </div>
        {/* adults */}
        <div className="col-md-2 col-6">
          <SelectField
            label={inventory.messages.adults}
            id="adults"
            name="adults"
            haslabel={true}
            options={adultsLookup}
            value={tourAvailabilityData?.adults?.label}
            onChange={(e) => {
              setTourAvailabilityData({
                ...tourAvailabilityData,
                adults: e,
              });
            }}
          />
        </div>
        {/* childrens */}
        <div className="col-md-2 col-6">
          <div className='d-flex align-items-center'>
            <label htmlFor="children" className='m-0'>{inventory.messages.children}</label>
            <i className="fas fa-info-circle new-color mx-1" title={marketPlace.messages.childrenAge} />
          </div>
          <SelectField
            id="children"
            name="children"
            haslabel={false}
            options={childsLookup}
            value={tourAvailabilityData?.childs?.label}
            onChange={(e) => handleChildsCount(e)}
          />
        </div>
        {/* children ages */}

        <div className="col-12">
          {tourAvailabilityData?.childs_ages?.length > 0 ? <label htmlFor="" className='d-block m-0 w-100'>{marketPlace.ChildrenAges}</label> : null}
          <div className="d-flex flex-wrap align-items-center">
            {tourAvailabilityData?.childs_ages?.map((age, index) => {
              return (
                <div className="col-2" key={index}>
                  <SelectField
                    haslabel={false}
                    options={childsAgesLookup}
                    value={age?.label}
                    onChange={(e) => handleChildAge(e, index)}
                  />
                </div>
              )
            })}
            {/* search button */}
            <div className="col-4">
              <button className='btn w-100 bg-nxt py-2'
                onClick={handleUpdateTourSearch}
              >
                {visa.search}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
