import moment from 'moment'
import React, { useMemo } from 'react'
import { calcCategoryPrice } from '../helpers/calcSelectedCategory';
import Locale from 'translations';
// import TourDetailsSummary from '../Details/Summary';

export default function BookingSummaryItem({ cartItem, toursCart, index }) {
  const { inventory } = Locale;
  const modalities = cartItem?.details?.modalities[0];
  const currency = cartItem?.details?.currency;
  const childsAges = cartItem?.children;


  // calc selected category price for adults and childs passed as prop in summary and dates
  const tourSummary = useMemo(() => {
    const currentCategory = { category: modalities, rate: modalities?.rates?.[0]?.rateDetails?.[0] }
    return calcCategoryPrice(cartItem?.noOfAdults, cartItem?.children, currentCategory);
  }, [cartItem?.children, cartItem?.noOfAdults, modalities]);


  const adultPrice = tourSummary?.adult?.amount;
  const totalAdultPrice = tourSummary?.adult?.totalAmount;
  const childrenAges = { min: tourSummary?.child?.minAge, max: tourSummary?.child?.maxAge || tourSummary?.freeChild?.maxAge };
  const totalChildsAsAdult = tourSummary?.childAsAdult?.totalAmount;
  const childPrice = tourSummary?.child?.amount;
  const totalChildPrice = tourSummary?.child?.totalAmount;
  const freeChildrenAges = { min: tourSummary?.freeChild?.minAge, max: tourSummary?.freeChild?.maxAge }
  const freeChildPrice = tourSummary?.freeChild?.amount;

  const childAsAdult = childsAges?.filter(age => age >= tourSummary?.adult?.minAge);
  const childsAboveAge = childsAges?.filter(age => age >= childrenAges.min && age <= childrenAges.max);
  const childsFreeCount = childsAges?.filter(age => age >= freeChildrenAges.min && age <= freeChildrenAges.max);



  return (
    <div>
      <div className='d-flex justify-content-between align-items-baseline' >
        <div className='d-flex flex-column col-12'>

          <div className='d-flex justify-content-between align-items-baseline mb-2'>
            <h3 className='font-weight-bold col-8 p-0'>{cartItem?.details?.name}</h3>
            <span className='font-weight-bold text-center'> {(modalities?.rates[0]?.rateDetails[0]?.totalAmount?.amount?.toFixed(2))} {currency}</span>
          </div>

          <div>
            <i className="fas fa-map-marker-alt text-yellow"></i>
            <span className='mx-1'>
              {cartItem?.details?.destinations?.map((city) => {
                return <span key={city?.code}>{city?.name} ({city?.code}), </span>
              })}
            </span>
          </div>

          <div>
            <i className="far fa-calendar-alt text-yellow"></i>
            <span className='mx-1'>{moment(cartItem?.date_from).format("DD/MM/YYYY")} ({moment(cartItem?.date_from).format("dddd")})</span>
          </div>

          {/* adults */}
          <div className='d-flex justify-content-between align-items-center flex-wrap mb-1'>
            <div>
              <i className="far fa-user text-yellow"></i>
              <span className='mx-1'>
                {inventory.messages.adults} {" "}
                (x{cartItem?.noOfAdults}) {" "}
                {`(${tourSummary?.amountUnitTypePax ? adultPrice : "0.00"} ${currency})`}
              </span>
            </div>
            <span className='font-weight-bold'>{tourSummary?.amountUnitTypePax && `${totalAdultPrice} ${currency}`}</span>
          </div>

          {childsFreeCount?.length ?
            <div className='d-flex justify-content-between align-items-center flex-wrap mb-1'>
              <p>
                <i className="far fa-user text-yellow"></i>
                <span className='mx-1'>
                  {inventory.messages.children} {" "}
                  {freeChildrenAges.min}-{freeChildrenAges.max} {" "}
                  (x{childsFreeCount?.length}) {" "}
                  {`(${tourSummary?.amountUnitTypePax ? freeChildPrice : "0.00"} ${currency})`}
                </span>
              </p>
              <span className='font-weight-bold'>{tourSummary?.amountUnitTypePax && `${freeChildPrice} ${currency}`}</span>
            </div>
            :
            null
          }

          {childsAboveAge?.length > 0 ?
            <div className='d-flex justify-content-between align-items-center flex-wrap mb-1'>
              <p>
                <i className="far fa-user text-yellow"></i>
                <span className='mx-1'>
                  {inventory.messages.children} {" "}
                  {childrenAges.min}-{childrenAges.max} {" "}
                  (x{childsAboveAge?.length}) {" "}
                  {`(${tourSummary?.amountUnitTypePax ? childPrice : "0.00"} ${currency})`}
                </span>
              </p>
              <span className='font-weight-bold'>{tourSummary?.amountUnitTypePax && `${totalChildPrice} ${currency}`}</span>
            </div>
            :
            null
          }

          {childAsAdult?.length > 0 ?
            <div className='d-flex justify-content-between align-items-center flex-wrap mb-1'>
              <p>
                <i className="far fa-user text-yellow"></i>
                <span className='mx-1'>
                  {inventory.messages.children} +{childrenAges.max || +tourSummary?.adult?.minAge > 0 ? +tourSummary?.adult?.minAge - 1 : tourSummary?.adult?.minAge} {" "}
                  (x{childAsAdult?.length}) {" "}
                  {`(${tourSummary?.amountUnitTypePax ? adultPrice : "0.00"} ${currency})`}
                </span>
              </p>
              <span className='font-weight-bold'>{tourSummary?.amountUnitTypePax && `${totalChildsAsAdult} ${currency}`}</span>

            </div>
            :
            null
          }


        </div>

        {/* <span className='font-weight-bold'> ({(modalities?.rates[0]?.rateDetails[0]?.totalAmount?.amount)} {currency})</span> */}
        {/* <span className='font-weight-bold'> ({(modalities?.rates[0]?.rateDetails[0]?.totalAmount?.amount)} {currency})</span> */}
      </div>

      {toursCart?.cartItems?.length - 1 !== index ? <hr /> : null}
    </div >
  )
}
