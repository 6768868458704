import React from "react";
import { useWebBuilderState } from "context/webBuilder";
import ServiceCardThemeTen from './ServiceCard';

export default function OurServicesThemeTen() {
	const { ourServices, selectedLanguage } = useWebBuilderState();

	let services = Array.isArray(ourServices?.services)
		? ourServices?.services
		: [];


	services = services?.filter((service) => service.icon && service.visible)

	return (
		<section className="wb-our-service" id="our-service">
			<div className="custom-container">
				<div className="service-cards-container">
					{services.map((service, index) => (
						<ServiceCardThemeTen
							type={service?.type}
							header={service?.header[selectedLanguage]}
							body={service?.body[selectedLanguage]}
							key={service?.type + index}
						/>
					))}
				</div>
			</div>
		</section>
	);
}
