import React from 'react'
import Locale from 'translations';

export default function ToursListTopbar({ toursCount, filters, setFilters }) {
  const { marketPlace } = Locale;
  
  function handleSort(sort_by, sort_type) {
    
    if (filters.sortBy === sort_by && filters.sortType === "asc") {
      sort_type = "desc";
    } else if (filters.sortBy === sort_by && filters.sortType === "desc") {
      sort_type = "asc";
    } else {
      sort_type = "desc";
    }
    setFilters({ ...filters, sortBy: sort_by, sortType: sort_type  });
  }
  return (
    <div className="tours-topbar-container d-flex flex-wrap justify-content-between">
      <div>
        <h6 className="text-uppercase text-dark-blue font-weight-bold">
          {`${marketPlace.messages.found} ${toursCount || 0} ${marketPlace.activaties}`}
        </h6>
        <span className="">
          {marketPlace.messages.mathingSearch}
        </span>
      </div>

      <div className="sort-filter d-flex align-items-center mt-md-0 mt-2">
        <span>{marketPlace.messages.sortBy} :</span>

        {/* price */}
        <button type='button' className="toggle-sort btn"
          onClick={(e)=> {
            handleSort("price", "asc")
          }}
        >
          <i className={`${filters?.sortBy === "price" && filters?.sortType === "asc" ? "fa fa-sort-amount-up" : "fa fa-sort-amount-down"}  mx-1`}></i>
          {marketPlace.messages.price}
        </button>


        {/* alphabetical */}
        <button type='button' className="toggle-sort btn" 
          onClick={(e) => {
            handleSort("alphabetical", "asc")
          }}
        >
          <i className={`${filters?.sortBy === "alphabetical" && filters?.sortType === "asc" ? "fa fa-sort-amount-up" : "fa fa-sort-amount-down"}  mx-1`}></i>
          {marketPlace.messages.name}
        </button>
      </div>
    </div>
  )
}
