import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function CustomModal({ isOpen, toggle, header, children, ...restProps }) {
	return (
		<Modal {...restProps} onClick={(e)=> e.stopPropagation()} isOpen={isOpen} centered className="web-builder-custom-modal">
			<ModalHeader toggle={toggle} className='web-builder-custom-modal-title'>{header}</ModalHeader>
			<ModalBody>{children}</ModalBody>
		</Modal>
	);
}

export default CustomModal;
