import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import PackageCard from "./PackageCard";
import Carousel from "react-multi-carousel";
import OurPackagesWithImage from "./PackagesWithImage";
import { useSBSState } from "context/global";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import OurPackagesThemeFour from "./theme4";
import { useWebBuilderState } from "context/webBuilder";
import OurPackagesThemeSeven from "./theme7";
import OurPackagesThemeFive from "./theme5";
import OurPackagesThemeTen from "./theme10";

function OurPackages({ details }) {
  const { locale } = useSBSState();
  const { style } = useWebBuilderState();
  const { title, body, items, hasSlider } = details;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1.5,
    },
  };

  const showArrows =
    items?.filter((item) => item?.product_uuid).length >
    responsive.desktop.items;


  return (
    <>
      {items?.filter((item) => item && item?.product_uuid)?.length > 0 ? (
        hasSlider ? (
          <div
            className="web-builder-content-our-packages-with-slider custom-container"
            id="packages-section"
          >
            <div className="our-packages-header">
              <ServicesContent header={title} body={body} />
              <button className="btn ">View All Package</button>
            </div>

            {items.length > 0 && (
              <div className="all-web-builder-our-service-carousel">
                <Carousel
                  responsive={responsive}
                  itemClass={"service-slide"}
                  className="web-builder-our-service-carousel"
                  arrows={false}
                  slidesToSlide={1}
                  keyBoardControl={true}
                  customButtonGroup={showArrows ? <ButtonGroup /> : null}
                  renderButtonGroupOutside={true}
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  infinite={true}
                  rtl={locale === "ar"}
                >
                  {items
                    ?.filter((item) => item?.product_uuid)
                    .map((props, index) => (
                      <PackageCard {...props} key={props.id} id={props.id} />
                    ))}
                </Carousel>

                <button className="btn btn-mobile ">View All Package</button>
              </div>
            )}
          </div>
        ) : style.theme === "theme-4" ? (
          <OurPackagesThemeFour
            details={details}
          />
        ) : style.theme === "theme-5" ? (
          <OurPackagesThemeFive
            details={details}
          />
        ) : style.theme === "theme-7" ? (
          <OurPackagesThemeSeven
            details={details}
          />
        ) : style.theme === "theme-10" ? (
          <OurPackagesThemeTen
            details={details}
          />
        ) : (
          <OurPackagesWithImage
            details={details}
          />
        )
      ) : null}
    </>
  );
}

export default OurPackages;
