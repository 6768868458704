import React from "react";
import { useWebBuilderState } from "context/webBuilder";
import ServiceCardThemeSeven from "./ServiceCard";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import { useSBSState } from "context/global";


export default function OurServicesThemeSeven() {
  const { ourServices } = useWebBuilderState();
  const { locale } = useSBSState();

  let services = Array.isArray(ourServices?.services)
    ? ourServices?.services
    : [];
  services = services?.filter((service) => service.icon && service.visible);

  return (
    <section className="theme_inner_wrapper" id="our-service">
      <div className="theme_services_section custom-container">
        <div className="section_wrapper">


          <ServicesContent
            header={ourServices?.title[locale]}
            body={ourServices?.body[locale]}
          />
          
          <div className="service_cards_container">
            {services.filter(service => service?.visible === true).map((service, index) => {
              return (
                <ServiceCardThemeSeven
                  type={service?.type}
                  image={service?.icon}
                  header={service?.header[locale]}
                  key={service?.type + index}
                />
              )
            })}

          </div>
        </div>
      </div>
    </section>
  );
}
