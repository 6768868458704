import walletImgSrc from "assets/images/inventory/walletSm.svg";
import Locale from "translations";

export default function TransferBookingSummary({ Details }) {
	const { marketPlace, inventory } = Locale;

	const items = Details?.cartItems;

	const confirmPickupUrl = items && items.find((i) => i.confirm_pickup_url);
	return (
		<div className="col-md-4 mt-2 mt-md-0">
			<div className="transfer-booking-details-header">
				<h2>{marketPlace.messages.bookingSummary}</h2>
			</div>
			{/* cart items */}
			<div className="p-2 mt-3 border-1-gray bg-white">
				{items && items?.length > 0
					? items.map((item, index) => {
							return item.status !== "CANCELED" ? (
								<>
									<div className="d-flex transfer-booking-summary-item">
										<div className="col-md-8">
											<p className="font-weight-bold">
												{item?.type} {item?.category} {item?.vehicle}
											</p>
											<div className="text-muted">
												<p>
													{marketPlace.From}: {item?.from_name}
												</p>
												<p>
													{marketPlace.To}: {item?.to_name}
												</p>
											</div>
										</div>
										<span className="col-md-4 font-weight-bold text-center">
											{item?.price} {item?.currency}
										</span>
									</div>
									{index !== items?.length - 1 ? <hr /> : null}
								</>
							) : null;
					  })
					: null}
			</div>
			{confirmPickupUrl && (
				<div className="d-flex transfer-booking-summary-item my-2">
					<h6 className="alert alert-info" role="alert">
						{marketPlace.confirmPickup}
					</h6>
				</div>
			)}

			{/* total price  */}
			<div
				className={`transfer-booking-totalprice mt-2 justify-content-endπ`}
			>
				<div className="d-flex">
					<img src={walletImgSrc} alt="wallet" className="img-fluid" />
					<p className="d-flex flex-column mx-2 ">
						<span className="text-dark-blue">
							{inventory.messages.ReservationTotalCost}{" "}
						</span>
						<span className="text-success font-weight-bold h5">
							{Details?.price ?? Details?.total_amount} {Details?.currency}
						</span>
					</p>
				</div>
			</div>
		</div>
	);
}
