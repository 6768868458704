import axios from "axios";
import { cleanEmpty } from "./general";
const lookupsApiUri = process.env.REACT_APP_LOOKUPS_ENDPOINT;
const builderApiUri = process.env.REACT_APP_PRODUCTS_BUILDER;
const marketPlaceURL = process.env.REACT_APP_API_URL + "/v1/marketplace";
const marketPackagesURL = process.env.REACT_APP_API_URL + "/v1/market-packages";
export const fetchCountries = async () => {
  return await axios
    .get(`${lookupsApiUri}/geolocation/countries`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchCities = async (countryID) => {
  if (countryID !== 0) {
    return await axios
      .get(`${lookupsApiUri}/geolocation/cities?country_id=${countryID}`)
      .then((res) => res.data.data)
      .catch((err) => err.response);
  }
};

export const fetchHotelsAll = async (city_id) => {
  if (city_id !== 0) {
    return await axios
      .get(`${marketPlaceURL}/lookups/supplier-hotels?city_id=${city_id}`)
      .then((res) => res.data)
      .catch((err) => err.response);
  }
};

export const fetchTransferHotels = async (data) => {
  return await axios
    .post(`${marketPlaceURL}/transfers/lookups/hotels`, cleanEmpty(data))
    .then((res) => res.data)
    .catch((err) => err.response);
};
export const fetchTransferPorts = async (data) => {
  return await axios
    .post(`${marketPlaceURL}/transfers/lookups/ports`, cleanEmpty(data))
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const fetchTransferHotelsPorts = async (data) => {
  return await axios
    .get(`${marketPlaceURL}/transfers/lookups/pickup-points`, {
      params: cleanEmpty(data),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const fetchAreasa = async (cityID) => {
  return await axios
    .get(
      `https://safavisa.us9.list-manage.com/subscribe/post-json?u=f7d4cf694e94eb8b1e3f44558&amp;id=b68206dead&amp;f_id=00e2d3e1f0&EMAIL=owaild%40yahoo.com&c=__jp4`
    )
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchAreas = async (cityID) => {
  return await axios
    .get(`${lookupsApiUri}/geolocation/areas?city_id=${cityID}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchPorts = async (id, type) => {
  return await axios
    .get(
      `${builderApiUri}/ports_with_halls?country_id=${id}&transportation_type_id=${type}`
    )
    .then((res) => res.data.data)
    .catch((err) => err.response);
};
export const fetchLookups = async () => {
  return await axios
    .get(`${builderApiUri}/lookups_common_product_builder`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchVehicleTypes = async () => {
  return await axios
    .get(`${builderApiUri}/vehicle_types`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchHotels = async (id) => {
  return await axios
    .get(`${builderApiUri}/hotels?area_id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchTransporters = async (id, name, country_id) => {
  return await axios
    .get(
      `${builderApiUri}/transporters?transportation_type_id=${id}&${
        name ? `name=${name}` : ""
      }&${country_id ? `country_id=${country_id}` : ""}`
    )
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchSightseeings = async (data) => {
  return await axios
    .get(`${builderApiUri}/sightseeings`, data)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchAllCurrencies = async (data) => {
  return await axios
    .get(`${marketPlaceURL}/lookups/get_all_currencies`, data)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const getAllSpecialRequests = async () => {
  return await axios
    .get(`${marketPlaceURL}/lookups/get_all_special_requests`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const fetchPackagesMonth = async (countryID) => {
  if (countryID !== 0) {
    return await axios
      .get(`${marketPackagesURL}/filters?country_id=${countryID}`)
      .then((res) => res.data)
      .catch((err) => err.response);
  }
};

export const fetchBusTypes = async () => {
  return await axios
    .get(`${lookupsApiUri}/trip-bus-types`)
    .then((res) => res)
    .catch((err) => err.response);
};
