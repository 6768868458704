import React, { useCallback } from "react";
import Hero from "./Hero";
import OurServices from "./OurServices";
import AboutUs from "./AboutUs";
import OurPackages from "./OurPackages";
import HotelService from "./HotelService";
import ToursService from "./ToursService";
import { useWebBuilderState } from "context/webBuilder";
import TransferService from "./Transfer";
import FlightsService from "./Flights";

// import Footer from "./Footer";
import ContactUs from './ContactUs';
import FAQs from './FAQs';
import { useSBSState } from "context/global";

function Content() {
  const { locale } = useSBSState();
  const { content, ourServices, style } = useWebBuilderState();

  const isServiceVisible = (type) => {
    const services = ourServices?.services;
    const index = services?.findIndex((service) => service.type === type);
    if (index === -1) {
      return true;
    } else {
      return services?.[index]?.visible;
    }
  };
  


  const bodyElements = {
    aboutUs: { component: AboutUs, acceptTypes: [] },
    contactUs: { component: ContactUs, acceptTypes: [] },
    packages: { component: OurPackages, acceptTypes: [] },
    hotels: { component: HotelService, acceptTypes: [] },
    flights: { component: FlightsService, acceptTypes: [] },
    transfer: { component: TransferService, acceptTypes: [] },
    tours: { component: ToursService, acceptTypes: [] },
    faq: { component: FAQs, acceptTypes: [] }
  };


  const renderComponent = useCallback(
    (element, index) => {
      const type = element.type;
      const selectedElement = bodyElements[type];
      const Component = selectedElement?.component;
      const isActive = isServiceVisible(type);

      return Component && isActive ? (
        <Component
          key={element.id}
          details={{ ...element, title: element?.title?.[locale], body: element?.body?.[locale], index }}
          id={element.id}
        />
      ) : null;
    },
    [bodyElements]
  );


  return (
    <>
      {content &&
        <div className={`web-builder-content p-0 ${style.theme}`}>
          <Hero />
          <OurServices />

          {content?.map((element, index) => renderComponent(element, index))}
          {/* <Footer /> */}
        </div>
      }
    </>

  );
}

export default Content;
