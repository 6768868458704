import React, { useState } from "react";
import { ArrowBottom, ArrowUp, RotateArrow } from "../Icons";
import { Collapse } from "reactstrap";
import { useSBSState } from "context/global";
import TimePickerField from "components/Form/TimePickerField/TimePickerField";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
import Locale from "translations";

export default function Filter({ filtersLookup, filters, setFilters, toggleOpenFilter }) {
  const { webBuilder } = Locale;
  const [FiltersIsOpen, setFiltersIsOpen] = useState({
    stops: true,
    airline: true,
    flightTimes: true,
    duration: true,
  });
  const { locale } = useSBSState();

  function filterByCheckbox(e, obj, filterName, transfer) {
    const checked = e.target.checked;
    let checkedArray = filters?.[filterName];
    if (checked) {
      checkedArray = [...filters[filterName], transfer ? obj : obj?.code];
    } else {
      if (transfer) {
        checkedArray = checkedArray.filter((c) => c !== obj);
      } else {
        checkedArray = checkedArray.filter(
          (c) => c.toString() !== obj?.code.toString()
        );
      }
    }
    setFilters({ ...filters, [filterName]: checkedArray, page: 1 });
  }

  function reset() {
    setFilters({
      transferCount: [],
      stops: [],
      airLines: [],
      departureTime: {},
      arrivalTime: {},
      durationTime: {},
      sortBy: "price",
      sortType: "desc",
      page: 1,
    });
  }
  const formatStops = {
    0: "Non",
    1: "One",
    2: "Two",
    3: "Three",
    4: "Four",
    5: "Five",
  }
  return (
    <div className="flight-filter">
      <button className="btn close-filter-btn p-0" onClick={toggleOpenFilter}>
        <CloseIcon />
      </button>
      <div className="title">
        <p>{webBuilder.filterYourResults}</p>
        <button className="reset" onClick={reset}>
          <RotateArrow /> {webBuilder.reset}
        </button>
      </div>

      <div className="stops">
        <div className="title">
          <h6>{webBuilder.stops}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({ ...prev, stops: !prev.stops }))
            }
          >
            {FiltersIsOpen.stops ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.stops}>
          <div className="items">
            {filtersLookup?.transferCount?.map((transfer) => (

              <div className="airlines-checkbox" key={transfer}>
                <div className="custom-control custom-checkbox ">
                  <input
                    className="custom-control-input permChecks"
                    id={"stop" + transfer}
                    name="stop"
                    type="checkbox"
                    value={transfer}
                    onChange={(e) => {
                      filterByCheckbox(e, transfer, "transferCount", true);
                    }}
                    checked={filters?.transferCount?.includes(transfer)}
                  />
                  <label className="custom-control-label" data htmlFor={"stop" + transfer}>
                    {formatStops[transfer]} Stop
                  </label>
                </div>
              </div>
            ))}

            {/* <div className="airlines-checkbox">
              <div className="custom-control custom-checkbox ">
                <input
                  className="custom-control-input permChecks"
                  id={`non-stop`}
                  name="stop"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor={`non-stop`}>
                  Non Stop
                </label>
              </div>
            </div>
            <div className="airlines-checkbox">
              <div className="custom-control custom-checkbox ">
                <input
                  className="custom-control-input permChecks"
                  id={`one-stop`}
                  name="stop"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor={`one-stop`}>
                  One Stop
                </label>
              </div>
            </div>
            <div className="airlines-checkbox">
              <div className="custom-control custom-checkbox ">
                <input
                  className="custom-control-input permChecks"
                  id={`two-stop`}
                  name="stop"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor={`two-stop`}>
                  Two Stop
                </label>
              </div>
            </div> */}
          </div>
        </Collapse>
      </div>
      <div className="stops flight-times">
        <div className="title">
          <h6>{webBuilder.flightTimes}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                flightTimes: !prev.flightTimes,
              }))
            }
          >
            {FiltersIsOpen.flightTimes ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.flightTimes}>
          <div className="times-item">
            <div className="trips">
              <p>{webBuilder.departureTrip}</p>
              <p>12:00 AM - 12:00 PM</p>
            </div>
            {/* <RangeSlider
              labels={[Math.floor(1), Math.floor(24)]}
              min={1}
              max={24}
              lab
              step="1"
              range
              ruler={false}
            /> */}
            <div className="d-flex align-items-center">
              {/* price range from */}
              <div>
                <TimePickerField
                  // label={marketPlace.returnDate}
                  haslabel={false}
                  date={filters?.departureTime?.from}
                  placeholder="00:00"
                  onChangeDate={(date) => {
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      departureTime: {
                        ...prev.departureTime,
                        from: date,
                      },
                    }));
                  }}
                />
              </div>
              <span className="mx-2 text-uppercase mt-1">To</span>
              <div>
                <TimePickerField
                  // label={marketPlace.returnDate}
                  haslabel={false}
                  date={filters?.departureTime?.to}
                  placeholder="00:00"
                  onChangeDate={(date) => {
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      departureTime: {
                        ...prev.departureTime,
                        to: date,
                      },
                    }));
                  }}
                />
              </div>
              {/* price range to */}
            </div>
          </div>




          <div className="times-item mt-5">
            <div className="trips">
              <p>{webBuilder.returnTrip}</p>
              <p>12:00 AM - 12:00 PM</p>
            </div>
            {/* <RangeSlider
              labels={[Math.floor(10), Math.floor(120)]}
              min={Math.floor(1)}
              max={Math.floor(120)}
              lab
              step="10"
              ruler={false}
            /> */}
            <div className="d-flex align-items-center">
              {/* price range from */}
              <div>
                <TimePickerField
                  // label={marketPlace.returnDate}
                  haslabel={false}
                  date={filters?.arrivalTime?.from}
                  placeholder="00:00"
                  onChangeDate={(date) => {
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      arrivalTime: {
                        ...prev.arrivalTime,
                        from: date,
                      },
                    }));
                  }}
                />
              </div>
              <span className="mx-2 text-uppercase mt-1">To</span>
              <div>
                <TimePickerField
                  // label={marketPlace.returnDate}
                  haslabel={false}
                  date={filters?.arrivalTime?.to}
                  placeholder="00:00"
                  onChangeDate={(date) => {
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      arrivalTime: {
                        ...prev.arrivalTime,
                        to: date,
                      },
                    }));
                  }}
                />
              </div>
              {/* price range to */}
            </div>
          </div>
        </Collapse>
      </div>
      <div className="stops flight-times">
        <div className="title">
          <h6>{webBuilder.durations}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                duration: !prev.duration,
              }))
            }
          >
            {FiltersIsOpen.duration ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.duration}>
          <div className="times-item">
            <div className="trips">
              <p>{webBuilder.durationTrip}</p>
              <p>12:00 AM - 12:00 PM</p>
            </div>
            {/* <RangeSlider
              labels={[Math.floor(10), Math.floor(120)]}
              min={Math.floor(1)}
              max={Math.floor(120)}
              lab
              step="10"
              // onChange={(e) => {
              //   if (
              //     filter?.price?.min !== e.minValue ||
              //     filter?.price?.max !== e.maxValue
              //   ) {
              //     setFilter({
              //       ...filter,
              //       price: { min: e.minValue, max: e.maxValue },
              //     });
              //   }
              // }}
              // maxValue={Math.floor(
              //   filter?.price?.max ? filter?.price?.max : lookup?.price?.max
              // )}
              // minValue={Math.floor(
              //   filter?.price?.min ? filter?.price?.min : lookup?.price?.min
              // )}
              ruler={false}
            /> */}
            <div className="d-flex align-items-center">
              {/* price range from */}
              <div>
                <TimePickerField
                  // label={marketPlace.returnDate}
                  haslabel={false}
                  date={filters?.durationTime?.from}
                  placeholder="00:00"
                  onChangeDate={(date) => {
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      durationTime: {
                        ...prev.durationTime,
                        from: date,
                      },
                    }));
                  }}
                  timeFormat="HH:mm"
                />
              </div>
              <span className="mx-2 text-uppercase mt-1">To</span>
              <div>
                <TimePickerField
                  // label={marketPlace.returnDate}
                  haslabel={false}
                  date={filters?.durationTime?.to}
                  placeholder="00:00"
                  onChangeDate={(date) => {
                    setFilters((prev) => ({
                      ...prev,
                      page: 1,
                      durationTime: {
                        ...prev.durationTime,
                        to: date,
                      },
                    }));
                  }}
                  timeFormat="HH:mm"
                />
              </div>
              {/* price range to */}
            </div>
          </div>
        </Collapse>
      </div>
      <div className="stops airline">
        <div className="title">
          <h6>{webBuilder.airline}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({ ...prev, airline: !prev.airline }))
            }
          >
            {FiltersIsOpen.airline ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.airline}>
          <div className="items">
            {filtersLookup?.stops?.map((stop) => (

              <div className="airlines-checkbox" key={stop?.code}>
                <div className="custom-control custom-checkbox ">
                  <input
                    className="custom-control-input permChecks"
                    id={stop?.code}
                    type="checkbox"
                    value={stop?.code}
                    onChange={(e) => {
                      filterByCheckbox(e, stop, "stops");
                    }}
                    checked={filters?.stops?.includes(stop?.code)}
                  />
                  <label className="custom-control-label" data htmlFor={stop?.code}>
                    {stop?.names?.[locale]}
                  </label>
                </div>
              </div>
            ))}
            {/* {Array.from({ length: 20 }, (index) => (
              <div className="airlines-checkbox">
                <div className="custom-control custom-checkbox ">
                  <input
                    className="custom-control-input permChecks"
                    id={`non-stop`}
                    name="airline"
                    type="checkbox"
                  />
                  <label className="custom-control-label" htmlFor={`non-stop`}>
                    Airline {index}
                  </label>
                </div>
              </div>
            ))} */}
          </div>
        </Collapse>
      </div>
    </div>
  );
}
