import React, { useState } from "react";
import {
  FlightIcon,
  HotelIcon,
  TourIcon,
  TransportationIcon,
} from "../models/images";
import FlightSearch from "modules/market-place/SearchForm/Flight";
import TransferSearch from "modules/market-place/SearchForm/Transfer";
import HotelSearch from "modules/market-place/SearchForm/Hotel";
import ToursSearchForm from "modules/market-place/SearchForm/Tours";
import { useWebBuilderState } from "context/webBuilder";
import Locale from "translations";
// import ToursSearchForm from "modules/market-place/SearchForm/Tours";
// import TransferSearch from "./SearchForm/Transfer";
// import FlightSearch from "./SearchForm/Flight";


export default function SearchService() {
  const { webBuilder } = Locale;
  const { active_modules } = useWebBuilderState();
  const [tab, setTab] = useState(active_modules[0]);
  return (
    <div className="search-service-hero">
      <ul className="tabs-list">
        {active_modules.includes("hotel") &&
          <li
            className={`tabs-item ${tab === "hotel" ? "active-item" : ""}`}
            onClick={() => setTab("hotel")}
          >
            <HotelIcon />
            <span>{webBuilder.hotels}</span>
          </li>
        }
        {active_modules.includes("flight") &&
          <li
            className={`tabs-item ${tab === "flight" ? "active-item" : ""}`}
            onClick={() => setTab("flight")}
          >
            <FlightIcon />
            <span>{webBuilder.flights}</span>
          </li>
        }
        {active_modules.includes("transfer") &&
          <li
            className={`tabs-item ${tab === "transfer" ? "active-item" : ""
              }`}
            onClick={() => setTab("transfer")}
          >
            <TransportationIcon />
            <span>{webBuilder.transfer}</span>
          </li>
        }
        {active_modules.includes("tour") &&
          <li
            className={`tabs-item ${tab === "tour" ? "active-item" : ""}`}
            onClick={() => setTab("tour")}
          >
            <TourIcon />
            <span>{webBuilder.tours}</span>
          </li>
        }
        {/* <li
          className={`tabs-item ${tab === "visa" ? "active-item" : ""}`}
          onClick={() => setTab("visa")}
        >
          <VisaIcon />
          <span>Visa</span>
        </li> */}
      </ul>
      <div className="tabs-form">
        {tab === "hotel" && active_modules.includes("hotel") && <div className="tab-form-item">
          <HotelSearch />
        </div>}
        {tab === "flight" && active_modules.includes("flight") && (
          <div className="tab-form-item">
            <FlightSearch />
          </div>
        )}
        {tab === "transfer" && active_modules.includes("transfer") && (
          <div className="tab-form-item">
            <TransferSearch />
          </div>
        )}
        {tab === "tour" && active_modules.includes("tour") && (
          <div className="tab-form-item">
            <ToursSearchForm />
          </div>
        )}
      </div>
    </div>
  );
}
