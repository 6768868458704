import { useMemo, useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import Locale from "translations";
import createDOMPurify from "dompurify";
import destinationIcon from "assets/images/market-place/servicesIcon/destinationIcon.png";
import { debounce } from "lodash";

/**
 * @Component TextField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> type: Type for input field
 *    -> name: Name for input field
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> extraText: Text for prepend input
 *    -> extraTextPostion: prepend || append
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

const AutoCompleteField = (props) => {
  const { commons, notifaction } = Locale;
  const [isFoucsed, setIsFoucsed] = useState(false);
  const DOMPurify = createDOMPurify(window);
  const prependText = (
    <div
      className={`control-field__prepend ${props.color ? " control-field__prepend--" + props.color : ""
        }`}
    >
      <span className="form-control border-0 text-headline text-center pt-2">
        {props.extraText}
      </span>
    </div>
  );

  const debouncedGetListAuto = useMemo(() => {
    return debounce(props.getListAuto, 350)
  }, []);

  const appendText = (
    <div
      className={`control-field__append ${props.color ? " control-field__append--" + props.color : ""
        }`}
    >
      <span className="form-control border-0 text-headline text-center pt-2">
        {props.extraText}
      </span>
    </div>
  );

  const generateErrorsMsgs = (errors) => {
    let message = [];
    if (errors) {
      for (const [k, v] of Object.entries(errors)) {
        if (k === "required" && v) {
          message.push(commons.isRequired);
        } else if (k === "min" && v) {
          message.push(` should be at least ${props.min} chars`);
        } else if (k === "maxNumber" && v) {
          message.push(` should not exceed ${props.max}`);
        } else if (k === "minNumber" && v) {
          message.push(` should be at least ${props.min}`);
        } else if (k === "email" && v) {
          message.push(commons.shouldBeValid);
        } else if (k === "phone" && v) {
          message.push(commons.shouldBeValid);
        } else if (k === "number" && v) {
          message.push(commons.shouldBeNumber);
        }
      }
      if (message.length === 1) {
        return `${props.label} ${message[0]}`;
      } else if (message.length > 1) {
        return `${props.label} ${message.join(" & ")}`;
      } else {
        return;
      }
    }
  };

  const closeAllTaps = () => {
    props.setListAuto([]);
  };

  const DetectClickOutside = useDetectClickOutside({
    onTriggered: closeAllTaps,
  });

  return (
    <>
      {props.haslabel ? (
        <label
          className={`control-field__label ${props.color ? " control-field__label--" + props.color : ""
            }
					${(isFoucsed && props.color !== "danger") ||
              (isFoucsed && props.value && props.color !== "danger")
              ? "custom-color"
              : ""
            }
					
					`}
        >
          {props.label}
        </label>
      ) : null}
      <div
        className={`my-1 input-group control-field + custom-input overflow-hidden

				${(isFoucsed && props.color !== "danger") ||
            (isFoucsed && props.value && props.color !== "danger")
            ? "custom-border-color"
            : ""
          }
				
				${props.color ? " control-field--" + props.color : ""}
				${props.labelInner ? "control-field-inner" : ""}
				`}

      >
        {/* Text Field Prepend */}
        {props.extraTextPosition === "prepend" && props.extraText !== "-"
          ? prependText
          : ""}

        {/* Text Field Body */}
        <div
          // style={{ padding: "11px 18px" }}
          className={`control-field__body  ${props.disabled ? "isDisabled" : ""}
					${props.labelInner ? 'innerPadding' : 'newFixPadding'}
					${props.extraTextPosition === "prepend" ? "flex-prepend" : ""}
					`}
        >
          {/* Label */}
          {props.labelInner && <span className='font-weight-bolder'>{props.label}</span>}

          {/* Text Input */}
          <div className="row no-gutters justify-content-between">
            {props.flag ? (
              props.flag.includes("new-hotels") ||
                props.flag.includes("airlines") || props?.imgFlag ? (
                <img
                  crossOrigin="anonymous"
                  src={props.flag}
                  className={`border ${props.labelInner ? "fix-flag-inner" : "fix-flag"}`}
                  alt="selected"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.crossOrigin = null;
                  }}
                />
              ) : (
                <div
                  className={`${props.labelInner ? "fix-flag-inner" : "fix-flag"}`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(props.flag),
                  }}
                />
              )
            ) : (
              ""
            )}
            <input
              type={props.type}
              name={props.name}
              style={{ textOverflow: 'ellipsis', paddingInlineEnd: "20px" }}

              className={`${props.className ?? ""} control-field__input w-100 	${props.flag ? "px-5" : ""
                }`}
              id={props.id}
              value={props.value}
              min={props.min}
              max={props.max}
              disabled={props.disabled}
              onChange={(e) => {
                debouncedGetListAuto(e.target.value);
                // props.getListAuto(e.target.value)
                props?.onChange(e.target.value);
              }}
              // onChange={props.onChange}
              // onKeyUp={props.onKeyUp}
              // onBlur={() => {
              // 	if (props.onBlur != undefined) {
              // 		props.onBlur();
              // 	}
              // 	setIsFoucsed(false);
              // }}
              onFocus={(e) => {
                props.onFocus && props.onFocus(e);
                setIsFoucsed(true);
              }}
              onBlur={(e) => {
                props.onBlur && props.onBlur(e);
                setIsFoucsed(false);
              }}
              placeholder={props.placeholder}
              autoComplete="off"
              onKeyDown={props.onEnter}
            // {...props}
            />
            <div className="fix_place_arrow">
              {props.openModal ? (
                <i
                  className="fas fa-list-alt new-color"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.openModal();
                    props.setListAuto([]);
                  }}
                  style={{ fontSize: 24 }}
                />
              ) : (
                <>
                  {props.value && (
                    <i
                      className="fas fa-times me-2 text-gray"
                      role='button'
                      onClick={() => {
                        props?.onChange("");
                        props.onSelectValue();
                      }}
                    ></i>
                  )}
                  {props.labelInner && props.isImage ?
                    <img src={props.image ?? destinationIcon} alt='Destination Icon' width={25} height={25} />
                    :
                    !props.value ? <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      className="css-6q0nyr-Svg"
                    >
                      <path
                        fill="#cfcfcf"
                        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
                      ></path>
                    </svg> : null}
                </>
              )}
            </div>
          </div>
        </div>
        {/* Text Field Append */}
        {props.extraTextPosition === "append" ? appendText : ""}
      </div>
      {props?.listAuto && props?.listAuto.length > 0 ? (
        <div
          className="col-md-12"
          style={{ position: "relative" }}
          ref={DetectClickOutside}
        >
          <ul className="d-flex listAuto flex-column">
            {props?.listAuto && props?.listAuto.length > 0
              ? props?.listAuto.map((res) => {
                return (

                  <li
                    onClick={(e) => {
                      props.onSelectValue(res);
                      props.setListAuto([]);
                    }}
                    key={res?.id || res?.value}
                  >
                    {res?.country?.flag ? (
                      <>
                        {res.type === "city" ? (
                          <div
                            className="flag_box"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                res?.country?.flag
                              ),
                            }}
                          />
                        ) : (
                          <img
                            crossOrigin="anonymous"
                            src={res?.country?.flag}
                            onError={(e) => {

                              e.target.onerror = null;
                              e.target.crossOrigin = null;
                            }}
                            width="30px"
                            alt="hotels"
                          />
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {res.name}
                  </li>

                );
              })
              : ""}
            {props.showMore && (
              <li
                className="text-primary justify-content-center auto-show-more"
                onClick={(e) => {
                  props.showMore();
                  props.setListAuto([]);
                }}
              >
                {notifaction.showMore}
              </li>
            )}
          </ul>
        </div>
      ) : (
        ""
      )}
      {/* Error Message */}
      <small
        className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
      >
        {generateErrorsMsgs(props.errors)}
      </small>
    </>
  );
};

AutoCompleteField.defaultProps = {
  haslabel: true,
};

export default AutoCompleteField;
