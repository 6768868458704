import * as actionTypes from "./actions";

// Define initialState
const initialState = {
	isAuth: localStorage.getItem("isAuth") ?? false,
	token: localStorage.getItem("token") ?? null,
	userInfo: null,
	companyInfo: null,
};

// Define the Auth Reducer
export default function auth_reducer(state = initialState, action) {
	switch (action.type) {
		// Auth: Login
		case actionTypes.Auth_Login:
			localStorage.setItem("isAuth", true);
			localStorage.setItem("token", action.token);

			return {
				...state,
				isAuth: action.isAuth,
				token: action.token,
				userInfo: action.userInfo,
				companyInfo: action.companyInfo,
			};

		// Auth: Logout
		case actionTypes.Auth_Logout:
			localStorage.removeItem("isAuth");
			localStorage.removeItem("token");
			return {
				...state,
				isAuth: false,
				token: null,
				userInfo: null,
				companyInfo: null,
			};

		// Auth: Default
		default:
			return state;
	}
}
