import Locale from "translations";

export default function PriceBooking({ flightData }) {
  const { inventory, webBuilder } = Locale;

  const adultsChildrenCount = +flightData?.adults + +flightData?.children;
  // adults
  const fareForAllAdults = +flightData?.adtFare * +flightData?.adults || 0;
  const taxForAllAdults = +flightData?.adtTax * +flightData?.adults || 0;

  // children
  const fareForAllChildren = +flightData?.chdFare * +flightData?.children || 0;
  const taxForAllChildren = +flightData?.chdTax * +flightData?.children || 0;


  return (
    <>
      <div className="price-details">
        <h3>{webBuilder.priceDetails} </h3>

        <div className="item">
          <div className="adults-price-summary">
            <div className="d-flex justify-content-between price-color mb-2">
              <h5 className="n-adult">{flightData?.adults} X {inventory.messages.Adult}</h5>
              <h5 className="bold">{(fareForAllAdults + taxForAllAdults)?.toFixed(2)} {" "}
                {flightData?.currency}</h5>
            </div>
            <div className="d-flex justify-content-between ms-4 price-color">
              <div>
                <p className="mb-2">{inventory.messages.FarePerAdult}</p>
                <p>{inventory.messages.TaxesPerAdult}</p>
              </div>
              <div>
                <p>{flightData?.adtFare?.toFixed(2)} {" "} {flightData?.currency}</p>
                <p>{flightData?.adtTax?.toFixed(2)} {" "}  {flightData?.currency}</p>
              </div>
            </div>
          </div>
        </div>
        {
          flightData?.children > 0 ? <div className="item">
            <div className="adults-price-summary">
              <div className="d-flex justify-content-between price-color mb-2">
                <h5 className="n-adult">{flightData?.children} X {inventory.messages.Child}</h5>
                <h5 className="bold">{(fareForAllChildren + taxForAllChildren).toFixed(2)}
                  {flightData?.currency}</h5>
              </div>
              <div className="d-flex justify-content-between ms-4 price-color">
                <div>
                  <p className="mb-2">{inventory.messages.FarePerChild}</p>
                  <p>{inventory.messages.TaxesPerChild}</p>
                </div>
                <div>
                  <p>{flightData?.chdFare?.toFixed(2)} {" "} {flightData?.currency}</p>
                  <p>{flightData?.chdTax?.toFixed(2)} {" "}  {flightData?.currency}</p>
                </div>
              </div>
            </div>
          </div> : null
        }
        {/* tickets fees */}
        <div className="item">
          <div className="adults-price-summary">
            <div className="d-flex justify-content-between price-color mb-2">
              <h5 className="n-adult">{adultsChildrenCount || 0} X {webBuilder.ticketingFee}</h5>
              <h5 className="bold"> {(+flightData?.tktFee).toFixed(2)}
                {flightData?.currency}</h5>
            </div>

          </div>
        </div>
      </div>
      {/* total price and penalties */}

      <div className="price-details total">
        <h3>{webBuilder.total}</h3>

        <div className="total-summary">
          <h3>{flightData?.totalPrice?.toFixed(2)}{" "}
            {flightData?.currency}</h3>
          <span>{webBuilder.notIncludingPaymentFee}</span>
        </div>
      </div>
    </>
  );
}
