import React, { useState } from "react";
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import { Collapse } from 'reactstrap';
import { useSBSState } from "context/global";


export default function FAQTheme5({
  title,
  faqs,
  faqImage
}) {


  const { locale } = useSBSState();
  const [collapsedItems, setCollapsedItems] = useState([]);


  return (
    <div className="faq-container">
      <div className="faq-questions">

        <h3 className="mb-4 font-weight-bold h4">{title}</h3>

        {faqs &&
          faqs?.map((que, index) => {
            const isOpen = collapsedItems.includes(index);
            const openCollapse = () => {
              const updated = [...collapsedItems, index];
              setCollapsedItems(updated);
            };
            const closeCollapse = () =>
              setCollapsedItems(
                collapsedItems.filter((i) => i !== index)
              );
            return (
              <div className="faqs-question-wrapper" key={que.id}>

                <div className="faqs-container-question">
                  <h6> {que.question[locale]} </h6>
                  {/* <span> {que.question[selectedLanguage]} </span> */}
                  {isOpen ? (
                    <button
                      className="bg-transparent border-0 faq-btn"
                      onClick={closeCollapse}
                    >
                      <MinusIcon />
                    </button>
                  ) : (
                    <button
                      className="bg-transparent border-0 faq-btn"
                      onClick={openCollapse}
                    >
                      <PlusIcon />
                    </button>
                  )}
                </div>
                <Collapse isOpen={isOpen}>
                  <p className="faqs-container-body">{que.body[locale]}</p>
                </Collapse>
              </div>
            )
          })}
      </div>

      <img src={faqImage} className="faq-image" alt="Faq-section" />

    </div>
  );
}
