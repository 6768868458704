import LocalizedStrings from "react-localization";
import ar from "./ar.json";
import en from "./en.json";
import fr from "./fr.json";
import id from "./id.json";
import uz from "./uz.json";
import IN from "./in.json";

const Locale = new LocalizedStrings({ en, ar, fr, id, uz, IN });

export default Locale;
