import { NoDataIcon } from "modules/WebBuilder-V2/Components/Content/OurServices/icons";
import React from "react";

import ResultItem from "./resultItem";
import Locale from "translations";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSBSState } from "context/global";

export default function SearchResultData({
  resultData,
  productType,
  filter,
  setFilter,
  handleSort,
  searchCountryId,
}) {
  const { webBuilder } = Locale;
  const { allCountries, locale } = useSBSState();

  let searchDescription = "";

  try {
    const searchCountry =
      allCountries.find((country) => +country?.id === +searchCountryId)?.name ||
      resultData[0]?.locationName?.[locale] ||
      resultData[0]?.locationName;
    if (searchCountry) {
      searchDescription += searchCountry + " - ";
    }

    if (productType?.name) {
      searchDescription += productType.name;
    }
  } catch (error) {}

  return (
    <div className="search-result">
      <div className="header">
        <p>{resultData?.length > 0 ? searchDescription : null}</p>
        <div className="select-box">
          <p>{webBuilder.sortBy}</p>
          <select
            className="sort-price-select"
            placeholder="Best Price"
            onChange={(e) => {
              setFilter({ ...filter, sortBy: e.target.value });
              handleSort(e.target.value);
            }}
          >
            {/* <option value="null">Best Price</option> */}
            <option value="asc" selected={filter?.sortBy === "asc"}>
              {" "}
              {webBuilder.lowestPrice}
            </option>
            <option value="desc" selected={filter?.sortBy === "desc"}>
              {webBuilder.highestPrice}
            </option>
          </select>
        </div>
      </div>

      {resultData?.length > 0 ? (
        <div className="result">
          {resultData?.map((item, index) => {
            return (
              <ResultItem
                key={item?.id || index}
                item={item}
                productType={productType}
              />
            );
          })}
        </div>
      ) : (
        <div className="no-result">
          <NoDataIcon />
          <h6>{webBuilder.noResultsFound}</h6>
          <p>{webBuilder.youCanTryAnotherSearch}</p>
          <Link className="btn" to="/">
            {webBuilder.backtoSearch}
          </Link>
        </div>
      )}

      
    </div>
  );
}
