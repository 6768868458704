import LocalizedStrings from 'react-localization';

const Locale = new LocalizedStrings({
    en:{
        en: "English",
        ar: "العربية",
    },
    ar: {
        en: "English",
        ar: "العربية",
    }
});

export default Locale;