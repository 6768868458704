
import { useEffect, useState } from "react";
import { useSBSDispatch, useSBSState } from "context/global";
import currencyImg from 'assets/images/market-place/servicesIcon/currency_exchange.png'
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";


export default function CurrencySwitcher({ show }) {
  const { commons } = Locale;
  const { allCurrencies, currency } = useSBSState();
  const selectedCurrencyCode = currency;
  const dispatch = useSBSDispatch();


  // ** states
  const [currenciesModalOpen, setCurrenciesModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [currenciesList, setCurrenciesList] = useState(null);
  
  function toggleCurrenciesModal() {
    setCurrenciesModalOpen(!currenciesModalOpen)
  }

  function selectCurrency(currency) {
    dispatch({
      type: "setCurrency",
      payload: currency?.currency_code
    });
    toggleCurrenciesModal();
    setSearchTerm('');
    setCurrenciesList(allCurrencies);
  }

  function currenciesSearch(e) {
    const value = e.target.value
    // filter currencies
    if (value !== '') {
      const filteredCurrencies = allCurrencies?.filter(currency => {
        return (
          (
            currency?.name?.toLowerCase().includes(value.toLowerCase())
            ||
            currency?.currency_code?.toLowerCase().includes(value.toLowerCase())
          )
          && currency
        );
      })
      setCurrenciesList(filteredCurrencies);
    } else {
      setCurrenciesList(allCurrencies);
    }
    setSearchTerm(value);
  }

  useEffect(() => {
    if (allCurrencies?.length > 0) {
      setCurrenciesList(allCurrencies);
    }
  }, [allCurrencies])


  return (
    <>
      {allCurrencies ?
        <div className="btn-light bg-white mx-2 p-2 pointer lang-btn currency-switcher " onClick={toggleCurrenciesModal}>
          <img
            className="img-lang1 p-1 "
            src={currencyImg}
            alt="currency"
            width="28"
          />
          <span className='mx-1'>{selectedCurrencyCode}</span>
        </div>
        :
        null
      }

      {currenciesModalOpen ?
        <Modal isOpen={currenciesModalOpen} centered size='lg'>
          <ModalHeader toggle={toggleCurrenciesModal}>
            {commons.selectYourCurrency}
          </ModalHeader>
          <ModalBody>
            {/* search bar */}
            <div>
              <TextField
                name="search-currencies"
                id="search-currencies"
                hasLabel={false}
                placeholder={commons.searchCurrencies}
                value={searchTerm}
                onChange={(e) => currenciesSearch(e)}
              />
            </div>

            <ul className='d-flex flex-wrap currency-modal align-items-baseline'>
              {currenciesList?.length > 0 ?
                currenciesList?.map(currency => {
                  const isSelectedCurrency = currency?.currency_code === selectedCurrencyCode;
                  return (
                    <li
                      key={currency?.id}
                      className={`currency-item col-md-3 my-2 ${isSelectedCurrency ? "currency-item--selected" : ""}`}
                    >
                      <button className='btn shadow-none' onClick={() => !isSelectedCurrency && selectCurrency(currency)}>
                        <span>{currency?.name}</span>
                        <span>{currency?.currency_code}</span>
                      </button>
                      {isSelectedCurrency ? <i className="fas fa-check"></i> : null}
                    </li>
                  )
                })
                :
                <span>no currencies</span>
              }
            </ul>
          </ModalBody>
        </Modal>
        :
        null
      }
    </>
  );
}