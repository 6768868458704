import React, { useEffect, useRef, useState } from "react";

import ResultItem from "./ResultItem";
import Filter from "./Filter";
import Locale from "translations";
import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import { formatSearchData } from "../helpers/formatSearchData";
import { fetchFlightsBuilder } from "services/webBuilder";
import _ from "lodash";
import { getFlightsFiltersLookup } from "services/marketplace";
import Pagination from "components/Pagination";
import { useSBSState } from "context/global";
import { Modal } from "reactstrap";
import SearchFilghtForm from "modules/market-place/SearchForm/Flight/SearchFilghtForm";
import moment from "moment";
import { NoDataIcon } from "../Icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as FilterSearchIcon } from "assets/images/webBuilder/filter-search.svg";

export default function FlightResulltOutbound() {
  const { webBuilder, marketPlace, inventory } = Locale;

  const { flightsSearchResults, flightsSearch } = useMarketplaceState();
  const { allCountries } = useSBSState();
  const previousValue = useRef({
    transferCount: [],
    stops: [],
    airLines: [],
    departureTime: {},
    arrivalTime: {},
    durationTime: {},
    sortBy: "price",
    sortType: "desc",
    page: 1,
  });

  const dispatch = useMarketplaceDispatch();
  const [isopenmodel, setisopenmodel] = useState(false);

  const [filtersLookup, setfiltersLookup] = useState({});

  const [filters, setFilters] = useState({
    transferCount: [],
    stops: [],
    airLines: [],
    departureTime: {},
    arrivalTime: {},
    durationTime: {},
    sortBy: "price",
    sortType: "desc",
    page: 1,
  });

  const modeltoggel = () => {
    setisopenmodel(!isopenmodel);
  };

  function goTo(page) {
    setFilters((prev) => ({ ...prev, page }));
  }
  const flightsSearchData = JSON.parse(localStorage.getItem("flightsSearch"));

  const handleSearch = async (filters) => {
    let data = formatSearchData(flightsSearch, filters);
    const response = await fetchFlightsBuilder(data, filters?.page);
    if (response.status === 200) {
      const data = response.data;

      if (response.data.data.length > 0) {
        dispatch({
          type: "flightsSearchResults",
          payload: data,
        });
      } else {
        dispatch({
          type: "flightsSearchResults",
          payload: [],
        });
        // store.addNotification({
        //   title: messages.noResults,
        //   message: messages.noSearchResults,
        //   type: "danger",
        //   insert: "top",
        //   container: "top-right",
        //   animationIn: ["animated", "fadeIn"],
        //   animationOut: ["animated", "fadeOut"],
        //   dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
        // });
      }
    }
  };

  useEffect(() => {
    dispatch({
      type: "inboundFlightsSearchResults",
      payload: undefined,
    });
    if (flightsSearchResults === undefined) {
      // handleSearch();
    }
  }, [flightsSearch]);

  useEffect(() => {
    if (flightsSearchResults && !_.isEqual(filters, previousValue.current)) {
      handleSearch(filters);
      previousValue.current = filters;
    }
  }, [filters]);

  useEffect(() => {
    async function fetchFiltersLookup() {
      const sideFilters = await getFlightsFiltersLookup(
        flightsSearch?.cacheKey
      );
      if (sideFilters?.status === 200) {
        setfiltersLookup(sideFilters?.data?.lookups);
      }
    }
    fetchFiltersLookup();
  }, [flightsSearch?.cacheKey]);
  const fromCountryId =
    flightsSearch?.type === "3"
      ? flightsSearch?.airLegs[0]?.from?.country_id
      : flightsSearch?.from?.country_id;

  const toCountryId =
    flightsSearch?.type === "3"
      ? flightsSearch?.airLegs[0]?.to?.country_id
      : flightsSearch?.to?.country_id;

  const fromCountry = allCountries.find((i) => i.id === fromCountryId)?.name;
  const toCountry = allCountries.find((i) => i.id === toCountryId)?.name;
  function handleSort(sort_by, sort_type) {
    if (filters.sortBy === sort_by && filters.sortType === "asc") {
      sort_type = "desc";
    } else if (filters.sortBy === sort_by && filters.sortType === "desc") {
      sort_type = "asc";
    } else {
      sort_type = "desc";
    }
    setFilters({ ...filters, sortBy: sort_by, sortType: sort_type });
  }
  const typeTrip = (type) => {
    switch (type) {
      case "1":
        return marketPlace.roundTrip;
      case "2":
        return marketPlace.oneway;
      case "3":
        return marketPlace.MultiDestination
      default:
        return ""
    }
  }
  function toggleOpenFilter() {
    const filterEle = document.getElementsByClassName("flight-filter")?.[0];
    if (filterEle) {
      filterEle.classList.contains("opened")
        ? filterEle.classList.remove("opened")
        : filterEle.classList.add("opened");
    }
  }
  return (
    <div className="flight-result">
      <div className="head">
        <div className="location">
          <div>
            <p>
              {fromCountry} - {toCountry}
              <span> ({typeTrip(flightsSearchData?.type)})</span>
            </p>
          </div>
          <div className="d-flex info w-100">
            <p>
              {marketPlace.class}: <span>{flightsSearchData?.cabinClass?.name}</span>

            </p>
            <p>
              {inventory.messages.travelers}: <span>{flightsSearchData?.adults}</span>
            </p>
            <p>
              {webBuilder.dates}: <span> {moment(flightsSearchData?.departureDate).format("DD/MM/YYYY")} -  {moment(flightsSearchData?.arrivalDate).format("DD/MM/YYYY")}</span>
            </p>
          </div>
        </div>
        <button className="btn btn-primary"
          onClick={modeltoggel}
        >
          <span>{webBuilder.editSearch}</span>

          <i className="fas fa-pen edit-icon-mobile"></i>
        </button>
      </div>
      <div className="content">
        <div className="row">
          <div className="col-md-3 filters-col">
            <div className="filters">
              <Filter
                filtersLookup={filtersLookup}
                filters={filters}
                setFilters={setFilters}
                toggleOpenFilter={toggleOpenFilter}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="num-result">
              <p><span className="font-bold">{webBuilder.chooseDepartureTrip}</span> ( {flightsSearchResults?.meta?.total} {webBuilder.flightsAvailable} )</p>

              <div className="sort">
                <p>{webBuilder.sortBy}</p>
                <select onChange={(e) => {
                  handleSort("price", e.target.value);
                }}>
                  <option value="">{webBuilder.select}</option>
                  <option value="desc">{webBuilder.lowestPrice}</option>
                  <option value="asc">{webBuilder.highestPrice}</option>

                </select>
              </div>
            </div>

            {flightsSearchResults?.data?.length > 0 ?
              flightsSearchResults?.data?.map((solution) =>
                solution.journeys.map((journey) => (
                  <ResultItem
                    journey={journey}
                    solution={solution}
                    searchResults={flightsSearchResults?.data}
                  />
                ))
              ) : <NoData />}

            <Pagination info={flightsSearchResults?.meta} goTo={goTo} />
          </div>
        </div>
      </div>
      <Modal isOpen={isopenmodel} toggle={modeltoggel} size="lg" className="modify-flight-search-modal">
        <div className="d-flex align-items-center justify-content-between border-bottom mb-4 p-2 px-4 pt-0 w-100">
          <h3>Modify</h3>
          <div onClick={modeltoggel} className=" btn-x">
            <i className="fas fa-times"></i>
          </div>
        </div>
        <div className='flight-modify-container pb-3'>

          <SearchFilghtForm modify closeModal={modeltoggel} />
        </div>
      </Modal>
      <button
        className="btn filter-mobile-btn shadow-none"
        onClick={toggleOpenFilter}
      >
        <FilterSearchIcon />
      </button>
    </div>
  );
}
export function NoData() {
  const { webBuilder } = Locale;
  const history = useHistory();
  return (
    <div className="no-data-available">
      <NoDataIcon />
      <h6>{webBuilder.noResultsFound}</h6>
      <p>{webBuilder.youCanTryAnotherSearch}</p>
      <button className="btn btn-primary" onClick={() => history.push("/")}>
        {webBuilder.backtoSearch}
      </button>
    </div>
  );
}