import { useSBSState } from "context/global";
import { useState } from "react";
import Locale from "translations";

/**
 * @Component TextField
 * @Description This component for any text input group like text, email, etc.
 * @Props
 *    -> type: Type for input field
 *    -> name: Name for input field
 *    -> label: Text label for input field
 *    -> placeholder: Input field placeholder
 *    -> className: HTML classes for input field
 *    -> id: HTML ID
 *    -> color: Bootstrap color
 *    -> value: Input value
 *    -> extraText: Text for prepend input
 *    -> extraTextPostion: prepend || append
 *    -> feedbackMessage: Feedback message for validation
 *    -> onBlur: Input Function
 *    -> onChange: Input Function
 *    -> isInvalid: [Bool] set field is valid or not
 */

const TextField = (props) => {
	const { commons, productsBuilder } = Locale;
	const { locale } = useSBSState();
	const [isFoucsed, setIsFoucsed] = useState(false);

	const prependText = (
		<div
			className={`control-field__prepend ${
				props.color ? " control-field__prepend--" + props.color : ""
			}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);

	const appendText = (
		<div
			className={`control-field__append ${
				props.color ? " control-field__append--" + props.color : ""
			}`}
		>
			<span className="form-control border-0 text-headline text-center pt-2">
				{props.extraText}
			</span>
		</div>
	);

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(
						`${commons.shouldBeAtLeast} ${props.min} ${
							props?.value?.length && (locale === "ar") > 10
								? commons.char
								: commons.chars
						}`
					);
				} else if (k === "max" && v) {
					message.push(
						`${productsBuilder.shouldnotexceed} ${props.max} ${
							props?.value?.length && (locale === "ar") > 10
								? commons.number
								: commons.numbers
						}`
					);
				} else if (k === "maxNumber" && v) {
					message.push(` ${productsBuilder.shouldnotexceed} ${props.max}`);
				} else if (k === "minNumber" && v) {
					message.push(` should be at least ${props.min}`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeNumber);
				} else if (k === "serverError" && v) {
					if (Array.isArray(v)) {
						v.forEach((i) => message.push(i));
					} else {
						message.push(v);
					}
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	const image = props.isImage ? (
		<img
			src={props.image}
			alt="Destination Icon "
			className="m-2 me-0"
			width={25}
			height={25}
		/>
	) : (
		""
	);
	
	return (
		<>
			{props.haslabel ? (
				<label
					className={`control-field__label ${
						props.color ? " control-field__label--" + props.color : ""
					}
					${
						(isFoucsed && props.color !== "danger") ||
						(isFoucsed && props.value && props.color !== "danger")
							? "custom-color"
							: ""
					}
					
					`}
				>
					{props.label}
				</label>
			) : null}
			<div
				className={`my-1 input-group control-field + custom-input overflow-hidden

				${
					(isFoucsed && props.color !== "danger") ||
					(isFoucsed && props.value && props.color !== "danger")
						? "custom-border-color"
						: ""
				}
				${props.labelInner ? "control-field-inner" : ""}
				${props.color ? " control-field--" + props.color : ""}
				`}
			>
				{/* Text Field Prepend */}
				{props.extraTextPosition === "prepend" && props.extraText !== "-"
					? prependText
					: ""}

				{/* Text Field Body */}
				{props.prependimage ? image :null }
				{props.prependSVG ? <div style={{margin: "auto 0 auto 0.5rem"}}>{props.prependSVG}</div>: null}
				<div
					className={`control-field__body  ${props.disabled ? "isDisabled" : ""}
					
					${props.extraTextPosition === "prepend" ? "flex-prepend" : ""}
					${props.labelInner ? "innerPadding" : "newFixPadding"} 
					`}
				>
					{/* Label */}
					{props.labelInner && (
						<span className="font-weight-bolder">{props.label}</span>
					)}

					{/* Text Input */}
					<div className="row no-gutters justify-content-between flex-nowrap">
						<input
							type={props.type}
							name={props.name}
							className={`${
								props.className ?? ""
							} control-field__input w-100  `}
							id={props.id}
							value={props.value}
							min={props.min}
							max={props.max}
							disabled={props.disabled}
							onChange={props.onChange}
							onKeyUp={props.onKeyUp}
							onBlur={() => {
								if (props.onBlur !== undefined) {
									props.onBlur();
								}
								setIsFoucsed(false);
							}}
							onFocus={() => {
								setIsFoucsed(true);
							}}
							placeholder={props.placeholder}
							autoComplete="off"
							
							onKeyDown={props.onEnter}
							{...props}
						/>
					</div>
				</div>
				{props.prependimage ? null : image}
				{/* Text Field Append */}
				{props.extraTextPosition === "append" ? appendText : ""}
			</div>

			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.color ? "d-block" : null}  error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
};

TextField.defaultProps = {
	haslabel: true,
};

export default TextField;
