import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";



function ToursService({ details }) {
  const { id, body, title, items } = details;

  return (
    <div className="web-builder-content-tours container" id={id}>
      <div className="tours-top-section">
        <ServicesContent
          header={title}
          body={body}
        />
      </div>

      <div className="tours-detials">
        <CustomCarousel>
          {items.map((props, index) => (
            <ServiceCardDetails {...props} key={props.id || `tour-${index}`} itemType="tour" />
          ))}
        </CustomCarousel>
      </div>
    </div >
  );
}

export default ToursService;
