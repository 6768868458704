import React from 'react'
import { ReactComponent as CalendarIcon } from 'assets/images/webBuilder/calendar-224.svg';
import { ReactComponent as Hotelcon } from 'assets/images/webBuilder/hotel.svg';
import { ReactComponent as StarIcon } from 'assets/images/webBuilder/star.svg';
import { ReactComponent as GoldenStarIcon } from 'assets/images/webBuilder/golden-star.svg';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
// import { useWebBuilderState } from 'context/webBuilder';
import Locale from 'translations';

function PackageCard(props) {
  const { webBuilder } = Locale;
  let { onClick, image, name, departure_date_at, return_date_at, stars, product_uuid, hotelName, price, currency } = props

  const history = useHistory();

  return (
    <div className="package-card-container"
      style={{
        backgroundImage: `url(${image})`
      }}
      onClick={() => {
        if (product_uuid) {
          history.push(`/web/package/${product_uuid}`)
        }
      }}
      key={product_uuid}
    >
      <div onClick={onClick} className='package-card-details'>
        <div className="start-price">
          <span>{webBuilder.from}</span>
          {/* <span className="amount">{price} {currency}</span> */}
        </div>
        {[...Array(5)].map((i, index) => (
          index < stars ? <GoldenStarIcon /> : <StarIcon />
        ))}
        <p className='font-weight-bold'>{name}</p>
        <div><CalendarIcon /> <span>{`${moment(departure_date_at).format("DD MMM YYYY")} - ${moment(return_date_at).format("DD MMM YYYY")}`}</span></div>
        {hotelName ? <div className='mt-1'><Hotelcon /> <span>{hotelName}</span></div> : null}
      </div>
    </div>
  );
}

export default PackageCard


