
import Locale from "translations";
import CustomModal from "./Modal";
import TransferSearch from "modules/market-place/SearchForm/Transfer";

function ModifySearch({ isOpen, toggleModal, header }) {
  const { onlineVisa } = Locale;


  return (
    <CustomModal
      modalIsOpen={isOpen}
      header={header || onlineVisa.ChoosePayment}
      toggle={toggleModal}
      size="md"
      centered={true}
    >

      <TransferSearch
        modify
        closeModal={() => {
          toggleModal();
          // deleteFromCart();
        }}
      />
    </CustomModal>
  );
}

export default ModifySearch;
