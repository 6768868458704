import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import SearchService from "modules/WebBuilder-V2/Components/Content/Hero/MainContent/SearchService";
import React from "react";

function HeroContent() {
  const { locale } = useSBSState();
  const { hero, style } = useWebBuilderState();
  const mainContent = hero?.mainContent;

  const bgImage = hero?.mainContent?.backgroundImage ?? "";

  const layoutType = hero?.mainContent?.layout?.layoutType;

  return (
    <div className={`web-builder-hero-content  content-layout-${layoutType}  content-layout-${style?.theme}`}>
      {layoutType === 2 || layoutType === 3 ? (
        <img src={bgImage} alt="" className="img-fluid" />
      ) : null}
      <div className="text-center hero-content">

        <p className="header h3">{mainContent?.title[locale]}</p>
        <p className="body">{mainContent?.body[locale]}</p>
      </div>
      <div className="search-form-web-builder search-form-horizontal mt-3">
        <SearchService />
      </div>
    </div>
  );
}

export default HeroContent;
