export const blockTypes = {
	aboutUs: "aboutUs",
	customContainer: "customContainer",
	packages: "packages",
	hotels: "hotels",
	flights: "flights",
	transfer: "transfer",
	tours: "tours",
	contactUs: "contactUs",
	faqs: "FAQs",
};
export const customContainerItems = {
	customContainerPhoto: "customContainerPhoto",
	customContainerVideo: "customContainerVideo",
	customContainerParagraph: "customContainerParagraph",
	customContainerMap: "customContainerMap",
	customContainerFaqs: "customContainerFaqs",
};

export const SORT_ITEMS_IN_CONTAINER_ACTION = "SORT_ITEMS_IN_CONTAINER_ACTION";
export const SORT_CONTAINER_ACTION = "SORT_CONTAINER_ACTION";
export const ADD_NEW_BLOCK_TO_BODY_ACTION = "ADD_NEW_BLOCK_TO_BODY_ACTION";
export const ADD_NEW_ITEM_TO_CUSTOM_CONTAINER_ACTION =
	"ADD_NEW_ITEM_TO_CUSTOM_CONTAINER_ACTION";
