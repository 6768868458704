import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import React from "react";
import ServiceCard from "./ServiceCard";
import { useWebBuilderState } from "context/webBuilder";
import { useLocation } from 'react-router-dom';
import { useSBSState } from "context/global";

function OurServices() {
  const { locale } = useSBSState();
  const { ourServices } = useWebBuilderState();


  const services = Array.isArray(ourServices?.services)
    ? ourServices?.services
    : [];
  const location = useLocation();
  const preview = !location.pathname.includes('edit');
  return (
    <div className={preview ? services?.filter(res => res.visible).length > 0 ? `web-builder-content-our-service` : null : `web-builder-content-our-service`} id="web-builder-our-service">
      {preview ? services?.filter(res => res.visible).length > 0 ?
        <ServicesContent
          header={ourServices?.title[locale]}
          body={ourServices?.body[locale]}
        /> : null :
        <ServicesContent
          header={ourServices?.title[locale]}
          body={ourServices?.body[locale]}
        />}

      {services.map((service, index) => (
        preview ? service?.visible ? <ServiceCard
          type={service?.type}
          icon={service?.icon}
          title={service?.title[locale]}
          key={service?.title + "aaa" + index}
          body={service?.body[locale]}
          visible={service?.visible}
        /> : null : <ServiceCard
          type={service?.type}
          icon={service?.icon}
          title={service?.title[locale]}
          key={service?.title + "bbb" + index}
          body={service?.body[locale]}
          visible={service?.visible}
        />
      ))}
    </div>
  );
}

export default OurServices;
