import { useSBSState } from "context/global";
import useExchangeRate from "hooks/useExchangeRate";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Locale from "translations";

const arrowIcon = (
  <svg
    width="14"
    height="7"
    viewBox="0 0 14 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5195 5.96L12.9995 3.47998L10.5195 1"
      stroke="#1E85FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 3.48047H13"
      stroke="#1E85FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function FlightServiceCard(props) {
  const { product_uuid, name, name_localized, price, flightItems, currency } = props;
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  let flightDestinations = Array.isArray(flightItems) ? flightItems : [];
  let firstFlightDestinations = flightDestinations[0];


  return (
    <article className="flight-service-card">
      <div className="header">
        <h3>{name_localized?.[locale] || name}</h3>
        <span className="destination-count">
          <b>{flightDestinations?.length}</b> {webBuilder.destinations}
        </span>
      </div>

      <div className="content d-flex align-items-center justify-content-between">
        {/* destinations list */}
        <div className="destinations-list">
          <DestinationPort
            fromPortCode={firstFlightDestinations?.fromPort?.code}
            fromCountry={firstFlightDestinations?.fromCountry?.name}
            toPortCode={firstFlightDestinations?.toPort?.code}
            toCountry={firstFlightDestinations?.toCountry?.name}
          />

          {/* show number of destionations after first destionation */}
          {flightDestinations.length > 1 && (
            <div className="addition-destinations">
              <span className="addition-destinations-count mx-3">
                +{flightDestinations.length - 1}
              </span>
              <div className="addition-destinations-list">
                {flightDestinations.length > 1 &&
                  flightDestinations.slice(1).map((destination, index) => {
                    return (
                      <DestinationPort
                        key={`${destination?.fromPort?.code}-${index}`}
                        fromPortCode={destination?.fromPort?.code}
                        fromCountry={destination?.fromCountry?.name}
                        toPortCode={destination?.toPort?.code}
                        toCountry={destination?.toCountry?.name}
                      />
                    );
                  })}
              </div>
            </div>
          )}
        </div>

        <div className="d-flex align-items-center gap-10">
          {/* flight price */}
          <p className="price">
            <span>{webBuilder.from}</span>
            <br />
            <span className="amount">
              {exchangedPrice} {exchangeCurrency}
            </span>
          </p>
          {/* button */}
          <Link to={`/flight/${product_uuid}`} className="cta-btn">
            {webBuilder.viewDeals}
          </Link>
        </div>
      </div>
    </article>
  );
}

function DestinationPort({ fromPortCode, fromCountry, toPortCode, toCountry }) {
  return (
    <div className="destination-port">
      <p className="font-weight-bold">
        <span>({fromPortCode})</span>
        <br />
        <span className="port-country">{fromCountry}</span>
      </p>

      {arrowIcon}

      <p className="font-weight-bold">
        <span>({toPortCode})</span>
        <br />
        <span className="port-country">{toCountry}</span>
      </p>
    </div>
  );
}
