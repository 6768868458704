import { SBSProvider } from "context/global";
import React from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import App from "./App";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const tagManagerArgs = {
	gtmId: "GTM-MQX9NBZ",
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
	
	<React.StrictMode>
		<SBSProvider>
			<Provider store={store}>
				<App />
			</Provider>
		</SBSProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
