import React from 'react'
import OverLayServiceCard from 'modules/WebBuilder-V2/shared/OverLayServiceCard';
import Carousel from 'react-multi-carousel';
import { useSBSState } from 'context/global';
import ButtonGroup from 'modules/WebBuilder-V2/shared/CarouselButtonGroup';



export default function ToursCarouselOverLayView(props) {
  const { locale } = useSBSState();
  const { items } = props;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1
    }
  };
  const showArrows = items.length > responsive.desktop.items;

  return (
    <Carousel
      responsive={responsive}
      itemClass={"tour-slide"}
      className="tours-carousel-overlay"
      arrows={false}
      slidesToSlide={1}
      keyBoardControl={true}
      customButtonGroup={showArrows ? <ButtonGroup /> : null}
      renderButtonGroupOutside={true}
      removeArrowOnDeviceType={["tablet", "mobile"]}
      infinite={true}
      rtl={locale === "ar"}
    >
      {items.map((props, index) => (
        <div className="px-2" key={props.id}>
          <OverLayServiceCard
            {...props}
            index={index}
            cardType="tours"
          />
        </div>
      ))}
    </Carousel>
  )
}


