
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import HotelCardThemeTen from "./HotelCard";


export default function HotelTheme10({ items, id, title, body }) {
	return (
		<section className="wb-hotels" id={id}>
			<div className="custom-container">
				<div className="service-top-section">
					<ServicesContent header={title} body={body} />
				</div>
				<div className="hotels-cards-container">
					{items.map((props, index) => (
						<HotelCardThemeTen
							key={`${props.product_uuid}-${index}`}
							{...props}
							itemType="hotel"
						/>
					))}
				</div>
			</div>
		</section>
	);
}
