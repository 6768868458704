import React from 'react'
import Locale from 'translations';
import walletImgSrc from 'assets/images/inventory/walletSm.svg';
import { useMarketplaceState } from 'context/marketplace';


export default function TourDetailsSummary({ tourData, tourSummary, originalPrice = 0 }) {
  const { marketPlace, inventory } = Locale;
  const { toursSearch, } = useMarketplaceState();

  const adultPrice = tourSummary?.adult?.amount;
  const totalAdultPrice = tourSummary?.adult?.totalAmount;
  const childrenAges = { min: tourSummary?.child?.minAge, max: tourSummary?.child?.maxAge || tourSummary?.freeChild?.maxAge };
  const totalChildsAsAdult = tourSummary?.childAsAdult?.totalAmount;
  const childPrice = tourSummary?.child?.amount;
  const totalChildPrice = tourSummary?.child?.totalAmount;
  const freeChildrenAges = { min: tourSummary?.freeChild?.minAge, max: tourSummary?.freeChild?.maxAge }
  const freeChildPrice = tourSummary?.freeChild?.amount

  const childsAges = toursSearch?.childs_ages?.map(age => age?.value);
  const childAsAdult = childsAges?.filter(age => age >= tourSummary?.adult?.minAge);
  const childsAboveAge = childsAges?.filter(age => age >= childrenAges.min && age <= childrenAges.max);
  const childsFreeCount = childsAges?.filter(age => age >= freeChildrenAges.min && age <= freeChildrenAges.max);

  return (
    <div>
      <div className='tour-details-selections h5'>
        {/* adults summary  */}
        <p className='my-2'>
          {inventory.messages.adults} {" "}
          {`(${adultPrice} ${tourData?.currency}) `}
          {`(x${toursSearch?.adults?.value})`}
          {tourSummary?.amountUnitTypePax && `: ${totalAdultPrice} ${tourData?.currency}`}
        </p>


        {/* childs free */}
        {childsFreeCount?.length > 0 &&
          <p className='my-2'>
            {inventory.messages.children} {" "}
            {freeChildrenAges.min}-{freeChildrenAges.max} {marketPlace.years} {" "}
            {`(${freeChildPrice} ${tourData?.currency})`} {" "}
            (x{childsFreeCount?.length})

            {tourSummary?.amountUnitTypePax && `: ${freeChildPrice} ${tourData?.currency}`}
          </p>
        }

        {/* childs summary */}
        {childsAboveAge?.length > 0 &&
          <p className='my-2'>
            {inventory.messages.children} {" "}
            {childrenAges.min}-{childrenAges.max} {marketPlace.years} {" "}
            ({childPrice} {tourData?.currency}) (x{childsAboveAge?.length})
            {tourSummary?.amountUnitTypePax && `: ${totalChildPrice} ${tourData?.currency}`}
          </p>
        }

        {/* childs consider as adults */}
        {childAsAdult?.length > 0 ?
          <p className='my-2'>
            {inventory.messages.children} +{childrenAges.max || (+tourSummary?.adult?.minAge > 0 ? +tourSummary?.adult?.minAge - 1 : tourSummary?.adult?.minAge ) }  {marketPlace.years}  {" "}
            ({adultPrice} {tourData?.currency}) (x{childAsAdult?.length})
            {tourSummary?.amountUnitTypePax && `: ${totalChildsAsAdult} ${tourData?.currency}`}
          </p>
          :
          null
        }
      </div>

      <div className='d-flex mt-3'>
        <img src={walletImgSrc} alt="wallet" className='img-fluid' />
        <div className='d-flex flex-column mx-2'>
          <span className='font-weight-bold text-dark-blue'>{inventory.messages.ReservationTotalCost} </span>
          <span className='price font-weight-bold h5 m-0'>{(tourSummary?.total - originalPrice).toFixed(2)} {tourData?.currency}</span>
        </div>
      </div>
    </div>
  )
}
