import React, { useEffect, useMemo, useState } from "react";
import { InfoCircle } from "../Icons";
import ContactInfo from "../../../ContactInfo";
import MessageInfo from "./MessageInfo";
import Adult from "./Adult";
import Locale from "translations";
import FreeBagsModel from "../Models/FreeBags";
import FreeRulesModel from "../Models/FreeRules";
import moment from "moment";
import { useMarketplaceState } from "context/marketplace";
import { useSBSState } from "context/global";
import { useHistory } from "react-router-dom";
import validate, { isFormValid } from "helpers/validate";
import { store } from "react-notifications-component";
import {
  FlightCheckAvailabilityBuilder,
  bookFlightBuilder,
  fetchFlightDetailsBuilder,
} from "services/webBuilder";
import FlightSummarySegmensts from "./FlightSummarySegmensts";
import NewPaymentAction from "components/NewPaymentAction";
import PriceBooking from "./PriceBooking";
import AdultInfoModel from "../Models/AdultInfoModel";

export default function FlightSummary() {
  const { marketPlace, inventory, webBuilder } = Locale;
  const { flightsSearch, outboundFlight, inboundFlight } =
    useMarketplaceState();
  const { allCountries, currency } = useSBSState();
  //   const [acceptedTerms, setAcceptTerms] = useState(false);
  const [BagModelIsOpen, setBagModelIsOpen] = useState(false);
  const [RuleModelIsOpen, setRuleModelIsOpen] = useState(false);
  const [AdultModelIsOpen, setAdultModelIsOpen] = useState(false);

  const toggleBag = () => {
    setBagModelIsOpen((prev) => !prev);
  };
  const toggleRule = () => {
    setRuleModelIsOpen((prev) => !prev);
  };
  const toggleAdult = () => {
    setAdultModelIsOpen((prev) => !prev);
  };
  const flightsSearchData = JSON.parse(localStorage.getItem("flightsSearch"));
  const typeTrip = (type) => {
    switch (type) {
      case "1":
        return marketPlace.roundTrip;
      case "2":
        return marketPlace.oneway;
      case "3":
        return marketPlace.MultiDestination
      default:
        return ""
    }
  }
  const fromCountryId =
    flightsSearch.type === "3"
      ? flightsSearch?.airLegs[0]?.from?.country_id
      : flightsSearch?.from?.country_id;

  const toCountryId =
    flightsSearch.type === "3"
      ? flightsSearch?.airLegs[0]?.to?.country_id
      : flightsSearch?.to?.country_id;

  const fromCountry = allCountries.find((i) => i.id === fromCountryId)?.name;
  const toCountry = allCountries.find((i) => i.id === toCountryId)?.name;

  /////////

  const history = useHistory();
  const countries = allCountries
    ? allCountries?.map((country) => {
      return { ...country, label: country?.name };
    })
    : null;
  const [flightData, setFlightData] = useState(null);
  const [flightAvailability, setFlightAvailability] = useState(null);

  const [actionType, setActionType] = useState("");
  const [formData, setFormData] = useState({});

  const [contactInfoData, setContactInfoData] = useState({
    contact_fullname: "",
    contact_email: "",
    contact_phoneCode: "",
    contact_phone: "",
  });
  const [passengersData, setPassengersData] = useState({
    adults: [],
    children: [],
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [acceptedTerms, setAcceptTerms] = useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
  const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
    ? JSON.parse(process.env.REACT_APP_OTP_CHECK)
    : false;

  const togglePaymentModal = () => {
    setActionType("");
    setFormData({});
    setPaymentModalIsOpen(!paymentModalIsOpen);
  };




  function checkFormErrors() {
    let submitError = {};
    // check errors for contact information
    Object.keys(contactInfoData).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: contactInfoData[key] },
          {
            required: true,
            email: key === "email" ? true : false,
            min: key === "phone" ? 9 : "",
            max: key === "phone" ? 13 : "",
          }
        ),
      };
    });

    // check errors for adults
    passengersData?.adults?.forEach((adult, index) => {
      Object.keys(adult).forEach((key) => {
        if (key === "birthday" || key === "cardExpiredDate") {
          Object.keys(adult[key]).forEach((subKey) => {
            submitError = {
              ...submitError,
              ...validate(
                {
                  name: [`adults-${key}-${subKey}-${index}`],
                  value: adult[key][subKey],
                },
                { required: true }
              ),
            };
          });
        } else {
          submitError = {
            ...submitError,
            ...validate(
              { name: [`adults-${key}-${index}`], value: adult[key] },
              { required: key !== "id" ? true : false }
            ),
          };
        }
      });
    });
    // check erros for childrens
    passengersData?.children?.forEach((child, index) => {
      Object.keys(child).forEach((key) => {
        if (key === "birthday" || key === "cardExpiredDate") {
          Object.keys(child[key]).forEach((subKey) => {
            submitError = {
              ...submitError,
              ...validate(
                {
                  name: [`children-${key}-${subKey}-${index}`],
                  value: child[key][subKey],
                },
                { required: true }
              ),
            };
          });
        } else {
          submitError = {
            ...submitError,
            ...validate(
              { name: [`children-${key}-${index}`], value: child[key] },
              { required: key !== "id" ? true : false }
            ),
          };
        }
      });
    });
    setErrors(() => submitError);
  }

  const passengerInitalObj = {
    firstName: "",
    lastName: "",
    sex: "",
    birthday: { day: "", month: "", year: "" },
    nationality: "",
    // idType: "",
    cardNum: "",
    cardExpiredDate: { day: "", month: "", year: "" },
    psgType: "",
  };
  // create adults and childs array from adults and child counts
  useEffect(() => {
    const adults =
      passengersData?.adults.length > 0
        ? passengersData?.adults
        : [...Array(+flightsSearch?.adults).keys()].map((index) => {
          return {
            ...passengerInitalObj,
            id: `adult-${index}`,
            psgType: "ADT",
          };
        });

    const children =
      passengersData?.children.length > 0
        ? passengersData?.children
        : [...Array(+flightsSearch?.children).keys()].map((index) => {
          return {
            ...passengerInitalObj,
            id: `child-${index}`,
            psgType: "CHD",
          };
        });
    setPassengersData({ adults, children });
  }, []);

  // get days and months and years for birthday and expiry date
  const calenderLookups = useMemo(() => {
    // adult start date, end date
    const adultMinAge = moment().subtract(12, "years");
    const adultMaxAge = moment().subtract(100, "years");
    const adultsDates = adultMinAge.startOf("year");
    // child start date, end date
    const childrenMinAge = moment().subtract(2, "years");
    const childrenMaxAge = moment().subtract(12, "years");
    const childrenDates = childrenMinAge.startOf("year");

    let adultYears = [];
    let childYears = [];
    let days = [...Array(31).keys()].map((day) => {
      day =
        String(day + 1).length === 1 ? `0${String(day + 1)}` : String(day + 1);
      return { id: day, name: day };
    });
    let months = [...Array(12).keys()].map((month) => {
      month =
        String(month + 1).length === 1
          ? `0${String(month + 1)}`
          : String(month + 1);
      return { id: month, name: month };
    });
    // adults calender lookup
    if (+flightsSearch?.adults > 0) {
      do {
        adultYears.push(adultsDates.year());
      } while (adultsDates.subtract(1, "year") > adultMaxAge);
      adultYears = adultYears.map((year) => {
        return { id: year, name: String(year) };
      });
    }
    // children calender lookup
    if (+flightsSearch?.children > 0) {
      do {
        childYears.push(childrenDates.year());
      } while (childrenDates.subtract(1, "year") > childrenMaxAge);
      childYears = childYears.map((year) => {
        return { id: year, name: String(year) };
      });
    }
    return { days, months, adultYears, childYears };
  }, [flightsSearch?.adults, flightsSearch?.children]);

  // check if there is any errors in passengers form
  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }
  // check Availability
  async function CheckAvailability() {
    let some_data = {}
    if (+flightsSearch.type === 2) {
      some_data = {
        cacheKey: flightsSearch?.cacheKey,
        solutionKey: outboundFlight?.solutionKey,
        journey_0: outboundFlight?.out_key,
        journey_1: inboundFlight?.journeys?.[0]?.flightId,
      };
    } else {
      some_data = {
        cacheKey: flightsSearch?.cacheKey,
        solutionKey: inboundFlight?.solutionKey,
        journey_0: inboundFlight?.out_key,
        journey_1: inboundFlight?.journeys?.[0]?.flightId,
      };
    }
    const data = {
      ...some_data,
      // cacheKey: flightsSearch?.cacheKey,
      // solutionKey: outboundFlight?.solutionKey,
      // journey_0: outboundFlight?.journeys?.[0]?.flightId,
      // journey_1: inboundFlight?.journeys?.[0]?.flightId,
      passengers: [...passengersData?.adults, ...passengersData?.children].map(
        (passanger) => {
          return {
            birthday: `${passanger?.birthday?.year?.name}-${passanger?.birthday?.month?.name}-${passanger?.birthday?.day?.name}`,
            firstName: passanger?.firstName,
            lastName: passanger?.lastName,
            nationality: passanger?.nationality?.code,
            // cardType: passanger.idType.value,
            cardType: "P",
            cardNum: passanger?.cardNum,
            cardExpiredDate: `${passanger?.cardExpiredDate?.year?.name}-${passanger?.cardExpiredDate?.month?.name}-${passanger?.cardExpiredDate?.day?.name}`,
            psgType: passanger?.psgType,
            sex: passanger?.sex?.name === "Male" ? "M" : "F",
          };
        }
      ),
    };
    const checkFlightAbaliabilityRes = await FlightCheckAvailabilityBuilder(
      data
    );
    if (checkFlightAbaliabilityRes.status === 200) {
      setFlightAvailability(checkFlightAbaliabilityRes?.data);
      togglePaymentModal();
    } else if (checkFlightAbaliabilityRes?.status === 202) {
      setActionType(checkFlightAbaliabilityRes?.data?.actionType);
      setFormData(checkFlightAbaliabilityRes?.data?.data?.formData);
    }
  }

  // handle book flight
  async function bookOnPayment(payment_method, currency, otp) {
    const data = {
      cacheKey: flightAvailability?.cacheKey,
      paymentMethod: payment_method,
      currency: currency,
      otp: otp,
      contactDetails: {   
        name:contactInfoData?.contact_fullname,
        email:contactInfoData?.contact_email,
        phone: `${contactInfoData?.contact_phoneCode?.value}${contactInfoData?.contact_phone}`,
      },
      passengers: [...passengersData?.adults, ...passengersData?.children].map(
        (passanger) => {
          return {
            birthday: `${passanger?.birthday?.year?.name}-${passanger?.birthday?.month?.name}-${passanger?.birthday?.day?.name}`,
            firstName: passanger?.firstName,
            lastName: passanger?.lastName,
            nationality: passanger?.nationality?.code,
            // cardType: passanger.idType.value,
            cardType: "P",
            cardNum: passanger?.cardNum,
            cardExpiredDate: `${passanger?.cardExpiredDate?.year?.name}-${passanger?.cardExpiredDate?.month?.name}-${passanger?.cardExpiredDate?.day?.name}`,
            psgType: passanger?.psgType,
            sex: passanger?.sex?.name === "Male" ? "M" : "F",
          };
        }
      ),
      client:{
        name:contactInfoData?.contact_fullname,
        email:contactInfoData?.contact_email,
        whatsapp_number: `${contactInfoData?.contact_phoneCode?.value}${contactInfoData?.contact_phone[0] === "0" ? contactInfoData?.contact_phone.substring(1) : contactInfoData?.contact_phone}`,
      },
      
    };
    const bookRes = await bookFlightBuilder(data);

    if (bookRes?.status === 200 || bookRes?.status === 201) {
      togglePaymentModal();
      store.addNotification({
        title: "info!",
        message: "Book successfully",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });

      history.push("/inventory/flights");
    } else if (bookRes?.status === 202) {
      setActionType(bookRes?.data?.actionType);
      setFormData(bookRes?.data?.data?.formData);
    }
  }

  // get flight details
  useEffect(() => {
    async function getFlightDetails() {
      let data = {};
      if (+flightsSearch.type === 2) {
        data = {
          cacheKey: flightsSearch?.cacheKey,
          solutionKey: outboundFlight?.solutionKey,
          journey_0: outboundFlight?.out_key,
          journey_1: inboundFlight?.journeys?.[0]?.flightId,
        };
      } else {
        data = {
          cacheKey: flightsSearch?.cacheKey,
          solutionKey: inboundFlight?.solutionKey,
          journey_0: inboundFlight?.out_key,
          journey_1: inboundFlight?.journeys?.[0]?.flightId,
        };
      }

      const viewFlightRes = await fetchFlightDetailsBuilder(data);
      if (viewFlightRes?.status >= 200 && viewFlightRes?.status < 300) {
        setFlightData(viewFlightRes?.data);
      } else {
        history.push("/flight/outbound-view");
      }
    }
    if (!flightData) {
      getFlightDetails();
    }
  }, []);

  // if there is no error in passenger form open payment modal and CheckAvailability
  useEffect(() => {
    if (isFormValid(errors)) {
      CheckAvailability();
    }
  }, [isErrorLoaded]);

  return (
    <div className="flight-summary">
      <div className="head">
        <div className="location">
          <div>
            <p>
              {fromCountry} - {toCountry}
              <span> ({typeTrip(flightsSearchData?.type)})</span>
            </p>
          </div>
          <div className="d-flex info w-100">
            <p>
              {marketPlace.class}: <span>{flightsSearchData?.cabinClass?.name}</span>
            </p>
            <p>
              {inventory.messages.travelers}: <span>{flightsSearchData?.adults}</span>
            </p>
            <p>
              {webBuilder.dates}:{" "}
              <span>
                {" "}
                {moment(flightsSearchData?.departureDate).format(
                  "DD/MM/YYYY"
                )}{" "}
                - {moment(flightsSearchData?.arrivalDate).format("DD/MM/YYYY")}
              </span>
            </p>
          </div>
        </div>
      </div>
      <div className="summary-content">
        <div className="row">
          <div className="col-md-9">
            <div className="details">
              <div className="confirmed">
                <p>
                  {webBuilder.congratulationsForChosenYourFlight}
                </p>
              </div>
              <div className="flight-detail">
                <h3>{webBuilder.flightDetails}</h3>

                {flightData?.journey_0?.[0] ? (
                  <FlightSummarySegmensts
                    solution={flightData}
                    journyData={flightData?.journey_0?.[0]}
                    type="departure"
                  />
                ) : null}
                {flightData?.journey_1?.[0] ? (
                  <FlightSummarySegmensts
                    solution={flightData}
                    journyData={flightData?.journey_1?.[0]}
                    type="arrival"
                  />
                ) : null}


              </div>
              {countries ? (
                <>
                  <ContactInfo
                    countries={countries}
                    contactInfoData={contactInfoData}
                    setContactInfoData={setContactInfoData}
                    errors={errors}
                    setErrors={setErrors}
                  />
                  <MessageInfo />
                  <div className="passanger">
                    <h3>{inventory.messages.passengers}</h3>
                    <div className="buttons">
                      <button
                        className="btn btn-rules"
                        onClick={() => toggleRule()}
                      >
                        <InfoCircle /> {webBuilder.fareRules}
                      </button>
                      <button
                        className="btn btn-rules"
                        onClick={() => toggleBag()}
                      >
                        <InfoCircle />  {webBuilder.fareBaggage}
                      </button>
                    </div>
                  </div>
                  {passengersData?.adults?.length > 0 &&
                    passengersData?.adults?.map((adult, index) => {
                      return (
                        <Adult
                          index={index}
                          passengerType={"adults"}
                          passanger={adult}
                          passengersData={passengersData}
                          setPassengersData={setPassengersData}
                          calenderLookups={calenderLookups}
                          countries={countries}
                          errors={errors}
                          setErrors={setErrors}
                          toggleAdult={toggleAdult}
                        />
                      );
                    })}
                  {passengersData?.children?.length > 0 &&
                    passengersData?.children?.map((child, index) => {
                      return (
                        <Adult
                          index={index}
                          passengerType={"children"}
                          passanger={child}
                          passengersData={passengersData}
                          setPassengersData={setPassengersData}
                          calenderLookups={calenderLookups}
                          countries={countries}
                          errors={errors}
                          setErrors={setErrors}
                        />
                      );
                    })}
                  {/* <Adult />
              <Adult /> */}

                  <div className="d-flex mt-4 align-items-center justify-content-between mb-3 flex-wrap">
                    <div>
                      <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        value={acceptedTerms}
                        onChange={(e) => setAcceptTerms(e.target.checked)}
                      />
                      <label htmlFor="terms" className="mx-1 pointer">
                        {marketPlace.acceptedTerms}
                      </label>
                    </div>
                    <button
                      className="btn flight-checkout-btn  btn-primary "
                      onClick={submit}
                      disabled={!acceptedTerms}
                    >
                      {marketPlace.submit}
                    </button>
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="col-md-3">
            <PriceBooking
              flightData={flightData}
            />




          </div>
        </div>
      </div>
      <FreeBagsModel
        title={marketPlace.freebaggage}
        toggle={toggleBag}
        isOpen={BagModelIsOpen}
        flightData={flightData}
      />
      <FreeRulesModel toggle={toggleRule} isOpen={RuleModelIsOpen} flightData={flightData} />
      <AdultInfoModel toggle={toggleAdult} isOpen={AdultModelIsOpen} />

      {paymentModalIsOpen && (

        <NewPaymentAction
          isOpen={paymentModalIsOpen}
          toggleModal={togglePaymentModal}
          canPayLater={flightAvailability?.canPayLater}
          formData={formData}
          actionType={actionType}
          //Price={flightAvailability?.exchange_rate_price}
          Price={flightData?.totalPrice}
          // currency={flightAvailability?.exchange_rate_currency}
          currency={currency}
          secondCurrency={currency}
          OTP={OTP_CHECK}
          onPayment={bookOnPayment}
          setActionType={setActionType}
          setFormData={setFormData}
        />
      )}
    </div>
  );
}
