import React, { useRef, useState } from "react";
import { ReactComponent as VideoIcon } from "assets/images/webBuilder/video.svg";
import CustomModal from "modules/WebBuilder/shared/CustomModal";
import { getYoutubeId } from 'modules/WebBuilder/helpers/videoUrl';

function VideoItem({ url, onChange, id }) {
	const [mediaModal, setmediaModal] = useState(false);
	const openModalHandler = () => {
		setmediaModal((prev) => !prev);
	};
	const inputRef = useRef(null);

	const saveUrl = () => {
		const value = getYoutubeId(inputRef.current.value);

		if (value) {
			onChange(value, id);
			openModalHandler();
		}


	};

	return (
		<div className="custom-container-video">
			{url ? (
				<iframe
					style={{ maxWidth: "100%" }}
					src={`https://www.youtube.com/embed/${url}`}
					className="w-100 h-100"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
					X-Frame-Options="*"
				></iframe>
			) : (
				// <iframe
				// 	style={{ maxWidth: "100%" }}
				// 	src={url}
				// 	className="w-100 h-100"
				// 	width="auto"
				// 	height="auto"
				// 	title="YouTube video player"
				// 	frameborder="0"
				// 	allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
				// 	allowfullscreen
				// ></iframe>
				<div className="container-video-input">
					<button onClick={openModalHandler}>
						<VideoIcon />
						Add video
					</button>
				</div>
			)}
			<CustomModal header="Video" isOpen={mediaModal} toggle={openModalHandler}>
				<div className="cutom-container-video-modal">
					<label htmlFor="video-url-input">Source</label>
					<input ref={inputRef} id="video-url-input" placeholder="URL link" />
					<button
						onClick={saveUrl}
						className="align-self-end primary-button w-25"
					>
						Save
					</button>
				</div>
			</CustomModal>
		</div>
	);
}

export default VideoItem;
