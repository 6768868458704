import { ReactComponent as LeftIcon } from "assets/images/webBuilder/left.svg";

export default function CustomLeftArrow(props) {
  return (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <LeftIcon className="r-left-icon" />
    </button>
  )
}