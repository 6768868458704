import { ReactComponent as RightICon } from "assets/images/webBuilder/right.svg";

export default function CustomRightArrow(props) {
  return (
    <button
      className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      onClick={props.onClick}
      disabled={props.disabled}
    >
      <RightICon />
    </button>
  )
}