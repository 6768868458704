import TourCardThemeFive from './TourCard';


export default function ToursTheme5({ items }) {


  return (
    <div className="tours-cards-container">
      {items.map((props, index) => (

        <TourCardThemeFive index={index} {...props} cardType="tours" />

      ))}
    </div>
  )
}