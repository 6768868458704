/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import Locale from 'translations';
import filterReset from "assets/images/filterReset.svg";
import { Collapse } from 'reactstrap';
import TextField from 'components/Form/TextField/TextField';
import { useMarketplaceState } from 'context/marketplace';
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";


export default function ToursFilter({ filtersLookup, initialPrices, filters,
   setFilters, setPage ,   toggleOpenFilter  }) {
  const { marketPlace, payment, landing } = Locale;
  const { toursSearchResults} = useMarketplaceState();
  const [isActivityNameOpen, setIsActivityNameOpen] = useState(true);
  const [isOpenPriceRange, setIsOpenPriceRange] = useState(true);
  const [isOpenCategories, setIsOpenCategories] = useState(true);
  const [isOpenActivityDuration, setIsOpenActivityDuration] = useState(true);
  const [isOpenServices, setIsOpenServices] = useState(true);
  const [isOpenActivityFor, setisOpenActivityFor] = useState(true);

  function reset() {
    setFilters({
      name: "",
      price: { from: initialPrices?.from, to: initialPrices?.to },
      categories: [],
      duration: [],
      services: [],
      activity_for: [],
      sortBy: "alphabetical",
      sortType: "desc",
    });
    setPage(1)
  }


  const filterByPrice = (type, value) => {
    let price = { from: initialPrices?.from, to: initialPrices?.to }
    value = +value
    if (value < 0 && type === "min") price.from = 0;
    if (value < 0 && type === "max") price.to = 0;
    else if (type === "max" && value > price?.to) price.to = +initialPrices?.to
    else {
      type === "min" ? price.from = value : price.to = value
    }
    setFilters({
      ...filters,
      price
    })
  };

  function filterByCheckbox(e, obj, filterName) {
    const checked = e.target.checked;
    let checkedArray = filters?.[filterName];
    if (checked) {
      checkedArray = [...filters[filterName], obj?.code];
    } else {
      checkedArray = checkedArray.filter(
        (c) => c.toString() !== obj?.code?.toString()
      );
    }
    setFilters({ ...filters, [filterName]: checkedArray });
  }

  return (
    <>
      <div className=" d-flex justify-content-between align-items-center mt-2">
     
        <div className="filter-head terms-color text-uppercase">
          <h6 className="font-weight-bold h5 hotel-found">
            {marketPlace.messages.filterBy}:
          </h6>
        </div>
        <button className="btn bg-white clear-filter px-3 mx-2 reset" onClick={reset}>
          <img src={filterReset} alt="" className="mx-2" />
          {payment.messages.clear}
        </button>
      </div>
      {/* filters */}
      <div className='tour-filter'>
      <button className="btn close-filter-btn p-0" onClick={toggleOpenFilter}>
        <CloseIcon />
      </button>
        {/* Activity Name */}
        <div className="bg-white p-3 rounded mt-2">
          <h6 className="text-dark-blue" onClick={() => setIsActivityNameOpen(!isActivityNameOpen)}>
            {marketPlace.activityName}
            <span className="float-right">
              <i className="fa fa-angle-down"></i>
            </span>
          </h6>
          <Collapse isOpen={isActivityNameOpen}>
            <div className="mt-3">
              <TextField
                type="text"
                hasLabel={false}
                placeholder={marketPlace.messages.search}
                value={filters?.name}
                onChange={(e) => {
                  if (e.target.value.length > 0 || e.target.value !== " ") {
                    setFilters({
                      ...filters,
                      name: e.target.value
                    })
                  }

                }}
              />
            </div>
          </Collapse>
        </div>
        {/* price range */}
        <div className="bg-white p-3 rounded mt-2">
          <h6 className="text-dark-blue" onClick={() => setIsOpenPriceRange(!isOpenPriceRange)}>
            {marketPlace.messages.priceRange}
            <span className="float-right">
              <i className="fa fa-angle-down"></i>
            </span>
          </h6>
          <Collapse isOpen={isOpenPriceRange}>
            <div className="d-flex  align-items-center mt-3 filter-price-range">
              {/* price range from */}
              <div>
                <TextField
                  hasLabel={false}
                  type="number"
                  min={1}
                  size="small"
                  sx={{ width: "80px" }}
                  value={filters?.price?.from !== 0 ? filters?.price?.from : initialPrices?.from}
                  onChange={(e) => {
                    if (+e.target.value > 0) {
                      setFilters({
                        ...filters,
                        price: { from: +e.target.value, to:filters?.price?.to !== 0 ? +filters?.price?.to : +initialPrices?.to }
                      });
                    }
                  }}
                  // onChange={(e) => {
                  //   filterByPrice("min", e.target.value)
                  // }}

                  autoComplete="off"
                  extraTextPosition={"append"}
                  extraText={toursSearchResults?.data?.[0]?.currency}
                />
              </div>
              <span className="mx-2 text-uppercase">{marketPlace.to}</span>
              <div>
                <TextField
                  hasLabel={false}
                  type="number"
                  min={1}
                  size="small"
                  sx={{ width: "80px" }}
                  value={filters?.price?.from !== 0 ? filters?.price?.to : initialPrices?.to}
                  // onChange={(e) => {
                  //   filterByPrice("max", e.target.value)
                  // }}

                  onChange={(e) => {
                    if (+e.target.value >= 1) {
                      setFilters({
                        ...filters,
                        price: { from: filters?.price?.from !== 0 ? +filters?.price?.from : +initialPrices?.from, to: +e.target.value }
                      });
                    }
                  }}
                  autoComplete="off"
                  extraTextPosition={"append"}
                  extraText={toursSearchResults?.data?.[0]?.currency}
                />
              </div>
              {/* price range to */}
            </div>
          </Collapse>
        </div>
        {/* categories */}
        <div className="bg-white p-3 rounded mt-2">
          <h6 className="text-dark-blue" onClick={() => setIsOpenCategories(!isOpenCategories)}>
            {marketPlace.categories}
            <span className="float-right">
              <i className="fa fa-angle-down"></i>
            </span>
          </h6>
          <Collapse isOpen={isOpenCategories}>
            <ul className="list-unstyled my-3">
              {filtersLookup?.categories?.map((category, index) => {
                return (
                  <li className='mt-2' key={`${category?.code}-${index}`}>
                    <div className="airlines-checkbox">
                      <div className="custom-control custom-checkbox ">
                        <input
                          className="custom-control-input permChecks"
                          id={category?.code}
                          name="classification[]"
                          type="checkbox"
                          value={category?.code}
                          onChange={(e) => {
                            filterByCheckbox(e, category, "categories")
                          }}
                          checked={filters?.categories?.includes(category?.code)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={category?.code}
                        >
                          {category?.name}
                        </label>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Collapse>
        </div>
        {/* Activity Duration */}
        <div className="bg-white p-3 rounded mt-2">
          <h6 className="text-dark-blue" onClick={() => setIsOpenActivityDuration(!isOpenActivityDuration)}>
            {marketPlace.activityDuration}
            <span className="float-right">
              <i className="fa fa-angle-down"></i>
            </span>
          </h6>
          <Collapse isOpen={isOpenActivityDuration}>
            <ul className="list-unstyled my-3">
              {filtersLookup?.duration?.map((activityDuration, index) => {
                return (
                  <li className='mt-2' key={`${activityDuration?.code}-${index}`}>
                    <div className="airlines-checkbox">
                      <div className="custom-control custom-checkbox ">
                        <input
                          className="custom-control-input permChecks"
                          id={activityDuration?.code}
                          name="classification[]"
                          type="checkbox"
                          value={activityDuration?.code}
                          onChange={(e) => {
                            filterByCheckbox(e, activityDuration, "duration")
                          }}
                          checked={filters?.duration?.includes(activityDuration?.code)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={activityDuration?.code}
                        >
                          {activityDuration?.name}
                        </label>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Collapse>
        </div>
        {/* Services */}
        <div className="bg-white p-3 rounded mt-2">
          <h6 className="text-dark-blue" onClick={() => setIsOpenServices(!isOpenServices)}>
            {landing.services}
            <span className="float-right">
              <i className="fa fa-angle-down"></i>
            </span>
          </h6>
          <Collapse isOpen={isOpenServices}>
            <ul className="list-unstyled my-3">
              {filtersLookup?.services?.map((service, index) => {
                return (
                  <li className='mt-2' key={`${service?.code}-${index}`}>
                    <div className="airlines-checkbox">
                      <div className="custom-control custom-checkbox ">
                        <input
                          className="custom-control-input permChecks"
                          id={service?.code}
                          name="classification[]"
                          type="checkbox"
                          value={service?.code}
                          onChange={(e) => {
                            filterByCheckbox(e, service, "services")
                          }}
                          checked={filters?.services?.includes(service?.code)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={service?.code}
                        >
                          {service?.name}
                        </label>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Collapse>
        </div>
        {/* Activity For */}
        <div className="bg-white p-3 rounded mt-2">
          <h6 className="text-dark-blue" onClick={() => setisOpenActivityFor(!isOpenActivityFor)}>
            {marketPlace.activityFor}
            <span className="float-right">
              <i className="fa fa-angle-down"></i>
            </span>
          </h6>
          <Collapse isOpen={isOpenActivityFor}>
            <ul className="list-unstyled my-3">
              {filtersLookup?.activity_for?.map((activity, index) => {
                return (
                  <li className='mt-2' key={`${activity?.code}-${index}`}>
                    <div className="airlines-checkbox">
                      <div className="custom-control custom-checkbox ">
                        <input
                          className="custom-control-input permChecks"
                          id={activity?.code}
                          name="classification[]"
                          type="checkbox"
                          value={activity?.code}
                          onChange={(e) => {
                            filterByCheckbox(e, activity, "activity_for")
                          }}
                          checked={filters?.activity_for?.includes(activity?.code)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={activity?.code}
                        >
                          {activity?.name}
                        </label>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </Collapse>
        </div>
      </div>
    </>

  )
}
