import { Modal, ModalBody } from "reactstrap";

const CustomModal = ({
	modalIsOpen,
	size,
	toggle,
	header,
	children,
	centered,
}) => {
	return (
		<>
			<Modal
				isOpen={modalIsOpen}
				toggle={toggle}
				size={size}
				centered={centered}
				className=""
			>
				<div className=" p-3  border-bottom d-flex justify-content-between">
					<h4 className="mb-0  font-weight-bold">{header}</h4>
					<span
						className="close-modal fas fa-times text-gray"
						onClick={toggle}
					></span>
				</div>

				<ModalBody className="payModal">
					<div>{children}</div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default CustomModal;
