import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { useWebBuilderState } from "context/webBuilder";
import { isArray } from "lodash";
import Locale from "translations";



export default function HeaderContactPhones() {
  const { webBuilder } = Locale;
  const { hero } = useWebBuilderState();
  const header = hero?.header;

  return (
    <div className="d-flex align-items-center gap-10 call-us">
      <PhoneIcon color="#1e85ff" className="mirror-rtl" />
      <div className="webBuilder-contact d-flex flex-column ml-1">
        <p>{webBuilder.callUs}</p>
        <div className="body text-primary-color contact-phones">
          {isArray(header?.phoneNumber) ? (
            <>
              <a href={`tel:${header?.phoneNumber?.[0]}`}>
                {header?.phoneNumber?.[0]}
              </a>
              {header?.phoneNumber?.length > 1 &&
                <>
                  <i className="fas fa-chevron-down ms-2" />
                  <p className="phone-list">
                    {header?.phoneNumber?.map((number, index) => {
                      if (index > 0) {
                        return (
                          <a href={`tel:${number}`} key={`${number}-${index}`}>
                            <span>{number}</span>
                          </a>
                        );
                      }
                      return null;
                    })}
                  </p>
                </>
              }
            </>
          ) : (
            <p className="body fw-700" style={{ width: "160px", direction: "ltr" }}>
              <a className="text-primary-color hover" href={`tel:${header?.phoneNumber?.split(" ")[0]}`}>
                {header?.phoneNumber?.split(" ")[0]}
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}
