import walletImg from "assets/images/wallet/walletSuccessfully.png";
import { useMarketplaceState } from "context/marketplace";
import useShowPermission from "hooks/useShowPermission";
import React from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function SuccessfullyTourPayment({ reservationRef, isManual }) {
	const history = useHistory();
	const { commons } = Locale;
	const inventoryPermission = useShowPermission({
		permission: ["View-Inventory-Tours", "Manage-Inventory-Tours"],
	});

	return (
		<div className="container">
			<div
				className="recharge-img my-2 py-5 mt-5 text-center"
				style={{ minHeight: "85vh" }}
			>
				<div className="w-100 py-4 pb-5">
					<img src={walletImg} className="mt-5" alt="Wallet" />
					{!isManual ? (
						<h6 className="mt-2 modal-head">
							<span>{commons.yourReservation}</span>{" "}
							<span className="blue-drop-btn font-weight-bold">
								#{reservationRef}
							</span>{" "}
							<span>{commons.successfullyCompleted}</span>
						</h6>
					) : (
						<h6 className="mt-5">
							{commons.YourRequestHasBeenSentSuccessfully}
						</h6>
					)}

					<div className="col-md-6 m-auto">
						<button
							onClick={() =>
								history.push(
									inventoryPermission ? "/inventory/tour" : "/statistics"
								)
							}
							className="btn bg-nxt w-100 mt-3"
						>
							{commons.GoToMyBookings}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
