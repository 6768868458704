import React, { useState } from "react";
import { Collapse } from 'reactstrap';
import faqImageBG from "assets/images/webBuilder/theme10-dots-bg.png"
import { useSBSState } from "context/global";

export default function FAQTheme10({
	title,
	faqs,
	faqImage,
	body
}) {


	const { locale } = useSBSState();
	const [collapsedItems, setCollapsedItems] = useState([]);


	return (
		<div className="faq-container">
			<div className="faq-questions">
				<h4 className="h4 font-weight-bold">{title}</h4>
				<p className="mb-4 body">{body}</p>
				{faqs &&
					faqs?.map((que, index) => {
						const isOpen = collapsedItems.includes(index);
						const openCollapse = () => {
							const updated = [...collapsedItems, index];
							setCollapsedItems(updated);
						};
						const closeCollapse = () =>
							setCollapsedItems(
								collapsedItems.filter((i) => i !== index)
							);

						return (
							<div className="faqs-question-wrapper"  key={que.id}>
								<div className="faqs-container-question pointer" onClick={isOpen ? closeCollapse : openCollapse}>
									<h5>{que.question[locale]}</h5>
									{isOpen
										? <i className="fas fa-chevron-up"></i>
										: <i className="fas fa-chevron-down"></i>
									}
								</div>
								<Collapse isOpen={isOpen}>
									<p className="faqs-container-body">{que.body[locale]}</p>
								</Collapse>
							</div>
						)
					})}
			</div>
			<div className="faq-image">
				<div className="abstract-bg" style={{ backgroundImage: `url(${faqImageBG})` }}></div>
				<img src={faqImage} alt={title} />
			</div>
		</div>
	);
}
