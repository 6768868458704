import { isObject } from "lodash";
// import Locale from "translations";
import { useSBSState } from "context/global";

export default function ThemeFourServiceCard(props) {
  const { locale } = useSBSState();
  // const { webBuilder } = Locale;
  const logoImage = props.image ? <img src={props.image} alt="Logo" /> : null;

  return (
    <div className="travel-service-card">
      <div className={`travel-service-image-container`}>{logoImage}</div>

      <div className="text">
        {/* <h3 className="">{webBuilder[props.type]}</h3> */}

        <p className="travel-service-title">
          {isObject(props?.header) ? props?.header?.[locale] : props?.header}
        </p>

        <p className="travel-service-description">
          {isObject(props?.body) ? props?.body?.[locale] : props?.body}
        </p>
      </div>
    </div>
  );
}
