import Locale from "translations";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { Link } from "react-router-dom";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function HotelCardThemeFive(props) {
  let {
    name,
    name_localized,
    price,
    image,
    currency,
    stars,
    product_uuid,
    initialName,
    locationName,
    city,
  } = props;
  const { webBuilder } = Locale;
  const { locale } = useSBSState()
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  const hotelUrl = `/hotels/${product_uuid}`;
  let rate = +stars && +stars > 5 ? 5 : +stars;


  return (
    <Link to={hotelUrl} className="package-hotel-card" >
      <span className="rate">
        <GoldenStarIcon />
        {rate}
      </span>
      <div className="card-img">

        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
            src="https://back-sbs.staging.safa.asia/api/v1/files/fetch/1847cef6-e5c0-40bb-b1dd-d2dc7827cd58"
            alt="Safa Visa"
          />
        )}
      </div>

      <div className="card-content-top">
        {/* package name and location */}
        <div className="card-details">

          <h4 className="title">{name_localized?.[locale] || name || initialName}</h4>
          <p className="location">
            <LocationIcon />
            <span className="mx-2">
              {locationName?.[locale] || locationName?.en || locationName}
              {city && <span>, {city}</span>}

            </span>
          </p>
        </div>
        {/* package price */}
        <div>
          <span>{webBuilder.from}</span>
          <p className="price">
            <span className="amount">{exchangedPrice}</span> {" "}
            <span className="currency">{exchangeCurrency}</span>
          </p>
        </div>
      </div>
    </Link>
  )
}