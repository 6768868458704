import Locale from "translations";
import * as actionTypes from "./actions";

// Define initialState
const initialState = {
	isAppear: false,
	title: Locale.messages.loading,
	message: null,
};

// Define the Reducer
export default function loader_reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.Show_Loader:
			return {
				...state,
				isAppear: true,
				title: Locale.messages.loading,
				message: action.message,
			};
		case actionTypes.Remove_Loader:
			return {
				...state,
				isAppear: false,
				title: null,
				message: null,
			};
		default:
			return state;
	}
}
