import Locale from 'translations';
import { FlightIcon, HotelIcon, PackageIcon, ToursIcon, TransportIcon, VisaIcon } from '../icons';
import { useSBSState } from "context/global";
import { isObject } from "lodash";


export default function ServiceCardThemeFive({ type, header, body }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();


  let icon;
  switch (type) {
    case "hotels":
      icon = <HotelIcon />;
      break;
    case "visa":
      icon = <VisaIcon />;
      break;
    case "transport":
      icon = <TransportIcon />;
      break;
    case "transfer":
      icon = <TransportIcon />;
      break;
    case "tours":
      icon = <ToursIcon />;
      break;
    case "packages":
      icon = <PackageIcon />;
      break;
    case "flights":
      icon = <FlightIcon />;
      break;
    default:
      icon = "";
  }

  return (
    <div className="service-card">
      <span className="icon">
        {icon}
      </span>
      <h3 className="title">{webBuilder[type]}</h3>


      <h4 className="sub-title">
        {isObject(header) ? header?.[locale] : header}
      </h4>

      <p className="travel-service-description">
        {isObject(body) ? body?.[locale] : body}
      </p>
    </div>
  );
}
