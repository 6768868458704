import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import { logoURL } from "helpers/utils";
import { useHistory, Link } from "react-router-dom";
import Locale from "translations";

const currentYear = new Date().getFullYear();

export default function FooterThemeFour({ socialMediaIcons }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const history = useHistory();

  const {
    footer: footerDetails,
    hero,
    mainMenu,
  } = useWebBuilderState();
  const header = hero?.header;

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const visibleMenuItems = mainMenu?.filter((item) => item?.visible && !item?.subNavs);
  const socialLinksArray = footerDetails?.links?.filter(link => link?.visible && link?.url)
  
  const nav = (
    <nav className="nav-list">
      <ul className="links-list d-flex" style={{ gap: 36 }}>
        <li
          onClick={() => {
            history.push({
              pathname: `/`,
            });
          }}
          className={"footer-text"}
        >
          {webBuilder.home}
        </li>

        <li
          onClick={() => {
            history.push({
              pathname: `/`,
              state: "faq-section",
            });
            scrollToSection("faq-section");
          }}
          className={"footer-text"}
        >
          {webBuilder.getHelp}
        </li>

        <li
          onClick={() => {
            history.push({
              pathname: `/`,
              state: "our-service",
            });
            scrollToSection("our-service");
          }}
          className={"footer-text"}
        >
          {webBuilder.ourServices}
        </li>

        {visibleMenuItems.map((menuItem) => (
          <li
            onClick={() => {
              history.push({
                pathname: `/${menuItem?.url}`,
              });
            }}
            className={"footer-text"}
          >
            {menuItem?.title?.[locale]}
          </li>
        ))}
      </ul>
    </nav>
  );

  return (
    <div className="custom-container">
      <div className=" footer-holder">
        <div className="footer-top">
          {/* logo and Page Links */}
          <div>
            <Link to="/">
              <img
                className="footer-photo"
                src={logoURL(header?.logo, (header?.logo?.[locale?.toLowerCase()] || header?.logo?.en || header?.logo?.ar))}
                alt="Logo"
              />
            </Link>

            <div className="footer-link-holder">{nav}</div>
          </div>

          {/* social */}
          {socialLinksArray.length > 0 &&
            <div className="footer-social">
              <p className="mb-3 footer-text">{webBuilder.followUsOn}</p>
              <div className="d-flex my-1 footer-social-holder">
                {socialLinksArray?.map((link) => {
                  return (
                    <div key={link?.id}>
                      <a href={link?.url} target="_blank" rel="noreferrer">
                        {socialMediaIcons[link?.id]}
                      </a>
                    </div>
                  );
                })}
              </div>
            </div>
          }
        </div>

        {/* copy right */}
        <div className="footer-copy-right d-flex justify-content-center align-items-center">
          <p className="footer-text mt-1">
            {" "}
            {webBuilder.allRightsReserved} {" - "}
            <a
              href="https://safasoft.com/"
              target="_blank"
              rel="noreferrer"
            // className="text-white"
            >
              {webBuilder.safasoft}
            </a>{" "}
            © {currentYear}
          </p>
        </div>
      </div>
    </div>
  );
}
