// Translate
import Locale from "translations";
// Assets
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import placeholderSrc from "assets/images/placeHoleder.png"
//  Hooks
import { Link } from "react-router-dom";
import { useSBSState } from "context/global";
import useExchangeRate from "hooks/useExchangeRate";

// ------------------------------------------------------------------------------------------

export default function HotelCardThemeTen(props) {
  let {
    name,
    name_localized,
    price,
    image,
    currency,
    stars,
    product_uuid,
    initialName,
    locationName,
    city,
    // index
  } = props;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });

  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const hotelUrl = `/hotels/${product_uuid}`;
  // let rate = +stars && +stars > 5 ? 5 : +stars;


  return (
    <Link to={hotelUrl} className="hotel-card">
      <div className="card-img">
        <img src={image} alt={name}
          onError={(e) => { e.target.src = placeholderSrc }}
        />
      </div>
      {/* card-caption */}
      <div className="card-content">
        <h2 className="title">{name_localized?.[locale] || name || initialName}</h2>
        {/* star */}
        <div className="rate">
          {[...Array(5)].map((i, index) =>
            index < stars ? (
              <GoldenStarIcon key={index} />
            ) : (
              <StarIcon key={index} />
            )
          )}
        </div>
        <p className="location">
          <LocationIcon />
          <span className="mx-2">{locationName?.[locale] || locationName?.en || locationName}</span>
          {city &&
            <>
              <span className='mx-1'>/</span>
              <span>{city}</span>
            </>
          }
        </p>

        {/* price-info */}
        <div className="price">
          <span>{webBuilder.from}</span>
          <p className="amount"> {exchangedPrice}</p>
          <span className="currency">{exchangeCurrency}</span>
        </div>
      </div>
    </Link>
  )
}