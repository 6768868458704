import { createStore, combineReducers, applyMiddleware, compose } from "redux";
// import logger from 'redux-logger';
import auth_reducer from "./reducers/auth/auth_reducer";
import register_reducer from "./reducers/auth/register_reducer";
import locale_reducer from "./reducers/locale/locale_reducer";
import loader_reducer from "./reducers/loader/loader_reducer";
import product_builder_reducer from "./reducers/products-builder/product_builder_reducer";
import product_status from "./reducers/products-builder/product_status";
import payment_reducer from "./reducers/payment/payment_reducer";
import reservation_reducer from "./reducers/reservation/reservation_reducer";
import interests_reducer from "./reducers/Interests/interests_reducer";

const middlewares = [];

// if (process.env.NODE_ENV === `development`) {
//   middlewares.push(logger);
// }

// Redux: Combine Multiple Reducers together.
const rootReducer = combineReducers({
	auth_reducer,
	register_reducer,
	locale_reducer,
	loader_reducer,
	product_builder_reducer,
	product_status,
	payment_reducer,
	reservation_reducer,
	interests_reducer,
});

// Redux: Setup Redux DevTools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux: Setup the Store
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(logger)));
const store = composeEnhancers(applyMiddleware(...middlewares))(createStore)(
	rootReducer
);

export default store;
