import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { paymentActionDone } from "services/webBuilder";
import Locale from "translations";

export default function PaymentAction(props) {
  const search = props.location.search;
  const name = new URLSearchParams(search);
  const [params, setParams] = useState({});
  const { tap } = useParams();

  const history = useHistory();
  const { payment, inventory } = Locale;


  useEffect(() => {
    let x = {};
    name.forEach(function (value, key) {
      x = { ...x, [key]: value };
    });
    setParams(x);
    pay(x);
  }, []);

  const pay = async (data) => {
    const response = await paymentActionDone(data, tap);

    if (response.status === 200 || response.status === "paid") {

      store.addNotification({
        title: inventory.messages.bookingSuccessfully,
        message: payment.messages.paymentSuccessful,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => {
          history.push("/");
        },
      });
      history.push("/");
    }else{
      setTimeout(() => {
        history.push("/");
      }, 2500);
    }
  };
  return <div>Loading...</div>;
}
