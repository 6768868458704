
export default function sbsReducer(state, action) {

	switch (action.type) {
		case "setLocale":
			localStorage.setItem("currentLocale", action.payload);
			window.location.reload();
			return { ...state, locale: action.payload };

		case "setLoading":
			return { ...state, loading: action.payload };

		case "setError":
			return {
				...state,
				isError: action.payload,
				ErrorMassage: { ...action.message },
			};

		case "setexchangeRates": {
			return { ...state, exchangeRates: action.payload }
		}
		case "setIsFirstEntry": {
			return { ...state, exchangeRates: action.payload }
		}

		case "getCountries": {
			return { ...state, allCountries: action.payload }
		}
		case "getCurrencies": {
			return { ...state, allCurrencies: action.payload }
		}
		case "setInitialCurrency": {
			localStorage.setItem("currentCurrency", action.payload);
			localStorage.setItem("first", action.payload);
			return { ...state, currency: action.payload };
		}

		case "setCurrency": {
			let currentUrl = window.location.href
			localStorage.setItem("currentCurrency", action.payload);
			// hotels 
			if (window.location.href.includes("/confirm-hotel")) {
				currentUrl = '/market-view'
			}
			// tours
			if (!window.location.href.includes("inventory") && (window.location.href.includes("/tour") || window.location.href.includes("/tours"))) {
				localStorage.removeItem("toursCart");
				let toursSearch = JSON.parse(localStorage.getItem('toursSearch'));
				localStorage.setItem('toursSearch', JSON.stringify({ ...toursSearch, cacheKey: null }));
				currentUrl = '/tours/result'
			}
			// transfer
			if (!window.location.href.includes("/inventory") && window.location.href.includes("/transfer")) {
				localStorage.removeItem("transferCart");
				currentUrl = '/transfer'
			}
			// flights
			if (window.location.href.includes("/flight/outbound-view") || window.location.href.includes("/flight/inbound-view")) {
				localStorage.removeItem("flightsSearch");
				currentUrl = '/market-place-new'
			}
			// visa saudi umrah
			if (window.location.href.includes("/market-search/visa-details")) {
				currentUrl = '/market-place-new/umrah/visa'
			}
			// visa global
			if (window.location.href.includes("/market-search/global-visa-details")) {
				currentUrl = '/market-place-new/global/visa'
			}

			window.location.href = currentUrl;
			return { ...state, currency: action.payload };
		}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}
