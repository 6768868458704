import { ClockIcon } from "../Icons";
import { useSBSState } from "context/global";
import moment from "moment";
import Locale from "translations";

export default function FlightSummarySegmensts({ solution, journyData, type }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(+totalMinutes / 60);
    const minutes = +totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  }

  const segments = journyData?.segments;

  return (
    <>
      <div className="all">
        {segments.map((segment, index) => {
          const stopTime = moment(
            `${segments[index + 1]?.departureDate} ${
              segments[index + 1]?.departureTime
            }`
          ).diff(`${segment?.arrivalDate} ${segment?.arrivalTime}`, "m");
          return (
            <>
              {index === 0 && (
                <div className="details-head">
                  <p>
                    {type === "arrival" ? "Reurn" : "Depart"}{" "}
                    {type === "arrival" ? (
                      <>
                        {moment(segment?.arrivalDate).format("ddd")} ,{" "}
                        {moment(segment?.arrivalDate).format("MMM DD")}
                      </>
                    ) : (
                      <>
                        {moment(segment?.departureDate).format("ddd")} ,{" "}
                        {moment(segment?.departureDate).format("MMM DD")}
                      </>
                    )}
                  </p>
                  <p>
                    <ClockIcon /> {webBuilder.totalTripDuration}
                    {/* : 6h 25m */}
                  </p>
                </div>
              )}
              <div className="details-content">
                <div className="details-item">
                  <div className="logo">
                    <img
                      className="img-company mr-2"
                      src={segment.airline.logo}
                      alt=""
                    />
                    <p>{segment.airline?.name[locale]}</p>
                    <span>
                      {" "}
                      {segment.airline?.code + " " + segment.flightNum}
                    </span>
                  </div>
                  <div className="info w-60">
                    <div className="w-40">
                      <h5>
                        {segment?.departure?.names[locale]?.split(",")?.[0]} ({" "}
                        {segment?.departure?.code} )
                      </h5>
                      <span>{`${moment(segment?.departureDate).format(
                        "DD-MM-YYYY"
                      )} ${segment?.departureTime} ${moment(
                        segment?.departureDate
                      ).format("ddd")}`}</span>
                      <p>
                        {segment?.departure?.names?.[locale] ||
                          segment?.departure?.code}
                      </p>
                    </div>
                    <p className="clock">
                      {" "}
                      <ClockIcon /> {toHoursAndMinutes(segment.flightTime)}
                    </p>
                    <div className="w-40">
                      <h5>
                        {segment?.arrival?.names[locale]?.split(",")?.[0]} ({" "}
                        {segment?.arrival?.code} )
                      </h5>
                      <span>
                        {" "}
                        {`${moment(segment.arrivalDate).format("DD-MM-YYYY")} ${
                          segment.arrivalTime
                        } ${moment(segment.arrivalDate).format("ddd")}`}
                      </span>
                      <p>
                        {segment?.arrival?.names?.[locale] ||
                          segment?.arrival?.code}
                      </p>
                    </div>
                  </div>
                  <div className="economy">
                    <p>Economy</p>
                    <h6>-</h6>
                    <h6>-</h6>
                  </div>
                </div>

                {segments?.length > 1 && index < segments?.length - 1 && (
                  <div className="dot-layout">
                    <div className="dot">
                      <div></div>
                      <p>
                        Stop{" "}
                        {segment?.arrival?.names?.[locale] ||
                          segment?.arrival?.code}{" "}
                        ({toHoursAndMinutes(stopTime)})
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </>
          );
        })}
      </div>
    </>
  );
}
