
import { useWebBuilderState } from "context/webBuilder";
import { Link } from "react-router-dom";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";

export default function HeaderThemeSeven({
  nav,
  logoImage,
  contactButton,
  handleOpenMobileNav,
}) {
  // *** hooks
  const { hero } = useWebBuilderState();
  const header = hero?.header;

  return (
    <header className="wb-header">
      {/* logo */}
      <div className="logo">
        <Link to="/">
          <img src={logoImage} alt="Logo" />
        </Link>
      </div>

      {/* nav */}
      {nav}
      {contactButton}
      <button className="nav-mobile-icon" onClick={handleOpenMobileNav}>
        <MenuIcon />
      </button>
    </header>
  );
}
