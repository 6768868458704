import { isObject } from "lodash";
import Locale from "translations";
import { useSBSState } from "context/global";

export default function ServiceCardThemeSeven(props) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  return (
    <div className="theme_service_card">
      <div className={`image`}>
        <img src={props.image} alt={props?.typeTitle} />
      </div>

      <div className="text">
        {/* <h3 className="">{webBuilder[props.type]}</h3> */}

        <h3 className="sub text-capitalize">
          {webBuilder[props?.type] || props?.typeTitle}
        </h3>

        <p className="title">
          {isObject(props?.header) ? props?.header?.[locale] : props?.header}
        </p>
      </div>
    </div>
  );
}
