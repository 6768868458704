import React from "react";

function ServicesContent({ header, body }) {
  return (
    <div className="services-content">
      <p className="service-header h4">{header}</p>
      <p className="service-body">{body}</p>
    </div>
  );
}

export default ServicesContent;
