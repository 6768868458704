import Content from "./Components/Content";
import { useLocation, useParams } from "react-router-dom";
import AboutusThemePage from "./Pages/Aboutus";
import DestinationThemePage from "./Pages/Destination";
import "react-multi-carousel/lib/styles.css";


function WebBuilderV2() {
  const location = useLocation();
  const { pageName } = useParams();
  const pageTheme = location.pageTheme || pageName;

  function renderContentView() {
    if (pageTheme === "about-us") {
      return <AboutusThemePage />;
    } else if (typeof pageTheme === "string") {
      return <DestinationThemePage />;
    } else {
      return <Content />;
    }
  }

  return (
    <div id="main-web-builder" className="main-web-builder row m-0">
      <div className="col-12 p-0">
        {renderContentView()}
      </div>
    </div>
  );
}

export default WebBuilderV2;
