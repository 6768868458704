import iconNotification from "assets/images/customHeader/wallet.svg";
import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import NewPaymentAction from "components/NewPaymentAction";
import { useSBSDispatch, useSBSState } from "context/global";
import { useMarketplaceDispatch, useMarketplaceState } from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import useShowPermission from "hooks/useShowPermission";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import Switch from "react-switch";
import { Collapse } from "reactstrap";
import Locale from "translations";
import ConfirmModel from "./ConfirmModel";
import Passenger from "./Passenger";
import { bookHotelBuilder, checkHotelAvailabilityBuilder } from "services/webBuilder";
import ContactInfo from "../../../ContactInfo";

function HotelSummary() {
  //
  const history = useHistory();
  const search = window.location.search;
  const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
    ? JSON.parse(process.env.REACT_APP_OTP_CHECK)
    : false;

  const { inventory, marketPlace } = Locale;
  const params = search;

  const inventoryPermission = useShowPermission({
    permission: ["View-Inventory-Hotels", "Manage-Inventory-Hotels"],
  });

  const [terms, setTerms] = useState(true);
  const dispatch = useMarketplaceDispatch();
  const dispatchSBS = useSBSDispatch();
  const [Total, setTotal] = useState({ total: 0 });
  const { selected, filters, hotelFilters } = useMarketplaceState();
  const [actionType, setActionType] = useState("");
  const [formData, setFormData] = useState({});

  const [noteHotel, setNoteHotel] = useState("");
  const [errorsClients, setErrorsClients] = useState([]);
  const [checked, setchecked] = useState({});
  const [newCheckAvailability, setnewCheckAvailability] = useState([]);
  const [DataCheckAvailability, setDataCheckAvailability] = useState([]);
  const [doneCheck, setDoneCheck] = useState(false);
  const HotelData = JSON.parse(localStorage.getItem("HotelData"));
  const [modalPay, setModalPay] = useState(false);
  const togglePayModal = () => setModalPay(!modalPay);

  const [modalConfirm, setModalConfirm] = useState(false);
  const toggleModalConfirm = () => setModalConfirm(!modalConfirm);

  const [resAvailability, setresAvailability] = useState({});
  const [SpecialRequests, setSpecialRequests] = useState([]);
  const [contactInfoData, setContactInfoData] = useState({
    contact_fullname: "",
    contact_email: "",
    contact_phoneCode: "",
    contact_phone: "",
  });
  const [errors, setErrors] = useState({})
  const { token, locale, serverErrors, currency, allCountries } = useSBSState();

  useEffect(() => {
    if (newCheckAvailability.length === 0) {
      setnewCheckAvailability(
        JSON.parse(localStorage.getItem("newCheckAvailability"))
      );
    }
  }, []);

  useEffect(() => {
    if (serverErrors) {
      setErrorsClients(serverErrors);

      dispatchSBS({
        type: "serverErrors",
        payload: null,
      });
    }

    return () => { };
  }, [serverErrors]);

  const checkFormallErrors = () => {
    setchecked(!checked);
  };

  function checkFormErrors () {
    let submitError = {};
    Object.keys(contactInfoData).forEach((key) => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: contactInfoData[key] },
          {
            required: true,
            email: key === "email" ? true : false,
            min: key === "phone" ? 9 : "",
            max: key === "phone" ? 13 : "",
          }
        ),
      };
    });
    setErrors(() => submitError);
  }

  const Night = moment(moment(filters?.checkOut).startOf("day")).diff(
    moment(filters?.checkIn).startOf("day"),
    "days"
  )




  const HotelDataToken = JSON.parse(localStorage.getItem("HotelData"));
  const saveClients = async () => {
    let rooms_data = [];
    let allocationDetailsListIndex = 0;
    let counter = 0;

    DataCheckAvailability.map((res, index) => {
      if (index != 0 && counter == 0) {
        allocationDetailsListIndex = allocationDetailsListIndex + 1;
      }

      if (res.guests.length === 0) {
        return false;
      }
      if (res.room_rates && res.room_rates.length > 0) {
        res.room_rates.map((Response, ind) => {
          let x = [];
          if (res.childrenAges) {
            x = res.childrenAges.split(",").map((res) => ({ age: res }));
          }
          if (!Response.allocationDetailsList[allocationDetailsListIndex]) {
            allocationDetailsListIndex = 0;
          }

          if (HotelData.gds == 5) {
            rooms_data.push({
              roomKey: res.roomKey,
              roomConfigurationId: Response.roomConfigurationId,
              allocationDetails:
                Response.allocationDetailsList &&
                  Response.allocationDetailsList.length > 0
                  ? Response.allocationDetailsList[allocationDetailsListIndex]
                  : Response.allocationDetails,
              beddingPreference: 0,
              number_of_adult: res.adultsCount,
              guests: res.guests.map((res) => ({
                first_name: res?.first_name,
                last_name: res?.last_name,
                salutation: res?.salutation,
                type: res?.type?.id,
              })),
              children: x,
              special_requests: res.special_requests,
            });
          } else {
            rooms_data.push({
              roomKey: res.roomKey,
              roomConfigurationId: Response.roomConfigurationId,
              allocationDetails:
                Response.allocationDetailsList &&
                  Response.allocationDetailsList.length > 0
                  ? Response.allocationDetailsList[allocationDetailsListIndex]
                  : Response.allocationDetails,
              beddingPreference: 0,
              number_of_adult: res.adultsCount,
              guests: res.guests.map((res) => ({
                first_name: res?.first_name,
                last_name: res?.last_name,
                salutation: res?.salutation,
              })),
              children: x,
              special_requests: res.special_requests,
            });
          }
        });
      }
    });
    let data = {
      //   search_token: selectedHotelSearch?.search_token_selected,
      search_token: HotelDataToken?.search_token,

      date_from: hotelFilters?.date_from,
      date_to: hotelFilters?.date_to,
      nationality: hotelFilters?.nationality,
      residency: hotelFilters?.residency,
      lang: hotelFilters?.lang,
      rooms_data: rooms_data,
      currency_code: hotelFilters?.currency_code,
      notes: noteHotel,
    };
    const flag = errorsClients.every((errors) => {
      return errors.every((error) => {
        if (!isFormValid(error)) {
          return false;
        }
        return true;
      });
    });

    if (flag && DataCheckAvailability && DataCheckAvailability.length > 0 && isFormValid(errors)) {
      const availability = await checkHotelAvailabilityBuilder(data);
      if (availability?.status === 200) {
        setresAvailability(availability?.data?.data);

        localStorage.setItem(
          "selected",
          JSON.stringify({
            ...newCheckAvailability.room,
            ...selected,
            checkId: availability.data.data.hotel_check_id,
          })
        );
        dispatch({
          type: "selected",
          payload: {
            ...newCheckAvailability.room,
            ...selected,
            checkId: availability.data.data.hotel_check_id,
          },
        });

        if (
          (!availability?.data?.data.requires_payment &&
            +availability?.data?.data.provider_id == 3) ||
          (!availability?.data?.data.requires_payment &&
            +availability?.data?.data.provider_id == 8)
        ) {
          setModalConfirm(true);
        } else {
          setActionType("");
          setFormData({});

          setModalPay(true);
        }

        // async function fetchSafaWallet() {
        // 	setListWallets([]);
        // 	// to DO asdasd

        // 	const res = await getListWallets({
        // 		currency: availability.data.data.currency+","+availability.data.data.providerCurrency,
        // 	});

        // 	if (res.status == 200) {
        // 		setListWallets(res?.data?.data);
        // 	}
        // }

        // fetchSafaWallet();
      }
    }
  };

  const setIsOpenCheckAvailability = (index) => {
    let x = [...newCheckAvailability];
    x = x.map((res, IND) => {
      return index == IND
        ? {
          ...res,
          show:
            index == 0
              ? res.show == undefined
                ? false
                : !res.show
              : !res.show,
        }
        : { ...res, show: false };
    });
    setnewCheckAvailability(x);
  };
  useEffect(async () => {
    if (!params.includes("token")) {
      //   const SpecialRequests = await getAllSpecialRequests({
      //     lang: "en",
      //   });
      const SpecialRequests = {};
      if (SpecialRequests?.status == 200) {
        setSpecialRequests(SpecialRequests.data);
      }
    }
  }, [params]);

  useEffect(() => {
    saveClients();
  }, [doneCheck]);


  useEffect(() => {
    if (newCheckAvailability && newCheckAvailability.length > 0) {
      let x = [];
      let c = {
        currency: "",
        total: 0,
        room_price_vat: 0,
      };
      newCheckAvailability.map((room, index) => {
        let roomCount = newCheckAvailability.filter(
          (res) => res.index == room.index
        ).length;

        if (room.room_rates && room.room_rates.length > 0) {
          room.room_rates.map((res) => {
            let xxxxxxx = [...Array(+res?.minPassengersNum)];
            room.guests = [...xxxxxxx];
            [...Array(res.selected).keys()].map((resa, ins) => {
              x.push(room);
              c = {
                currency: res?.room_price_currency,
                total: c.total + +res.room_total_price,
                room_price_vat: c.room_price_vat + +res.room_price_vat,
              };
            });
          });
        }
      });

      setTotal(c);
      setDataCheckAvailability(x);
    }
  }, [newCheckAvailability.length]);

  const setValueSpecialRequests = (RoomIndex, checked, value) => {
    let NewlistClient;
    NewlistClient = DataCheckAvailability;
    if (!NewlistClient[RoomIndex].special_requests) {
      NewlistClient[RoomIndex].special_requests = [];
    }
    if (checked) {
      NewlistClient[RoomIndex].special_requests.push({ req: value });
    } else {
      NewlistClient[RoomIndex].special_requests = NewlistClient[
        RoomIndex
      ].special_requests.filter((res) => res.req != value);
    }

    setDataCheckAvailability(NewlistClient);
    //
  };

  const pay = async (radio, currency, otpNumber) => {
    const roomData = {
      special_request: "",
      hotel_availability_cache_id: selected.checkId,

      provider_id: HotelData.gds,
      // dummy room_guests

      payment_info: {
        payment_method:
          radio === "online"
            ? "online"
            : radio === "pay_later"
              ? "pay_later"
              : radio === "split"
                ? "split"
                : "debit",
        account_number: null,
        otp: null,
        pay_now: radio === "pay_later" ? 0 : 1,
        currency: currency ?? "SAR",
      },
      otp: otpNumber,

      lang: locale,
      client:{
        name:contactInfoData?.contact_fullname,
        email:contactInfoData?.contact_email,
        whatsapp_number: `${contactInfoData?.contact_phoneCode?.value}${contactInfoData?.contact_phone[0] === "0" ? contactInfoData?.contact_phone.substring(1) : contactInfoData?.contact_phone}`,
      }
    };
    const res = await bookHotelBuilder(roomData);
    if (res?.status === 200 || res?.status === 201) {
      store.addNotification({
        title: "info!",
        message: inventory.messages.bookingSuccessfully,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
        onRemoval: () => {
          history.push(inventoryPermission ? "/inventory" : "/statistics");
        },
      });
      window.dataLayer.push({ event: "buyNowtest" });

      togglePayModal(null);
    } else if (res?.status === 202) {
      setActionType(res?.data?.actionType);
      setFormData(res?.data?.data?.formData);
    }
  };


  return (
    <>
      <div className="b2c-invoice-wrapper responsive-padding-container hotel-summary">
        <div className="row align-items-start mx-0">


          <div className="col-12 col-sm-8 items mb-2">
            <ContactInfo
              countries={allCountries}
              contactInfoData={contactInfoData}
              setContactInfoData={setContactInfoData}
              errors={errors}
              setErrors={setErrors}
            />
            <h5 className="font-weight-bold db-title h5">
              {marketPlace.messages.GusetDetails}
            </h5>
            <div className="cartItem row align-items-start mb-2">
              <div className="col-md-12">
                {DataCheckAvailability && DataCheckAvailability.length > 0
                  ? DataCheckAvailability.map((room, IND) => {
                    return (
                      <>
                        {room.room_rates && room.room_rates.length > 0
                          ? room.room_rates.map((res) => {
                            return (
                              <>
                                {" "}
                                <div className="room-description db-style">
                                  {/* {roomTypes.find((type) => type.id === room.quantity).name} */}
                                  <p>
                                    <strong style={{ fontWeight: "600" }}>
                                      {HotelData?.name}
                                    </strong>
                                    {"  "}
                                    {[
                                      ...Array(HotelData.rating).keys(),
                                    ].map((i) => (
                                      <i
                                        key={i}
                                        className="fa fa-star"
                                        aria-hidden="true"
                                      ></i>
                                    ))}
                                  </p>
                                  {room.name} {" - "}{" "}
                                  {room.room_description}
                                  {/* <span className="span-count">
																					{+res?.selected}
																				</span> */}
                                </div>
                                {room.guests && room.guests.length > 0
                                  ? room.guests.map((item, index) => {
                                    return (
                                      <>
                                        <Passenger
                                          data={item}
                                          setDoneCheck={setDoneCheck}
                                          doneCheck={doneCheck}
                                          setErrorsClients={
                                            setErrorsClients
                                          }
                                          errorsClients={errorsClients}
                                          key={index}
                                          checkFormallErrors={checked}
                                          index={index}
                                          RoomIndex={IND}
                                          DataCheckAvailability={
                                            DataCheckAvailability
                                          }
                                          HotelData={HotelData}
                                          setDataCheckAvailability={
                                            setDataCheckAvailability
                                          }
                                        />
                                      </>
                                    );
                                  })
                                  : []}
                                {/* <button
																				className="btn text-success add-guest"
																				// onClick={()=>{
																				// let allguests = room.guests;
																				// setguest([...allguests,emptyGuest])
																				// DataCheckAvailability[0].guests.push(setguest) }}
																			>
																				{" "}
																				<i className="fa fa-plus icon-plus"></i>
																				{marketPlace.messages.addAnotherGuests}
																			</button> */}
                                <div className="col-md-12">
                                  <span className="border-top w-100 my-3 d-block"></span>
                                </div>
                                <div className="col-md-12 SpecialRequests mt-2 mb-2">
                                  <div className="col-md-12">
                                    <div className="col-md-4 offset-md-8">
                                      <label>
                                        {marketPlace.specialRequest}
                                        <Switch
                                          className="react-switch mx-3"
                                          disabled
                                          checked={
                                            res.allowsSpecialRequests
                                          }
                                          height={25}
                                          handleDiameter={25}
                                          width={55}
                                          borderRadius={4}
                                          uncheckedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "#fff",
                                                paddingRight: 2,
                                              }}
                                            >
                                              {marketPlace.No}
                                            </div>
                                          }
                                          checkedIcon={
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                height: "100%",
                                                fontSize: 15,
                                                color: "#fff",
                                                paddingRight: 2,
                                              }}
                                            >
                                              {marketPlace.Yes}
                                            </div>
                                          }
                                        />
                                      </label>
                                    </div>
                                  </div>
                                  {res.allowsSpecialRequests &&
                                    SpecialRequests
                                    ? SpecialRequests.map((res, index) => {
                                      return (
                                        <>
                                          <div className="form-check">
                                            <input
                                              className="form-check-input"
                                              type="checkbox"
                                              value=""
                                              onChange={(e) => {
                                                setValueSpecialRequests(
                                                  IND,
                                                  e.target.checked,
                                                  res.code
                                                );
                                              }}
                                              id={
                                                "flexCheckIndeterminate" +
                                                index +
                                                IND
                                              }
                                            />
                                            <label
                                              className="form-check-label"
                                              for={
                                                `flexCheckIndeterminate` +
                                                index +
                                                IND
                                              }
                                            >
                                              {res.text}
                                            </label>
                                          </div>
                                        </>
                                      );
                                    })
                                    : ""}
                                </div>
                              </>
                            );
                          })
                          : null}
                      </>
                    );
                  })
                  : ""}
              </div>
            </div>
            <div className="row">
              <div className="w-100 col-md-12 bg-white py-3 mb-2  Text-Booking">
                <TextAreaField
                  label={marketPlace.BookingRemarks}
                  placeholder={marketPlace.BookingRemarks}
                  onChange={(e) => {
                    setNoteHotel(e.target.value);
                  }}
                  cols="30"
                  rows="10"
                ></TextAreaField>
              </div>
            </div>

            <div
              className="align-items-start col-md-12 m-auto mt-2 row  w-100 border border-danger"
              style={{ "--bs-gutter-x": "1.5rem" }}
            >
              <div className="w-100 col-md-12 px-4">
                <p className="text-danger pt-3">
                  {marketPlace.ImportantInformations}
                </p>
                {newCheckAvailability && newCheckAvailability.length > 0
                  ? newCheckAvailability.map((room, IND) => {
                    return (
                      <>
                        {room.room_rates && room.room_rates.length > 0
                          ? room.room_rates.map((res) => {
                            return res.selected > 0 ? (
                              <>
                                <div className="cartItem row align-items-start mt-3">
                                  <div
                                    className={`w-100  ${room.show ? "bg-white" : "bg-disable"
                                      } p-2`}
                                    onClick={() =>
                                      setIsOpenCheckAvailability(IND)
                                    }
                                  >
                                    <strong className="font-weight-bold">
                                      {room.name} {" - "}{" "}
                                      {room.room_description}
                                    </strong>
                                    <i
                                      className={`fa ${room.show
                                        ? "fa-chevron-up"
                                        : "fa-chevron-down"
                                        }  float-custome mt-1	`}
                                    ></i>
                                  </div>
                                  <Collapse
                                    isOpen={
                                      room.show != undefined
                                        ? room.show
                                        : IND == 0
                                          ? true
                                          : room.show
                                    }
                                  >
                                    <div className="row col-md-12 m-0">
                                      <p className="font-weight-bold mt-2">
                                        {
                                          marketPlace.CancellationAndAmendmentsPolicies
                                        }
                                      </p>
                                      {res?.cancellation_policy.map(
                                        (polic) => {
                                          return (
                                            <>
                                              {!polic.hasOwnProperty(
                                                "fromDate"
                                              ) ? (
                                                <>
                                                  <p className="w-100 mt-2">
                                                    {marketPlace.before}{" "}
                                                    {polic.toDate}
                                                  </p>
                                                  <div className="col-md-6">
                                                    {polic.cancelCharge ==
                                                      0 ? (
                                                      <div className="boxCancellation bg-Ground">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.NoCancellationCharge
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : polic.cancelRestricted ? (
                                                      <div className="boxCancellation bg-dangerr">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.CancellationRestricted
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      <div className="boxCancellation">
                                                        {
                                                          marketPlace.CancellationCharge
                                                        }
                                                        <p>
                                                          {" "}
                                                          {
                                                            polic.cancelCharge
                                                          }{" "}
                                                          {
                                                            res?.room_price_currency
                                                          }
                                                        </p>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="col-md-6 ">
                                                    {polic.amendCharge ==
                                                      0 ? (
                                                      <div className="boxCancellation bg-Ground">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.NoAmendCharge
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : polic.amendRestricted ? (
                                                      <div className="boxCancellation bg-dangerr">
                                                        {
                                                          marketPlace.AmendRestricted
                                                        }
                                                      </div>
                                                    ) : HotelData.gds !=
                                                      5 &&
                                                      HotelData.gds != 6 ? (
                                                      <div className="boxCancellation">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.AmendmentCharge
                                                          }
                                                        </p>
                                                        {polic.amendCharge}
                                                        {
                                                          res?.room_price_currency
                                                        }
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </>
                                              ) : !polic.hasOwnProperty(
                                                "toDate"
                                              ) &&
                                                !polic.hasOwnProperty(
                                                  "noShowPolicy"
                                                ) ? (
                                                <>
                                                  <p className="w-100 mt-3">
                                                    {" "}
                                                    {marketPlace.after}{" "}
                                                    {polic.fromDate}
                                                  </p>
                                                  <div className="col-md-6">
                                                    {polic.cancelCharge ==
                                                      0 ? (
                                                      <div className="boxCancellation bg-Ground">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.NoCancellationCharge
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : polic.cancelRestricted ? (
                                                      <div className="boxCancellation bg-dangerr">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.CancellationRestricted
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      <div className="boxCancellation">
                                                        {
                                                          marketPlace.CancellationCharge
                                                        }
                                                        <p>
                                                          {" "}
                                                          {
                                                            polic.cancelCharge
                                                          }{" "}
                                                          {
                                                            res?.room_price_currency
                                                          }
                                                        </p>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="col-md-6 ">
                                                    {polic.amendCharge ==
                                                      0 ? (
                                                      <div className="boxCancellation bg-Ground">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.NoAmendCharge
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : polic.amendRestricted ? (
                                                      <div className="boxCancellation bg-dangerr">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.AmendRestricted
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : HotelData.gds !=
                                                      5 &&
                                                      HotelData.gds != 6 &&
                                                      HotelData.gds != 9 ? (
                                                      <div className="boxCancellation">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.AmendmentCharge
                                                          }
                                                        </p>
                                                        {polic.amendCharge}{" "}
                                                        {
                                                          res?.room_price_currency
                                                        }
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </>
                                              ) : polic.hasOwnProperty(
                                                "noShowPolicy"
                                              ) ? (
                                                <>
                                                  <p className="w-100 mt-3">
                                                    {" "}
                                                    {marketPlace.after}{" "}
                                                    {polic.fromDate}
                                                  </p>

                                                  <div className="col-md-12">
                                                    <div className="boxCancellation bg-dangerNew ">
                                                      <p>
                                                        {" "}
                                                        {
                                                          marketPlace.NoShowPolicyCharge
                                                        }
                                                      </p>
                                                      {polic.charge}{" "}
                                                      {
                                                        res?.room_price_currency
                                                      }
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <>
                                                  <p className="w-100 mt-3">
                                                    {" "}
                                                    {
                                                      marketPlace.between
                                                    }{" "}
                                                    {polic.fromDate}{" "}
                                                    {marketPlace.and}{" "}
                                                    {polic.toDate}
                                                  </p>
                                                  <div className="col-md-6">
                                                    {polic.cancelCharge ==
                                                      0 ? (
                                                      <div className="boxCancellation bg-Ground">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.NoAmendCharge
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : polic.cancelRestricted ? (
                                                      <div className="boxCancellation bg-dangerr">
                                                        <p>
                                                          {
                                                            marketPlace.CancellationRestricted
                                                          }
                                                        </p>{" "}
                                                      </div>
                                                    ) : (
                                                      <div className="boxCancellation">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.CancellationCharge
                                                          }
                                                        </p>
                                                        <p>
                                                          {" "}
                                                          {
                                                            polic.cancelCharge
                                                          }{" "}
                                                          {
                                                            res?.room_price_currency
                                                          }
                                                        </p>
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div className="col-md-6 ">
                                                    {polic.amendCharge ==
                                                      0 ? (
                                                      <div className="boxCancellation bg-Ground">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.NoAmendCharge
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : polic.amendRestricted ? (
                                                      <div className="boxCancellation bg-dangerr">
                                                        <p>
                                                          {
                                                            marketPlace.AmendRestricted
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : HotelData.gds !=
                                                      5 &&
                                                      HotelData.gds != 6 ? (
                                                      <div className="boxCancellation">
                                                        <p>
                                                          {" "}
                                                          {
                                                            marketPlace.AmendmentCharge
                                                          }
                                                        </p>
                                                        <p>
                                                          {" "}
                                                          {
                                                            polic.amendCharge
                                                          }{" "}
                                                          {
                                                            res?.room_price_currency
                                                          }
                                                        </p>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </Collapse>
                                </div>
                              </>
                            ) : (
                              ""
                            );
                          })
                          : ""}
                      </>
                    );
                  })
                  : ""}
              </div>
            </div>

            {/* {allListClients} */}
          </div>
          <div className="col-sm-4">
            <div className="col-md-12">
              <div className="col-md-12">
                <h5 className="font-weight-bold h5 ">
                  {marketPlace.messages.bookingSummary}
                </h5>
                <div className="w-100 bg-white p-2 summary-box">
                  <p>
                    <span>{marketPlace.arrivalDate}</span>
                    <span className="font-weight-bold">
                      {moment(filters?.checkIn).format("YYYY-MM-DD")}
                    </span>
                  </p>
                  <p>
                    <span>{marketPlace.departureDate}</span>
                    <span className=" font-weight-bold">
                      {moment(filters?.checkOut).format("YYYY-MM-DD")}
                    </span>
                  </p>
                  <p>
                    <span>{marketPlace.Numberofrooms}</span>
                    <span className="font-weight-bold">
                      {newCheckAvailability && newCheckAvailability.length > 0
                        ? newCheckAvailability.length
                        : null}
                    </span>
                  </p>

                  <p>
                    <span>{marketPlace.Nights}</span>
                    <span className="font-weight-bold">{Night}</span>
                  </p>
                </div>
                {newCheckAvailability && newCheckAvailability.length > 0
                  ? newCheckAvailability.map((room, index) => {
                    return room.room_rates && room.room_rates.length > 0
                      ? room.room_rates.map((rate) => {
                        return rate.selected > 0 ? (
                          <div className="w-100 bg-white p-2 mt-2 summary-box">
                            <h3 className="h5 font-weight-normal text-muted">
                              {marketPlace.Numberofrooms} {index + 1}
                            </h3>
                            <span className="w-100 border-bottom d-block mt-2 mb-2 "></span>

                            <p>
                              <span>{marketPlace.RoomType}</span>
                              <span className="font-weight-bold">
                                {room.name}
                              </span>
                            </p>
                            <p>
                              <span>{marketPlace.BoardBasis}</span>
                              <span className="font-weight-bold">
                                {rate.mealTitle}
                              </span>
                            </p>
                            <p
                              style={{
                                height: "auto",
                                overflow: "hidden",
                              }}
                            >
                              <span>
                                {marketPlace.messages.Roomoccupancy}
                              </span>
                              <span className="font-weight-bold">
                                {room?.childrenCount} {marketPlace.children}
                                {room?.adultsCount} {marketPlace.adult}
                              </span>{" "}
                            </p>
                            {room.childrenAges ? (
                              <p
                                style={{
                                  height: "auto",
                                  overflow: "hidden",
                                }}
                              >
                                <span className="font-weight-bold">
                                  {marketPlace.ChildrenAges}:{" "}
                                  {room.childrenAges}
                                </span>
                              </p>
                            ) : null}
                            <p>
                              <span>{marketPlace.messages.quantity}</span>
                              <span className="font-weight-bold">
                                {rate.selected}
                              </span>
                            </p>
                            <span className="w-100 border-bottom d-block mt-2 mb-2 "></span>
                            <p>
                              <span>{marketPlace.RoomCost}</span>
                              <span className="font-weight-bold">
                                {" "}
                                {rate.room_total_price.toFixed(2)}{" "}
                                {rate?.room_price_currency}
                                {/* {setTotal({
																				currency: rate?.room_price_currency,
																				total:
																					Total.total + rate.room_total_price,
																			})} */}
                              </span>
                            </p>
                          </div>
                        ) : null;
                      })
                      : null;
                  })
                  : null}

                <div className="w-100 text-white p-2 mt-2">
                  <div className="d-flex align-items-center justify-content-end cost-title-container">
                    <div>
                      <img
                        src={iconNotification}
                        className="mx-2"
                        alt=""
                        srcset=""
                      />
                    </div>
                    <div>
                      <p className="Cost-title">
                        {marketPlace.ReservationTotalCost}
                      </p>{" "}
                      {/* {Total.room_price_vat ? (
													<p className="font-weight-bold text-dark">
														{" "}
														Total Tax : {Total.room_price_vat} {Total?.currency}
													</p>
												) : (
													""
												)} */}
                      <p className="font-weight-bold price-title">
                        {" "}
                        {Total.total.toFixed(2)} {Total?.currency}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-100 mt-3 col-sm-8">
            <div className="row">
              <div className="col-md-8">
                <label htmlFor="test" className="title-terms">
                  <input
                    onChange={() => setTerms(!terms)}
                    type="checkbox"
                    className="mx-2"
                    name=""
                    id="test"
                  />
                  <span>{marketPlace.messages.agreeTandC}</span>
                </label>
              </div>
              <div className="col-md-4 mb-4">
                {params.includes("token") && token == null ? (
                  <button
                    disabled={terms == true}
                    onClick={() => {
                      dispatchSBS({
                        type: "setError",
                        payload: true,
                        message: {
                          title: "Login",
                          body: "To Can Complete Reservation should be login",
                        },
                      });

                      setTimeout(() => {
                        history.push("/auth/login?guest=1");
                        dispatchSBS({
                          type: "setError",
                          payload: false,
                        });
                        // localStorage.clear();
                      }, 3000);
                    }}
                    className="w-100 btn btn btn-success btn-lg  bg-gradient"
                  >
                    {marketPlace.messages.completeReservation}
                  </button>
                ) : (
                  <button
                    disabled={terms === true}
                    onClick={() => {
                      checkFormallErrors();
                      checkFormErrors();
                    }}
                    className="w-100 btn btn btn-success btn-lg  bg-gradient"
                  >
                    {marketPlace.messages.completeReservation}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {modalPay ? (
        <NewPaymentAction
          setActionType={setActionType}
          setFormData={setFormData}
          onPayment={pay}
          OTP={OTP_CHECK}
          formData={formData}
          actionType={actionType}
          toggleModal={togglePayModal}
          isOpen={modalPay}
          canPayLater={resAvailability?.is_auto_reservation}
          //   Price={resAvailability?.providerPrice}
          //   currency={resAvailability?.providerCurrency}
          currency={currency}
          Price={resAvailability?.total_price}
          secondCurrency={currency}
          secondPrice={resAvailability?.total_price}
        />
      ) : null}

      <ConfirmModel
        modal={modalConfirm}
        toggleModal={toggleModalConfirm}
        availability={resAvailability}
      />
    </>
  );
}

export default HotelSummary;
