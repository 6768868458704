import HeroContent from "./MainContent";
import { useWebBuilderState } from "context/webBuilder";
import Carousel from "react-multi-carousel";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";
import HeroContentTheme4 from "./Theme4";
import HeroContentTheme7 from "./Theme7";
import HeroContentTheme5 from "./Theme5";

function Hero() {
  const { hero, has_marketplace, active_modules, style } = useWebBuilderState();

  const bgImage = hero?.mainContent?.backgroundImage ?? "";
  const sliders = hero?.mainContent?.sliders ?? [];
  const hasSlider = hero?.mainContent?.hasSlider;

  const contentLayout = hero?.mainContent?.layout?.layoutType;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  let hasMarketplace = has_marketplace && active_modules?.length > 0;

  return (
    <>
      {(style.theme === "theme-1" ||
        style.theme === "theme-2" ||
        style.theme === "theme-3") && (
        <div
          className={`web-builder-hero ${
            hasMarketplace ? "has-api-integration" : ""
          }`}
          style={{
            ...(contentLayout === 1 &&
              !hasSlider && {
                backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${
                  bgImage?.src ? bgImage.src : bgImage
                })`,
              }),
          }}
        >
          {hasSlider ? (
            <Carousel
              responsive={responsive}
              itemClass={"web-builder-slide"}
              slidesToSlide={1}
              keyBoardControl={true}
              customRightArrow={<CustomRightArrow />}
              customLeftArrow={<CustomLeftArrow />}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              renderButtonGroupOutside={false}
              showDots={true}
              infinite={true}
            >
              {sliders.map((slide) => {
                return (
                  <img
                    src={slide.src || slide}
                    key={slide.id || slide}
                    alt=""
                  />
                );
              })}
            </Carousel>
          ) : null}
          <div className={`hero-content-container`}>
            <HeroContent />
          </div>
        </div>
      )}

      {(style?.theme === "theme-4" || style?.theme === "theme-10") && <HeroContentTheme4 hasMarketplace={hasMarketplace} />}
      {style.theme === "theme-5" && <HeroContentTheme5 hasMarketplace={hasMarketplace} />}
      {style.theme === "theme-7" && <HeroContentTheme7 hasMarketplace={hasMarketplace} />}
    </>
  );
}

export default Hero;
