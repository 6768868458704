import React, { useEffect, useState } from "react";
import banner from "assets/images/transfer-list-bg.png";

import TransferSearchBar from "./TransferSearchBar";
import TransferFilters from "./TransferFilters";
import Locale from "translations";
import TransferListItem from "./TransferListItem";
import CartModal from "../Modals/CartModal";
import TransferDetailsModal from "../Modals/TransferDetails";

import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import { store } from "react-notifications-component";
import Pagination from "components/Pagination";
import AddToCart from "../Modals/AddToCart";
import ModifySearch from "../Modals/ModifySearch";
import { fetchTransferBuilder } from "services/webBuilder";
import { formatTransfer } from "../helper/formatTransfer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { NoDataIcon } from "../../Flight/Icons";
// import AddToCart from "../Modals/AddToCart";
// import ModifySearch from "../Modals/ModifySearch";

function TransferListBulider() {
  const initialFilter = {
    type: "",
    min_price: "",
    max_price: "",
    sort_type: "",
    sort_by: "",
  };
  const { marketPlace, messages } = Locale;
  const [selectedTransfer, setSelectedTransfer] = useState([]);
  const [modalState, setModalState] = useState({
    cart: false,
    details: false,
    addToCart: false,
    modify: false,
  });
  const [filters, setFilters] = useState(initialFilter);
  const { transferSearch, transferCriteria } = useMarketplaceState();

  const dispatch = useMarketplaceDispatch();

  const toggleModals = (name) => {
    setModalState((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  // filter handler
  async function fetchTransferFilter(newData = {}, page) {
    const data = {
      refresh: false,
      ...filters,
      ...newData,
    };
    for (const key in data) {
      if (data[key] === "") {
        data[key] = null;
      }
    }
    setFilters(data);

    const formatDate = formatTransfer(transferCriteria);

    const requestData = { ...data, ...formatDate };
    const transfers = await fetchTransferBuilder(requestData, page);
    if (transfers.status === 200) {
      const data = transfers.data;
      dispatch({
        type: "transferSearch",
        payload: {
          transferCriteria: transferCriteria,
          searchData: data,
        },
      });
    }
    // setMetaData(response.data.meta);
  }

  const clearFitlter = () =>
    fetchTransferFilter({
      type: "",
      min_price: "",
      max_price: "",
      sort_type: "",
      sort_by: "",
    });

  useEffect(() => {
    if (transferSearch === undefined) {
      const handleSearch = async () => {
        const formatDate = formatTransfer(transferCriteria);

        const transfers = await fetchTransferBuilder(formatDate);
        if (transfers.status === 200) {
          const data = transfers.data;
          if (transfers.data.data.length > 0) {
            dispatch({
              type: "transferSearch",
              payload: {
                transferCriteria: transferCriteria,
                searchData: data,
              },
            });
          } else {
            store.addNotification({
              title: messages.noResults,
              message: messages.noSearchResults,
              type: "danger",
              insert: "top",
              container: "top-right",
              animationIn: ["animated", "fadeIn"],
              animationOut: ["animated", "fadeOut"],
              dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
            });
          }
        }
      };
      handleSearch();
    }
  }, [transferSearch]);

  const ascPrice = filters.sort_by === "price" && filters.sort_type === "asc";
  const ascText = filters.sort_by === "text" && filters.sort_type === "asc";

  return (
    <div className="marketplace-details transfer-list">
      <div
        className="mybooking-search-wrapper search-bar mb-3"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <TransferSearchBar modify={() => toggleModals("modify")} />
      </div>
      <div className="container m-auto row px-0">
        <TransferFilters
          onChangeFilter={fetchTransferFilter}
          filters={filters}
          clearFitlter={clearFitlter}
        />
        <div className="col-md-9 px-3">
          <div className="d-flex justify-content-between align-items-md-center flex-column flex-md-row aligin-items-start mt-2 mt-md-0">
            <div className="hotel-found">
              <h6 className="font-weight-bold terms-color">
                {`${marketPlace.messages.found}	${transferSearch?.meta?.total} ${marketPlace.messages.transfer}`}
              </h6>
              <p>{marketPlace.messages.mathingSearch}</p>
            </div>

            <div className="sort-filter mx-2 my-1">
              <span>{marketPlace.messages.sortBy} :</span>
              <button
                onClick={() => {
                  fetchTransferFilter({
                    sort_type: ascPrice ? "desc" : "asc",
                    sort_by: "price",
                  });
                }}
                className="bg-white mx-1 px-3 border-0"
              >
                <i
                  className={`fa fa-sort-amount-${ascPrice ? "down" : "up"}`}
                />

                {marketPlace.messages.price}
              </button>

              <button
                onClick={() => {
                  fetchTransferFilter({
                    sort_type: ascText ? "desc" : "asc",
                    sort_by: "text",
                  });
                }}
                className="bg-white mx-1 px-3 border-0"
              >
                <i className={`fa fa-sort-amount-${ascText ? "down" : "up"}`} />
                A to Z
              </button>
            </div>
          </div>

          {transferSearch?.data?.length > 0 ? (
            transferSearch?.data.map((transfer) => (
              <TransferListItem
                transfer={transfer}
                onDetails={() => {
                  setSelectedTransfer(transfer);
                  toggleModals("details");
                }}
                addToCart={() => {
                  toggleModals("addToCart");
                  setSelectedTransfer(transfer);
                }}
              />
            ))
          ) : (
            <NoData />
          )}

          <Pagination
            info={transferSearch?.meta}
            goTo={(e) => fetchTransferFilter({}, e)}
          />
        </div>
      </div>

      {transferSearch?.cacheKey && <CartModal />}

      {modalState["details"] && (
        <TransferDetailsModal
          isOpen={modalState["details"]}
          toggleModal={() => {
            toggleModals("details");
            setSelectedTransfer([]);
          }}
          details={selectedTransfer}
        />
      )}
      {modalState["addToCart"] && (
        <AddToCart
          isOpen={modalState["addToCart"]}
          selectedTransfer={selectedTransfer}
          toggleModal={() => {
            toggleModals("addToCart");
            setSelectedTransfer([]);
          }}
        />
      )}
      {modalState["modify"] && (
        <ModifySearch
          isOpen={modalState["modify"]}
          toggleModal={() => toggleModals("modify")}
          header={marketPlace.modify}
		  
        />
      )}
    </div>
  );
}
export function NoData() {
  const { webBuilder } = Locale;
  const history = useHistory();
  return (
    <div className="no-data-available">
      <NoDataIcon />
      <h6>{webBuilder.noResultsFound}</h6>
      <p>{webBuilder.youCanTryAnotherSearch}</p>
      <button className="btn btn-primary" onClick={() => history.push("/")}>
        {webBuilder.backtoSearch}
      </button>
    </div>
  );
}

export default TransferListBulider;
