import ErrorPreLogin from "components/Errors/Error404/ErrorPreLogin";
import { Route, Switch } from "react-router-dom";
// Lazy Components

// Application Routes
const Routes = (
  <Switch>
    {/* UI Static Routes */}
    {/* ****************** Start Reservation Route ******************** */}
    {/* ****************** End Private Route ******************** */}
    <Route path="*" component={ErrorPreLogin} />
  </Switch>
);

export default Routes;
