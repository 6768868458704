export const Change_Editing_Status = "Change_Editing_Status";
export const Save_Basic_Product_Details = "Save_Basic_Product_Details";

// Product
export const Product_Status = "Product_Status";
export const Remove_Product = "Remove_Product";
export const Filter = "Filter";
export const Product_Info = "Product_Info";
export const Update_Product_Info = "Update_Product_Info";
export const ItemInfo = "ItemInfo";

// Hotels
export const Append_New_Hotel = "Append_New_Hotel";
export const Update_Hotel = "Update_Hotel";
export const Delete_Hotel = "Delete_Hotel";

// External Transportation
export const Append_New_External_Transportation =
	"Append_New_External_Transportation";
export const Update_External_Transportation = "Update_External_Transportation";
export const Delete_External_Transportation = "Delete_External_Transportation";

// Transit
export const Append_New_Transit = "Append_New_Transit";
export const Update_Transit = "Update_Transit";
export const Delete_Transit = "Delete_Transit";

// Insurance
export const Append_New_Insurance = "Append_New_Insurance";
export const Update_Insurance = "Update_Insurance";
export const Delete_Insurance = "Delete_Insurance";

// Domestic Flight
export const Append_New_Domestic_Flight = "Append_New_Domestic_Flight";
export const Update_Domestic_Flight = "Update_Domestic_Flight";
export const Delete_Domestic_Flight = "Delete_Domestic_Flight";

// Internal Tranportation
export const Append_New_Internal_Transportation =
	"Append_New_Internal_Transportation";
export const Update_Internal_Transportation = "Update_Internal_Transportation";
export const Delete_Internal_Transportation = "Delete_Internal_Transportation";

// Sightseeing
export const Append_New_Sightseeing = "Append_New_Sightseeing";
export const Update_Sightseeing = "Update_Sightseeing";
export const Delete_Sightseeing = "Delete_Sightseeing";

// Visa
export const Append_New_Visa = "Append_New_Visa";
export const Update_Visa = "Update_Visa";
export const Delete_Visa = "Delete_Visa";

// Prices
export const Store_Full_Prices = "Store_Full_Prices";
export const Store_BreakDown_Prices = "Store_BreakDown_Prices";
