import { useSBSState } from "context/global";
import Locale from "translations";

export default function AboutUs({ details, }) {
  const { locale } = useSBSState();
  const { items, id, } = details;

  const AboutItems = {
    content: AboutContent,
    media: AboutMedia,
  };

  return (
    <div id={id}>
      <div className="web-builder-content-about-us container">
        {items.map((item, index) => {
          const componentType = item.id.includes("media")
            ? "media"
            : "content";
          const Component = AboutItems[componentType];

          return (
            <Component details={{ ...item, head: item?.head?.[locale], body: item?.body?.[locale] }} key={item.id} />
          );
        })}
      </div>

    </div>
  );
}

const AboutContent = ({ details }) => {
  const { webBuilder } = Locale;
  const { title, head, body, textAlign = "end" } = details;
  return (
    <div className="about-us-details" style={{ textAlign }}>
      <p className="about-us-title">
        {webBuilder.aboutus}
      </p>
      <p className="h4">
        {head}
      </p>
      <p className="text-body mt-3">
        {body}
      </p>
    </div>
  );
};

const AboutMedia = ({ details }) => {
  const { mediaUrl, mediaType } = details;


  return (
    <div className="about-media-container">
      {mediaType === "video" ? (
        <iframe
          style={{ maxWidth: "100%" }}
          className="w-100 h-100"
          width="auto"
          height="auto"
          src={`https://www.youtube.com/embed/${mediaUrl}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <img src={mediaUrl} className="img-fluid" alt="about us" />
      )
      }
    </div>
  );
};
