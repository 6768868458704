import { useSBSState } from 'context/global';
import { calcExchangeRatePrice } from 'helpers/utils';

export default function useExchangeRate({ price, currency }) {
  const { currency: currentCurrency, exchangeRates } = useSBSState();

  let exchageRate = exchangeRates?.find((rate) => rate?.from === currency)?.rate;

  
  let exchangedPrice = calcExchangeRatePrice(exchageRate, +price);
  let exchangeCurrency = exchageRate ? currentCurrency : currency || "";


  return {
    exchangedPrice,
    exchangeCurrency
  }
}
