import validate from "./validate";

export default class FiedlsServerErrors {
  constructor(errors) {
    this.errors = errors;
  }

  formatNestedArrayOfErrors() {
    let fieldserror = [];
    Object.keys(this.errors).forEach((i) => {
      const [parentIndex, childIndex, fieldName] =
        i.split(".");

      const handelErrorMessages = this.errors[i].map((mes) =>
        mes.replace(i, fieldName)
      );

      if (!Array.isArray(fieldserror[parentIndex]))
        fieldserror[parentIndex] = [];

      fieldserror[parentIndex][childIndex] = {
        ...fieldserror[parentIndex][childIndex],
        ...validate(
          { name: fieldName, value: null },
          {
            serverError: true,
            messages: handelErrorMessages,
          }
        ),
      };
    });
    return fieldserror;
  }
  // {
  // 	"passengers.0.passport_photo": [
  // 		"please add passport image"
  // 	],
  // 	"passengers.1.passport_photo": [
  // 		"please add passport image"
  // 	]
  // }
  formtArrayOfErrors() {
    let fieldserror = [];
    Object.keys(this.errors).forEach((i) => {

      const [parentIndex, fieldName, lastName] = i.split(".");
      const handelErrorMessages = this.errors[i].map((mes) =>
        mes.replace(i, fieldName)
      );

      // if (!Array.isArray(fieldserror[parentIndex])){
      // 	fieldserror[parentIndex] = [];
      // }


      fieldserror[parentIndex] = {
        ...fieldserror[parentIndex],
        ...validate(
          { name: lastName ? `residencey.${lastName}` : fieldName, value: null },
          {
            serverError: true,
            messages: handelErrorMessages,
          }
        ),
      };
    });
    return fieldserror;
  }
}
