import React from "react";
import { Redirect, Route } from "react-router-dom";
import { MarketPlaceProvider } from "../../context/marketplace";
// React Component
export default function MarketPlaceRoute({ component: Component, ...props }) {

	if (true) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<MarketPlaceProvider>
												{/* <DashboardLayout> */}
							<Component {...matchProps} />
												{/* </DashboardLayout> */}
					</MarketPlaceProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
