import React from 'react'
import Carousel from "react-multi-carousel";
import { useSBSState } from 'context/global';
import Locale from 'translations';
import ButtonGroup from 'modules/WebBuilder-V2/shared/CarouselButtonGroup';


export default function Theme1OurServices({ services }) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1.5,
    },
  };

  const showArrows = services?.filter(service => service?.visible && service?.icon !== null).length > responsive.desktop.items;

  return (
    <div className="all-web-builder-our-service-carousel  custom-container">
      <Carousel
        responsive={responsive}
        itemClass={"service-slide"}
        className="web-builder-our-service-carousel"
        slidesToSlide={1}
        keyBoardControl={true}
        arrows={false}
        customButtonGroup={showArrows  ? <ButtonGroup /> : null}
        renderButtonGroupOutside={true}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        infinite={true}
        rtl={locale === "ar"}
      >
        {services.map((service, index) => service?.visible && service?.icon !== null ?
          <article className="our-service_card-with-image h-100" key={`${service?.type}-${index}`}>
            <div className="our-service" style={{ backgroundImage: `url(${service?.icon})` }}>
              <h3 className="service-type">
                {webBuilder[service?.type] || service?.type?.toLocaleUpperCase()}
              </h3>
              <h4 className="service-header">
                {service?.header?.[locale]}
              </h4>
            </div>
            <p className="text-secondary-color service-content">
              {service?.body?.[locale]}
            </p>
          </article>
          : null
        )}
      </Carousel>
    </div>
  )
}
