import { useWebBuilderState } from 'context/webBuilder';
import ToursCarouselOverLayView from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursCarouselOverLayView';
import ToursCarouselView from 'modules/WebBuilder-V2/Components/Content/ToursService/ToursCarouselView';
import { ServiceCardDetails } from 'modules/WebBuilder-V2/shared/ServiceCardDetails';



export default function CustomPageTours({ details, title }) {
  const { style } = useWebBuilderState();
  const { id, items, tours_type } = details;

  function renderToursViews() {
    switch (style.theme || tours_type) {
      case "theme-1":
        return <ToursCarouselView items={items} />
      case "theme-2":
        return <ToursCarouselOverLayView items={items} />
      case "theme-3":
        return <div className="slider-edit-container">
          {items.map((props, index) => (
            <ServiceCardDetails {...props} itemType="tour" key={props?.id || index} />
          ))}
        </div>
      default:
        return <ToursCarouselView items={items} />
    }
  }


  return (
    <>
      <h3 className="section-title">
        {title}
      </h3>
      <div className="web-builder-content-tours" id={id}>

        <div className="hotels-detials services-carousel-container">
          {items && renderToursViews()}
        </div>
      </div>
    </>
  )
}
