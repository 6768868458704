import React from 'react';
import OverLayServiceCard from 'modules/WebBuilder-V2/shared/OverLayServiceCard';




export default function HotelGridView({ items }) {

  return (
    <div className="hotels-grid-view">
      {items.map((props, index) => (
        <OverLayServiceCard index={index} {...props} key={props.id || index} cardType="hotels" />
      ))}
    </div>
  )
}