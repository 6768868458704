import Locale from "translations";
import CustomModal from "./Modal";
import moment from "moment";

function TransferDetailsModal({ isOpen, toggleModal, details }) {
	return (
		<CustomModal
			modalIsOpen={isOpen}
			header={`${details.type}-${details.category} (${details.vehicle})`}
			toggle={toggleModal}
			size="lg"
			centered={true}
		>
			<TransferDetails details={details} />
		</CustomModal>
	);
}

export default TransferDetailsModal;

export const TransferDetails = ({ details }) => {
	const { marketPlace, inventory, payment } = Locale;

	return (
		<div className="transfer-cart-model">
			<h6 className="h6 font-weight-bold">
				{marketPlace.messages.generalInformation}
			</h6>

			<ul className="mb-3 mx-4" style={{ listStyle: "disc" }}>
				<li>
					Maximum Customer Waiting Time : {details?.customer_waiting_time}
				</li>
				<li>
					Maximum Supplier Waiting Time:{" "}
					{details?.domestic_supplier_waiting_time}(Domestic)
				</li>
				<li>
					Maximum Supplier Waiting Time:{" "}
					{details?.international_supplier_waiting_time} (International)
				</li>
				{details?.transferDetailInfo.map((i, index) => (
					<li>{i}</li>
				))}
			</ul>

			{details?.description && details?.description !== "" ?
				<>
					<h6 className="h6 font-weight-bold">
						{payment.messages.description}
					</h6>
					<p className='mt-1' dangerouslySetInnerHTML={{ __html: details?.description }} />
				</>
				: null
			}

			{details?.check_pickup?.mustCheckPickupTime ?
				<div className='mt-3 font-weight-bold d-flex flex-wrap'>
					<span>
						{marketPlace.messages.thisItemIsRequirePickup}
					</span>
					<a href={details?.check_pickup?.url} target="_blank" rel="noreferrer" className='mx-md-2'>{details?.check_pickup?.url}</a>
				</div>
				:
				null
			}

			{details?.cancellation_policies?.length > 0 ? (
				<div className="mt-3 transfer-booking-item-cancelation py-3">
					<span className="font-weight-bold h6 m-0">
						{inventory.messages.cancellationFees}
					</span>

					<div className="d-flex align-items-center flex-wrap">
						<div className="col-md-6">
							{details?.cancellation_policies?.map((policy, index) => {
								return (
									<div className="" key={index}>
										<div className="d-flex justify-content-between text-success">
											<span>
												{inventory.viewReservation.until}{" "}
												{moment(policy?.from)
													.subtract(1, "minute")
													.format("hh:mm A")}{" "}
												{moment(policy?.from)
													.subtract(1, "day")
													.format("DD/MM/YYYY")}
											</span>
											<span className="font-weight-bold text-capitalize">
												{inventory.viewReservation.free}
											</span>
										</div>
										<div className="d-flex justify-content-between text-danger">
											<span>
												{marketPlace.after}{" "}
												{moment(policy?.from).format("hh:mm A")}{" "}
												{moment(policy?.from).format("DD/MM/YYYY")}
											</span>
											<span className="font-weight-bold text-capitalize">
												{" "}
												{policy?.exchange_search_amount} {policy?.exchange_search_currency}
											</span>
										</div>
									</div>
								);
							})}
						</div>

						<div className="col-md-6 text-muted">
							{marketPlace.transferTermsAndCondition}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};
