import HeroContent from "./MainContent";
import { useWebBuilderState } from "context/webBuilder";

function Hero() {
  const { hero } = useWebBuilderState();

  const bgImage = hero?.mainContent?.backgroundImage ?? "";
  const contentLayout = hero?.mainContent?.layout?.layoutType;

  return (
    <div className="web-builder-hero"
      style={{
        ...(contentLayout === 1 && {
          backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${bgImage})`,
        }),
      }}
    >

      <HeroContent />
    </div>
  );
}

export default Hero;
