import * as actionTypes from "./actions";
import generateUniqueID from "helpers/generateUniqueID";

// Define initialState
const initialState = {
	isEditing: false,
	product_type_id: null,
	name: {},
	product_uuid: null,
	validation_date_from_at: null,
	validation_date_to_at: null,
	departure_date_at: null,
	return_date_at: null,
	product_classification_id: null,
	items: {
		visa: null,
		insurance: null,
		hotels: [],
		external_transportations: [],
		internal_transportations: [],
		sightseeing: [],
		domestics: [],
		other_services: [],
		transits: [],
	},
};

// Define the Reducer
export default function product_builder_reducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.Change_Editing_Status:
			return {
				...state,
				isEditing: action.isEditing,
			};

		case actionTypes.Save_Basic_Product_Details:
			return {
				...state,
				product_type_id: action.productType,
				name: action.name,
				product_uuid: action.product_uuid,
				validation_date_from_at: action.validationStartDate,
				validation_date_to_at: action.validationEndDate,
				departure_date_at: action.tripStartDate,
				return_date_at: action.tripEndDate,
				product_classification_id: action.classification,
			};

		case actionTypes.Append_New_Hotel:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					hotels: [
						...state.items.hotels,
						{
							id: generateUniqueID(),
							elementType: "hotels",
							icon: "icx icx-hotel",
							title: "Hotel",
						},
					],
				},
			};

		case actionTypes.Update_Hotel:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					hotels: state.items.hotels.map((element) =>
						element.id === action.elementID ? action.newElementDetails : element
					),
				},
			};

		case actionTypes.Delete_Hotel:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					hotels: state.items.hotels.filter(
						(element) => element.id !== action.elementID
					),
				},
			};

		case actionTypes.Append_New_External_Transportation:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					external_transportations: [
						...state.items.external_transportations,
						{
							id: generateUniqueID(),
							elementType: "external_transportations",
							icon: "fas fa-plane",
							title: "External Transportation",
						},
					],
				},
			};

		case actionTypes.Update_External_Transportation:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					external_transportations: state.items.external_transportations.map(
						(element) =>
							element.id === action.elementID
								? action.newElementDetails
								: element
					),
				},
			};
		case actionTypes.Append_New_Transit:
			return {
				...state,
				departure_at: action.departure_at,
				arrival_at: action.arrival_at,
				transporter_id: action.transporter_id,
				country_id: action.country_id,
				city_id: action.city_id,
				departure_port_id: action.departure_port_id,
				arrival_port_id: action.arrival_port_id,
			};
		case actionTypes.Delete_External_Transportation:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					external_transportations: state.items.external_transportations.filter(
						(element) => element.id !== action.elementID
					),
				},
			};

		case actionTypes.Append_New_Domestic_Flight:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					domestics: [
						...state.items.domestics,
						{
							id: generateUniqueID(),
							elementType: "domestics",
							icon: "fas fa-plane-departure",
							title: "Domestic Flight",
						},
					],
				},
			};

		case actionTypes.Update_Domestic_Flight:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					domestics: state.items.domestics.map((element) =>
						element.id === action.elementID ? action.newElementDetails : element
					),
				},
			};

		case actionTypes.Delete_Domestic_Flight:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					domestics: state.items.domestics.filter(
						(element) => element.id !== action.elementID
					),
				},
			};

		case actionTypes.Append_New_Internal_Transportation:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					internal_transportations: [
						...state.items.internal_transportations,
						{
							id: generateUniqueID(),
							elementType: "internal_transportations",
							icon: "fas fa-bus",
							title: "Internal Transportation",
						},
					],
				},
			};
		case actionTypes.Update_Internal_Transportation:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					internal_transportations: state.items.internal_transportations.map(
						(element) =>
							element.id === action.elementID
								? action.newElementDetails
								: element
					),
				},
			};
		case actionTypes.Delete_Internal_Transportation:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					internal_transportations: state.items.internal_transportations.filter(
						(element) => element.id !== action.elementID
					),
				},
			};

		case actionTypes.Append_New_Sightseeing:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					sightseeing: [
						...state.items.sightseeing,
						{
							id: generateUniqueID(),
							elementType: "sightseeing",
							icon: "icx icx-camera-location",
							title: "Attraction / Landmark",
						},
					],
				},
			};

		case actionTypes.Update_Sightseeing:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					sightseeing: state.items.sightseeing.map((element) =>
						element.id === action.elementID ? action.newElementDetails : element
					),
				},
			};

		case actionTypes.Delete_Sightseeing:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					sightseeing: state.items.sightseeing.filter(
						(element) => element.id !== action.elementID
					),
				},
			};

		case actionTypes.Append_New_Visa:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					visa: {
						id: generateUniqueID(),
						elementType: "visa",
						visa_option_id: 0,
						visa_count: null,
						icon: "fas fa-passport",
						title: "Visa",
					},
				},
			};

		case actionTypes.Update_Visa:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					visa: {
						...action.newElementDetails,
					},
				},
			};

		case actionTypes.Delete_Visa:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					visa: null,
				},
			};

		case actionTypes.Append_New_Insurance:
			return {
				...state,
				isEditing: true,
				items: {
					...state.items,
					insurance: {
						id: generateUniqueID(),
						elementType: "insurance",
						name: null,
						icon: "fas fa-shield-alt",
						title: "Insurance",
					},
				},
			};

		case actionTypes.Update_Insurance:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					insurance: {
						...action.newElementDetails,
					},
				},
			};

		case actionTypes.Delete_Insurance:
			return {
				...state,
				isEditing: false,
				items: {
					...state.items,
					insurance: null,
				},
			};

		case actionTypes.Store_Full_Prices:
			return {
				...state,
				...action.fullPrices,
			};

		default:
			return state;
	}
}
