import React from "react";
import { useHistory, useLocation, useParams } from 'react-router-dom';
import Locale from "translations";

export const ServiceCardDetails = (props) => {
  const {webBuilder} = Locale;
  const { onClick, image, name, product_uuid, itemType, price, currency } = props

  const location = useLocation();
  const history = useHistory();
  const preview = !location.pathname.includes('edit');
  const params = useParams()
  return (
    <div className="service-card-details pointer"
      onClick={() => {
        if (product_uuid) {
          if (preview) {
            if (params.name) {
              history.push(`/web/${params.name}/${itemType}/${product_uuid}`)
            } else {
              history.push(`/web/${itemType}/${product_uuid}`)
            }

          }
        }
      }}
      style={{
        backgroundImage: `url(${image})`
      }}
    >
      <div onClick={onClick} className='service-card-details-name'>
        {name}
        {price ?
          <div className="start-price">
            <span>{webBuilder.from}</span>
            <span className="amount">{price} {currency || ""}</span>
          </div>
          :
          null
        }
      </div>
    </div>
  );
};
