import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import React, { useMemo } from "react";
import Locale from "translations";
import { InfoCircle } from "../Icons";
import moment from "moment";
import validate from "helpers/validate";

export default function Adult({
  index,
  passengerType,
  passanger,
  passengersData,
  setPassengersData,
  calenderLookups,
  countries,
  errors,
  setErrors,
  toggleAdult

}) {
  const { marketPlace, inventory } = Locale;


  const gendersLookup = [
    { id: 1, name: marketPlace.male },
    { id: 2, name: marketPlace.female },
  ];

  // id expiry lookup
  const idExpiryDateLookup = useMemo(() => {
    let idExpiryYears = [];
    const expiryDay = passanger?.cardExpiredDate?.day?.label;
    const expiryMonth = passanger?.cardExpiredDate?.month?.label;
    const currentYear = moment().year();
    const selectedDate =
      expiryDay && expiryMonth && `${currentYear}-${expiryMonth}-${expiryDay}`;
    const isSelectedDateValid = moment()
      .add(6, "months")
      .isBefore(moment(selectedDate));
    let idExpiryStartDate = moment().add(isSelectedDateValid ? 0 : 1, "year");
    let idExpiryEndDate = moment(selectedDate).add(15, "years");
    let idExpiryDates = idExpiryStartDate.startOf("years");

    do {
      idExpiryYears.push(idExpiryDates.year());
    } while (idExpiryDates.add(1, "year") < idExpiryEndDate);
    idExpiryYears = idExpiryYears.map((month) => {
      return { id: month, name: String(month) };
    });
    return idExpiryYears;
  }, [
    passanger?.cardExpiredDate?.day?.label,
    passanger?.cardExpiredDate?.month?.label,
  ]);

  function handlePassengerChange(value, valueName) {
    let allPassengers = { ...passengersData };
    let currentPassenger = allPassengers[passengerType][index];
    currentPassenger = { ...currentPassenger, [valueName]: value };
    allPassengers[passengerType][index] = currentPassenger;
    setPassengersData(allPassengers);
    setErrors({
      ...errors,
      ...validate(
        { name: [`${passengerType}-${valueName}-${index}`], value: value },
        { required: true }
      ),
    });
  }

  function handlePassengerDatesChange(value, valueName, type) {
    let allPassengers = { ...passengersData };
    let currentPassenger = allPassengers[passengerType][index];
    let date = { ...currentPassenger[valueName] };
    // reset card expire year if month or day changed
    if (
      (type === "month" || type === "day") &&
      valueName === "cardExpiredDate"
    ) {
      currentPassenger = {
        ...currentPassenger,
        [valueName]: { ...date, [type]: value, year: "" },
      };
    } else {
      currentPassenger = {
        ...currentPassenger,
        [valueName]: { ...date, [type]: value },
      };
    }
    allPassengers[passengerType][index] = currentPassenger;
    setPassengersData(allPassengers);
    setErrors({
      ...errors,
      ...validate(
        {
          name: [`${passengerType}-${valueName}-${type}-${index}`],
          value: value,
        },
        { required: true }
      ),
    });
  }

  // TODO add hasError to form that has input not valid then add red border to form box

  return (
    <div className="adult-info" key={passanger?.id}>
      <h3>
        {passengerType === "adults"
          ? inventory.messages.Adult
          : inventory.messages.Child}{" "}
        {index + 1}{" "}

        <button className="btn btn-link px-1" onClick={() => toggleAdult()}>
          <InfoCircle />
        </button>
      </h3>
      <div className="row px-0 mx-0">
        <div className="col-md-8">
          <TextField
            placeholder={marketPlace.FirstNameAndMiddleName}
            label={marketPlace.FirstNameAndMiddleName}
            type={"text"}
            value={passanger?.firstName}
            id="firstName"
            name="firstName"
            onChange={(e) => {
              handlePassengerChange(e.target.value, "firstName");
            }}
            errors={errors?.[`${passengerType}-firstName-${index}`]}
            color={
              errors?.[`${passengerType}-firstName-${index}`]?.required
                ? "danger"
                : ""
            }
          />
        </div>
        {/* <div className="col-md-4">
          <TextField
            placeholder={"Middle Name"}
            label={"Middle Name"}
            type={"text"}
            id="middleName"
            name="middleName"
           
          />
        </div> */}
        <div className="col-md-4">
          <TextField
            placeholder={marketPlace.LastName}
            label={marketPlace.LastName}
            type={"text"}
            id="LastName"
            name="LastName"
            value={passanger?.lastName}
            onChange={(e) => {
              handlePassengerChange(e.target.value, "lastName");
            }}
            errors={errors?.[`${passengerType}-lastName-${index}`]}
            color={
              errors?.[`${passengerType}-lastName-${index}`]?.required
                ? "danger"
                : ""
            }
          />
        </div>

        <div className="col-md-4 d-flex flex-column ">
          <label htmlFor="" className="m-0">
            {marketPlace.Dateofbirth}
          </label>
          <div className="d-flex date-of-birth">
            <div className="col-md-4 p-0 start">
              <SelectField
                haslabel={false}
                label="Birth Day"
                name="birthday"
                id="birthday"
                placeholder="DD"
                value={passanger?.birthday?.day?.name}
                options={calenderLookups?.days}
                onChange={(e) => {
                  handlePassengerDatesChange(e, "birthday", "day");
                }}
                errors={errors?.[`${passengerType}-birthday-day-${index}`]}
                color={
                  errors?.[`${passengerType}-birthday-day-${index}`]?.required
                    ? "danger"
                    : ""
                }
              />
            </div>
            <div className="col-md-4 p-0">
              <SelectField
                haslabel={false}
                label="Birth Month"
                name="birthmonth"
                id="birthmonth"
                placeholder="MM"
                value={passanger?.birthday?.month?.name}
                options={calenderLookups?.months}
                onChange={(e) => {
                  handlePassengerDatesChange(e, "birthday", "month");
                }}
                errors={errors?.[`${passengerType}-birthday-month-${index}`]}
                color={
                  errors?.[`${passengerType}-birthday-month-${index}`]?.required
                    ? "danger"
                    : ""
                }
              />
            </div>
            <div className="col-md-4 p-0 end">
              <SelectField
                haslabel={false}
                label="Birth Year"
                name="birthyear"
                id="birthyear"
                placeholder="YYYY"
                value={passanger?.birthday?.year?.name}
                options={
                  passengerType === "adults"
                    ? calenderLookups?.adultYears
                    : calenderLookups?.childYears
                }
                onChange={(e) => {
                  handlePassengerDatesChange(e, "birthday", "year");
                }}
                errors={errors?.[`${passengerType}-birthday-year-${index}`]}
                color={
                  errors?.[`${passengerType}-birthday-year-${index}`]?.required
                    ? "danger"
                    : ""
                }
              />
            </div>
          </div>
        </div>

        <div className="col-md-4 ">
          <SelectField
            label={marketPlace.gender}
            placeholder={marketPlace.select}
            value={passanger?.sex?.name}
            id="gender"
            name="gender"
            options={gendersLookup}
            onChange={(e) => {
              handlePassengerChange(e, "sex");
            }}
            errors={errors?.[`${passengerType}-sex-${index}`]}
            color={
              errors?.[`${passengerType}-sex-${index}`]?.required
                ? "danger"
                : ""
            }
          />
        </div>
        <div className="col-md-4">
          <SelectField
            label={marketPlace.nationality}
            placeholder={marketPlace.select}
            name="nationality"
            id="nationality"
            value={passanger?.nationality?.name}
            options={countries}
            onChange={(e) => {
              handlePassengerChange(e, "nationality");
            }}
            errors={errors?.[`${passengerType}-nationality-${index}`]}
            color={
              errors?.[`${passengerType}-nationality-${index}`]?.required
                ? "danger"
                : ""
            }
          />
        </div>

        <div className="col-md-4">
          <TextField
            placeholder={inventory.messages.PassportNo}
            label={inventory.messages.PassportNo}
            name="idNumber"
            id="idNumber"
            value={passanger?.cardNum}
            onChange={(e) => {
              handlePassengerChange(e.target.value, "cardNum");
            }}
            errors={errors?.[`${passengerType}-cardNum-${index}`]}
            color={
              errors?.[`${passengerType}-cardNum-${index}`]?.required
                ? "danger"
                : ""
            }
          />
        </div>
        {/* id expiry date */}
        <div className="col-md-4 d-flex flex-column ">
          <label htmlFor="" className="m-0">
            {inventory.messages.passportExpiryDate}
          </label>
          <div className="d-flex date-of-birth">
            <div className="col-md-4 p-0 start">
              <SelectField
                haslabel={false}
                label="Expiry Day"
                name="cardExpiredDate"
                id="cardExpiredDate"
                placeholder="DD"
                value={passanger?.cardExpiredDate?.day?.name}
                options={calenderLookups?.days}
                onChange={(e) => {
                  handlePassengerDatesChange(e, "cardExpiredDate", "day");
                }}
                errors={
                  errors?.[`${passengerType}-cardExpiredDate-day-${index}`]
                }
                color={
                  errors?.[`${passengerType}-cardExpiredDate-day-${index}`]
                    ?.required
                    ? "danger"
                    : ""
                }
              />
            </div>
            <div className="col-md-4 p-0">
              <SelectField
                haslabel={false}
                label="Expiry Month"
                name="expirymonth"
                id="expirymonth"
                placeholder="MM"
                value={passanger?.cardExpiredDate?.month?.name}
                options={calenderLookups?.months}
                onChange={(e) => {
                  handlePassengerDatesChange(e, "cardExpiredDate", "month");
                }}
                errors={
                  errors?.[`${passengerType}-cardExpiredDate-month-${index}`]
                }
                color={
                  errors?.[`${passengerType}-cardExpiredDate-month-${index}`]
                    ?.required
                    ? "danger"
                    : ""
                }
              />
            </div>
            <div className="col-md-4 p-0 end">
              <SelectField
                haslabel={false}
                label="Expiry Year"
                name="expiryyear"
                id="expiryyear"
                placeholder="YYYY"
                value={passanger?.cardExpiredDate?.year?.name}
                options={idExpiryDateLookup}
                onChange={(e) => {
                  handlePassengerDatesChange(e, "cardExpiredDate", "year");
                }}
                disabled={!passanger?.cardExpiredDate?.month?.name}
                errors={
                  errors?.[`${passengerType}-cardExpiredDate-year-${index}`]
                }
                color={
                  errors?.[`${passengerType}-cardExpiredDate-year-${index}`]
                    ?.required
                    ? "danger"
                    : ""
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
