import React from "react";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-black.svg";

import moment, { now } from "moment";
import { useHistory } from "react-router-dom";
import Locale from "translations";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

function PackageCard(props) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const {
    image,
    name,
    name_localized,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    hotel_name_locale,
    price,
    locationName,
    country,
    initialName,
    currency
  } = props;

  const history = useHistory();
  let hotel_name = hotel_name_locale?.[locale] || hotelName;
  const trimHotelName = hotel_name ? hotel_name?.length > 16 ? `${hotel_name?.substr(0, 16)}...` : hotel_name : "Hotel Name";

  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  let countryName = country ? (country[locale] || country) : (locationName[locale] || locationName);


  return (
    <div className="package-card-container"
      onClick={() => {
        if (product_uuid) {
          history.push({
            pathname: `/package/${product_uuid}`,
            state: { productImage: image }
          });
        }
      }}
      key={product_uuid}
    >
      <div className="package-card-details">
        <div className={`package-img ${!image ? "empty-service" : ""}`}>
          {image && <img src={image} alt="our package" />}
          <span className="card-ribbon">{webBuilder.topRate}</span>
          <div className="package-img-data">
            <p className="font-weight-bold">{name_localized?.[locale] || name || initialName}</p>
            {stars > 0 && (
              <div className="starts">
                {[...Array(5)].map((i, index) =>
                  index < stars ? <GoldenStarIcon key={`rate-${index}`} /> : <StarIcon key={`rate-${index}`} />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="package-info">
          {countryName &&
            <div className="location">
              <LocationIcon /> <span className="mr-2">{countryName}</span>
            </div>
          }
          <div className="date">
            <CalendarIcon />{" "}
            <span className="">{`${moment(departure_date_at || now()).format(
              "DD MMM YYYY"
            )} - ${moment(return_date_at || now()).format(
              "DD MMM YYYY"
            )}`}</span>
          </div>
          <div className={`hotel mt-1 ${!hotel_name ? "opacity-0" : ""}`}>
            <Hotelcon /> <span className="mr-2">{hotel_name ? trimHotelName : ""}</span>
          </div>
          <div className="hr" />
          <div className="price">
            <span className="me-1">{webBuilder.from}</span>
            <span className="amount">
              {exchangedPrice}
              <span className="currency"> {exchangeCurrency}</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PackageCard;
