import { useSBSState } from 'context/global';
import { useWebBuilderState } from 'context/webBuilder';
import { ReactComponent as PhoneIcon } from "assets/images/webBuilder/call.svg";
import { ReactComponent as EnvlopIcon } from "assets/images/webBuilder/sms.svg";
import { logoURL } from 'helpers/utils';
import { Link } from 'react-router-dom';
import Locale from 'translations';
import { LocationMarkerIcon } from 'modules/WebBuilder-V2/shared/icons';


// const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
const currentYear = new Date().getFullYear();


export default function FooterThemeTen({ socialMediaIcons }) {
  const { webBuilder } = Locale;
  const { footer: footerDetails, hero, business_email, ourServices, country, city } = useWebBuilderState();
  const { locale } = useSBSState();
  const services = ourServices?.services?.filter(service => service?.visible);

  // const services = ourServices?.services?.filter(service => service?.visible);
  const header = hero?.header;
  // const logoImage = isObject(header?.logo) ?
  // 	<img src={`${header?.logo[locale].includes("http") ? header?.logo[locale] : URI + "/" + header?.logo[locale]}`} alt="Logo" />
  // 	:
  // 	<img src={`${header?.logo[locale]}`} alt="Logo" />;




  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id)
      element?.scrollIntoView({ behavior: 'smooth' })
    }
  }


  return (
    <div className="custom-container">
      <div className="footer-content">
        {/* right section */}
        <div className="footer-main-data">
          <div className="logo-container">
            <Link to="/">
              <img
                className="footer-photo"
                src={logoURL(header?.logo, (header?.logo?.[locale?.toLowerCase()] || header?.logo?.en || header?.logo?.ar))}
                alt="Logo"
              />
            </Link>
          </div>

          <p className="footer-text">{footerDetails?.footerText[locale]}</p>
      
          <div className="social-media" >
            <ul className="links">
              {footerDetails?.links?.map((link) => {
                if (link?.visible) {
                  return (
                    <div key={link?.id}>
                      <a href={link?.url} target="_blank" rel="noreferrer">
                        {socialMediaIcons[link?.id]}
                      </a>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
        </div>

        <ul className="links-list">
          <span className="footer-title">{webBuilder?.services}</span>
          {services && services.length > 0 && services?.map(service => {
            return (
              <li className="pointer"
                onClick={() => scrollToSection(service?.id)}
              >
                <span> {webBuilder[service?.type]}</span>
              </li>
            )
          })}
        </ul>


        <div className="footer-contact-info">
          <span className="footer-title">{webBuilder?.contactUs}</span>
          <ul className="d-flex flex-column gap-24">
            {header?.phoneNumber && header?.phoneNumber?.[0] &&
              <li>
                <PhoneIcon />
                <span className="mx-2">{header?.phoneNumber?.[0]}</span>
              </li>
            }
            {business_email &&
              <li>
                <EnvlopIcon />
                <span className="mx-2">{business_email}</span>
              </li>
            }
            <li>
							<LocationMarkerIcon />
							<span className="mx-2">{country?.name[locale]}, {city?.name[locale]}</span>
						</li>
          </ul>
        </div>
      </div>


      <div className="footer-copy-right d-flex justify-content-center align-items-center">
        <p className="footer-text mt-1">
          {" "}
          {webBuilder.allRightsReserved} {" - "}
          <a
            href="https://safasoft.com/"
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            {webBuilder.safasoft}
          </a>{" "}
          © {currentYear}
        </p>
      </div>
    </div>
  )
}