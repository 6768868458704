export function CheckMarkIcon({ width, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "10.694"} height="8.029" viewBox="0 0 10.694 8.029">
      <path d="M7.906,13.985a.667.667,0,0,1-.487-.213L4.18,10.325a.667.667,0,1,1,.973-.913L7.9,12.339l5.607-6.133a.667.667,0,1,1,.987.893L8.4,13.765a.667.667,0,0,1-.487.22Z" transform="translate(-3.999 -5.956)" fill={color || "#027b30"} />
    </svg>
  )
}
export function CrossDangerIcon({ width, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "8.931"} height="8.93" viewBox="0 0 8.931 8.93">
      <path id="Path_57319" data-name="Path 57319" d="M9.246,8.461,12.76,4.952a.56.56,0,1,0-.792-.792L8.46,7.674,4.951,4.16a.56.56,0,1,0-.792.792L7.673,8.461,4.159,11.969a.56.56,0,1,0,.792.792L8.46,9.247l3.509,3.514a.56.56,0,1,0,.792-.792Z" transform="translate(-3.994 -3.996)" fill={color || "#c30101"} />
    </svg>
  )
}

export function InfoIcon({ width, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width || "39.5"} height="39.5" viewBox="0 0 39.5 39.5">
      <path d="M19.75,0A19.75,19.75,0,1,0,39.5,19.75,19.751,19.751,0,0,0,19.75,0ZM17.281,17.281a2.469,2.469,0,0,1,4.938,0V29.625a2.469,2.469,0,0,1-4.937,0Zm2.469-4.9a2.469,2.469,0,1,1,2.47-2.469A2.469,2.469,0,0,1,19.75,12.383Z" fill={color || "#d29d4d"} />
    </svg>
  )
}
export function ShoppingCartIcon({ width, color }) {
  return (
    <svg id="Group_40057" data-name="Group 40057" xmlns="http://www.w3.org/2000/svg" width="34.435" height="34.435" viewBox="0 0 34.435 34.435">
      <path id="Path_57304" data-name="Path 57304" d="M10.609,26.957a2.87,2.87,0,1,0,2.87,2.87A2.857,2.857,0,0,0,10.609,26.957ZM2,4V6.87H4.87l5.158,10.883L8.091,21.268a2.917,2.917,0,0,0-.352,1.385,2.869,2.869,0,0,0,2.87,2.87H27.826v-2.87H11.219a.355.355,0,0,1-.359-.359.341.341,0,0,1,.043-.172l1.284-2.339H22.876a2.879,2.879,0,0,0,2.511-1.478l5.129-9.312A1.387,1.387,0,0,0,30.7,8.3,1.435,1.435,0,0,0,29.261,6.87H8.048L6.685,4H2ZM24.957,26.957a2.87,2.87,0,1,0,2.87,2.87A2.857,2.857,0,0,0,24.957,26.957Z" transform="translate(-0.565 -1.13)" fill="#fff" />
      <path id="Path_57305" data-name="Path 57305" d="M0,0H34.435V34.435H0Z" fill="none" />
    </svg>
  )
}