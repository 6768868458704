import langIcon from "assets/images/market-place/servicesIcon/langIcon.svg";

import { useSBSDispatch, useSBSState } from "context/global";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";
import Locale from "./LanguageSwitcher.locale";
import { useWebBuilderState } from "context/webBuilder";


export default function LanguageSwitcher({ show }) {
  const { locale } = useSBSState();
  const { languages } = useWebBuilderState();
  Locale.setLanguage(locale);
  const dispatch = useSBSDispatch();

  return (
    <UncontrolledDropdown className={`lang-dropdown p-0 ${show ? "visibility-hidden" : ""}`}>
      <DropdownToggle caret className="btn-light lang-btn" >
        <div className="header-control">
          <img
            className="img-lang px-1"
            src={langIcon}
            width="25"
            alt="lang"
          />
          <span>
            {languages?.find(l => l?.code === locale)?.name === "Arabic" ? "العربية" : languages?.find(l => l?.code === locale)?.name}
          </span>
        </div>
      </DropdownToggle>

      <DropdownMenu className="lang-dropdown-item">
        {languages?.map(language => {
          return (
            <DropdownItem
              key={language?.code}
              onClick={() => {
                if (locale !== language?.code) {
                  dispatch({ type: "setLoading", payload: true });

                  localStorage.setItem("currentLocale", language?.code);
                  window.location.reload();
                }
              }}
            >

              {/* <img className="img-lang px-2" src={enFLag} width="40" alt="lang" /> */}
              <span className="ms-2">
                {language?.name === "Arabic" ? "العربية" : language?.name}
              </span>
            </DropdownItem>
          )
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}
