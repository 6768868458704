import { useSBSState } from "context/global";
import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import React, { useEffect, useRef, useState } from "react";
import Locale from "translations";
import { getCities } from "services/marketplace";
import { searchTours } from "services/tours";
import { useHistory } from "react-router-dom";
import { store } from "react-notifications-component";
import AutoCompleteField from "components/Form/AutoCompleteField/AutoCompleteField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import destinationIcon from "assets/images/market-place/servicesIcon/nightIcon.svg";
import { useDetectClickOutside } from "react-detect-click-outside";

export default function ToursSearchForm({ modify, toggleModifyModal }) {
  const { messages, marketPlace, inventory, visa, commons } = Locale;
  const { locale } = useSBSState();
  const { status } = useParams();

  const dispatch = useMarketplaceDispatch();
  const { allCountries } = useSBSState();
  const { toursSearch } = useMarketplaceState();
  const maxChildAge = 7;
  const maxAdultsCount = 20;
  let goingToPrevValue = useRef(null);
  const [paxIsOpen, setPaxIsOpen] = useState(false);
  const paxToggle = () => setPaxIsOpen((prev) => !prev);
  const [toursData, setToursData] = useState({
    country: null,
    destination: null,
    date_from: null,
    date_to: null,
    nights: 0,
    adults: 0,
    childs: { id: 0, value: 0, label: "0", name: "0" },
    childs_ages: [],
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [destinationList, setDestinationList] = useState([]);
  const history = useHistory();
  const [adultState, setAdultState] = useState(0);
  const [childState, setChildState] = useState(0);
  const countryRef = useRef(null)


  const DetectClickOutside = useDetectClickOutside({
    onTriggered: () => setPaxIsOpen(false),
  });

  const childsAgesLookup = [...Array(maxChildAge + 1).keys()].map((age) => {
    return {
      id: age,
      value: age,
      label: age.toString(),
      name: age.toString(),
    };
  });

  function handleChildAge(e, ageIndex) {
    const inputValue = {
      id: e.target.value,
      label: e.target.value,
      name: e.target.value,
      value: e.target.value,
    };
    const ages = [...toursData?.childs_ages];
    ages[ageIndex] = inputValue;
    setToursData({ ...toursData, childs_ages: ages });
  }

  function handleChildsCount(e) {
    const currentChildsCount = toursData?.childs_ages.length;
    let newChildsAges = toursData.childs_ages;
    let addedRemovedChildsCount = 0;
    // check if we increase
    if (e.value > currentChildsCount) {
      addedRemovedChildsCount = e.value - currentChildsCount;
      newChildsAges = [
        ...toursData.childs_ages,
        ...[...Array(+addedRemovedChildsCount).keys()].map(() => {
          return {
            id: 0,
            value: 0,
            label: "0",
            name: "0",
          };
        }),
      ];
    } else if (e.value < currentChildsCount) {
      addedRemovedChildsCount = -(currentChildsCount - e.value);
      newChildsAges = newChildsAges.slice(0, addedRemovedChildsCount);
    }
    setToursData({
      ...toursData,
      childs: e,
      childs_ages: newChildsAges,
    });
  }

  function checkFormErrors() {
    let SubmitError = {};
    Object.keys(toursData).forEach((key) => {
      SubmitError = {
        ...SubmitError,
        ...validate(
          { name: key, value: toursData[key] },
          {
            required:
              key === "nights" ||
                key === "childs_ages" ||
                (status === "umrah" && key === "country")
                ? false
                : true,
            minNumber: key === "adults" && +adultState === 0,
          }
        ),
      };
    });
    setErrors(() => SubmitError);
  }

  // get tour results and store search cretria in localstorage
  async function getToursResults(data) {
    const toursRes = await searchTours(data);
    if (toursRes?.status >= 200 && toursRes?.status < 300) {
      if (toursRes?.data?.data?.length > 0) {
        dispatch({
          type: "toursSearchResults",
          payload: toursRes?.data,
        });
        dispatch({
          type: "saveToursSearch",
          payload: { ...toursData, cacheKey: toursRes?.data?.cacheKey },
        });
        if (modify) {
          toggleModifyModal();
        }
        history.push(`/tours/result`);
      } else {
        store.addNotification({
          title: messages.noResults,
          message: messages.noSearchResults,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
        });
      }
    } else {
      store.addNotification({
        title: messages.noResults,
        message: messages.noSearchResults,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
      });
    }
  }

  // search call get result function
  function search() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      const data = {
        date_from: moment(toursData?.date_from).locale("en").format("YYYY-MM-DD"),
        date_to: moment(toursData?.date_to).locale("en").format("YYYY-MM-DD"),
        city_code: toursData?.destination?.id,
        noOfAdults: toursData?.adults?.value,
        children: toursData?.childs_ages?.map((age) => age?.value),
      };
      getToursResults(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isErrorLoaded]);

  // in modify mode set the tour data with cached data from localstorge
  useEffect(() => {
    if (modify) {
      setToursData({
        ...toursSearch,
        date_from: moment(toursSearch?.date_from),
        date_to: moment(toursSearch?.date_to),
        childs_ages: toursSearch?.childs_ages
      });
      setAdultState(toursSearch?.adults?.id);
      setChildState(toursSearch?.childs?.id)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function clearSearchText(e, key) {
    goingToPrevValue.current = toursData[key];
    const value = e.target.value;
    if (value.length > 0) {
      setToursData({ ...toursData, [key]: null });
    }
  }
  // auto comapelete
  async function getDestinationList(inputValue) {
    if (inputValue.length > 2) {
      const countryId = countryRef.current?.id;
      const citiesRes = await getCities(inputValue, countryId);
      if (citiesRes?.status >= 200 && citiesRes?.status < 300) {
        const formatCities = citiesRes?.data?.data?.map((city) => {
          return {
            id: city?.id,
            label: city?.name,
            value: city?.id,
            name: city?.name,
            country_id: city?.country_id,
          };
        });
        setDestinationList(formatCities);
      }
    }
  }



  return (
    <div className={`p-0 m-auto ${modify ? "marketsearch-tours-modify" : ""}`}>
      <div className="row mx-0">
        {/* country */}
        <div className="col-md-6 col-12">
          <SelectField
            label={visa.country}
            haslabel={false}
            labelInner={true}
            id="country"
            name="country"
            options={allCountries}
            value={toursData?.country?.label}
            onChange={(e) => {
              countryRef.current = e;
              setToursData({
                ...toursData,
                country: e,
                destination: null,
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "country", value: e.id },
                  { required: true }
                ),
              });
            }}
            errors={errors?.country}
            color={errors?.country?.required ? "danger" : ""}
          />
        </div>

        {/* destination */}
        <div className="col-md-6 col-12">
          <AutoCompleteField
            haslabel={false}
            labelInner={true}
            id="destination"
            name="destination"
            label={marketPlace.messages.destination}
            isSearchable={true}
            placeholder={marketPlace.messages.destination}
            listAuto={destinationList}
            setListAuto={setDestinationList}
            getListAuto={getDestinationList}
            value={toursData?.destination?.label || ""}
            onFocus={(e) => clearSearchText(e, "destination")}
            onBlur={() =>
              setToursData({
                ...toursData,
                destination: goingToPrevValue.current,
              })
            }
            onChange={(e) => {
              setToursData({ ...toursData, destination: { label: e } });
            }}
            onSelectValue={(e) => {
              setToursData({ ...toursData, destination: e });
              setErrors({
                ...errors,
                ...validate(
                  { name: "destination", value: e },
                  { required: true }
                ),
              });
            }}
            disabled={status !== "umrah" && !toursData?.country && !modify}
            errors={errors?.destination}
            color={errors?.destination?.required ? "danger" : ""}
          />
        </div>

        {/* date from */}
        <div className="col-md-3 col-12">
          <DatePickerField
            haslabel={false}
            labelInner={true}
            label={marketPlace.From}
            id="date_from"
            name="date_from"
            placeholder="DD/MM/YYYY"
            date={toursData?.date_from}
            onChangeDate={(date) => {
              setToursData({
                ...toursData,
                date_from: date,
                date_to:
                  moment(date) >= moment(toursData?.date_to)
                    ? null
                    : toursData?.date_to,
                nights:
                  moment(date) >= moment(toursData?.date_to)
                    ? 0
                    : toursData?.date_to?.diff(date, "days") || 0,
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "date_from", value: date },
                  { required: true }
                ),
              });
            }}
            isOutsideRange={(day) =>
              !day.isAfter(moment(new Date()).add(0, "d"), "day")
            }
            errors={errors?.date_from}
            color={errors?.date_from?.required ? "danger" : ""}
          />
        </div>

        {/* date to */}
        <div className="col-md-3 col-12">
          <DatePickerField
            haslabel={false}
            labelInner={true}
            label={marketPlace.To}
            id="date_to"
            name="date_to"
            placeholder="DD/MM/YYYY"
            date={toursData?.date_to}
            initialVisibleMonth={() => moment(toursData.date_from) || null}
            onChangeDate={(date) => {
              setToursData({
                ...toursData,
                date_to: date,
                date_from:
                  moment(date) <= moment(toursData?.date_from)
                    ? null
                    : toursData?.date_from,
                nights: date?.diff(toursData?.date_from, "days") || 0,
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "date_to", value: date },
                  { required: true }
                ),
              });
            }}
            isOutsideRange={(day) =>
              !day.isAfter(moment(toursData?.date_from).add(0, "d"), "day")
            }
            disabled={!toursData?.date_from}
            errors={errors?.date_to}
            color={errors?.date_to?.required ? "danger" : ""}
          />
        </div>

        {/* nights */}
        {!modify ? (
          <div className="col-md-6 col-12">
            <TextField
              haslabel={false}
              labelInner={true}
              isImage={true}
              image={destinationIcon}
              label={marketPlace.Nights}
              id="nights"
              name="nights"
              value={toursData?.nights}
              readOnly
              disabled
            />
          </div>
        ) : null}

        {/**Adults and children */}
        <div className="col-md-6 col-12" ref={DetectClickOutside}>
          <div className="button-collapse mt-1">
            <div
              className={`title-style-container d-flex bg-light  ${errors?.adults?.required || errors?.adults?.minNumber
                ? "control-field--danger"
                : ""
                }`}
              style={{ borderRadius: "5px" }}
              onClick={() => paxToggle()}
            >
              <div className="w-100">
                <div className="global-input font-weight-bold border-0  d-flex justify-content-between p-0">
                  <div className="d-flex flex-column">
                    <span style={{ fontWeight: 400, color: "#000" }}>
                      {marketPlace.messages.pax}
                    </span>
                    <span
                      className="roomsNumber1"
                      style={{
                        lineHeight: 1,
                        fontWeight: 700,
                        color: "#000",
                      }}
                    >
                      {toursData?.adults?.label ?? 0}{" "}
                      {marketPlace.messages.adult}
                      {" , "}
                      {toursData?.childs?.label} {marketPlace.messages.child}
                    </span>
                  </div>

                  <div></div>
                </div>
              </div>

              <div className="icon-items-section-services d-flex align-items-center bg-light">
                <i
                  className={`fas fa-fw text-gray px-4 fa-lg ${paxIsOpen ? "fa-chevron-up " : "fa-chevron-down"
                    }`}
                ></i>
              </div>
            </div>
            {paxIsOpen ? (
              <div className="row pax-container ">
                <div className="adult-pax">
                  <p>
                    {inventory.messages.adults}{" "}
                    <span>{locale === "en" ? "18+ yrs" : "18+ سنة"}</span>
                  </p>

                  <div className="action">
                    {/* decrease adults */}
                    <button
                      onClick={() => {
                        setAdultState((prev) => prev - 1);
                        setToursData({
                          ...toursData,
                          adults: {
                            id: adultState - 1,
                            label: adultState - 1,
                            name: adultState - 1,
                            value: adultState - 1,
                          },

                          childs:
                            adultState === 0
                              ? { id: 0, value: 0, label: "0", name: "0" }
                              : toursData?.childs,
                          childs_ages:
                            adultState === 0 ? [] : toursData?.childs_ages,
                        });
                        setErrors({
                          ...errors,
                          ...validate(
                            {
                              name: "adults",
                              value: {
                                id: adultState - 1,
                                label: adultState - 1,
                                name: adultState - 1,
                                value: adultState - 1,
                              },
                            },
                            { required: true, minNumber: adultState - 1 === 0 }
                          ),
                        });
                      }}
                      disabled={adultState <= 0}
                    >
                      <span>-</span>
                    </button>
                    {/* adult value */}
                    <p className="adultNum">{adultState}</p>
                    {/* increase adults */}
                    <button
                      onClick={() => {
                        setAdultState((prev) => prev + 1);
                        setToursData({
                          ...toursData,
                          adults: {
                            id: adultState + 1,
                            label: adultState + 1,
                            name: adultState + 1,
                            value: adultState + 1,
                          },

                          childs:
                            adultState === 0
                              ? { id: 0, value: 0, label: "0", name: "0" }
                              : toursData?.childs,
                          childs_ages:
                            adultState === 0 ? [] : toursData?.childs_ages,
                        });
                        setErrors({
                          ...errors,
                          ...validate(
                            {
                              name: "adults",
                              value: {
                                id: adultState + 1,
                                label: adultState + 1,
                                name: adultState + 1,
                                value: adultState + 1,
                              },
                            },
                            { required: true, minNumber: adultState + 1 === 0 }
                          ),
                        });
                      }}
                      disabled={maxAdultsCount === adultState + 1}
                    >
                      <span>+</span>
                    </button>
                  </div>
                </div>

                <div className="adult-pax child-pax">
                  <p>
                    {inventory.messages.children}{" "}
                    <span>{locale === "en" ? "0-7 yrs" : "0-7 سنة"}</span>
                  </p>
                  <div className="action">
                    <button
                      onClick={() => {
                        setChildState((prev) => prev - 1);
                        handleChildsCount({
                          id: childState - 1,
                          value: childState - 1,
                          label: childState - 1,
                          name: childState - 1,
                        });
                      }}
                      disabled={childState <= 0}
                    >
                      <span>-</span>
                    </button>
                    <p className="adultNum">{childState}</p>
                    <button
                      onClick={() => {
                        setChildState((prev) => prev + 1);
                        handleChildsCount({
                          id: childState + 1,
                          value: childState + 1,
                          label: childState + 1,
                          name: childState + 1,
                        });
                      }}
                    >
                      <span>+</span>
                    </button>
                  </div>
                </div>

                <div className="childrensAge w-100">
                  {toursData?.childs_ages?.length > 0 ? (
                    <>
                      <hr className="w-100 my-2" />
                      <p className="p_children mb-2">
                        {marketPlace.selectChildrenAge}
                      </p>
                    </>
                  ) : null}
                </div>
                <div className="children-age-select w-100">
                  {toursData?.childs_ages?.map((childAge, index) => {

                    return (
                      <div
                        className="d-flex justify-content-between"
                        key={index}
                      >
                        <p>
                          {inventory.messages.children} {index + 1}
                        </p>
                        <select
                          id="selectbox1"
                          onChange={(e) => handleChildAge(e, index)}
                        >
                          {childsAgesLookup.map((age, ind) => (
                            <option value={age.value} selected={age.label === childAge.label}>
                              {age.label > 0 ? age.label : "<1"}Years Old
                            </option>
                          ))}
                        </select>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {errors?.adults?.required || errors?.adults?.minNumber ? (
              <small className="control-field__feedback control-field__feedback--danger d-block error-message">
                {inventory.messages.pax} {commons.isRequired}
              </small>
            ) : null}
          </div>
        </div>
        {/* search btn */}
        <div className="col-md-6 col-12 mt-2">
          <button
            onClick={()=>{
              search();
              dispatch({
                type: "toursCart",
                payload: null,
              });
            }}
            className="btn w-100 bg-nxt py-2"
            type="button"
            style={{ paddingBlock: "12px" }}
          >
            {marketPlace.messages.search}
          </button>
        </div>

      </div>
    </div>
  );
}
