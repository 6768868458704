import React from "react";
import { Route } from "react-router-dom";
import { MarketPlaceProvider } from "../../context/marketplace";
// React Component
export default function WebBuilderRoute({ component: Component, ...props }) {

  return (
    <Route
      {...props}
      render={(matchProps) => (
        <MarketPlaceProvider>
          <Component {...matchProps} />
        </MarketPlaceProvider>
      )}
    />
  );

}
