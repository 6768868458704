import React from "react";
import { useWebBuilderState } from "context/webBuilder";
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import ServiceCardThemeFive from "./ServiceCard";
import { useSBSState } from "context/global";

export default function OurServicesThemeFive() {
  const { ourServices } = useWebBuilderState();
  const { locale } = useSBSState()

  let services = Array.isArray(ourServices?.services)
    ? ourServices?.services
    : [];
  services = services?.filter((service) => service.icon && service.visible);

  return (
    <section className="wb-our-service" id="our-service">
      <div className="custom-container">
        <ServicesContent
          header={ourServices?.title[locale]}
          body={ourServices?.body[locale]}
        />

        <div className="service-cards-container">
          {services.map((service, index) => (
            <ServiceCardThemeFive
              type={service?.type}
              header={service?.header[locale]}
              body={service?.body[locale]}
              key={service?.type + index}
              visible={service?.visible}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
