
import ServicesContent from 'modules/WebBuilder-V2/shared/ServicesContent';
import PackageCardTheme10 from './PackageCard';
import sectionBG from "assets/images/webBuilder/theme10-packages-bg.png"


export default function OurPackagesThemeTen({ details }) {
	const { id, title, body, items } = details;


	return (
		<section
			className={`wb-our-packages`}
			id={id}
			style={{ backgroundImage: `url(${sectionBG})` }}
		>
			<div className="custom-container">
				<div className="service-top-section">
					<ServicesContent
						header={title}
						body={body}
					/>

				</div>

				<div className="packages-cards-container">
					{items?.filter((item) => item && item?.product_uuid).map((item, index) => (
						<PackageCardTheme10
							{...item}
							key={`${item?.product_uuid}-${index}`}
						/>
					))}
				</div>
			</div>
		</section>
	);
}
