import Locale from "translations";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/webBuilder/calendar-2.svg";
import { ReactComponent as Hotelcon } from "assets/images/webBuilder/hotel-black.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { Link } from "react-router-dom";
import moment, { now } from "moment";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function PackageCardThemeFive(props) {
  const {
    image,
    name,
    name_localized,
    departure_date_at,
    return_date_at,
    stars,
    product_uuid,
    hotelName,
    price,
    locationName,
    country,
    initialName,
    currency,
  } = props;

  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  const packageUrl = `/package/${product_uuid}`;
  let rate = +stars && +stars > 5 ? 5 : +stars;
  let countryName = country ? (country[locale] || country) : (locationName[locale] || locationName);
  const packageName = name_localized?.[locale] ? name_localized?.[locale] : (name || initialName);

  return (
    <Link to={packageUrl} className="package-hotel-card">
      <span className="rate">
        <GoldenStarIcon />
        {rate}
      </span>
      <div className="card-img">
        <img src={image} alt="our package" />
      </div>

      <div className="card-content-top">
        {/* package name and location */}
        <div className="card-details">

          <h4 className="title">{packageName}</h4>
          {countryName &&
            <p className="location">
              <LocationIcon />
              <span className="mx-2">{countryName}</span>
            </p>
          }
        </div>
        {/* package price */}
        <div>
          <span>{webBuilder.from}</span>
          <p className="price">
            <span className="amount">{exchangedPrice}</span> {" "}
            <span className="currency">{exchangeCurrency}</span>
          </p>
        </div>
      </div>
      <div className="card-content-bottom">
        <div className="d-flex align-items-center mb-2">
          <Hotelcon className="me-1" />
          {hotelName ? hotelName : " - "}
        </div>

        <div className="d-flex align-items-center mb-2">
          <CalendarIcon className="me-1" />
          <span>
            {`${moment(departure_date_at || now()).format(
              "DD MMM YYYY"
            )} - ${moment(return_date_at || now()).format("DD MMM YYYY")}`}
          </span>
        </div>

      </div>
    </Link>
  );
}
