import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";


function TransferService({ details }) {
  const { id, body, title, items } = details;


  return (
    <div className="web-builder-content-tours container" id={id}>
      <div className="service-top-section">
        <ServicesContent
          header={title}
          body={body}
        />
      </div>

      <div className="tours-detials">
        <CustomCarousel>
          {items.map((item, index) => (
            <ServiceCardDetails {...item} key={item.id || `transfer-${index}`} itemType="transfer" />
          ))}
        </CustomCarousel>
      </div>
    </div>
  );
}

export default TransferService;
