import * as actionTypes from "./actions";

// Define initialState
const initialState = {
	product: [],
	meta: {},
	info: {},
	editMoodEnable: null,
	currentPage: null,
	productName: null,
	productType: null,
	productValidFrom: null,
	productValidTo: null,
	productPricing: null,
	productStatus: null,
	productClass: null,
	productDepature: null,
	productReturn: null,
};
// Define the Reducer
export default function product_status(state = initialState, action) {
	switch (action.type) {
		case actionTypes.Product_Status:
			return {
				...state,
				product: action.product,
				meta: action.meta,
			};
		case actionTypes.Product_Info:
			return {
				...state,
				editMoodEnable: action.editMoodEnable,
				info: action.info,
			};

		case actionTypes.ItemInfo:
			return {
				...state,
				hotelId: action.hotelId,
				externalId: action.externalId,
				internalId: action.internalId,
				domesId: action.domesId,
				landMarkId: action.landMarkId,
				visaId: action.visaId,
				insuranceId: action.insuranceId,
			};

		case actionTypes.Remove_Product:
			return {
				product: action.product,
				meta: state.meta,
			};

		case actionTypes.Filter:
			return {
				currentPage: action.currentPage,
				productName: action.productName,
				productType: action.productType,
				productValidFrom: action.productValidFrom,
				productValidTo: action.productValidTo,
				productPricing: action.productPricing,
				productStatus: action.productStatus,
				productClass: action.productClass,
				productDepature: action.productDepature,
				productReturn: action.productReturn,
			};

		default:
			return state;
	}
}
