
import ServicesContent from "modules/WebBuilder-V2/shared/ServicesContent";
import Carousel from "react-multi-carousel";
import ThemeSevenPackageCard from "./PackageCard";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";
import { useSBSState } from "context/global";


export default function OurPackagesThemeSeven({ details }) {
	const { locale } = useSBSState()
	const { id, title, body, items } = details;

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1200 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1200, min: 464 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const showArrows = items.length > responsive.desktop.items;

	return (
		<section className="theme_packages_section section_padding__md custom-container" id={id}>
			<div className="row d-flex align-items-center">
				<div className="col-xl-3 col-lg-4">
					<div className="section_heading w-100">
						<ServicesContent
							header={title}
							body={body}
						/>
					</div>
				</div>

				<div className="col-xl-9 col-lg-8">
					<div className="section_content">
						<Carousel
							responsive={responsive}
							slidesToSlide={1}
							keyBoardControl={true}
							removeArrowOnDeviceType={["tablet", "mobile"]}
							customButtonGroup={showArrows ? <ButtonGroup arrowsBesideDots={true} /> : null}
							renderButtonGroupOutside={false}
							showDots={showArrows}
							arrows={false}
							infinite={false}
							rtl={locale === "ar"}
						>
							{items?.filter((item) => item && item?.product_uuid).map((props, index) => (
								<ThemeSevenPackageCard
									{...props}
									key={index}
								/>

							))}
						</Carousel>
					</div>
				</div>
			</div>
		</section>

	);
}
