import React, { useEffect, useMemo, useState } from "react";
import PublicPageHeader from "./PublicPageHeader";
import { useParams } from "react-router-dom";
import FLightView from "./Flights";
import { getPackage } from "services/webBuilder";
import Locale from "translations";
import WebBuilderRequestForm from "./RequestForm";

import { ReactComponent as StarIcon } from "assets/images/webBuilder/star.svg";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { ReactComponent as CalenderIcon } from "assets/images/webBuilder/calendar-224.svg";
import { ReactComponent as CheckMarkCircleIcon } from "assets/images/market-place/tick-circle.svg";
import { ReactComponent as RequiredDocIcon } from "assets/images/market-place/required-document-text.svg";
import CustomRightArrow from "modules/WebBuilder-V2/shared/CustomRightArrow";
import CustomLeftArrow from "modules/WebBuilder-V2/shared/CustomLeftArrow";
import Carousel from "react-multi-carousel";
import PackageNavTabs from "./PackageNavTabs";
import moment from "moment";
import PackageAttractionsView from "components/shared/PackageAttractionsView";
import PackageHotelView from "components/shared/PackageHotelView";
import ProductItinerary from "components/shared/ProductItinerary";
import { itineraryElementsIds } from "modules/WebBuilder-V2/helpers/formatProductItems";
import { GalleryremoveIcon } from "components/Icons";
import { useWebBuilderState } from "context/webBuilder";
import { useSBSState } from "context/global";
import placeholderSrc from "assets/images/placeHoleder.png"

function PreviewServicesV2() {
  const { content } = useWebBuilderState();
  const { currency: currentCurrency, locale } = useSBSState();
  const { service, id } = useParams();
  const [packageData, setPackageData] = useState(null);
  // const location = useLocation();
  const { productsBuilder, webBuilder } = Locale;

  useEffect(() => {
    // getPageDetails();
    window.scrollTo(0, 0);
    return () => { };
  }, []);

  useEffect(() => {
    if (service) {
      async function getDataView() {
        const res = await getPackage(id, currentCurrency);
        let data = res?.data?.data;
        let nights_count = moment(moment(data?.return_date_at)).diff(moment(data?.departure_date_at), "days")
        setPackageData({ ...data, nights_count });
      }

      getDataView();
    }
  }, [currentCurrency, id, service]);




  const productItemsTabs = useMemo(() => {
    let items = [];
    const productHasItinerary = packageData?.itinerary && packageData?.itinerary?.length > 0;
    packageData?.product_items?.forEach((element) => {
      const elemetID = element?.item?.id;
      switch (elemetID) {
        case itineraryElementsIds.external:
          items.push({ name: webBuilder.flights, id: elemetID, });
          break;
        case itineraryElementsIds.hotel:
          items.push({ name: webBuilder.packageAccommodation, id: elemetID });
          break;
        case itineraryElementsIds.sightseeing:
          items.push({ name: webBuilder.packageAttraction, id: elemetID });
          break;
        default:
          break;
      }
    });
    if (productHasItinerary) {
      items.push({ name: productsBuilder.itinerary, id: 101 });
    }

    items = [
      ...new Map(items.map((item) => [item["name"], item])).values(),
      // { name: "Package Pricing", id: "package-pricing" },
      { name: webBuilder.includedServices, id: "package-included-services" },
    ];
    return items;
  }, [packageData?.itinerary, packageData?.product_items]);

  const packageContainHotel =
    productItemsTabs.filter((item) => item?.id === itineraryElementsIds.hotel)
      .length > 0;
  const packageContainAttraction =
    productItemsTabs.filter(
      (item) => item?.id === itineraryElementsIds.sightseeing
    ).length > 0;

  console.log(packageContainHotel);
    

  const HotelElements = () => {
    return packageData?.product_items?.map((element, index) => {
      if (element?.item?.id === itineraryElementsIds.hotel) {
        return <PackageHotelView hotelData={element?.itemable} key={index} />;
      }
      return null;
    });
  };

  const AttractionElements = () => {
    return packageData?.product_items?.map((element, index) => {
      if (element?.item?.id === itineraryElementsIds.sightseeing) {
        return (
          <PackageAttractionsView
            attractionData={element?.itemable}
            key={index}
          />
        );
      }
      return null;
    });
  };



  const packageMetaData = useMemo(() => {
    let packageName = packageData?.name?.en;
    let hotels = packageData?.product_items?.filter(element => element?.item?.id === itineraryElementsIds.hotel);
    let slides = [];
    if (hotels?.length > 0) {
      for (let i = 0; i < hotels.length; i++) {
        let hotelImages = hotels[i]?.itemable?.hotel?.images;
        if (hotelImages?.length > 5) {
          hotelImages = hotelImages.slice(0, 5);
        }
        slides.push(hotelImages);
      }
    }
    // if (location.state?.productImage) {
    //   slides.unshift([location.state.productImage]);
    // } else if (Array.isArray(content)) {
    if (Array.isArray(content)) {
      for (const item of content) {
        for (const product of item?.items || []) {
          if (product?.product_uuid === id) {
            item?.type === "packages" && slides.unshift([product.image]);
            packageName = product?.name_localized;
            break;
          }
        }
      }
    }
    // }
    return { slides: slides.flat(), packageName };
  }, [content, id, packageData?.name?.en, packageData?.product_items])

  const minPakcagePrice = useMemo(() => {
    let minPrice = 0;
    let priceCombination = packageData?.product_price_combinations;
    let isFullPackagePrice = packageData?.product_price?.product_price_type?.id === 1;
    let priceKey = isFullPackagePrice ? "full_price_after_discount" : "breakdown_price_after_discount";

    if (packageData && priceCombination?.length > 0) {
      for (let i = 0; i < priceCombination.length; i++) {
        const element = priceCombination[i];
        if (i === 0) minPrice = element[priceKey];
        if (element[priceKey] < minPrice) {
          minPrice = element[priceKey]
        }
      }
    }

    return minPrice;
  }, [packageData])

  const terms = packageData?.share_terms ? JSON.parse(packageData?.share_terms) : {};
  const requiredDocuments = packageData?.share_required_documents? JSON.parse(packageData?.share_required_documents) : {};


  return (
    <div className="view-package-wrapper public-page-preview-services web-builder-preview" id='main-web-builder'>
      {packageData &&
        <>
          {/* {location.pathname.includes("web") ? <HeroHeader /> : <HeroHeaderV2 />} */}

          <PublicPageHeader service={service} packageName={packageMetaData.packageName?.[locale] || packageData?.name?.en} packageData={packageData} />

          <div className="package-details-container w-85 mx-auto">

            <div className="d-flex flex-wrap justify-content-between align-items-center gap-10 py-3">
              <div className="">
                <div className="d-flex align-items-center gap-10">
                  <h1 className="service-title">{packageMetaData.packageName?.[locale] || packageData?.name?.en}</h1>
                  {packageData?.product_classification &&
                    <span className="rate">
                      {[...Array(5)].map((i, index) =>
                        index < packageData?.product_classification?.id ? <GoldenStarIcon key={index} width="18px" /> : <StarIcon key={index} width="18px" />
                      )}
                    </span>
                  }
                </div>
                <div className="d-flex align-items-center gap-24">
                  <span className="location">
                    <LocationIcon />
                    {packageData?.country?.name?.[locale] || packageData?.country?.name?.en || packageData?.country?.name}
                  </span>
                  {packageData?.nights_count && service !== "tours" && service !== "flight" ?
                    <span className="duration">
                      <CalenderIcon />
                      {packageData?.nights_count} {webBuilder.night}
                    </span>
                    :
                    null
                  }
                </div>
              </div>
              <div className="book-price">
                <div className="d-flex flex-column">
                  <span>{webBuilder.from}</span>
                  <p>
                    <span className="min-price">{minPakcagePrice}</span>{" "}
                    <span>{currentCurrency}</span>
                  </p>
                </div>
                <button className="btn book-btn"
                  onClick={() => document?.querySelector(".request-form ")?.scrollIntoView({ behavior: "smooth", block: "start" })}
                >
                  {webBuilder.bookNow}
                </button>
              </div>
            </div>

            {/*  */}
            <div className="d-flex flex-wrap  gap-24 pb-5">
              <div className="package-content">
                {/* carousel */}
                {packageMetaData.slides.length > 0 &&
                  <div className="package-carousel">
                    <Carousel
                      responsive={{ desktop: { breakpoint: { max: 4000, min: 0 }, items: 1 } }}
                      itemClass={"hotel-slide"}
                      className=""
                      slidesToSlide={1}
                      keyBoardControl={true}
                      customRightArrow={<CustomRightArrow />}
                      customLeftArrow={<CustomLeftArrow />}
                      renderButtonGroupOutside={true}
                      shouldResetAutoplay={true}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      infinite={true}
                    >
                      {packageMetaData.slides.map((slide, index) => {
                        return <img src={slide} alt="" key={slide || index} onError={(e) => { e.target.src = placeholderSrc }} />
                      })}
                    </Carousel>
                  </div>
                }
                {/* package nav */}
                {service === "package"
                  ? <PackageNavTabs productItemsTabs={productItemsTabs} />
                  : null
                }
                {/* flights */}

                {(service === "package" || service === "flight")
                  && packageData?.product_items?.filter((item) => item?.item?.id === itineraryElementsIds.external)?.length > 0 ?
                  <div className="package-item" id={`tab-${itineraryElementsIds.external}`}>
                    {packageData?.product_items?.filter((item) => item?.item?.id === itineraryElementsIds.external)
                      ?.map((item, index) => {
                        return (
                          <div className="public-page-preview-services mt-4" index={index} key={item?.itemable?.id}>
                            <FLightView details={item} />
                          </div>
                        );
                      })}
                  </div>
                  :
                  null
                }


                {/* Hotels */}
                {service === "package" || service === "hotels" ? packageContainHotel ? (
                  <div
                    className="package-item"
                    id={`tab-${itineraryElementsIds.hotel}`}
                  >
                    <h2 className="package-title">{webBuilder.packageAccommodation}</h2>
                    {HotelElements()}
                  </div>
                ) : null : null}

                {/* Attraction */}
                {service === "package" || service === "tours" ? packageContainAttraction ? (
                  <div
                    className="package-item"
                    id={`tab-${itineraryElementsIds.sightseeing}`}
                  >
                    <h2 className="package-title">
                      {webBuilder.packageAttraction}
                    </h2>
                    {AttractionElements()}
                  </div>
                ) : null : null}


                {/* Itinerary */}
                {service === "package" ? packageData?.itinerary?.length > 0 ? (
                  <div className="package-item" id="tab-101">
                    <h2 className="package-title">{productsBuilder.itinerary}</h2>
                    <ProductItinerary
                      itineraryData={packageData?.itinerary}
                      itineraryElementsIds={itineraryElementsIds}
                    />
                  </div>
                ) : null : null}

                {/* services */}
                {service === "package" ? <div className="package-item">
                  <h2 className="package-title" id="tab-package-included-services">{webBuilder.services}</h2>
                  <div className="mt-4">
                    <p className="d-flex align-items-center mb-2">
                      {packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.internal) ?
                        <>
                          <CheckMarkCircleIcon />
                          <span className="mx-1">
                            {productsBuilder.internalTransportations}
                          </span>
                        </>
                        :
                        <>
                          <GalleryremoveIcon color="#EA5455" />
                          <span className="mx-1">
                            {productsBuilder.internalTransportations}
                          </span>
                        </>
                      }
                    </p>
                    <p className="d-flex align-items-center mb-2">
                      {packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.visa && item?.itemable?.included) ?
                        <>
                          <CheckMarkCircleIcon />
                          <span className="mx-1">{productsBuilder.visa}</span>
                        </>
                        :
                        <>
                          <GalleryremoveIcon color="#EA5455" />
                          <span className="mx-1">{productsBuilder.visa}</span>
                        </>
                      }
                    </p>
                    <p className="d-flex align-items-center mb-2">
                      {packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.insurance && item?.itemable?.included) ?
                        <>
                          <CheckMarkCircleIcon />
                          <span className="mx-1">{productsBuilder.insurance}</span>
                        </>
                        :
                        <>
                          <GalleryremoveIcon color="#EA5455" />
                          <span className="mx-1">{productsBuilder.insurance}</span>
                        </>
                      }
                    </p>
                    <p className="d-flex align-items-center mb-2">
                      {packageData?.product_items?.find((item) => item?.item?.id === itineraryElementsIds.otherServices) ?
                        <>
                          <CheckMarkCircleIcon />
                          <span className="mx-1">{productsBuilder.other}</span>
                        </>
                        :
                        <>
                          <GalleryremoveIcon color="#EA5455" />
                          <span className="mx-1">{productsBuilder.other}</span>
                        </>
                      }
                    </p>
                  </div>
                </div> : null}
              </div>

              <div className="package-request">
                <WebBuilderRequestForm
                  packageData={packageData}
                  packageContainHotel={packageContainHotel}
                />

                {/* Required Documents */}
                {requiredDocuments?.[locale]  &&
                  <div className="package-item required-docs">
                    <div className="d-flex align-items-center">
                      <RequiredDocIcon />
                      <h2 className="package-title mx-2">
                        {productsBuilder.requiredDocuments}
                      </h2>
                    </div>
                    <div className="mt-4" style={{whiteSpace: "pre-line"}}>
                      {requiredDocuments?.[locale] ?? (
                        <p className="text-muted text-center">{webBuilder.noFoundDocument}</p>
                      )}
                    </div>
                  </div>
                }
                {terms?.[locale] &&
                  <div className="package-item required-docs">
                    <div className="d-flex align-items-center">
                      <RequiredDocIcon />
                      <h2 className="package-title mx-2">
                        {productsBuilder.termsAndConditions}
                      </h2>
                    </div>
                    <div className="mt-4" style={{whiteSpace: "pre-line"}}>
                      {terms?.[locale] ?? (
                        <p className="text-muted text-center"></p>
                      )}
                    </div>
                  </div>
                }
              </div>
            </div>

          </div>
        </>
      }
    </div>
  );
}

export default PreviewServicesV2;
