import moment from "moment";
import React from "react";
import Locale from "translations";

// const details = {
// 	id: 4056,
// 	item: {
// 		id: 1,
// 		name: "external transportation",
// 	},
// 	itemable: {
// 		id: 1438,
// 		count: 6,
// 		transporter: {
// 			id: 2251,
// 			name: "Egypt Air",
// 			code: "MS",
// 		},
// 		destination_type: {
// 			id: 1,
// 			name: "departure",
// 		},
// 		transportation_type: {
// 			id: 2,
// 			name: "Air",
// 		},
// 		from_country: {
// 			id: 20,
// 			name: "Egypt",
// 			code: "EG",
// 			nat_code: "EGY",
// 			currency: "EGP",
// 			nationality_id: 20,
// 			phone_code: "+20",
// 		},
// 		from_port: {
// 			id: 2729,
// 			name: "El Nouzha Airport",
// 		},
// 		to_country: {
// 			id: 966,
// 			name: "Saudi Arabia",
// 			code: "SA",
// 			nat_code: "SAR",
// 			currency: "SAR",
// 			nationality_id: 966,
// 			phone_code: "+966",
// 		},
// 		to_port: {
// 			id: 6053,
// 			name: "King Abdulaziz International Airport",
// 		},
// 		trip_number: "TRIP_EG_100",
// 		departure_at: "2023-11-16T09:30:00.000000Z",
// 		arrival_at: "2023-11-18T10:30:00.000000Z",
// 		price_adult: null,
// 		price_child: null,
// 		price_infant: null,
// 		round_trip: 1,
// 		transits: [
// 			{
// 				id: 162,
// 				transporter: {
// 					id: 2251,
// 					name: "Egypt Air",
// 					code: "MS",
// 				},
// 				country: {
// 					id: 974,
// 					name: "Qatar",
// 					code: "QA",
// 					nat_code: "QAT",
// 					currency: "QAR",
// 					nationality_id: 974,
// 					phone_code: "+974",
// 				},
// 				city: {
// 					id: 24,
// 					name: "Doha",
// 				},
// 				arrival_port: {
// 					id: 2,
// 					name: "Bost Airport",
// 				},
// 				departure_port: {
// 					id: 2,
// 					name: "Bost Airport",
// 				},
// 				departure_at: "2023-11-17T07:30:00.000000Z",
// 				arrival_at: "2023-11-17T05:30:00.000000Z",
// 				created_at: "2023-11-14T07:20:02.000000Z",
// 				updated_at: "2023-11-14T07:20:02.000000Z",
// 			},
// 			{
// 				id: 162,
// 				transporter: {
// 					id: 2251,
// 					name: "Egypt Air",
// 					code: "MS",
// 				},
// 				country: {
// 					id: 974,
// 					name: "Qatar",
// 					code: "QA",
// 					nat_code: "QAT",
// 					currency: "QAR",
// 					nationality_id: 974,
// 					phone_code: "+974",
// 				},
// 				city: {
// 					id: 24,
// 					name: "Doha",
// 				},
// 				arrival_port: {
// 					id: 2,
// 					name: "Bost Airport",
// 				},
// 				departure_port: {
// 					id: 2,
// 					name: "Bost Airport",
// 				},
// 				departure_at: "2023-11-18T03:30:00.000000Z",
// 				arrival_at: "2023-11-17T11:30:00.000000Z",
// 				created_at: "2023-11-14T07:20:02.000000Z",
// 				updated_at: "2023-11-14T07:20:02.000000Z",
// 			},
// 		],
// 		note: {
// 			ar: "ar_note",
// 			en: "en_note",
// 		},
// 		created_at: "2023-11-14T07:20:02.000000Z",
// 		updated_at: "2023-11-14T07:20:02.000000Z",
// 	},
// 	sort: 3,
// 	has_internal_transportation: true,
// 	created_at: "2023-11-14T07:20:02.000000Z",
// 	updated_at: "2023-11-14T07:20:02.000000Z",
// };

function toHoursAndMinutes(totalMinutes) {
	const hours = Math.abs(Math.floor(+totalMinutes / 60));
	const minutes = Math.abs(+totalMinutes % 60);
	return `${hours}h ${minutes ? minutes + "min" : ""}`;
}
function FLightView({ details, index }) {
	const itemable = details.itemable;
	const { webBuilder } = Locale;
	const calcStopTime = (departure, arrival) => {
		return toHoursAndMinutes(moment(arrival).diff(departure, "m"));
	};

	const destinationDate = moment(
		itemable?.destination_type?.id === 1
			? itemable.departure_at
			: itemable.arrival_at
	).format("dddd DD MMMM YYYY");

	const transits = [
		{
			departure_at: itemable.departure_at,
			departure_port: itemable.from_port,
			transporter: itemable.transporter,
			from_country: itemable.from_country,
		},
		...itemable?.transits,
		{ arrival_at: itemable.arrival_at, arrival_port: itemable.to_port },
	];
	const transitsLength = transits?.length;

	const timeLine =
		transitsLength > 0
			? transits?.map((trans, index, array) => {
				let time = {
					...trans,
				};

				if (!trans?.arrival_at) {
					time["durationTime"] = calcStopTime(
						trans?.departure_at,
						array[index + 1]?.arrival_at
					);
				}

				if (trans?.arrival_at && trans?.departure_at) {
					time["waiting"] = calcStopTime(
						trans?.arrival_at,
						trans.departure_at
					);
				}

				if (index > 1) {
					time["durationTime"] = calcStopTime(
						array[index - 1]?.departure_at,
						trans?.arrival_at
					);
				}

				return time;
			})
			: [
				{
					durationTime: calcStopTime(
						itemable.departure_at,
						itemable.arrival_at
					),

					departure_port: itemable.from_port,
					arrival_port: itemable.to_port,
					transporter: itemable?.transporter,
					from_country: itemable?.from_country,
					to_country: itemable.to_country,
				},
			];

	return (
		<div className="service-container">
			<div className="public-page-flight">
				<p className="flight-header" >{webBuilder.flights}</p>

				<div className="flight-details">
					<p className="flight-type">{`${itemable?.destination_type?.name} : ${destinationDate}`}</p>
					<div className="flight-details-container">
						<div className="trip-details">
							<img
								className="main-air-line-logo"
								style={{ width: "110px" }}
								src={itemable?.transporter?.logo} alt="air line logo"
							/>
							<div className="flight-time-line">
								<div className="flight-departure">
									<p>{moment(itemable?.departure_at).utc().format("HH:mm")}</p>
									<p>{itemable?.from_port?.code} </p>
								</div>
								<div className="flight-time">

									{timeLine.map((i, index) => {
										return i?.waiting || i.durationTime ? <div key={`flight-time-${index}`}>
											{i.durationTime ? (
												<div className="flight-duration">
													{i.durationTime}
												</div>
											) : null}

											{i?.waiting ? (
												<div className="stop-duration">
													<span className="stop-color">Stop</span>{" "}
													<span>- {i.waiting}</span>
												</div>
											) : null}
										</div> : null

									})}
								</div>
								<div className="flight-arrival">
									<p>{moment(itemable?.arrival_at).utc().format("HH:mm")}</p>
									<p>{itemable?.to_port?.code} </p>
								</div>
							</div>
						</div>

						<hr />

						<div className="trip-details-container">
							<TripDetails itemable={itemable} />
							{/* <div className="waiting-time">
								<span>45min</span>
								<span className="waiting-lable">Wait</span>
							</div> */}
							{/* <TripDetails /> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FLightView;

const TripDetails = ({ itemable }) => {
	const calcStopTime = (departure, arrival) => {
		return toHoursAndMinutes(moment(arrival).diff(departure, "m"));
	};
	return (
		<>
			<div className="air-line">
				<img
					className="small-air-line-logo"
					src={itemable?.transporter?.logo}
					alt="air line logo"
				/>
				<span>{itemable.transporter.name}</span>
				<span className='mx-1'>{itemable.trip_number}</span>
			</div>

			<div className="flight-stops">
				<p className="trip-duration">{
					calcStopTime(
						itemable?.departure_at,
						itemable?.arrival_at
					)}</p>
				<div className="flight-depart-arrival">
					<div></div>
					<div></div>
					<div></div>
				</div>

				<div className="ports">
					<p>{moment(itemable?.departure_at).utc().format("HH:mm")} {' '}{itemable?.from_port?.code} {" "}{itemable.from_port.name}</p>
					<p>{moment(itemable?.arrival_at).utc().format("HH:mm")} {" "} {itemable?.to_port?.code} {" "}{itemable.to_port.name}</p>

				</div>
			</div>
		</>
	);
};
