import * as actionTypes from "./actions";

//Define InitialState
const initialState = {
	receiptNo: null,
	amount: null,
	amountDate: null,
	receiptImg: null,
};

// Define Payment Reducer
export default function payment_reducer(state = initialState, action) {
	switch (action.type) {
		//Payment: Bank Transfer
		case actionTypes.Bank_Transfer_Receipt:
			return {
				...state,
				receiptNo: action.receiptNo,
				amount: action.amount,
				amountDate: action.amountDate,
				receiptImg: action.receiptImg,
			};
		default:
			return state;
	}
}
