import React from 'react'
import Locale from 'translations'

export default function MessageInfo() {
  const { webBuilder } = Locale;
  return (
    <div className='message-info'>
      <p>
        {webBuilder.flightWarning}
      </p>
    </div>
  )
}
