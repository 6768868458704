import arrowTwoDirectionsSrc from "assets/images/inventory/arrow-two-directions.svg";
import bin from "assets/images/transfer/bin.svg";
import Locale from "translations";
import { useMarketplaceDispatch } from "context/marketplace";
import moment from "moment";
import { deleteFromTransferCartBuilder } from "services/webBuilder";

export default function TransferBookingDetailsCard({ Details, allData }) {
  const { inventory, marketPlace, productsBuilder } = Locale;

  const dispatch = useMarketplaceDispatch();
  const transferSearch = JSON.parse(localStorage.getItem("transferSearch"));

  const deleteFromCart = async (id) => {
    const res = await deleteFromTransferCartBuilder({ id, cacheKey: transferSearch?.cacheKey });
    if (res.status === 200) {
      dispatch({
        type: "transferCart",
        payload: res.data,
      });
    }
    if (
      res.status === 400 &&
      res.data?.custom_error_code === "TRANSFER-CRT-001"
    ) {
      dispatch({
        type: "transferCart",
        payload: [],
      });
    }
  };

  const translateTripType = (name) => {
    switch (name) {
      case "Round Trip":
        return productsBuilder.roundtrip;
      case "one way":
        return marketPlace.oneway;

      default:
        break;
    }
  };

  return (
    <>
      <div className="transfer-booking-item">
        <div className="d-flex flex-wrap">
          <img
            src={Details?.image}
            alt={`${Details?.type} ${Details?.category}	${Details?.vehicle}`}
            className="img-fluid transfer-booking-item-img"
            onError={(e) => {
              e.target.onerror = null;
              e.target.crossOrigin = null;
            }}
          />
          <div className="d-flex flex-column mx-md-2">
            <h2 className="mb-2">
              <span className="font-weight-bold text-dark-blue h5">
                {Details?.type}{" "}
              </span>
              <span className="text-dark-blue">{Details?.category} </span>
              <span className="h6 text-uppercase text-muted">
                {Details?.vehicle}{" "}
              </span>
            </h2>
            <i className="fal fa-sort-alt"></i>

            <div className="transfer-booking-item-info">
              <i className="fas fa-map-marker-alt"></i>
              <p className="mx-1">
                <span>{Details?.from_name}</span>
                <img
                  src={arrowTwoDirectionsSrc}
                  alt=""
                  className="img-fluid mx-1"
                />
                <span>{Details?.to_name}</span>
              </p>
            </div>

            <div className="transfer-booking-item-info">
              <i className="far fa-calendar-alt"></i>
              <span className="mx-1 font-weight-bold">
                {`${moment(Details?.pickup_time).format(
                  "DD/MM/YYYY HH:mm"
                )}  (${moment(Details?.pickup_time).format("dddd")})`}
              </span>
            </div>

            <div className="transfer-booking-item-info">
              <i className="far fa-user"></i>
              <span className="mx-1 font-weight-bold">
                {`${translateTripType(Details?.trip_type)} , ${Details?.adults
                  } ${marketPlace.adult} , ${Details?.children} ${marketPlace.messages.child
                  }, ${Details?.infant} ${marketPlace.infant}`}
              </span>
            </div>
          </div>
          <button
            className="btn p-0 transfer-delete-icon"
            onClick={() => deleteFromCart(Details.id)}
          >
            <img alt="delete reservation" src={bin} />
          </button>
        </div>
        {Details.departure_flight_time && (
          <p className="font-weight-bold text-dark-blue mt-1">
            {`${inventory.messages.departureFlightNumber}  ${Details?.departure_flight_code}, ${marketPlace.flightTime
              } ${moment(Details.departure_flight_time).format("HH:mm")}`}
          </p>
        )}
        {Details.arrival_flight_time && (
          <p className="font-weight-bold text-dark-blue mt-1">
            {`${inventory.messages.arrivalFlightNumber}  ${Details?.arrival_flight_code},
						${marketPlace.flightTime} ${moment(Details.arrival_flight_time).format(
              "HH:mm"
            )}`}
          </p>
        )}

        <p className="transfer-booking-item-warning mt-1">
          <i className="fas fa-exclamation-triangle"></i>
          <span className="mx-1">{marketPlace.transferBookingWarning}</span>
        </p>

        {/* cancelations info */}
        {Details?.cancellation_policies?.length > 0 ? (
          <div className="mt-3 transfer-booking-item-cancelation py-3">
            <span className="font-weight-bold h6 m-0">
              {inventory.messages.cancellationFees}
            </span>

            <div className="d-flex align-items-center flex-wrap">
              <div className="col-md-6">
                {Details?.cancellation_policies?.map((policy, index) => {
                  return (
                    <div className="" key={index}>
                      <div className="d-flex justify-content-between text-success">
                        <span>
                          {inventory.viewReservation.until}{" "}
                          {moment(policy?.from)
                            .subtract(1, "minute")
                            .format("hh:mm A")}{" "}
                          {moment(policy?.from)
                            .subtract(1, "day")
                            .format("DD/MM/YYYY")}
                        </span>
                        <span className="font-weight-bold text-capitalize">
                          {inventory.viewReservation.free}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between text-danger">
                        <span>
                          {marketPlace.after}{" "}
                          {moment(policy?.from).format("hh:mm A")}{" "}
                          {moment(policy?.from).format("DD/MM/YYYY")}
                        </span>
                        <span className="font-weight-bold text-capitalize">
                          {" "}
                          {policy?.exchange_search_amount} {policy?.exchange_search_currency}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className="col-md-6 text-muted">
                {marketPlace.transferTermsAndCondition}
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}
