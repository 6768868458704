import { useSBSState } from "context/global";
import React from "react";

export default function FAQTheme4({ faqs }) {
  const { locale } = useSBSState();
  return (
    <div className="faq-container">
      {faqs &&
        faqs?.map((que, index) => (
          <div className="faq-card-holder">
            <h5 className="faq-title">{que.question[locale]}</h5>

            <p className="faq-desc">{que.body[locale]}</p>
          </div>
        ))}
    </div>
  );
}
