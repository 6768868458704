import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import { ServiceCardDetails } from "modules/WebBuilder/shared/ServiceCardDetails";
import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";

function HotelService({ details }) {
  const { id, title, body, items } = details;

  return (
    <div className="web-builder-content-hotels container" id={id}>
      <div className="hotels-top-section">
        <ServicesContent
          header={title}
          body={body}
        />
      </div>

      <div className="hotels-detials">
        {items && (
          <CustomCarousel>
            {items.map((props) => (
              <ServiceCardDetails {...props} itemType="hotel" key={props.id} />
            ))}
          </CustomCarousel>
        )}
      </div>
    </div>
  );
}

export default HotelService;
