import React from 'react'
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import { Link } from 'react-router-dom';
import Locale from 'translations';


export default function PackageBreadCrumb() {
  const {webBuilder} = Locale;
  // const params = useParams();
  // const link = params.name ? `/` : `/`;
  return (
    <div className='search-bread-crumb'>
      <Link className='home' to={'/'}>{webBuilder.home}</Link>
      <span> <ChevronDown /></span>
      <span className='search'>{webBuilder.searchResults}</span>
    </div>
  )
}
