import React, { useState } from "react";
import clear from "assets/images/filterReset.svg";

import { Collapse } from "reactstrap";
import TextField from "components/Form/TextField/TextField";
import Locale from "translations";

function TransferFilters({ onChangeFilter, filters, clearFitlter }) {
  const { marketPlace, visa } = Locale;
  const [collapseState, setCollapseState] = useState({
    search: true,
    priceRange: true,
    type: true,
  });

  const handelCollapseState = (name) => {
    setCollapseState((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  return (
    <div className="col-md-3">
      <div className="d-flex justify-content-between align-items-center mt-2">
        <div className="filter-head terms-color text-uppercase ">
          <h6 className="font-weight-bold h6 hotel-found">
            {marketPlace.filterBy}:
          </h6>
        </div>
        <button
          className="btn bg-white clear-filter px-3 mx-2"
          onClick={clearFitlter}
        >
          <img src={clear} alt="" className="mx-2" />
          {visa.clear}
        </button>
      </div>

      {/* <div className="bg-white p-3 rounded mt-2">
				<h6
					className="font-weight-bold  terms-color"
					onClick={() => handelCollapseState("search")}
				>
					{marketPlace.messages.search}
					<span className="float-right">
						<i className="fa fa-angle-down"></i>
					</span>
				</h6>
				<Collapse isOpen={collapseState["search"]}>
					<div className="col-md-12 mt-3">
						<TextField
							type="text"
							haslabel={false}
							placeholder={marketPlace.messages.search}
							// value={hotelFilters?.name ? hotelFilters?.name : ""}
							onChange={(e) => {
								onChangeFilter({})
								// const search_filters = {
								// 	search_filters: { ...hotelFilters, name: e.target.value },
								// };
								// searchFilters(search_filters);
								// setHotelFilters(search_filters.search_filters);
								// setAllFilters(search_filters);
							}}
						/>
					</div>
				</Collapse>
			</div> */}

      <div className="bg-white p-3 rounded mt-2 stops flight-times">
        <h6
          className="font-weight-bold  terms-color"
          onClick={() => handelCollapseState("priceRange")}
        >
          {marketPlace.messages.priceRange}
          <span className="float-right">
            <i className="fa fa-angle-down"></i>
          </span>
        </h6>
        <Collapse isOpen={collapseState["priceRange"]}>
          <div className="d-flex  align-items-center mt-3 filter-price-range times-item">
            <div>
              <TextField
                haslabel={false}
                type="number"
                min={1}
                size="small"
                sx={{ width: "80px" }}
                value={filters?.min_price || ""}
                placeholder={marketPlace.min}
                onChange={(e) => {
                  onChangeFilter({
                    min_price: +e.target.value,
                  });
                }}
                autoComplete="off"
                extraTextPosition={"append"}
                // extraText={filters?.currency_code?.label}
              />
            </div>
            <span className="mx-2 text-uppercase">{marketPlace.to}</span>
            <div>
              <TextField
                haslabel={false}
                type="number"
                size="small"
                min={1}
                sx={{ width: "80px" }}
                value={filters?.max_price || ""}
                placeholder={marketPlace.max}
                onChange={(e) => {
                  onChangeFilter({
                    max_price: +e.target.value,
                  });
                }}
                autoComplete="off"
                extraTextPosition={"append"}
                // extraText={filters?.currency_code?.label}
              />
            </div>
          </div>
        </Collapse>
      </div>

      <div className="bg-white p-3 rounded mt-2 stops airline">
        <h6
          className="font-weight-bold terms-color mb-3"
          onClick={() => handelCollapseState("type")}
        >
          {marketPlace.messages.type}
          <span className="float-right">
            <i className="fa fa-angle-down"></i>
          </span>
        </h6>
        <Collapse isOpen={collapseState["type"]}>
          <div className="items">
            {["SHARED", "PRIVATE"].map((i) => (
              <div className="airlines-checkbox" key={i}>
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input permChecks"
                    id={i}
                    value={i}
                    onChange={(e) =>
                      onChangeFilter({ type: e.target.checked ? i : "" })
                    }
                    checked={filters.type === i}
                  />
                  <label
                    htmlFor={i}
                    className="mx-2 custom-control-label text-capitalize"
                  >
                    {i.toLowerCase()}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
}

export default TransferFilters;
