const emailRegex =
  /(?=^.{10,90}$)^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
const nameRegex = /^[a-zA-Z\u0621-\u064A\u0660-\u0669]*$/;

export const isFormValid = (errors) => {
  let errorsList = [];
  if (errors !== undefined && Object.values(errors).length > 0) {
    Object.values(errors).map((value, i) => {
      if (!Array.isArray(value)) {
        errorsList = [...errorsList, ...Object.values(value)];
      }
    });
    return errorsList.every((value) => !value);
  } else {
    return false;
  }
};

export default function validate(target, rules) {
  const { name, value } = target;
  const errors = { [name]: {} };

  if (rules.serverError) {
    if (
      value === "" ||
      value === undefined ||
      value == null ||
      value === false
    ) {
      errors[name]["serverError"] = rules.messages;
    } else {
      errors[name]["serverError"] = false;
    }
  }

  if (rules.required) {
    if (
      value === "" ||
      value === undefined ||
      value == null ||
      value === false
    ) {
      errors[name]["required"] = true;
    } else {
      errors[name]["required"] = false;
    }
  }

  if (rules.min) {
    if (value !== undefined && value.length >= rules.min) {
      errors[name]["min"] = false;
    } else {
      errors[name]["min"] = true;
    }
  }

  if (rules.max) {
    if (value !== undefined && value.length <= rules.max) {
      errors[name]["max"] = false;
    } else {
      errors[name]["max"] = true;
    }
  }

  if (rules.maxNumber) {
    if (value !== undefined && +value <= rules.maxNumber) {
      errors[name]["maxNumber"] = false;
    } else {
      errors[name]["maxNumber"] = true;
    }
  }

  if (rules.minNumber) {
    if (value !== undefined && +value >= rules.minNumber) {
      errors[name]["minNumber"] = false;
    } else {
      errors[name]["minNumber"] = true;
    }
  }

  if (rules.email) {
    const emailRegex =
      /(?=^.{10,90}$)^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
    if (emailRegex.test(value)) {
      errors[name]["email"] = false;
    } else {
      errors[name]["email"] = true;
    }
  }

  if (rules.number) {
    const numberRegex = /^((?!501|504)[0-9]*)$/;

    if (numberRegex.test(value)) {
      errors[name]["number"] = false;
    } else {
      errors[name]["number"] = true;
    }
  }
  if (rules.password) {
    const passwordRegex = /^(?=.*?[a-zA-Z])(?=.*?[0-9]).{8,}$/g;

    if (passwordRegex.test(value)) {
      errors[name]["password"] = false;
    } else {
      errors[name]["password"] = true;
    }
  }

  if (rules.confirm) {
    if (value) {
      errors[name]["confirm"] = false;
    } else {
      errors[name]["confirm"] = true;
    }
  }

  if (rules.phone) {
    if (value) {
      errors[name]["phone"] = false;
    } else {
      errors[name]["phone"] = true;
    }
  }

  if (rules.date) {
    if (value) {
      errors[name]["date"] = false;
    } else {
      errors[name]["date"] = true;
    }
  }

  return errors;
}

export function validateEmail(email) {
  if (!email) return true;
  return emailRegex.test(String(email));
}

export function validateName(name) {
  if (!name) return true;
  return nameRegex.test(String(name));
}
