import React from "react";
import { ReactComponent as GoldenStarIcon } from "assets/images/webBuilder/golden-star.svg";
import { ReactComponent as ResetIcon } from "assets/images/webBuilder/rotate-right.svg";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
// import RangeSlider from "components/Form/RangeSlider";
import CollapseSidebar from "components/CollapseSidebar";
import Locale from "translations";

export default function SearchFilter({
  filter,
  setFilter,
  length,
  initalPrices,
  resetFilters,
  productType,
  toggleOpenFilter,
  filterByPrice,
}) {
  const { webBuilder, marketPlace } = Locale;

  function filterByCheckbox(e, obj, filterName) {
    const checked = e.target.checked;
    let checkedArray = filter?.[filterName];
    if (checked) {
      checkedArray = [...filter[filterName], e.target.value];
    } else {
      checkedArray = checkedArray.filter((c) => c !== e.target.value);
    }
    setFilter({ ...filter, [filterName]: checkedArray });
  }
  let newArrayStart = Array.from({ length: 4 }, (value, index) => 5 - index);

  return (
    <div className="search-filter">
      <button className="btn close-filter-btn p-0" onClick={toggleOpenFilter}>
        <CloseIcon />
      </button>
      <div className="reset">
        <p>
          {" "}
          {webBuilder.resultFound} ({length})
        </p>
        <button
          className="btn bg-white clear-filter px-3"
          onClick={resetFilters}
        >
          <ResetIcon />
          {webBuilder.reset}
        </button>
      </div>
      {/* <div className="package-type">
				<CollapseSidebar title="package Type">
					{product_types?.map((item) => (
						<div>
							<input
								className="me-2"
								id={item?.id}
								type="checkbox"
								value={item?.name}
								onChange={(e) => {
									filterByCheckbox(e, item, "packageType");
								}}
								checked={filter?.packageType.includes(item?.name)}
							/>
							<label data htmlFor={item?.id}>
								{item.name}
							</label>
						</div>
					))}
				</CollapseSidebar>
			</div> */}
      <div className="price-range">
        <CollapseSidebar title={marketPlace.messages.priceRange}>
          <div className="d-flex align-items-center justify-content-between gap-24 flex-wrap">
            {/* min */}
            <div>
              <label htmlFor="min-price-input">{marketPlace.min}</label>
              <input
                type="number"
                id="min-price-input"
                name="min-price-input"
                className="min-price-input price-input"
                value={
                  filter?.fromPriceRange
                    ? filter?.fromPriceRange
                    : initalPrices.min
                }
                // defaultValue={Math.floor(initalPrices.min)}
                onChange={(e) => {
                  if (+e.target.value >= 0) {
                    setFilter({
                      ...filter,
                      fromPriceRange:
                        +e.target.value >= initalPrices.max
                          ? Number(initalPrices.max).toString()
                          : Number(e.target.value).toString(),
                    });
                  }
                }}
              />
            </div>
            {/* max */}
            <div>
              <label htmlFor="max-price-input">{marketPlace.max}</label>
              <input
                type="number"
                id="max-price-input"
                name="max-price-input"
                className="max-price-input price-input"
                value={
                  filter?.toPriceRange ? filter?.toPriceRange : initalPrices.max
                }
                // defaultValue={Math.floor(initalPrices.max)}
                onChange={(e) => {
                  if (+e.target.value >= 0) {
                    setFilter({
                      ...filter,
                      toPriceRange:
                        +e.target.value >= initalPrices.max
                          ? Number(initalPrices.max).toString()
                          : Number(e.target.value).toString(),
                    });
                  }
                }}
              />
            </div>
          </div>

          <button className="btn apply-price-filter" onClick={filterByPrice}>
            {webBuilder.apply}
          </button>
          {/* <RangeSlider
            min={Math.floor(initalPrices.min)}
            max={Math.floor(initalPrices.max)}
            minValue={
              filter?.fromPriceRange
                ? Math.floor(filter?.fromPriceRange)
                : Math.floor(initalPrices.min)
            }
            maxValue={
              filter?.toPriceRange
                ? Math.floor(filter?.toPriceRange)
                : Math.floor(initalPrices.max)
            }
            step="10"
            ruler={false}
            onChange={(e) => {
              if (
                filter?.fromPriceRange !== e.minValue ||
                filter?.toPriceRange !== e.maxValue
              ) {
                setFilter({
                  ...filter,
                  fromPriceRange: e.minValue,
                  toPriceRange: e.maxValue,
                });
              }
            }}
          /> */}
        </CollapseSidebar>
      </div>
      {!productType.includes("tour") && !productType.includes("flight") ? (
        <div className="star-rating">
          <CollapseSidebar title={marketPlace.messages.starRate}>
            {newArrayStart?.map((item, ind) => (
              <div>
                <input
                  className="me-2"
                  id={"star" + item}
                  type="checkbox"
                  value={item}
                  onChange={(e) => {
                    filterByCheckbox(e, item, "starRating");
                  }}
                  checked={filter?.starRating.includes(item.toString())}
                />
                <label data htmlFor={"star" + item}>
                  {[...Array(item)].map((i, index) =>
                    index < item ? <GoldenStarIcon /> : null
                  )}
                </label>
              </div>
            ))}
          </CollapseSidebar>
        </div>
      ) : null}
    </div>
  );
}
