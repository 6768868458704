import React from "react";
import { Modal } from "reactstrap";
import Locale from "translations";

export default function FreeRulesModel({ isOpen, toggle }) {
  const {webBuilder} = Locale;
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={"lg"} className="free-rules-builder-modal" centered>
      <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100 modal-header">
        <h3> {webBuilder.fareRules}</h3>
        <a href="##" onClick={toggle} className="btn-x">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <p className="text">
        {webBuilder.fareRulesCuation}
      </p>
    </Modal>
  );
}
