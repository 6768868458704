import { ReactComponent as RouteIcon } from "assets/images/webBuilder/routing-2.svg";
import React, { useState } from 'react'

export default function PackageNavTabs({ productItemsTabs }) {
  const [activeTab, setActiveTab] = useState("");

  function scrollToElement(eleId) {
    if (document && eleId) {
      // let navHeight = document.querySelector(".packages-navigation").getBoundingClientRect().height;
      // let elePos = document?.getElementById(eleId).getBoundingClientRect().top;
      // let offsetPos = elePos + window.scrollY - (navHeight );
      // window.scrollTo({ behavior: "smooth", top: 0 })
      setActiveTab(eleId)
      document?.getElementById(eleId)?.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }

  // useEffect(() => {
  //   const targetSections = document.querySelectorAll("div[id*='tab-']");

  //   const observer = new IntersectionObserver((entries) => {
  //     entries.forEach((entry) => {
  //       if (entry.isIntersecting) {
  //         setActiveTab(entry.target.getAttribute("id").split("-")[1]);
  //       }
  //     });
  //   },
  //     // {threshold: 0.65 }
  //   );

  //   targetSections.forEach((section) => {
  //     observer.observe(section);
  //   });
  //   return () => observer.disconnect(); // Clenaup the observer if component unmount.
  // }, [productItemsTabs]);

  return (
    <ul className="packages-navigation">
      {productItemsTabs?.map((tab) => {

        return (
          <li className={`item ${String(activeTab) === `tab-${tab.id}` ? "active" : ""} `}
            key={`tab-${tab.id}`}
            onClick={() => {
              // setActiveTab(tab.id);
              scrollToElement(`tab-${tab.id}`);
            }}
          >
            <RouteIcon />
            {tab.name}
          </li>
        );
      })}
    </ul>
  )
}


