import TourCardThemeTen from './TourCard';


export default function ToursTheme10({ items }) {


  return (
    <div className="tours-cards-container">
      {items.map((props, index) => (
        <TourCardThemeTen key={`${props.product_uuid}-${index}`} {...props} cardType="tours" />
      ))}
    </div>
  )
}