import PackageCard from "modules/WebBuilder-V2/Components/Content/OurPackages/PackageCard";
import Carousel from "react-multi-carousel";
import PackageCardwithImage from "modules/WebBuilder-V2/Components/Content/OurPackages/PackageCardwithImage";
import { useSBSState } from "context/global";
import ButtonGroup from "modules/WebBuilder-V2/shared/CarouselButtonGroup";

export default function CustomPagePackage({ details, title }) {
  const { id, items, hasSlider } = details;
  const { locale } = useSBSState();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 567 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 567, min: 0 },
      items: 1,
    },
  };

  const showArrows =
    items?.filter((item) => item?.product_uuid).length >
    responsive.desktop.items;

  return (
    <>
      <h3 className="section-title mb-4 font-weight-bold">{title}</h3>
      {hasSlider ? (
        <div className="web-builder-content-our-packages-with-slider p-0">
          {items?.length > 0 && (
            <div className="all-web-builder-our-service-carousel">
              <Carousel
                responsive={responsive}
                itemClass={"service-slide"}
                className="web-builder-our-service-carousel"
                slidesToSlide={1}
                keyBoardControl={true}
                customButtonGroup={showArrows ? <ButtonGroup /> : null}
                renderButtonGroupOutside={true}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                infinite={true}
                arrows={false}
                rtl={locale === "ar"}
              >
                {items?.map((props) => (
                  <PackageCard {...props} key={props.id} />
                ))}
              </Carousel>
            </div>
          )}
        </div>
      ) : (
        <div id={id} className="web-builder-content-our-packages-with-image">
          {items.length > 0 && (
            <div className="all-web-builder-our-service-images">
              {items.map((props, index) => (
                <div className="our-service-item">
                  <PackageCardwithImage {...props} key={props.id} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
}
