import Carousel from 'react-multi-carousel';
import ThemeSevenHotelCard from './HotelCard';
import HotelBGSrc from "assets/images/webBuilder/theme-7-hotel-bg.png"
import ButtonGroup from 'modules/WebBuilder-V2/shared/CarouselButtonGroup';
import { useSBSState } from 'context/global';


export default function HotelTheme7({
  items,
  openEditModal,
  title,
  body,
  id
}) {

  const { locale } = useSBSState();


  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1200, min: 700 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 700, min: 0 },
      items: 1.2,
    },
  };

  const showArrows = items.length > responsive.desktop.items;

  return (
    <section className='theme_hotels_section section_padding__lg' id={id}>
      <div className="abstract" style={{ backgroundImage: `url(${HotelBGSrc})` }}></div>
      <div className="section_content custom-container">
        <div className="heading px-2">
          <div className="wrapper">
            <h4 className='title'>{title}</h4>
            <p className="heading_desc">
              {body}
            </p>
          </div>
        </div>

        <div className="hotels_list_container mt-4">
          <Carousel
            responsive={responsive}
            slidesToSlide={1}
            keyBoardControl={true}
            removeArrowOnDeviceType={["tablet", "mobile"]}
            customButtonGroup={showArrows ? <ButtonGroup arrowsBesideDots={true} /> : null}
            renderButtonGroupOutside={false}
            showDots={showArrows}
            arrows={false}
            infinite={false}
            rtl={locale === "ar"}
          >
            {items.map((props, index) => (
              <ThemeSevenHotelCard {...props} itemType="hotel" openEditModal={openEditModal} />
            ))}
          </Carousel>
        </div>


      </div>
    </section>
  )
}