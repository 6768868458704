import FlightServiceCardTheme4 from '../Theme4Card';



export default function ThemeSevenFlights({
  items,
  title,
  body,
  image,
}) {



  return (
    // <div className="theme_flight_section section_padding__lg" id={id}>\
    <>
      <div className="content_col">
        <div className="heading mb-4">
          <h4 className="title">{title}</h4>
          <p className="heading_text">
            {body}
          </p>
        </div>

        <div className="content">
          {items.map((item, index) => (
            <FlightServiceCardTheme4 {...item} itemType="flight" />
          ))}
        </div>
      </div>


      <div className="bg_col">
        <img src={image} alt="Logo" />
      </div>
    </>
  )
}