import Locale from 'translations';
import { FlightIcon, HotelIcon, PackageIcon, ToursIcon, TransportIcon, VisaIcon } from '../icons';


export default function ServiceCardThemeTen({
	type,
	// header,
	body,
}) {
	const { webBuilder } = Locale;

	let icon;
	switch (type) {
		case "hotels":
			icon = <HotelIcon />;
			break;
		case "visa":
			icon = <VisaIcon />;
			break;
		case "transport":
			icon = <TransportIcon />;
			break;
		case "transfer":
			icon = <TransportIcon />;
			break;
		case "tours":
			icon = <ToursIcon />;
			break;
		case "packages":
			icon = <PackageIcon />;
			break;
		case "flights":
			icon = <FlightIcon />;
			break;
		default:
			icon = "";
	}

	return (
		<div className="service-card">
			<span className="icon">
				{icon}
			</span>
			<h3 className="title">{webBuilder[type]}</h3>
			{/* <h4>{header}</h4> */}
			<p>{body}</p>
		</div>
	);
}
