import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Locale from 'translations';

export default function CancellationModel({isOpen,toggle,selectedData,selectedHotel}) {
    const {inventory } = Locale;

  return (
    <Modal
    size="lg"
    isOpen={isOpen}
    toggle={toggle}
    className="mt-8 T-cancellation-modal"
>
    <ModalHeader
        className="align-items-center"
        toggle={toggle}
    >
        {inventory.messages.CancellationAndAmendmentsPolicies}
    </ModalHeader>
    <ModalBody className="mx-3">
        <div className="col-md-11 m-auto">
            <div className="row">
                {selectedData?.room_rate?.cancellation_policy.map((res) => {
                    return (
                        <>
                            {!res.hasOwnProperty("fromDate") ? (
                                <>
                                    <p className="w-100 mt-2">
                                        {inventory.messages.before} {res.toDate}
                                    </p>
                                    <div className="col">
                                        {res.cancelCharge == 0 ? (
                                            <div className="boxCancellation bg-Ground">
                                                <p>{inventory.messages.noCancellationCharge}</p>
                                            </div>
                                        ) : res.cancelRestricted ? (
                                            <div className="boxCancellation bg-dangerr">
                                                <p>
                                                    {" "}
                                                    {inventory.messages.cancellationRestricted}
                                                </p>
                                            </div>
                                        ) : (
                                            <div className="boxCancellation">
                                                {inventory.messages.cancellationCharge}
                                                <p>
                                                    {" "}
                                                    {res.cancelCharge}{" "}
                                                    {selectedData?.room_rate?.room_price_currency}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <>
                                        {res.amendCharge == 0 ? (
                                            <div className="col ">
                                                <div className="boxCancellation bg-Ground">
                                                    <p>{inventory.messages.noAmendCharge}</p>
                                                </div>
                                            </div>
                                        ) : res.amendRestricted ? (
                                            <div className="col ">
                                                <div className="boxCancellation bg-dangerr">
                                                    {inventory.messages.amendRestricted}
                                                </div>
                                            </div>
                                        ) : selectedHotel.gds != 5 &&
                                          selectedHotel.gds != 6 ? (
                                            <div className="col ">
                                                <div className="boxCancellation">
                                                    <p>{inventory.messages.amendmentCharge}</p>
                                                    {res.amendCharge}
                                                    {selectedData?.room_rate?.room_price_currency}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                </>
                            ) : !res.hasOwnProperty("toDate") &&
                              !res.hasOwnProperty("noShowPolicy") ? (
                                <>
                                    <p className="w-100 mt-3">
                                        {inventory.messages.after} {res.fromDate}
                                    </p>
                                    <div className="col">
                                        {res.cancelCharge == 0 ? (
                                            <div className="boxCancellation bg-Ground">
                                                <p>{inventory.messages.noCancellationCharge}</p>
                                            </div>
                                        ) : res.cancelRestricted ? (
                                            <div className="boxCancellation bg-dangerr">
                                                <p>{inventory.messages.cancellationRestricted}</p>
                                            </div>
                                        ) : (
                                            <div className="boxCancellation">
                                                {inventory.messages.cancellationCharge}
                                                <p>
                                                    {" "}
                                                    {res.cancelCharge}{" "}
                                                    {selectedData?.room_rate?.room_price_currency}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    <>
                                        {res.amendCharge == 0 ? (
                                            <div className="col ">
                                                <div className="boxCancellation bg-Ground">
                                                    <p>{inventory.messages.noAmendCharge}</p>
                                                </div>
                                            </div>
                                        ) : res.amendRestricted ? (
                                            <div className="col ">
                                                <div className="boxCancellation bg-dangerr">
                                                    <p>{inventory.messages.amendRestricted}</p>
                                                </div>
                                            </div>
                                        ) : selectedHotel.gds != 5 &&
                                          selectedHotel.gds != 6 && selectedHotel.gds != 9 ? (
                                            <div className="col ">
                                                <div className="boxCancellation">
                                                    <p>{inventory.messages.amendmentCharge}</p>
                                                    {res.amendCharge}{" "}
                                                    {selectedData?.room_rate?.room_price_currency}
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                </>
                            ) : res.hasOwnProperty("noShowPolicy") ? (
                                <>
                                    <p className="w-100 mt-3">
                                        {inventory.messages.after} {res.fromDate}
                                    </p>

                                    <div className="col-md-12">
                                        <div className="boxCancellation bg-dangerNew ">
                                            <p>{inventory.messages.noShowPolicyCharge}</p>
                                            {res.charge}{" "}
                                            {selectedData?.room_rate?.room_price_currency}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="w-100 mt-3">
                                        {" "}
                                        between {res.fromDate} and {res.toDate}
                                    </p>
                                    <>
                                        {res.cancelCharge == 0 ? (
                                            <div className="col">
                                                <div className="boxCancellation bg-Ground">
                                                    <p> {inventory.messages.noAmendCharge}</p>
                                                </div>
                                            </div>
                                        ) : res.cancelRestricted ? (
                                            <div className="col">
                                                <div className="boxCancellation bg-dangerr">
                                                    <p>
                                                        {inventory.messages.cancellationRestricted}
                                                    </p>{" "}
                                                </div>
                                            </div>
                                        ) : selectedHotel.gds != 6 ? (
                                            <div className="col">
                                                <div className="boxCancellation">
                                                    <p>{inventory.messages.cancellationCharge}</p>
                                                    <p>
                                                        {" "}
                                                        {res.cancelCharge}{" "}
                                                        {selectedData?.room_rate?.room_price_currency}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                    <>
                                        {res.amendCharge == 0 ? (
                                            <div className="col ">
                                                <div className="boxCancellation bg-Ground">
                                                    <p>{inventory.messages.noAmendCharge}</p>
                                                </div>
                                            </div>
                                        ) : res.amendRestricted ? (
                                            <div className="col">
                                                <div className="boxCancellation bg-dangerr">
                                                    <p>{inventory.messages.amendRestricted}</p>
                                                </div>
                                            </div>
                                        ) : selectedHotel.gds != 5 &&
                                          selectedHotel.gds != 6 ? (
                                            <div className="col">
                                                <div className="boxCancellation">
                                                    <p>{inventory.messages.amendmentCharge}</p>
                                                    <p>
                                                        {" "}
                                                        {res.amendCharge}{" "}
                                                        {selectedData?.room_rate?.room_price_currency}
                                                    </p>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </>
                                </>
                            )}
                        </>
                    );
                })}
            </div>
        </div>
    </ModalBody>
    <ModalFooter>
        <button
            className="bg-gray-150 text-dark border-0 px-4"
            onClick={toggle}
        >
            {inventory.messages.cancel}
        </button>
    </ModalFooter>
</Modal>
  )
}
