import { Route, Switch } from "react-router-dom";
import WebBuilderRoute from "components/Routes/WebBuilderRoute";
import ErrorPreLogin from "components/Errors/Error404/ErrorPreLogin";
import WebBuilder from "modules/WebBuilder";
import PackageSearch from "modules/WebBuilder-V2/PackageSearch";
import WebBuilderV2 from "modules/WebBuilder-V2";
import PreviewServicesV2 from "modules/WebBuilder-V2/Pages/PreviewServices";
import MarketPlaceRoute from "components/Routes/MarketPlaceRoute";

import PrivateRoute from "components/Routes/PrivateRoute";
import TransferListBulider from "modules/market-place/ResponeServices/Transfer/TransferList";
import PaymentHyperpayBuild from "modules/market-place/ResponeServices/payment/PaymentHyperpay";
import TransferReservationsBuilder from "modules/market-place/ResponeServices/Transfer/Reservation";
import FlightResulltOutbound from "modules/market-place/ResponeServices/Flight/FlightResult";
import FlightResulltInbound from "modules/market-place/ResponeServices/Flight/FlightResult/Inbound";
import FlightSummary from "modules/market-place/ResponeServices/Flight/FlightSummary";
import HotelResult from "modules/market-place/ResponeServices/Hotel/HotelResult";
import HotelDetails from "modules/market-place/ResponeServices/Hotel/HotelDetails";
import HotelSummary from "modules/market-place/ResponeServices/Hotel/HotelSummary";
import ToursList from "modules/market-place/ResponeServices/Tour/ToursList";
import TourDetails from "modules/market-place/ResponeServices/Tour/Details";
import ToursSummary from "modules/market-place/ResponeServices/Tour/Summary";
import PaymentAction from "modules/market-place/ResponeServices/payment/paymentAction";
import SuccessfullyPayment from "components/successfullyPayment";
// import HotelServiceView from "modules/WebBuilder-V2/Pages/PreviewService/Hotel";
// import TourServiceView from "modules/WebBuilder-V2/Pages/PreviewService/Tour";
// import TransferServiceView from "modules/WebBuilder-V2/Pages/PreviewService/Transfer";
// import OthersServiceView from "modules/WebBuilder-V2/Pages/PreviewService/Others";

// Lazy Components

// Application Routes
const preLoginRoutes = (
  <Switch>
    {/* version 1 */}
    <MarketPlaceRoute exact path="/v1" component={WebBuilder} />
    <WebBuilderRoute
      path="/web/:service/:id"
      component={PreviewServicesV2}
    />
    {/* <WebBuilderRoute exact path="/" component={WebBuilderV2} /> */}
    <MarketPlaceRoute exact path="/" component={WebBuilderV2} />
    <WebBuilderRoute path="/packages-results" component={PackageSearch} />
  

  

    {/* version 2 */}
    <WebBuilderRoute
      exact
      path="/public-page-v2/:name"
      component={WebBuilderV2}
    />


    <MarketPlaceRoute path='/flight/outbound-view' component={FlightResulltOutbound} />
		<MarketPlaceRoute path='/flight/inbound-view' component={FlightResulltInbound} />
    
    
    <MarketPlaceRoute path='/hotel/hotel-view' component={HotelResult} />
    <MarketPlaceRoute path='/hotel/hotel-detail/:id' component={HotelDetails} />

    <MarketPlaceRoute path='/hotel/hotel-summary' component={HotelSummary} />




    
    <MarketPlaceRoute
      path='/flight/flight-summary'
      exact
      component={FlightSummary}
    />
    {/* Start Transfer */}
    <MarketPlaceRoute
       path='/:status/transfer' exact component={TransferListBulider}
    />
   <MarketPlaceRoute
			path='/transfer/reservations'
			component={TransferReservationsBuilder}
			exact
		/>
    {/* End Transfer */}

    {/* tours routes */}
    <MarketPlaceRoute path='/tours/result' exact component={ToursList} />
    <MarketPlaceRoute path='/tour/details/:id' component={TourDetails} />
    <MarketPlaceRoute path='/tour/summary' exact component={ToursSummary} />

    {/* heperpay */}
    <PrivateRoute exact path='/hyperpay/pay' component={PaymentHyperpayBuild} />
    <Route path="/PayOnline/:tap" component={PaymentAction} />
    <Route path="/PayOnline" component={PaymentAction} />
    <Route
      path='/successfullyPayment/:type'
      component={SuccessfullyPayment}
    />

    {/* service */}
    {/* <WebBuilderRoute path="/hotel-service/:id" exact component={HotelServiceView} />
    <WebBuilderRoute path="/tour-service/:id" exact component={TourServiceView} />
    <WebBuilderRoute path="/transfer-service/:id" exact component={TransferServiceView} />
    <WebBuilderRoute path="/others-service/:id" exact component={OthersServiceView} /> */}

    {/* pacakge builder */}
    <WebBuilderRoute path="/:service/:id"  component={PreviewServicesV2} />
    
    <WebBuilderRoute path="/:pageName" exact component={WebBuilderV2} />
    {/* ****************** End Auth Route ******************** */}

    {/* UI Static Routes */}

    <Route path="*" component={ErrorPreLogin} />
  </Switch>
);

export default preLoginRoutes;
