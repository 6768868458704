import CustomCarousel from "modules/WebBuilder/shared/CustomCarousel";
import ServicesContent from "modules/WebBuilder/shared/ServicesContent";
import PackageCard from "./PackageCard";

function OurPackages({ details }) {
  const { title, body, items } = details;

  return (
    <div className="web-builder-content-our-packages container">
      <ServicesContent
        header={title}
        body={body}
      />

      {items.length > 0 && (
        <CustomCarousel desktopItemNumber={4}>
          {items.map((props, index) => (
            <PackageCard {...props} key={props.id || index} />
          ))}
        </CustomCarousel>
      )}
    </div>
  );
}

export default OurPackages;
