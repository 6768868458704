import React from "react";
import Locale from "translations";

function ServiceCard({ icon, title, body, type, editCardDetails }) {
  const { webBuilder } = Locale;
  const isTransfer = type === "transfer"

  const logo = icon ? <img src={icon} alt="Logo" /> : null;

  return (
    <div className={`web-builder-content-our-service_card`} >
      {isTransfer ? <span className="soon">soon</span> : null}
      <div className={`${isTransfer ? "opacity-50 user-select-none pe-defualt" : ""}`}>
        <div className="our-service-icon">
          {logo}
        </div>
        <div>
          <p className="service-header h5 mb-1">
            {title}
          </p>
          <p className="service-body text-secondary-color">
            {webBuilder[type] || type?.toLocaleUpperCase()}
          </p>
          <p className="my-3 text-secondary-color">
            {body}
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServiceCard;
