import React from "react";
import SearchForm from "./SearchForm";
import { useSBSState } from "context/global";

export default function HotelSearch() {

  const {allCountries} = useSBSState();


  return (
      <SearchForm 
        allCountries={allCountries}
      />
  );
}
