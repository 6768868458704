import React, { useEffect, useState } from "react";
import { Collapse } from "reactstrap";
import { ArrowBottom, ArrowUp, RotateArrow } from "../../Flight/Icons";
import { useMarketplaceState } from "context/marketplace";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";

export default function Filter({
  searchFilters,
  setAllFilters,
  maxPrice,
  minPrice,
  setPrice,
  price,
  lookups,
  toggleOpenFilter
}) {
  const [FiltersIsOpen, setFiltersIsOpen] = useState({
    search: true,
    price_range: true,
    property_type: true,
    star_rating: true,
    meal_options: true,
    cancellation_policy: true,
    hotel_facilities: true,
  });
  const { filters } = useMarketplaceState();
  const { marketPlace, webBuilder } = Locale;
  const [hotelFilters, setHotelFilters] = useState(null);
  
  const [accommodationTerm, setAccommodationTerm] = useState("");
  const [filterdAccommodations, setFilterdAccommodations] = useState();
  const [borderBaseTerm, setBorderBaseTerm] = useState("");
  const [filterdBorderBases, setFilterdBorderBases] = useState();

  // const [isOpenPointsOfInterset, setIsOpenPointsOfInterset] = useState(true);

  const [selectedRating, setSelectedRating] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [selectedMealsOptions, setSelectedMealsOptions] = useState([]);
  const [selectedPropertyTypes, setSelectedPropretyTypes] = useState([]);
  const [isRefundable, setIsRefundable] = useState(null);

  // filter by price range
  const filterByPrice = (type, value) => {
    if (price.min < 0) price.min = 0;
    if (price.max > maxPrice) price.max = +maxPrice;
    const search_filters = {
      search_filters: { ...hotelFilters, price },
    };
    searchFilters(search_filters);
    setAllFilters(search_filters);
    setHotelFilters({ ...hotelFilters, price });
  };
  // filter by any checkbox (rating, meals , facilties, proprety_type)
  function filterByCheckbox(e, value, state, stateSetter) {
    const checked = e.target.checked;
    const filterName = e.target.name;
    let data;
    if (checked) {
      stateSetter([...state, value]);
      data = {
        [filterName]: [...state, value],
      };
    } else {
      stateSetter(state?.filter((item) => item !== value));
      data = {
        [filterName]: state?.filter((item) => item !== value),
      };
    }
    const search_filters = { search_filters: { ...hotelFilters, ...data } };
    searchFilters(search_filters);
    setAllFilters(search_filters);
    setHotelFilters({ ...hotelFilters, [filterName]: data?.[filterName] });
  }

  function filterByRefundPolicy(e) {
    const checked = e.target.checked;
    let data;
    if (checked) {
      setIsRefundable(1);
      data = { isRefundable: 1 };
    } else {
      setIsRefundable(null);
      data = { isRefundable: null };
    }
    const search_filters = { search_filters: { ...hotelFilters, ...data } };
    searchFilters(search_filters);
    setAllFilters(search_filters);
    setHotelFilters({ ...hotelFilters, isRefundable: data.isRefundable });
  }

  // search in lookups names (accommodations, border bases)
  function searchByTextInLookups(
    e,
    originalArray,
    textStateSetter,
    filterdArraySetter
  ) {
    const value = e.target.value;
    if (e.target.value !== "") {
      const filter = originalArray?.filter((item) => {
        return item?.name.toLowerCase().includes(value.toLowerCase()) && item;
      });
      filterdArraySetter(filter);
    } else {
      filterdArraySetter(originalArray);
    }
    textStateSetter(value);
  }

  const reset = () => {
    setHotelFilters(null);
    setPrice({ min: minPrice, max: maxPrice });
    setAllFilters({});
    setSelectedFacilities([]);
    setSelectedRating([]);
    setSelectedMealsOptions([]);
    setSelectedPropretyTypes([]);
    setIsRefundable(null);
    searchFilters({});
    setAccommodationTerm("");
    setFilterdAccommodations(lookups?.accommodations);
    setBorderBaseTerm("");
    setFilterdBorderBases(lookups?.borderBases);
  };

  // set price range when min or max price changes
  useEffect(() => {
    setPrice({ min: minPrice, max: maxPrice });
  }, [minPrice, maxPrice]);

  useEffect(() => {
    if (!price && minPrice && maxPrice) {
      setPrice({ min: minPrice, max: maxPrice });
    }
  }, [price, minPrice, maxPrice]);

  useEffect(() => {
    setFilterdAccommodations(lookups?.accommodations);
    setFilterdBorderBases(lookups?.borderBases);
  }, [lookups?.accommodations, lookups?.borderBases]);

  return (
    <div className="flight-filter">
      <button className="btn close-filter-btn p-0" onClick={toggleOpenFilter}>
        <CloseIcon />
      </button>
      <div className="title">
        <p>{webBuilder.filterYourResults}</p>
        <button className="reset" 	onClick={() => reset()}>
          <RotateArrow /> {webBuilder.reset}
        </button>
      </div>
      {/* price range */}
      <div className="stops flight-times">
        <div className="title">
          <h6>{webBuilder.priceRange}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                price_range: !prev.price_range,
              }))
            }
          >
            {FiltersIsOpen.price_range ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.price_range}>
          <div className="times-item">
            <div className="trips mb-1">
              <p>From</p>
              <p>To</p>
            </div>

            <div className="d-flex align-items-center">
              {/* price range from */}
              <div>
                <TextField
                  hasLabel={false}
                  type="number"
                  min={1}
                  size="small"
                  sx={{ width: "80px" }}
                  value={
                    price?.min?.toString()[0] === "0"
                      ? price?.min?.toString().slice(1)
                      : price?.min
                  }
                  onChange={(e) => {
                    setPrice({
                      ...price,
                      min:
                        e.target.value.charAt(0) === "0"
                          ? +e.target.value.slice(1)
                          : +e.target.value,
                    });
                    filterByPrice(
                      "min",
                      e.target.value.charAt(0) === "0"
                        ? +e.target.value.slice(1)
                        : +e.target.value
                    );
                  }}
                  autoComplete="off"
                  extraTextPosition={"append"}
                  extraText={filters?.currency_code?.label}
                />
              </div>
              <span className="mx-2 text-uppercase mt-4">To</span>
              <div>
                <TextField
                  hasLabel={false}
                  type="number"
                  size="small"
                  min={1}
                  sx={{ width: "80px" }}
                  value={
                    price?.max?.toString()[0] === "0"
                      ? price?.max?.toString().slice(1)
                      : price?.max
                  }
                  onChange={(e) => {
                    setPrice({
                      ...price,
                      max:
                        e.target.value.charAt(0) === "0"
                          ? +e.target.value.slice(1)
                          : +e.target.value,
                    });
                    filterByPrice(
                      "max",
                      e.target.value.charAt(0) === "0"
                        ? +e.target.value.slice(1)
                        : +e.target.value
                    );
                  }}
                  autoComplete="off"
                  extraTextPosition={"append"}
                  extraText={filters?.currency_code?.label}
                />
              </div>
              {/* price range to */}
            </div>
          </div>
        </Collapse>
      </div>
      {/* proprety type */}

      <div className="stops airline">
        <div className="title">
          <h6>{webBuilder.propertyType}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                property_type: !prev.property_type,
              }))
            }
          >
            {FiltersIsOpen.stops ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.property_type}>
          <div className="items">
            <div className="my-1">
              <TextField
                type="text"
                hasLabel={false}
                placeholder={marketPlace.messages.search}
                value={accommodationTerm}
                onChange={(e) =>
                  searchByTextInLookups(
                    e,
                    lookups?.accommodations,
                    setAccommodationTerm,
                    setFilterdAccommodations
                  )
                }
              />
            </div>

            {filterdAccommodations?.map((accommodation, idx) => {
              return (
                <div
                  className="airlines-checkbox"
                  key={`accommodation-${accommodation?.id}-${idx}`}
                >
                  <div className="custom-control custom-checkbox">
                    <input
                      key={`accommodation-${accommodation?.id}-${idx}`}
                      className="custom-control-input permChecks"
                      id={accommodation?.id}
                      name="accommodations"
                      type="checkbox"
                      value={accommodation?.id}
                      onChange={(e) => {
                        filterByCheckbox(
                          e,
                          +e.target.value,
                          selectedPropertyTypes,
                          setSelectedPropretyTypes
                        );
                      }}
                      checked={
                        selectedPropertyTypes.includes(accommodation?.id)
                          ? true
                          : false
                      }
                    />
                    <label
                      className="custom-control-label text-capitalize"
                      htmlFor={accommodation?.id}
                    >
                      {accommodation?.name}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </Collapse>
      </div>

      {/* STARs */}
      <div className="stops">
        <div className="title">
          <h6>{webBuilder.starRating}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                star_rating: !prev.star_rating,
              }))
            }
          >
            {FiltersIsOpen.star_rating ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.star_rating}>
          <div className="items">
            {[5, 4, 3, 2, 1].map((el) => (
              <div className="airlines-checkbox" key={el}>
                <div className="custom-control custom-checkbox ">
                  <input
                    className="custom-control-input permChecks"
                    id={`classification-0${el}`}
                    name="rating"
                    type="checkbox"
                    value={el}
                    onChange={(e) =>
                      filterByCheckbox(
                        e,
                        +e.target.value,
                        selectedRating,
                        setSelectedRating
                      )
                    }
                    checked={selectedRating.includes(el) ? true : false}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor={`classification-0${el}`}
                  >
                    {[1, 2, 3, 4, 5].map((_, i) => {
                      return el <= i ? (
                        ""
                      ) : (
                        <i key={i} className="fas fa-star"></i>
                      );
                    })}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
      {/* meals options */}

      <div className="stops airline">
        <div className="title">
          <h6>{webBuilder.mealOptions}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                meal_options: !prev.meal_options,
              }))
            }
          >
            {FiltersIsOpen.meal_options ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.meal_options}>
          <div className="items">
            <div className="my-1">
              <TextField
                type="text"
                hasLabel={false}
                placeholder={marketPlace.messages.search}
                value={borderBaseTerm}
                onChange={(e) =>
                  searchByTextInLookups(
                    e,
                    lookups?.borderBases,
                    setBorderBaseTerm,
                    setFilterdBorderBases
                  )
                }
              />
            </div>
            {filterdBorderBases?.map((meal, idx) => {
              return (
                <div
                  className="airlines-checkbox"
                  key={`meal-${meal?.id}-${idx}`}
                >
                  <div className="custom-control custom-checkbox ">
                    <input
                      className="custom-control-input permChecks"
                      id={`meal-${meal?.id}-${idx}`}
                      name="borderbases"
                      type="checkbox"
                      value={meal?.id}
                      onChange={(e) =>
                        filterByCheckbox(
                          e,
                          +e.target.value,
                          selectedMealsOptions,
                          setSelectedMealsOptions
                        )
                      }
                      checked={
                        selectedMealsOptions.includes(meal.id) ? true : false
                      }
                    />
                    <label
                      className="custom-control-label  text-capitalize"
                      htmlFor={`meal-${meal?.id}-${idx}`}
                    >
                      {meal?.name?.toLowerCase()}
                    </label>
                  </div>
                </div>
              );
            })}
          </div>
        </Collapse>
      </div>
      {/* Cancellation Policy */}
      <div className="stops">
        <div className="title">
          <h6>{webBuilder.cancellationPolicy}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                cancellation_policy: !prev.cancellation_policy,
              }))
            }
          >
            {FiltersIsOpen.cancellation_policy ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.cancellation_policy}>
          <div className="items">
            <div className="airlines-checkbox">
              <div className="custom-control custom-checkbox ">
                <input
                  className="custom-control-input permChecks"
                  id={`isRefundable`}
                  name="isRefundable"
                  type="checkbox"
                  value={isRefundable}
                  onChange={(e) => filterByRefundPolicy(e)}
                  checked={isRefundable ? true : false}
                />
                <label
                  className="custom-control-label"
                  htmlFor={`isRefundable`}
                >
                  {marketPlace.messages.isRefundable}
                </label>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
      {/* Hotel Facilities  */}

      <div className="stops airline">
        <div className="title">
          <h6>{webBuilder.hotelFacilities}</h6>
          <button
            className="btn btn-link px-2 py-0"
            onClick={() =>
              setFiltersIsOpen((prev) => ({
                ...prev,
                hotel_facilities: !prev.hotel_facilities,
              }))
            }
          >
            {FiltersIsOpen.hotel_facilities ? (
              <ArrowUp color="#222" />
            ) : (
              <ArrowBottom color="#222" />
            )}
          </button>
        </div>
        <Collapse isOpen={FiltersIsOpen.hotel_facilities}>
          <div className="items">
            {["internet", "restaurant", "gym", "spa"].map((facility) => (
              <div className="airlines-checkbox" key={facility}>
                <div className="custom-control custom-checkbox ">
                  <input
                    className="custom-control-input permChecks"
                    id={facility}
                    name="facilities"
                    type="checkbox"
                    value={facility}
                    onChange={(e) =>
                      filterByCheckbox(
                        e,
                        e.target.value,
                        selectedFacilities,
                        setSelectedFacilities
                      )
                    }
                    checked={
                      selectedFacilities.includes(facility) ? true : false
                    }
                  />
                  <label
                    className="custom-control-label"
                    data
                    htmlFor={facility}
                  >
                    {marketPlace.messages[facility]}
                  </label>
                </div>
              </div>
            ))}
          </div>
        </Collapse>
      </div>
    </div>
  );
}
