import React from "react";
import { Modal } from "reactstrap";
import Locale from "translations";

export default function AdultInfoModel({ isOpen, toggle }) {
  const {marketPlace}=Locale
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={"lg"} className="free-rules-builder-modal" centered>
      <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100 modal-header">
        <h3>Adult Info</h3>
        <a href="##" onClick={toggle} className="btn-x">
          <i className="fas fa-times"></i>
        </a>
      </div>
      <ul style={{ width: "100%", textAlign: "inherit" }}>
          <li className="my-1">1. {marketPlace.PleaseEnterPassengerNames}</li>
          <li className="my-1">2. {marketPlace.UseEnglishPassengerNames}</li>
          <li className="my-1">
            <span>
              3. {marketPlace.PassengerNamesAccordingToTheFollowingFormat}
            </span>
            <ul className="sub-list">
              <li className="my-1">• {marketPlace.LastNameOfAdults}</li>
              <li className="my-1">• {marketPlace.lastNameOfChildren}</li>
              <li className="my-1">• {marketPlace.givenNameMustBe}</li>
              <li className="my-1">• {marketPlace.lastNameMustBe}</li>
            </ul>
          </li>
          <li className="my-1">4. {marketPlace.ifYouHaveOtherProblems}</li>
        </ul>
    </Modal>
  );
}
