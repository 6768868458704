import TextField from 'components/Form/TextField/TextField';
import { useMarketplaceState } from 'context/marketplace';
import validate from 'helpers/validate';
import React, { useMemo, useState } from 'react'
import Locale from 'translations';
import TourPassengerItem from './TourPassengerItem';

export default function TourPassengersDetails({ passengerTypeRadio, setPassengerTypeRadio, errors, setErrors, passengesData, setPassengersData, reference, setReference }) {
  const { inventory, marketPlace } = Locale;
  const { toursCart } = useMarketplaceState();

  const generatePassengers = useMemo(() => {
    const toursPassengers = toursCart?.cartItems?.map((cartItem) => {
      return {
        adults: [...Array(cartItem?.noOfAdults).keys()].map((item, index) => {
          if (index === 0) {
            return {
              lead: true,
              type: "ADULT",
              age: 30,
              name: "",
              surname: "",
            }
          }
          return {
            lead: false,
            type: "ADULT",
            age: 30,
            name: "",
            surname: "",
          }
        }),
        childs: cartItem?.children?.map((child) => {
          return {
            type: "CHILD",
            age: child,
            name: "",
            surname: "",
          }
        }),
        ...cartItem
      }
    });
    return toursPassengers;
  }, [toursCart?.cartItems]);

  const [toursPassengers, setToursPassengers] = useState({
    leadAge: 30,
    leadName: "",
    leadSurname: "",
  });


  // set passenger data
  // useEffect(() => {
  //   setPassengersData(toursPassengers);
  // }, [toursPassengers]);
  return (
    <div className='tours-summary-passngers mt-4'>
      <div className='tours-summary-header'>
        <h1>{inventory.messages.passengerDetails}</h1>
      </div>
      <div className='mt-3 bg-white p-3 border'>
        <div className='d-flex tours-summary-passngers-type'>
          {/* lead passeger */}
          <div className="d-flex align-items-center">
            <input
              type="radio"
              id="lead-passenger"
              name="lead-passenger"
              value="lead-passenger"
              checked={passengerTypeRadio === "lead-passenger"}
              onChange={(e) => {
                setPassengerTypeRadio(e.target.value);
                setPassengersData({
                  leadAge: 30,
                  leadName: "",
                  passengesDataeadSurname: "",
                });
                setToursPassengers({
                  leadAge: 30,
                  leadName: "",
                  leadSurname: "",
                });
                setErrors({})
              }}
            />
            <label htmlFor="lead-passenger" className="mx-1 my-0">{marketPlace.messages.leadPassengerData}</label>
          </div>
          {/* all passeger */}
          <div className="d-flex align-items-center mx-3">
            <input
              type="radio"
              id="all-passengers"
              name="all-passengers"
              value="all-passengers"
              checked={passengerTypeRadio === "all-passengers"}
              onChange={(e) => {
                setPassengerTypeRadio(e.target.value);
                setPassengersData(generatePassengers);
                setToursPassengers(generatePassengers);
                setErrors({})
              }}
            />
            <label htmlFor="all-passengers" className="mx-1 my-0">{marketPlace.messages.allPassengersData}</label>
          </div>
        </div>

        {/* loop  */}
        {passengerTypeRadio === 'all-passengers' ?
          <>
            {toursPassengers?.map((tour, index) => {
              return (
                <TourPassengerItem
                  key={tour?.id}
                  tourData={tour}
                  passengesData={passengesData}
                  setPassengersData={setPassengersData}
                  errors={errors}
                  setErrors={setErrors}
                  index={index}
                  toursPassengers={toursPassengers}
                  setToursPassengers={setToursPassengers}
                />
              )
            })}
          </>
          :
          // lead passenger
          <div className="my-3 d-flex flex-column">
            <span className='font-weight-bold text-dark-blue mb-2'>{inventory.messages.leadPassenger}</span>
            <div className='row m-0'>
              <div className='col-md-4 p-0'>
                <TextField
                  label={marketPlace.adultName}
                  id="leadName"
                  name="leadName"
                  placeholder={marketPlace.messages.enterName}
                  value={passengesData?.leadName}
                  onChange={(e) => {
                    setPassengersData({
                      ...passengesData,
                      leadName: e.target.value
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: 'leadName', value: e.target.value },
                        { required: true },
                      ),
                    });
                  }}
                  errors={errors['leadName']}
                  color={errors['leadName']?.required ? "danger" : ""}
                />
              </div>
              <div className='col-md-4 p-0 mx-md-2'>
                <TextField
                  label={marketPlace.adultSurname}
                  id="leadSurname"
                  name="leadSurname"
                  placeholder={marketPlace.messages.enterSurname}
                  value={passengesData?.leadSurname}
                  onChange={(e) => {
                    setPassengersData({
                      ...passengesData,
                      leadSurname: e.target.value
                    });
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: 'leadSurname', value: e.target.value },
                        { required: true },
                      ),
                    });
                  }}
                  errors={errors['leadSurname']}
                  color={errors['leadSurname']?.required ? "danger" : ""}
                />
              </div>
            </div>
          </div>
        }

        {/* checkout  */}
        <div>
          <span className='d-block text-dark-blue mb-2'>{inventory.messages.bookingDetails}</span>
          <TextField
            type="text"
            label={marketPlace.messages.agencyReference}
            id="agency-reference"
            name="agency-reference"
            value={reference}
            onChange={(e) => {
              setReference(e.target.value);
              setErrors({
                ...errors,
                ...validate(
                  { name: 'agency-reference', value: e.target.value },
                  { required: true },
                ),
              });
            }}
            errors={errors['agency-reference']}
            color={errors['agency-reference']?.required ? "danger" : ""}
          />
        </div>
      </div>

    </div>
  )
}
