import Content from "./Components/Content";
import "react-multi-carousel/lib/styles.css";

function WebBuilder() {

  return (
    <div id="main-web-builder" className="main-web-builder row m-0">
      <div className="col-12 p-0">
        <Content />
      </div>
    </div>
  );
}

export default WebBuilder;
