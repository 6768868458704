import * as actionTypes from "./actions";

// Define initialState
const initialState = {};

// Define the Auth Reducer
export default function auth_reducer(state = initialState, action) {
	switch (action.type) {
		// Register: User Details
		case actionTypes.Register_User_Details:
			return {
				...state,
				userName: action.userName,
				userEmail: action.userEmail,
				userCountry: action.userCountry,
				userPhone: action.userPhone,
				userCountryPhoneCode: action.userCountryPhoneCode,
				userPassword: action.userPassword,
				userPasswordConfirmation: action.userPasswordConfirmation,
			};

		// Register: Company Details
		case actionTypes.Register_Company_Details:
			return {
				...state,
				companyName: action.companyName,
				companyLanguage: action.companyLanguage,
				companyAddress: action.companyAddress,
				companyEmail: action.companyEmail,
				companyCountry: action.companyCountry,
				companyCity: action.companyCity,
				companyPhone: action.companyPhone,
				companySecondPhone: action.companySecondPhone,
				companyCountryPhoneCode: action.companyCountryPhoneCode,
			};

		// Register: Company Logo
		case actionTypes.Register_Company_Logo:
			return {
				...state,
				companyLogo: action.companyLogo,
				avatarID: action.avatarID,
			};

		// Register: Progress
		case actionTypes.Register_Progress:
			return {
				...state,
				progress: action.progress,
			};

		// Register: Current Step
		case actionTypes.Register_Current_Step:
			return {
				...state,
				currentStep: action.currentStep,
			};

		default:
			return state;
	}
}
