import React, { useEffect, useState } from "react";
import { AdultIcon, ChildIcon, InfoCircle } from "../../Flight/Icons";
import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";

import { useMarketplaceState } from "context/marketplace";

export default function Room({
  toggleCancellation,
  rooms,
  selectedHotel,
  selectedDataReq,
  setSelectedDataReq,
  setSelectedData,
}) {
  const { marketPlace, webBuilder } = Locale;

  const { hotelFilters } = useMarketplaceState();

  const [RoomsList, setRoomsList] = useState([]);
  const [optionsSelected, setOptionsSelected] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let x = [];
    x.push({
      id: 0,
      name: 0,
    });
    if (hotelFilters.rooms_data && hotelFilters.rooms_data.length > 0) {
      hotelFilters.rooms_data.map((res, index) => {
        x.push({
          id: index + 1,
          name: index + 1,
        });
      });
    }
    setRoomsList(x);
  }, []);

  useEffect(() => {
    setOptionsSelected(rooms.listSeleted);
  }, [rooms.listSeleted.length]);

  const removedOption = (e) => { };

  useEffect(() => {
    let totalx = 0;
    selectedDataReq.map((res) => {
      if (res.room_description == rooms.roomData.roomText) {
        totalx = totalx + res.room_rates[0].selected;
      }
    });

    setTotal(totalx);

    let x = [{ id: 0, name: 0 }];
    if (rooms.listSeleted.length - 1 - totalx >= 0) {
      [...Array(rooms.listSeleted.length - 1 - totalx).keys()].map(
        (res, index) => {
          x.push({
            id: index + 1,
            name: index + 1,
          });
        }
      );
      setOptionsSelected(x);
    }
  }, [selectedDataReq]);

  return rooms.roomData.roomTypes.map((room, IND) => {
    let x =
      selectedHotel.currency !== selectedHotel.providerCurrency
        ? "exchange_room_rates"
        : "room_rates";

    return room[x].map((res, index) => {
      return (
        <div className="room-item">
          <div className="room-info">
            <p className="name">{room.name}</p>
            <div className="info">
              <p>Room Only</p>
              <p>
                <span className="mx-3">Room Fit</span>{" "}
                <span className="adult mx-1">
                  {[...Array(+room?.paxes?.adults || 1).keys()].map((i) => (
                    <AdultIcon />
                  ))}
                </span>
                <span className="child">
                  {[...Array(+room?.paxes?.children || 1).keys()].map((i) => (
                    <ChildIcon />
                  ))}
                </span>
              </p>
              {!res.withinCancellationDeadline ? (
                <span className="refund">{marketPlace.refundable}</span>
              ) : (
                <span className="non-refund">{marketPlace.nonrefundable}</span>
              )}
            </div>
          </div>
          <div className="room-price">
            <div className="price">
              <p className="label">{marketPlace.messages.price}</p>
              <p>
                {res.room_total_price}{" "}
                <span className="currency">{res.room_price_currency}/</span>
                <span className="night">{webBuilder.night}</span>
              </p>
            </div>
            <div className="availability">
              <SelectField
                room={true}
                options={optionsSelected}
                hasLabel={false}
                onChange={(e) => {
                  removedOption(e);
                  if (
                    selectedDataReq.filter(
                      (res) =>
                        res.indexssss ===
                        "listClients" + index + IND + rooms.roomData.roomText
                    ).length > 0
                  ) {
                    let x = [...selectedDataReq];
                    let indexxxxxx = x.findIndex(
                      (rank) => rank.index === "listClients" + index + IND
                    );
                    let z = [];
                    z.push({
                      ...res,
                      selected: e.id,
                    });
                    x[indexxxxxx] = {
                      ...x[indexxxxxx],
                      room_rates: z,
                    };
                    setSelectedDataReq(x);
                  } else {
                    let newData = {
                      ...room,
                      index: "listClients" + index + IND,
                      indexssss:
                        "listClients" + index + IND + rooms.roomData.roomText,
                      room_description: rooms.roomData.roomText,
                      adultsCount: rooms.roomData.adultsCount,
                      childrenAges: rooms.roomData.childrenAges,
                      childrenCount: rooms.roomData.childrenCount,
                    };

                    newData.room_rates = [];
                    newData.room_rates.push({
                      ...res,
                      selected: e.id,
                    });
                    let x = [...selectedDataReq];
                    x.push(newData);
                    setSelectedDataReq(x);
                  }
                }}
              />
            </div>
            <button
              className="btn btn-link mt-4"
              onClick={() => {
                toggleCancellation();
                setSelectedData({
                  room_rate: res,
                  room: room,
                });
              }}
            >
              <InfoCircle />
            </button>
          </div>
        </div>
      );
    });
  });
}
