import React from 'react'
import Locale from 'translations';
import { FlightIcon } from "components/Icons";
import { HotelIcon, PackageIcon, ToursIcon, TransportIcon, VisaIcon } from '../icons';
import { useSBSState } from 'context/global';
import { isObject } from "lodash";

export default function Theme2OurServices({ services }) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;

  return (
    <div className="web-builder-content-our-service custom-container" id="web-builder-our-service">

      {services.map((service) => {
        if (!service?.visible && service?.icon === null) return null
        let icon = renderServiceIcon(service?.type);
        return (
          <article className="our-service_card-with-icon" key={service?.type}>
            <div className="our-service-icon">{icon}</div>
            <p className="service-body">
              {webBuilder[service?.type] || service?.typeTitle}
            </p>

            <p className="service-header h5 mb-0">
              {isObject(service?.header) ? service?.header?.[locale] : service?.header}
            </p>

            <p className="text-secondary-color service-content">
              {isObject(service?.body) ? service?.body?.[locale] : service?.body}
            </p>
          </article>
        )
      })}
    </div>
  )
}


function renderServiceIcon(type) {
  let icon;
  switch (type) {
    case "hotels":
      icon = <HotelIcon />;
      break;
    case "visa":
      icon = <VisaIcon />;
      break;
    case "transport":
      icon = <TransportIcon />;
      break;
    case "transfer":
      icon = <TransportIcon />;
      break;
    case "tours":
      icon = <ToursIcon />;
      break;
    case "packages":
      icon = <PackageIcon />;
      break;
    case "flights":
      icon = <FlightIcon />;
      break;
    default:
      icon = "";
  }
  return icon
}
