import React, { useState } from "react";
import { Collapse } from "reactstrap";
import { ReactComponent as PlusIcon } from "assets/images/webBuilder/plus.svg";
import { ReactComponent as MinusIcon } from "assets/images/webBuilder/minus.svg";
import { useSBSState } from "context/global";
import Locale from "translations";


function FAQs({faqs }) {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const [collapsedItems, setCollapsedItems] = useState([]);

  return (
    <div className="web-builder-faqs-container">
      <h4 className="h4">{webBuilder.anyQuestionsFindHere}</h4>
      {faqs ? faqs.map((que, index) => {
        const isOpen = collapsedItems.includes(index);
        const openCollapse = () => {
          const updated = [...collapsedItems, index];
          setCollapsedItems(updated);
        };
        const closeCollapse = () =>
          setCollapsedItems(collapsedItems.filter((i) => i !== index));
        return (
          <div key={que.id}>
            <div className="faqs-container-question">
              <span> {que.question[locale] || que.question } </span>
              {isOpen ? (
                <button
                  className="bg-transparent border-0"
                  onClick={closeCollapse}
                >
                  <MinusIcon />
                </button>
              ) : (
                <button
                  className="bg-transparent border-0"
                  onClick={openCollapse}
                >
                  <PlusIcon />
                </button>
              )}
            </div>
            <Collapse isOpen={isOpen}>
              <p className="faqs-container-body">{que.body[locale] || que.body}</p>
            </Collapse>
          </div>
        );
      }) : null}
    </div>
  );
}

export default FAQs;
