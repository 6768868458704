import { useWebBuilderState } from "context/webBuilder";
import React, { useCallback } from "react";
import { useParams, Link } from "react-router-dom";
import { ReactComponent as ChevronDown } from "assets/images/webBuilder/chevron-down.svg";
import CustomPageAboutUs from "./AboutUs";
import CustomPagePackage from "./Package";
import CustomPageHotels from "./Hotels";
import CustomPageTours from "./Tours";
import { useSBSState } from "context/global";
import Locale from "translations";

const bodyElements = {
  packages: { component: CustomPagePackage, acceptTypes: [] },
  hotels: { component: CustomPageHotels, acceptTypes: [] },
  tours: { component: CustomPageTours, acceptTypes: [] },
};

export default function DestinationThemePage() {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { pagesData, style } = useWebBuilderState();
  const params = useParams();
  const pageId = params.pageName;
  const link = true ? `/` : `/web-builder-v2/preview/`;
  const customPageData = pagesData?.find((page) => page.id === pageId);

  const mainheadertranslations = {
    "world-packages": webBuilder.worldPackages,
    "hajj-umrah": webBuilder.hajjUmrah
  }
  
  const mainHeader = mainheadertranslations[pageId] || customPageData?.mainHeader[locale] || "";

  const renderComponent = useCallback((element, index) => {
    const type = element.type;
    const selectedElement = bodyElements[type];
    const Component = selectedElement?.component;

    return Component && element?.items?.length > 0 ? (
      <div className="py-3" key={element?.id || index}>
        <Component
          details={{ ...element, index }}
          pageId={pageId}
          title={locale === "ar"
            ? webBuilder[element?.type] + " " + mainHeader
            : mainHeader + " " + webBuilder[element?.type]
          }
        />
      </div>
    ) : null;
  }, [locale, mainHeader, pageId, webBuilder]);

  return (
    <div className={`web-builder-preview  web-builder-page-contianer custom-page ${style?.theme}`}>

      {customPageData && (
        <>
          <div className="web-builder-hero p-3"
            style={{
              backgroundImage: `linear-gradient(#00000021 50%, #00000021), url(${customPageData?.hero})`,
            }}
          >
            <div className="d-flex flex-column gap-24 hero-content">
              <h1>{mainHeader}</h1>
              <div className="page-bread-crumb">
                <Link className="home" to={link}>
                  {webBuilder.home}
                </Link>
                <span>
                  {" "}
                  <ChevronDown />
                </span>
                <span className="search">{mainHeader}</span>
              </div>
            </div>
          </div>

          <div className="web-builder-content">
            <CustomPageAboutUs
              details={customPageData?.about}
              pageId={pageId}
              mainHeader={mainHeader}
            />

            <div className="custom-container mt-5">
              {customPageData?.content?.map((element, index) => {
                return renderComponent(element, index);
              })}
            </div>
          </div>
        </>
      )}

      {/* <Footer /> */}
    </div>
  );
}
