import React from "react";
import { Modal } from "reactstrap";
import Locale from "translations";

function FreeBagsModel({ isOpen, toggle, title, flightData }) {
  const { marketPlace, webBuilder } = Locale;

  const BaggageAD =
    flightData?.journey_0?.length > 0
      ? flightData?.journey_0?.map((journeyItem, index) => {
        return (
          journeyItem?.segments?.length > 0 &&
          journeyItem?.segments?.map((item, index) => {
            return (
              <>
                <tr>
                  <td rowspan="2" className={"two-row text-center"}>
                    {item?.arrival?.code} - {item?.departure?.code}
                  </td>
                  <td className="text-center">Adult</td>
                  <td className="text-center">
                    {item?.baggages?.AD?.baggageAmount ||
                      item?.baggages?.AD?.baggageWeight}
                  </td>
                  <td className="text-center">
                    {" "}
                    {item?.baggages?.AD?.carryOnWeight ??
                      "Be subject to airline contractus"}
                  </td>
                </tr>
                <tr>
                  <td className="text-center">Child</td>
                  <td className="text-center">
                    {item?.baggages?.CHD?.baggageAmount ||
                      item?.baggages?.CHD?.baggageWeight}
                  </td>
                  <td className="text-center">

                    {item?.baggages?.CHD?.carryOnWeight ??
                      "Be subject to airline contractus"}
                  </td>
                </tr>
              </>
            );
          })
        );
      })
      : null;

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} size={"lg"} className="free-rules-builder-modal" centered>
        <div className="d-flex align-items-center justify-content-between p-2 px-4 pt-0 w-100 modal-header">
          <h3>{title}</h3>
          <a href="##" onClick={toggle} className="btn-x">
            <i className="fas fa-times"></i>
          </a>
        </div>
        <div className='table-container'>
          <table
            striped
            className="table-update table-flights p-2 bg-white m-0 table table-striped"
          >
            <thead>
              <tr>
                <th className="text-center">{marketPlace.Trip}</th>
                <th className="text-center">{marketPlace.passanger}</th>
                <th className="text-center">{marketPlace.BaggageAllow}</th>
                <th className="text-center">{marketPlace.CarryOnAllow}</th>
              </tr>
            </thead>
            {flightData?.journey_0?.length > 0 ? (
              <tbody>
                {/* <tr>
							<td rowSpan={2} className={"two-row"}>
								{flightsSearch?.from?.code} - {flightsSearch?.to?.code}
							</td>

							<td>Adult</td>
							<td>
								{flightData?.baggages?.AD?.baggageAmount ||
									flightData?.baggages?.AD?.baggageWeight}
							</td>
							<td>Be subject to airline contractus</td>
						</tr> */}
                {BaggageAD}
                {/* {BaggageCHD} */}
                {/* {flightData?.baggages?.CHD?.baggageAmount ||
						flightData?.baggages?.CHD?.baggageWeight ? (
							<tr>
								<td>child</td>
								<td>
									{flightData?.baggages?.CHD?.baggageAmount ||
										flightData?.baggages?.CHD?.baggageWeight}
								</td>
								<td>Be subject to airline contractus</td>
							</tr>
						) : null} */}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="4">
                    <div className="product-build__product-no-data fullHeight">
                      <h4 className="no-data-color">No Baggage Found</h4>
                    </div>
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <p className="text">
          {webBuilder.fareBagsCuation}
        </p>
      </Modal>
    </>
  );
}

export default FreeBagsModel;
