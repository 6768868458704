import React, { Fragment, useState } from "react";
import {
  ArrowBottom,
  ArrowUp,
  ClockIcon,
  Luggage1Icon,
  Luggage2Icon,
} from "../Icons";
import { Collapse } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSBSState } from "context/global";
import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import Locale from "translations";
function toHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(+totalMinutes / 60);
  const minutes = +totalMinutes % 60;
  return `${hours}h ${minutes}m`;
}

export default function ResultItem({ journey, solution, inbound }) {
  const { webBuilder } = Locale;
  const [detailsIsOpen, setDetailsIsOpen] = useState(false);

  const { locale } = useSBSState();

  const history = useHistory();

  const segments = journey?.segments;
  const segmentsCount = segments.length;

  const departureAirPort = segments[0];
  const arrivalAirPort = segments[segmentsCount - 1];

  const departureTime = departureAirPort?.departureTime;
  const arrivalTime = arrivalAirPort?.arrivalTime;

  const { flightsSearch } = useMarketplaceState();
  const dispatch = useMarketplaceDispatch();

  const oneWay = flightsSearch.type === "2";

  const flightsNumbers = segments.reduce(
    (prev, current, index) =>
      solution.gds === 9
        ? prev + (index === 0 ? "" : " | ") + current.flightNum
        : prev +
          (index === 0 ? "" : " | ") +
          current.airline.code +
          current.flightNum,
    ""
  );
  return (
    <div className="content-item">
      <div className="data">
        <div className="data-info ">
          <div className="logo">
            <img
              className="img-company me-1"
              src={departureAirPort.airline?.logo}
              alt=""
            />
            <div>
              <p>{departureAirPort.airline.name?.[locale]}</p>
              <p>{flightsNumbers}</p>
            </div>
          </div>
          <div className="box">
            {segments.map((segment) => (
              <p
                key={segment.segmentId}
                style={{
                  backgroundColor: "#EBEBEB",
                  borderRadius: "2px",
                  marginInlineEnd: "4px",
                }}
                className="px-1"
              >
                {segment.equipment}
              </p>
            ))}
          </div>
          <div className="stop">
            <div className="time-line">
              <div className="infoAir">
                <h4 className="takeoff">{departureTime}</h4>
                <p className="airport-name">
                  {departureAirPort?.departure?.code}
                </p>
              </div>
              <div className="text-center clock">
                <ClockIcon />
                <span>{toHoursAndMinutes(journey?.journeyTime)}</span>
                <p className="spent text-success">{`${
                  journey.stops.length
                    ? journey.stops.join("|") + " Stop"
                    : "Non-stop"
                }`}</p>
              </div>
              <div className="Landing">
                <h4 className="takeoff">{arrivalTime}</h4>
                <p className="airport-name">{arrivalAirPort.arrival.code}</p>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="flight-details">
          <div className="details">
            <p>{detailsIsOpen ? webBuilder.hideFlightDetails : webBuilder.flightDetails}</p>
            <button
              className="btn btn-link px-2 py-0"
              onClick={() => setDetailsIsOpen((prev) => !prev)}
            >
              {detailsIsOpen ? <ArrowUp /> : <ArrowBottom />}
            </button>
          </div>
          <div className="luggage">
            <span>{webBuilder.luggage}:</span>
            <span>
              1 <Luggage1Icon />
            </span>
            <span>
              2 <Luggage2Icon />
            </span>
          </div>
          <p
            className="price-mobile"
            role="button"
            onClick={() => {
              dispatch({
                type: inbound ? "inboundFlight" : "outboundFlight",
                payload: solution,
              });
              history.push(
                inbound || oneWay
                  ? "/flight/flight-summary"
                  : "/flight/inbound-view"
              );
            }}
          >
            {" "}
            {solution.totalPrice} <span>{solution.currency}</span>
          </p>
        </div>
        <Collapse isOpen={detailsIsOpen}>
          <div className="details-dropdown">
            <div className="details-head">
              <p>
                Depart {moment(segments[0]?.departureDate).format("ddd")} ,{" "}
                {moment(segments[0]?.departureDate).format("MMM DD")}
              </p>
              <p>
                <ClockIcon /> {webBuilder.totalTripDuration}
                {/* : 6h 25m */}
              </p>
            </div>
            <div className="details-content">
              {segments.map((segment, index) => {
                const stopTime = moment(
                  `${segments[index + 1]?.departureDate} ${
                    segments[index + 1]?.departureTime
                  }`
                ).diff(`${segment?.arrivalDate} ${segment?.arrivalTime}`, "m");
                return (
                  <Fragment key={segment.segmentId}>
                    <div className="details-item">
                      <div className="logo">
                        <img
                          className="img-company mr-2"
                          src={segment.airline.logo}
                          alt=""
                        />
                        <p>{segment.airline?.name[locale]}</p>
                        <span>
                          {" "}
                          {segment.airline?.code + " " + segment.flightNum}
                        </span>
                      </div>
                      <div className="info">
                        <div className="w-40">
                          <h5>
                            {segment?.departure?.names[locale]?.split(",")?.[0]}{" "}
                            ( {segment?.departure?.code} )
                          </h5>
                          <span>{`${moment(segment?.departureDate).format(
                            "DD-MM-YYYY"
                          )} ${segment?.departureTime} ${moment(
                            segment?.departureDate
                          ).format("ddd")}`}</span>
                          <p>
                            {segment?.departure?.names?.[locale] ||
                              segment?.departure?.code}
                          </p>
                        </div>
                        <p className="clock">
                          {" "}
                          <ClockIcon /> {toHoursAndMinutes(segment.flightTime)}
                        </p>
                        <div className="w-40">
                          <h5>
                            {segment?.arrival?.names[locale]?.split(",")?.[0]}{" "}
                            ( {segment?.arrival?.code} )
                          </h5>
                          <span>
                            {" "}
                            {`${moment(segment.arrivalDate).format(
                              "DD-MM-YYYY"
                            )} ${segment.arrivalTime} ${moment(
                              segment.arrivalDate
                            ).format("ddd")}`}
                          </span>
                          <p>
                            {segment?.arrival?.names?.[locale] ||
                              segment?.arrival?.code}
                          </p>
                        </div>
                      </div>
                      <div className="economy">
                        <p>Economy</p>
                        <h6>-</h6>
                        <h6>-</h6>
                      </div>
                    </div>

                    {segmentsCount > 1 && index < segmentsCount - 1 && (
                      <div className="dot-layout">
                        <div className="dot">
                          <div></div>
                          <p>
                            Stop{" "}
                            {segment?.arrival?.names?.[locale] ||
                              segment?.arrival?.code}{" "}
                            ({toHoursAndMinutes(stopTime)})
                          </p>
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </Collapse>
      </div>
      <div className="price">
        <span>{webBuilder.perPerson}</span>
        <p>
          {solution.totalPrice} <span>{solution.currency}</span>
          {/* 3,127 <span>SAR</span> */}
        </p>
        <button
          className="btn btn-primary"
          //   onClick={() => history.push("/flight/flight-summary")}
          onClick={() => {
            dispatch({
              type: inbound ? "inboundFlight" : "outboundFlight",
              payload: solution,
            });
            history.push(
              inbound || oneWay
                ? "/flight/flight-summary"
                : "/flight/inbound-view"
            );
          }}
          type="submit"
        >
          {webBuilder.selectFlight}
        </button>
      </div>
    </div>
  );
}
