import React, { useState } from 'react'
import { Collapse } from 'reactstrap'

function CollapseSidebar({ children, title }) {

  const [isOpen, setisOpen] = useState(true)

  return (
    <>
      <div className="bg-white p-3 rounded mt-2">
        <h6
          className="font-weight-bold  terms-color"
        >
          {title}
          <span className="float-right" onClick={() => {
            setisOpen(!isOpen)
          }}>
            <i className="fa fa-angle-down"></i>
          </span>
        </h6>
        <Collapse isOpen={isOpen}>
          <div className="col-md-12 mt-3 all-inputs">
            {children}
          </div>
        </Collapse>
      </div>
    </>
  )
}

export default CollapseSidebar