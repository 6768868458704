// import * as React from "react";

import sbsReducer from "context/reducers/sbsReducer";
import { createContext, useContext, useReducer } from "react";
const SBSContext = createContext(undefined);
const SBSDispatchContext = createContext(undefined);
const currentLocale = localStorage.getItem("currentLocale");
const currentCurrency = localStorage.getItem("currentCurrency");
//const permissions = JSON.parse(localStorage.getItem("permissions"));
const initialState = {
	locale: currentLocale ?? "en",
	isError: false,
	ErrorMassage: { title: "", body: "" },
	allCountries: [],
	allCurrencies: [],
	currency: currentCurrency ?? "SAR",
	//permissions: permissions ? permissions : [],
};

function useSBSState() {
	const context = useContext(SBSContext);
	if (context === undefined) {
		throw new Error("useSBSState must be used within a SBSProvider");
	}
	return context;
}

function useSBSDispatch() {
	const context = useContext(SBSDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useMarketplaceDispatch must be used within a MarketPlaceProvider"
		);
	}
	return context;
}

function SBSProvider({ children }) {
	const [state, dispatch] = useReducer(sbsReducer, initialState);
	return (
		<SBSContext.Provider value={state}>
			<SBSDispatchContext.Provider value={dispatch}>
				{children}
			</SBSDispatchContext.Provider>
		</SBSContext.Provider>
	);
}

export { SBSProvider, useSBSState, useSBSDispatch };
