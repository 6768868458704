import TextField from 'components/Form/TextField/TextField'
import validate from 'helpers/validate';
import React from 'react'
import Locale from 'translations';

export default function TourPassengerItem({ tourData, toursPassengers, setToursPassengers, index, errors, setErrors, }) {
  const { inventory, marketPlace } = Locale;
  const modalities = tourData?.details?.modalities?.[0];

  return (
    <div className=' mt-4'>
      {/*  */}
      <div className='d-flex flex-column'>
        <span className='font-weight-bold'>
          {tourData?.details?.name} - {modalities?.name}
        </span>
        <span>
          {" "}
          {tourData?.noOfAdults}
          {" "}
          {+tourData?.noOfAdults > 1 ? inventory.messages.adults : inventory.messages.Adult}
          {tourData?.children?.length > 0 ?
            <span>
              {", "}
              {tourData?.childs?.length}
              {" "}
              {inventory.messages.children}
            </span>
            :
            null
          }
        </span>
      </div>


      <div className="my-3 d-flex flex-column">
        {/* adults passegers */}
        {tourData?.adults?.map((adult, idx) => {
          return (
            <div className='row m-0' key={`adult-${index}-${idx}`}>
              <div className="my-1 d-flex flex-column col-12">
                <span className='font-weight-bold text-dark-blue mb-2'>
                  {adult.lead && idx === 0 ? inventory.messages.leadPassenger : 
                    !adult?.lead && idx === 1 ? marketPlace.messages.otherPassenger : ""
                  }
                </span>
                <div className='row m-0'>
                  {/* adult name */}
                  <div className='col-md-4 p-0'>
                    <TextField
                      label={`${marketPlace.adultName} ${idx + 1}`}
                      id={`name${index}${idx}`}
                      name={`name${index}${idx}`}
                      placeholder={marketPlace.messages.enterName}
                      value={adult?.name}
                      onChange={(e) => {
                        let tours = [...toursPassengers];
                        let tourAdultsList = tours[index]?.adults;
                        let currentAdult = tourAdultsList[idx];
                        tourAdultsList[idx] = { ...currentAdult, name: e.target.value };
                        tours[index].adults = tourAdultsList;
                        setToursPassengers(tours);
                        setErrors({
                          ...errors,
                          ...validate(
                            { name: [`name${index}${idx}`], value: e.target.value },
                            { required: true },
                          ),
                        });
                      }}
                      errors={errors[`name${index}${idx}`]}
                      color={errors[`name${index}${idx}`]?.required ? "danger" : ""}
                    />
                  </div>
                  {/* adult surname */}
                  <div className='col-md-4 p-0 mx-md-2'>
                    <TextField
                      label={`${marketPlace.adultSurname} ${idx + 1}`}
                      id={`surname${index}${idx}`}
                      name={`surname${index}${idx}`}
                      placeholder={marketPlace.messages.enterSurname}
                      value={adult?.surname}
                      onChange={(e) => {
                        let tours = [...toursPassengers];
                        let tourAdultsList = tours[index]?.adults;
                        let currentAdult = tourAdultsList[idx];
                        tourAdultsList[idx] = { ...currentAdult, surname: e.target.value };
                        tours[index].adults = tourAdultsList;
                        setToursPassengers(tours);
                        setErrors({
                          ...errors,
                          ...validate(
                            { name: [`surname${index}${idx}`], value: e.target.value },
                            { required: true },
                          ),
                        });
                      }}
                      errors={errors[`surname${index}${idx}`]}
                      color={errors[`surname${index}${idx}`]?.required ? "danger" : ""}
                    />
                  </div>
                </div>
              </div>

            </div>
          )
        })}


        {/* child will be loop based on child count from search */}
        {tourData?.childs?.map((child, idx) => {
          return (
            <div className='row m-0' key={`child-${index}-${idx}`}>
              {/* child name */}
              <div className='col-md-4 p-0'>
                <TextField
                  label={`${marketPlace.childName} ${idx + 1} `}
                  id={`child-name${index} ${idx}`}
                  name={`child-name${index} ${idx}`}
                  placeholder={marketPlace.messages.enterName}
                  value={child?.name}
                  onChange={(e) => {
                    let tours = [...toursPassengers];
                    let tourChildsList = tours[index]?.childs;
                    let currentChild = tourChildsList[idx];
                    tourChildsList[idx] = { ...currentChild, name: e.target.value };
                    tours[index].childs = tourChildsList;
                    setToursPassengers(tours);
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: [`child-name${index}${idx}`], value: e.target.value },
                        { required: true },
                      ),
                    });
                  }}
                  errors={errors[`child-name${index}${idx}`]}
                  color={errors[`child-name${index}${idx}`]?.required ? "danger" : ""}
                />
              </div>
              {/* child surname */}
              <div className='col-md-4 p-0 mx-md-2'>
                <TextField
                  label={`${marketPlace.childSurname}  ${idx + 1} `}
                  id={`child-surname${index}${idx}`}
                  name={`child-surname${index}${idx}`}
                  placeholder={marketPlace.messages.enterSurname}
                  value={child?.surname}
                  onChange={(e) => {
                    let tours = [...toursPassengers];
                    let tourChildsList = tours[index]?.childs;
                    let currentChild = tourChildsList[idx];
                    tourChildsList[idx] = { ...currentChild, surname: e.target.value };
                    tours[index].childs = tourChildsList;
                    setToursPassengers(tours);
                    setErrors({
                      ...errors,
                      ...validate(
                        { name: [`child-surname${index}${idx}`], value: e.target.value },
                        { required: true },
                      ),
                    });
                  }}
                  errors={errors[`child-surname${index}${idx}`]}
                  color={errors[`child-surname${index}${idx}`]?.required ? "danger" : ""}
                />
              </div>
            </div>
          )
        })}
      </div>

    </div>
  )
}
