import { useSBSState } from "context/global";
// import Locale from "translations";

export default function CustomPageAboutUs({ details, mainHeader }) {
  const { items, id, visible } = details;
  const { locale } = useSBSState();

  const AboutItems = {
    content: AboutContent,
    media: AboutMedia,
  };

  return (
    <>
      {visible || typeof visible === "undefined" ?
        <div id={id}>
          <div className="web-builder-content-about-us custom-container">
            {items?.map((item, index) => {
              const componentType = item.id.includes("media") ? "media" : "content";
              const Component = AboutItems[componentType];
              return (
                <Component
                  key={item.id || index}
                  details={{
                    ...item,
                    head: item?.head?.[locale],
                    body: item?.body?.[locale],
                  }}
                  mainHeader={mainHeader}
                />
              );
            })}
          </div>
        </div>
        :
        null
      }
    </>
  );
}

const AboutContent = ({ details, mainHeader }) => {
  const { body, textAlign = "start" } = details;
  // const { webBuilder } = Locale;

  return (
    <div className="about-us-details" style={{ textAlign }}>
      <h3 className="about-us-title">{` ${mainHeader}`}</h3>
      <p className="text-body mt-3">{body}</p>
    </div>
  );
};

const AboutMedia = ({ details }) => {
  const { mediaUrl, mediaType } = details;

  return (
    <div
      className={`about-media-container ${mediaType === "image" ? "about-media-image" : ""
        }`}
    >
      {mediaType === "video" ? (
        <iframe
          style={{ maxWidth: "100%" }}
          className="w-100 h-100"
          width="auto"
          height="auto"
          src={`https://www.youtube.com/embed/${mediaUrl}`}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      ) : (
        <img src={mediaUrl} className="img-fluid" alt="about us" />
      )}
    </div>
  );
};
