import React, { useEffect, useState } from "react";
import ResultItem from "./ResultItem";
import Filter from "./Filter";
import { NoDataIcon } from "../../Flight/Icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSBSState } from "context/global";
import Locale from "translations";
import {
  useMarketplaceDispatch,
  useMarketplaceState,
} from "context/marketplace";
import { cleanEmpty } from "services/general";
import {
  fetchHotelsBuilder,
  searchHotelFiltersBuilder,
} from "services/webBuilder";
import { store } from "react-notifications-component";
import moment from "moment";
import {
  fetchAccommodationsLookup,
  fetchBoardBasesLookup,
} from "services/marketplace";
import Pagination from "components/Pagination";
import Loader from "components/Loader";
import SearchBar from "./SearchBar";
import { ReactComponent as FilterSearchIcon } from "assets/images/webBuilder/filter-search.svg";

export default function HotelResult(props) {

  const history = useHistory();
  const search = props.location.search;
  const name = new URLSearchParams(search);
  const { messages, webBuilder } = Locale;
  const { searchResults, filters, hotelFilters } = useMarketplaceState();

  const { locale, token_data } = useSBSState();

  const dispatch = useMarketplaceDispatch();
  const [searchOrdering, setSearchOrdering] = useState({});

  const [allFilters, setAllFilters] = useState(false);
  const [priceRanges, setPriceRanges] = useState(null);
  const [lookups, setLookups] = useState(null);
  const [page, setpage] = useState(0);

  // search filter when item change in the filter list get the data from the backend
  const searchFilters = async (filters, page = 0) => {
    let data = {
      search_ordering: searchOrdering,
      ...filters,
      page: page,
      token: token_data?.token,
    };

    // let data = {
    // 	...filters,
    // 	page: page,
    // 	search_ordering: { ...searchOrdering, ...filters.search_ordering },
    // };
    data = cleanEmpty(data);

    const response = await searchHotelFiltersBuilder(data);

    dispatch({ type: "searchResults", payload: response.data });
  };
  // sort hotel list
  const sort = (by, value) => {
    const data = {
      search_ordering: {
        // ...searchOrdering,
        [by]: value,
      },
    };
    // setSearchOrdering({ ...setSearchOrdering, [by]: value });
    setAllFilters({ ...allFilters, ...data });
    searchFilters({ ...allFilters, ...data });
  };
  // get price range from search results context
  useEffect(() => {
    const localPriceRanges = searchResults?.filter_data;

    if (localPriceRanges) {
      setPriceRanges({
        min: localPriceRanges?.price?.min_price,
        max: localPriceRanges?.price?.max_price,
      });
    }
  }, [searchResults?.filter_data]);



  // get last result on page reload

  // TODO Refactor
  // fetch hotels data
  useEffect(() => {
    async function fetchData() {
      const hotelData = {
        ...hotelFilters,
        lang: locale,
      };

      if (searchResults === undefined) {
        if (filters === undefined) {
          dispatch({
            type: "filter",
            payload: JSON.parse(localStorage.getItem("filters")),
          });
        }
        let response;

        let x = {};
        name.forEach(function (value, key) {
          x = { ...x, [key]: value };
        });

        response = await fetchHotelsBuilder(hotelData);

        if (response?.data?.data?.length > 0) {
          dispatch({ type: "searchResults", payload: response.data });
          setPriceRanges({
            min: response.data.filter_data.price.min_price,
            max: response.data.filter_data.price.max_price,
          });
        } else {
          store.addNotification({
            title: "Error!",
            message: messages.noResults + " !",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 4000,
              onScreen: true,
              pauseOnHover: true,
            },
          });

          setTimeout(() => {
            history.push("/");
          }, 4000);
        }
      } else if (searchResults.length === 0);
    }
    fetchData();
  }, [searchResults]);

  const hotelFiltersData = hotelFilters;

  const [Night, setnight] = useState(
    moment(hotelFiltersData?.date_to).diff(
      moment(hotelFiltersData?.date_from),
      "days"
    )
  );
  // go to function for pagination
  const goTo = async (e) => {
    let data = {
      search_ordering: searchOrdering,
      ...allFilters,
      page: e,
      token: token_data?.token,
    };
    data = cleanEmpty(data);

    const response =
      token_data && token_data?.token != null
        ? await searchHotelFiltersBuilder(data)
        : await searchHotelFiltersBuilder(data);
    dispatch({ type: "searchResults", payload: response.data });
  };
  const [price, setPrice] = useState({
    min: priceRanges?.min,
    max: priceRanges?.max,
  });
  //
  useEffect(() => {
    setAllFilters({
      ...allFilters,
      search_filters: {
        ...allFilters?.search_filters,
        price: { min: price?.min, max: price.max },
      },
    });
  }, []);

  // useEffect(() => {
  //   // setAllFilters({
  //   //   ...allFilters,
  //   //   search_filters: {
  //   //     ...allFilters?.search_filters,
  //   //     price: { min: price?.min, max: price.max },
  //   //   },
  //   // });
  //   if (allFilters) {
  //     searchFilters({
  //       ...allFilters,
  //       search_filters: {
  //         ...allFilters?.search_filters,
  //         price: { min: price?.min, max: price.max },
  //       },
  //     });
  //   }
  // }, [price, allFilters]);

  // lookups
  useEffect(() => {
    async function fetchLookups() {
      const [accommodations, borderBases] = await Promise.all([
        fetchAccommodationsLookup(),
        fetchBoardBasesLookup(),
      ]);
      setLookups({
        accommodations: accommodations?.data,
        borderBases: borderBases?.data,
      });
    }
    if (!lookups) {
      fetchLookups();
    }
  }, []);

  function toggleOpenFilter() {
    const filterEle = document.getElementsByClassName("flight-filter")?.[0];
    if (filterEle) {
      filterEle.classList.contains("opened")
        ? filterEle.classList.remove("opened")
        : filterEle.classList.add("opened");
    }
  }
  return searchResults !== undefined ? (
    <div className="flight-result hotel-result">
      {/* <div className="head">
        <div className="location w-100 text-center">
          <PackageBreadCrumb />
        </div>
      </div> */}
      <SearchBar setnight={setnight} page={page} setpage={setpage} />

      <div className="content">
        <div className="row">
          <div className="col-md-3">
            <div className="filters">
              <Filter
                searchFilters={searchFilters}
                setAllFilters={setAllFilters}
                allFilters={allFilters}
                maxPrice={priceRanges?.max}
                minPrice={priceRanges?.min}
                setPrice={setPrice}
                price={price}
                lookups={lookups}
                toggleOpenFilter={toggleOpenFilter}
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="num-result px-1 align-items-center">
              <p>
                Hotel search (
                {searchResults?.data?.length > 0 &&
                  searchResults?.data[0]?.country}{" "}
                -{" "}
                {searchResults?.data?.length > 0 &&
                  searchResults?.data[0]?.city}
                ){" "}
              </p>
              <div className="sort">
                <p>{webBuilder.sortBy}</p>
                <select
                  onChange={(e) => {
                    setSearchOrdering({
                      ...searchOrdering,
                      price: e.target.value,
                    });
                    sort(
                      "price",
                      e.target.value
                    );
                  }}
                >
                  <option value="">{webBuilder.select}</option>
                  <option value="desc">{webBuilder.highestPrice}</option>
                  <option value="asc"> {webBuilder.lowestPrice}</option>
                </select>
              </div>
            </div>
            <div className="row mx-0">
              {searchResults?.data?.length === 0 ? (
                <NoData />
              ) : (
                searchResults?.data?.map((item, i) => {
                  return (
                    <ResultItem
                      key={i}
                      item={item}
                      searchBy="hotel"
                      Night={Night}
                    />
                  );
                })
              )}

              <Pagination info={searchResults?.meta} goTo={goTo} items={10} />
            </div>
          </div>
        </div>
      </div>
      <button
        className="btn filter-mobile-btn shadow-none"
        onClick={toggleOpenFilter}
      >
        <FilterSearchIcon />
      </button>
    </div>
  ) : (
    <Loader />
  );
}
export function NoData() {
  const { webBuilder } = Locale;
  const history = useHistory();
  return (
    <div className="no-data-available">
      <NoDataIcon />
      <h6>{webBuilder.noResultsFound}</h6>
      <p>{webBuilder.youCanTryAnotherSearch}</p>
      <button className="btn btn-primary" onClick={() => history.push("/")}>
        {webBuilder.backtoSearch}
      </button>
    </div>
  );
}
