import React, { useEffect, useRef, useState } from "react";
import { LocationIcon, MoonIcon, PathIcon } from "../../Flight/Icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Locale from "translations";
import noHotel from "assets/images/placeHoleder.png";

export default function ResultItem({ item, searchBy, Night }) {
  const history = useHistory();
  const { webBuilder } = Locale;
  const imageRef = useRef(null);


  const search = window.location.search;
  const name = new URLSearchParams(search);
  const [params, setParams] = useState({});


  useEffect(() => {
    let x = {};
    for (const [key, value] of name) {
      x = { ...x, [key]: value };
    }
    setParams(x);
  }, []);
  const hotelRating = +item?.rating > 5 ? 5 : Math.ceil(+item?.rating);
  const cachedHotels = item?.cachedHotels?.sort((a, b) => a.total_price - b.total_price)


  return (
    <div className=" col-md-4">
      <div className="hotel-item">
        <div className="img-container">
          <img
            className="img-fluid"
            src={item?.image ? `${item?.image}` : noHotel}
            ref={imageRef}
            onError={(e) => {
              imageRef.current.src = noHotel;
            }}
            alt="MarketImg"
          />
          {/* <p>Top Rate</p> */}
        </div>
        <div className="rating">

          {+hotelRating > 0 ? (
            <div className="rating">
              {[...Array(+hotelRating).keys()].map((i) => (
                <i key={i} className="fas fa-star"></i>
              ))}
            </div>
          ) : null}
        </div>
        <div className="info">
          <h6 className="title">{item?.name}</h6>
          <p>
            <LocationIcon />{" "}
            {item?.address
              ? item?.address.length > 40
                ? item?.address.slice(0, 40) + "..."
                : item?.address
              : ""}
          </p>
          <div className="d-flex justify-content-between">
            <p>
              <PathIcon /> {webBuilder.hotel}
            </p>
            <p>
              <MoonIcon /> {Night} {webBuilder.night}
            </p>
          </div>
        </div>
        <div className="price">
          <div>
            <span>{webBuilder.startFrom}</span>
            <p>
              {cachedHotels[0]?.total_price}{" "}
              <span className="currency">
                {cachedHotels[0]?.currency}
              </span>
            </p>
          </div>
          <button
            className="btn btn-primary"
            onClick={() =>
              history.push(
                `/hotel/hotel-detail/${cachedHotels[0].search_token}`
              )
            }
          >
            {webBuilder.view}
          </button>
        </div>
      </div>
    </div>
  );
}
