import { useWebBuilderState } from "context/webBuilder";
import { FacebookIcon, LinkedinIcon, TwitterIcon, YoutubeIcon } from "modules/WebBuilder-V2/shared/icons";
import { ReactComponent as InstagramIcon } from "assets/images/webBuilder/instagram-colorful.svg";
import { ReactComponent as TiktokIcon } from "assets/images/webBuilder/tiktok-colorful.svg";
import { ReactComponent as SnapchatIcon } from "assets/images/webBuilder/snapchat-colorful.svg";
import SectionBG from "assets/images/webBuilder/theme-5-top-bg.svg";
import Locale from "translations";
import { logoURL } from "helpers/utils";
import { useSBSState } from "context/global";
import { Link } from "react-router-dom";
import { isArray } from "lodash";

const currentYear = new Date().getFullYear();

export default function FooterThemeFive() {
  const { webBuilder } = Locale;
  const { locale } = useSBSState();
  const { footer: footerDetails, hero, ourServices } = useWebBuilderState();

  const socialMediaIcons = {
    facebook: <FacebookIcon />,
    twitter: <TwitterIcon />,
    youtube: <YoutubeIcon />,
    instagram: <InstagramIcon />,
    linkedin: <LinkedinIcon />,
    snapchat: <SnapchatIcon />,
    tiktok: <TiktokIcon />,
  };
  const services = ourServices?.services?.filter((service) => service?.visible);
  const header = hero?.header;

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };
  const phoneNumber = isArray(header?.phoneNumber)
    ? header?.phoneNumber?.[0]
    : header?.phoneNumber;

  return (
    <>
      <div
        className="abstract-bg"
        style={{ maskImage: `url(${SectionBG})` }}
      ></div>

      <div className="footer_content">
        <div className="logo_container">
          <Link to="/">
            <img
              className="footer-photo"
              src={logoURL(header?.logo, (header?.logo?.[locale?.toLowerCase()] || header?.logo?.en || header?.logo?.ar))}
              alt="Logo"
            />
          </Link>
        </div>
        <div className="footer_text">{footerDetails?.footerText[locale]}</div>
        <div className="info_container">
          <div className="social_media">
            <p className="text">{webBuilder.followUsOn} :</p>
            <ul className="links">
              {footerDetails?.links?.map((link) => {
                if (link?.visible) {
                  return (
                    <div key={link?.id}>
                      <a href={link?.url} target="_blank" rel="noreferrer">
                        {socialMediaIcons[link?.id]}
                      </a>
                    </div>
                  );
                } else {
                  return null;
                }
              })}
            </ul>
          </div>
          {phoneNumber && (
            <div className="contact_phone">
              <p className="text">{webBuilder.contactUs} : </p>
              <p className="phone">
                <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
              </p>
            </div>
          )}
        </div>
        <ul className="footer_links">
          {services &&
            services.length > 0 &&
            services?.map((service) => {
              return (
                <li
                  className="pointer"
                  onClick={() => scrollToSection(service?.id)}
                >
                  <span> {webBuilder[service?.type]}</span>
                </li>
              );
            })}
        </ul>
      </div>
      <div className="footer-copy-right d-flex justify-content-center align-items-center">
        <p className="footer-text mt-1">
          {" "}
          {webBuilder.allRightsReserved} {" - "}
          <a
            href="https://safasoft.com/"
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            {webBuilder.safasoft}
          </a>{" "}
          © {currentYear}
        </p>
      </div>
    </>
  );
}
