import Locale from "translations";
import { ReactComponent as LocationIcon } from "assets/images/webBuilder/location-outline-icon.svg";
import { Link } from "react-router-dom";
import useExchangeRate from "hooks/useExchangeRate";
import { useSBSState } from "context/global";

export default function TourCardThemeFive(props) {
  let {
    name,
    name_localized,
    price,
    image,
    currency,
    product_uuid,
    initialName,
    locationName,
    city,
  } = props;
  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { exchangedPrice, exchangeCurrency } = useExchangeRate({ price, currency });
  const tourUrl = `/tours/${product_uuid}`;


  return (
    <Link to={tourUrl} className="tour-card">
      <div className="card-img">
        {image ? (
          <img src={image} alt="our package" />
        ) : (
          <img
            src="https://s3-alpha-sig.figma.com/img/a4c9/9d6c/eb4e350f82bf2a0b98b1b0bd670881b0?Expires=1728864000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=IQ8njZBDtaGbFP4kCFcV3WOUBetwTXScRGoV~e8gduHF-emSiek~S1kLO7tHl3MkPtvrr-C5VDTm-CxZIGrAzVcXxGMNeFZoI~rku8TspVERIVtjD5Lza5DNgYltABv3qd4tTv6aJD0oHv-B9Z1WExR09TBX~PBxwKF1U67nvt~Z77ymwlCbOqJcbnS6KvE2Pwonc2VmDwC0zLLXunzIfIECA3JIqHMVt6l6ffvx-Hq64CFNke5BiKBYK2GvCx~oUYEIC2WFFpg-J48uSKKGlucTyXHLUTdiUqqWOHkb-9C077a7j1nzHsy70HxOqEh6yyRvHzOiUFrEsKSjuCYVnw__"
            alt="Safa Visa"
          />
        )}
      </div>

      <div className="card-content">
        {/* package name and location */}
        <div className="card-details">
          <h4 className="title">{name_localized?.[locale] || name || initialName}</h4>
          <p className="location">
            <LocationIcon />
            <span className="mx-2">
              {locationName?.[locale] || locationName?.en || locationName}
              {city && <span>, {city}</span>}
            </span>
          </p>
        </div>

        <p className="price">
          <span>{webBuilder.from}</span>
          <span className="amount">{exchangedPrice}</span> {" "}
          <span className="currency">{exchangeCurrency}</span>
        </p>
      </div>
    </Link>
  )
}