import * as actionTypes from "./actions";

// Define initialState
const initialState = {};

export default function interests_reducer(state = initialState, action) {
	switch (action.type) {
		//Interests: Makkah Hotel
		case actionTypes.Interests_Makkah_Hotels:
			return {
				...state,
				makkahHotel: action.makkahHotel,
				makkahArea: action.makkahArea,
				makkahClass: action.makkahClass,
				makkahHaramMeters: action.makkahHaramMeters,
			};

		//Interests: Madinah Hotel
		case actionTypes.Interests_Madinah_Hotels:
			return {
				...state,
				madinaHotel: action.madinaHotel,
				madinaArea: action.madinaArea,
				madinaClass: action.madinaClass,
				madinaHaramMeters: action.madinaHaramMeters,
			};

		//Interests: Madinah Hotel
		case actionTypes.Interests_Airlines:
			return {
				...state,
				airlines: action.airlines,
			};

		//Interests: Progress
		case actionTypes.Interests_Progress:
			return {
				...state,
				progress: action.progress,
			};

		//Interests: Current Step
		case actionTypes.Interests_Current_Step:
			return {
				...state,
				currentStep: action.currentStep,
			};

		default:
			return state;
	}
}
