import React from "react";

function PhotoItem({ url }) {
  return (
    <div className="custom-container-photo">
      {url
        ? <img src={url} alt="" />
        : null
      }
    </div>
  );
}

export default PhotoItem;
