import { useWebBuilderState } from "context/webBuilder";
import React, { useEffect, useRef } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { ReactComponent as MenuIcon } from "assets/images/webBuilder/menu.svg";
import { ReactComponent as CloseIcon } from "assets/images/webBuilder/close-nav.svg";
import { Link, useLocation } from "react-router-dom";
import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSState } from "context/global";
import CurrencySwitcher from "components/CurrencySwitcher/CurrencySwitcher";
import Locale from "translations";
import { logoURL } from "helpers/utils";
import HeaderThemeFour from "./Theme-4";
import HeaderThemeSeven from "./Theme-7";
import HeaderThemeFive from "./Theme-5";
import HeaderContactPhones from "./ContactPhones";

const URI = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";

export default function HeroHeaderV2({ editHeader }) {
  const { locale } = useSBSState();
  const { webBuilder } = Locale;
  const { hero, mainMenu, style } = useWebBuilderState();
  const navRef = useRef(null);
  const location = useLocation();

  // const location = useLocation();
  const preview = true;

  const header = hero?.header;
  const layoutType = header?.layout?.layoutType;
  // const hasButton = header?.layout?.hasButton;
  const hasLogo = header?.layout?.hasLogo;
  const headerBg = header?.layout?.background === "white";
  // const call_us = header?.layout?.callUs;
  const call_us = true;
  const visibleMenuItems = mainMenu?.filter((item) => item?.visible);

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const history = useHistory();
  // const params = useParams();

  // scroll to location from view package page
  useEffect(() => {
    if (location.state && typeof location.state === "string") {
      setTimeout(() => {
        scrollToSection(location.state);
      }, 250);
    }
  }, [location.state]);

  const nav = (
    <nav className="header-nav" ref={navRef}>
      <button className="close-nav" onClick={handleOpenMobileNav}>
        <CloseIcon />
      </button>
      {/* <a
        className="header-nav__call"
        href={`tel:${header?.phoneNumber?.split(" ")[0]}`}
      >
        <PhoneIcon color="#1e85ff" className="mirror-rtl" />
      </a> */}
      <ul className="main-nav">
        <li
          onClick={() => {
            history.push(`/`);
            handleOpenMobileNav();
          }}
          className={headerBg ? "text-secondary-color" : ""}
        >
          {webBuilder.home}
        </li>
        {visibleMenuItems?.map((item) => {
          return (
            <li
              key={item?.id}
              className="position-relative"
              onClick={() => {
                if (!item?.subNavs || item?.subNavs?.length === 0) {
                  let url = true
                    ? `/${item.url}`
                    : `/web-builder-v2/preview/${item?.url}`;
                  if (preview) {
                    history.push({ pathname: `${url}` });
                  }
                  handleOpenMobileNav();
                }
              }}
            >
              {item?.subNavs?.length > 0 ? null : (
                <span>{item?.title?.[locale]}</span>
              )}
              {/********************************************** sub navs *************************************************************/}
              {item?.subNavs?.length > 0 && (
                <UncontrolledDropdown>
                  <DropdownToggle caret className={`service-dropdown`}>
                    {item?.title?.[locale]}
                  </DropdownToggle>

                  <DropdownMenu right>
                    {item?.subNavs.map((subNav) => (
                      <DropdownItem
                        key={subNav.id}
                        onClick={() => {
                          let url = true
                            ? `/${subNav.url}`
                            : `/web-builder-v2/preview/${subNav?.url}`;
                          if (preview) {
                            history.push({ pathname: `${url}` });
                          }
                          handleOpenMobileNav();
                        }}
                      >
                        {subNav.title?.[locale]}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );

  const clickContactButton = () => {
    if (preview) {
      scrollToSection(header?.contactUs?.id);
      return;
    }
  };

  function handleOpenMobileNav() {
    if (navRef.current.classList.contains("nav-opened")) {
      navRef.current.classList.remove("nav-opened");
    } else {
      navRef.current.classList.add("nav-opened");
    }
  }

  const contactButton = (
    <div className="d-flex-language-contact d-flex align-items-center gap-10">
      <button
        className="contact-button mr-2"
        onClick={() => {
          // check if we inside view package page when click scroll back to preview page the scroll using useeffect
          history.push({ pathname: `/`, state: header?.contactUs?.id });
          clickContactButton();
        }}
      >
        <span>
          {typeof header?.contactUs?.name === "string" ? header?.contactUs?.name : (header?.contactUs?.name?.[locale] || "Let's Talk")}
          {/* {header?.contactUs?.name?.[locale] || header?.contactUs?.name} */}
        </span>
      </button>
      <LanguageSwitcher show={false} />
      <CurrencySwitcher show={false} />

      {call_us && (
        <HeaderContactPhones />
      )}
    </div>
  );

  // const urlPattern = new RegExp("^(http|https)://", "i");
  const imageSrc = logoURL(header?.logo, (header?.logo?.[locale?.toLowerCase()] || header?.logo?.en || header?.logo?.ar));

  return (
    <>
      <div className={`web-builder-hero-header`} onClick={editHeader}>
        {(style?.theme === "theme-1" ||
          style?.theme === "theme-2" ||
          style?.theme === "theme-3") &&
          (layoutType === 1 || layoutType === 2 ? (
            <header
              className={`${layoutType === 2 ? "flex-row-reverse " : ""}`}
            >
              {hasLogo && (
                <div className="logo">
                  {header?.layout?.hasLogo ? (
                    <Link to="/">
                      {header?.logo ? <img src={imageSrc} alt="Logo" /> : null}
                    </Link>
                  ) : null}
                </div>
              )}
              {(style?.theme === "theme-1" || style?.theme === "theme-3") && (
                <>
                  {nav}
                  {contactButton}
                  <button
                    className="nav-mobile-icon"
                    onClick={handleOpenMobileNav}
                  >
                    <MenuIcon />
                  </button>
                </>
              )}
              {style?.theme === "theme-2" && (
                <div className="nav-container">
                  {nav}
                  {contactButton}
                  <button
                    className="nav-mobile-icon"
                    onClick={handleOpenMobileNav}
                  >
                    <MenuIcon />
                  </button>
                </div>
              )}
            </header>
          ) : (
            <header
              className={`${layoutType === 4 ? "flex-row-reverse " : ""}`}
            >
              {contactButton}

              {hasLogo && (
                <div className="logo">
                  {header?.layout?.hasLogo ? (
                    <Link to="/">
                      {header?.logo ? (
                        <img
                          src={
                            header?.logo?.[locale]
                              ? `${URI}/${header?.logo?.[locale]}`
                              : header?.logo
                          }
                          alt="Logo"
                        />
                      ) : null}
                    </Link>
                  ) : null}
                </div>
              )}
              {nav}
            </header>
          ))}

        {(style?.theme === "theme-4" || style?.theme === "theme-10") && (
          <HeaderThemeFour
            contactButton={contactButton}
            logoImage={imageSrc}
            nav={nav}
            handleOpenMobileNav={handleOpenMobileNav}
          />
        )}

        {style?.theme === "theme-5" && (
          <HeaderThemeFive
            logoImage={imageSrc}
            nav={nav}
            handleOpenMobileNav={handleOpenMobileNav}
            clickContactButton={clickContactButton}
          />
        )}

        {style?.theme === "theme-7" && (
          <HeaderThemeSeven
            logoImage={imageSrc}
            nav={nav}
            contactButton={contactButton}
            handleOpenMobileNav={handleOpenMobileNav}
          />
        )}
      </div>
    </>
  );
}
