import ProfileIcon from "assets/images/webBuilder/profile.svg";
import SmsIcon from "assets/images/webBuilder/sms.svg";
import MobileIcon from "assets/images/webBuilder/mobile-icon.svg";

import TextField from "components/Form/TextField/TextField";
import Locale from "translations";

function ContactUsWithImage({ details,
  onSubmitForm,
  handleFormData,
  formData,
  formError
}) {
  const { webBuilder, errors } = Locale;
  const { id, body, title, image } = details;

  const preview = true;
  return (
    <div className="web-builder-content-contact-us-with-image" id={id}>
      <div className="custom-container">
        <div className="contact-us-layout">
          <div className="contact-us-data">
            <div className="content-top-section">
              <p className="text-body">
                {body}
              </p>
              <h4 className="h3 font-weight-bold mb-3 mt-1">
                {title}
              </h4>
            </div>
            {formError && (
              <div
                className="alert alert-danger w-100 text-center"
                style={{ maxWidth: "900px" }}
              >
                {errors.pleaseValidateDataBeforeSubmission}
              </div>
            )}
            <form onSubmit={onSubmitForm} className="contact-us-form">
              <div className="contact-us-fields">
                <TextField
                  haslabel={false}
                  placeholder={webBuilder.name}
                  value={formData.name}
                  onChange={(e) =>
                    handleFormData({ name: "name", value: e.target.value })
                  }
                  isImage
                  image={ProfileIcon}
                  prependimage
                />
              </div>
              <div className="contact-us-fields">
                <TextField
                  haslabel={false}
                  placeholder={webBuilder.email}
                  type="email"
                  value={formData.email}
                  onChange={(e) =>
                    handleFormData({ name: "email", value: e.target.value })
                  }
                  isImage
                  image={SmsIcon}
                  prependimage
                />
              </div>

              {/* phone  */}
              <div className="contact-us-fields">
                <TextField
                  type="number"
                  haslabel={false}
                  id="form-phone"
                  name="form-phone"
                  placeholder={webBuilder.phone}
                  value={formData.phone}
                  isImage
                  image={MobileIcon}
                  prependimage
                  onChange={(e) =>
                    handleFormData({ name: "phone", value: e.target.value })
                  }
                />
              </div>
              <textarea
                rows={5}
                placeholder={webBuilder.message}
                value={formData.notes}
                onChange={(e) =>
                  handleFormData({ name: "notes", value: e.target.value })
                }
                className="textarea-field"
              />

              <button
                type={preview ? "submit" : "button"}
                className="contact-us-submit-button"
              >
                {webBuilder.submit}
              </button>
            </form>
          </div>
          <div className="contact-us-image">
            {image ? <img src={image} alt="Logo" /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUsWithImage;
