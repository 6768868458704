import successImg from "assets/images/wallet/walletSuccessfully.png";
import { useSBSState } from "context/global";
import useShowPermission from 'hooks/useShowPermission';
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { bookHotel } from "services/marketplace";
import Locale from 'translations';

export default function ConfirmModel({
	modal,
	toggleModal,
	msg,
	availability,
}) {
	const { locale } = useSBSState();
	const { inventory } = Locale;
	const inventoryPermission = useShowPermission({
		permission: [
			"View-Inventory-Hotels",
			"Manage-Inventory-Hotels",

		],
	});
	
	const history = useHistory();
	// const { price } = useMarketplaceState();
	const HotelData = JSON.parse(localStorage.getItem("HotelData"));

	const book = async () => {
		const roomData = {
			special_request: "",
			hotel_availability_cache_id: availability?.hotel_check_id,

			provider_id: HotelData?.gds?HotelData?.gds:3,
			// dummy room_guests

			payment_info: {
				account_number: null,
				otp: null,
				payment_method: "pay_later",
				currency: availability?.currency
			},
			lang: locale,
		};
		const res = await bookHotel(roomData);

		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: inventory.messages.bookingSuccessfully,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					history.push(inventoryPermission?"/inventory":"/statistics");
				},
			});
		}

	};
	return (
		<>
			<Modal
				size="md"
				className="price-model"
				isOpen={modal}
				toggle={toggleModal}
			>
				<ModalHeader className="align-items-center" toggle={toggleModal}>
					<p>
						{+HotelData?.gds == 4
							? "Confirm your Reservation"
							: "Send Reservations Request "}
					</p>
				</ModalHeader>
				<ModalBody className="payModal">
					<div className="container ">
						<div className="text-center my-3">
							<img className="mt-2" src={successImg} alt="" />

							<p>
								<button
									className="btn btn-success btn-lg fx-15 mt-3"
									onClick={() => {
										book();
									}}
								>
									{+HotelData?.gds == 4 ? "confirm" : "Send"}
								</button>

								{+HotelData?.gds == 4 ? (
									<button
										className="btn btn-danger btn-lg fx-15 mt-3 mx-2"
										onClick={() => {
											toggleModal();
										}}
									>
										{inventory.cancel}
									</button>
								) : (
									""
								)}
							</p>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
