import React from "react";

import { Redirect } from "react-router-dom/cjs/react-router-dom";
const ErrorPreLogin = (props) => {
  return (
    <div className="error-404 text-center">
      <Redirect to="/" />
    </div>
  );
};

export default ErrorPreLogin;
