import TextField from 'components/Form/TextField/TextField';
import { useMarketplaceState } from 'context/marketplace';
import ToursSearchForm from 'modules/market-place/SearchForm/Tours';
import moment from 'moment';
import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import Locale from 'translations';

export default function ToursSearchBar() {
  const { visa, marketPlace, inventory } = Locale;
  const [modifyModalOpen, setModifyModalOpen] = useState(false);
  const { toursSearch } = useMarketplaceState();

  function toggleModifyModal() {
    setModifyModalOpen(!modifyModalOpen)
  }



  return (
    <div className='tours-search-wrapper'>
      <div className='container'>
        <div className='tours-search-bar'>
          {/* country */}
          <div className="col-md-2 col-6">
            <TextField
              hasLabel={true}
              label={visa.country}
              value={toursSearch?.country?.label}
              readOnly
              disabled
            />
          </div>
          {/* Destination */}
          <div className="col-md-2 col-6">
            <TextField
              hasLabel={true}
              label={marketPlace.messages.destination}
              value={toursSearch?.destination?.label}
              readOnly
              disabled
            />

          </div>
          {/* From */}
          <div className="col-md-2 col-6">
            <TextField
              label={marketPlace.From}
              value={moment(toursSearch?.date_from).format("DD-MM-YYYY")}
              readOnly
              disabled
            />
          </div>
          {/* To */}
          <div className="col-md-2 col-6">
            <TextField
              label={marketPlace.To}
              value={moment(toursSearch?.date_to).format("DD-MM-YYYY")}
              readOnly
              disabled
            />
          </div>
          {/* adults and children */}
          <div className="col-md-3 p-0">
            <div className="d-flex">
              {/* Adults */}
              <div className="col-6">
                <TextField
                  label={inventory.messages.adults}
                  value={toursSearch?.adults?.label}
                  readOnly
                  disabled
                />
              </div>
              {/* Children */}
              <div className="col-6">
                <div className='d-flex align-items-center'>
                  <label htmlFor="children" className='m-0'>{inventory.messages.children}</label>
                  <i className="fas fa-info-circle new-color mx-1" title={marketPlace.messages.childrenAge} />
                </div>
                <TextField
                  haslabel={false}
                  value={toursSearch?.childs?.label}
                  readOnly
                  disabled
                />
              </div>
            </div>
          </div>
          {/* button */}
          <div className='col-md-1 col-12 mt-md-4'>
            <button className="btn bg-nxt w-100" type="button" onClick={() => setModifyModalOpen(true)}>
              {marketPlace.modify}
            </button>
          </div>

        </div>
      </div>

      {/* modify search */}
      {modifyModalOpen ?
        <Modal isOpen={modifyModalOpen} size="md" className="search-form-web-builder" backdrop={false}>
          <ModalHeader className="font-weight-bold justify-content-between w-100" toggle={toggleModifyModal}>
            <div className='d-flex justify-content-between w-100'>
              {marketPlace.modify}
            </div>
          </ModalHeader>

          <ModalBody className="search-service-hero">
            <ToursSearchForm
              modify={true}
              toggleModifyModal={toggleModifyModal}
            />
          </ModalBody>
        </Modal>
        :
        null
      }
    </div>
  )
}
