
/**
 * 
 * @param {*Number} adultsCount required
 * @param {*[]Number} childrenAges required array of children ages
 * @param {*} selectedCategory object {modility, rate}
 * @returns {} 
 * 
 *  
 */

export function calcCategoryPrice(adultsCount = 0, childrenAges = [], modility) {

  const amountUnitType = modility?.category?.amountUnitType;
  const amountUnitTypePax = amountUnitType === "PAX" || null;
  const amountUnitTypeService = amountUnitType === "SERVICE" || null;
  const modilityRate = modility?.rate;


  if (modility) {
    let adultsAge = modilityRate?.paxAmounts?.filter(
      amount => amount?.paxType === "ADULT"
    )[0];
    const childAge = modilityRate?.paxAmounts?.filter(
      amount => amount?.paxType === "CHILD" && amount?.amount > 0
    )[0];
    const freeChildAge = modilityRate?.paxAmounts?.filter(
      amount => amount?.paxType === "CHILD" && amount?.amount === 0
    )[0];

    const adultPrice = adultsAge?.amount || 0;
    const childPrice = childAge?.amount || 0;
    const minAdultAge = adultsAge?.ageFrom;
    const maxChildAge = childAge?.ageTo;
    const minChildAge = childAge?.ageFrom;


    let adultPrices = adultsCount * adultPrice || 0;
    let childrenPrices = 0;
    let childsAsAdultPrices = 0;

    if (childrenAges?.length > 0) {
      childrenAges?.forEach(childAge => {
        // check if child in modility child pax ages range
        if (childAge >= minChildAge && childAge <= maxChildAge) {
          childrenPrices = childrenPrices + childPrice;
        }

        // check if child age is not in modility child pax ages range
        else if (childAge >= minAdultAge) {
          childsAsAdultPrices = childsAsAdultPrices + adultsAge?.amount;
        }

      });
    }

    // show price from modility if amount unit service
    // calc adult prices and children prices if amount unit is pax
    const total = amountUnitTypePax ? +(adultPrices + childrenPrices + childsAsAdultPrices).toFixed(2) : amountUnitTypeService ? +modilityRate?.totalAmount?.amount : 0;

    return {
      amountUnitTypePax,
      amountUnitTypeService,
      adult: {
        minAge: minAdultAge,
        amount: +adultPrice?.toFixed(2),
        totalAmount: +adultPrices?.toFixed(2),
      },
      child: {
        minAge: childAge?.ageFrom,
        maxAge: childAge?.ageTo,
        amount: +childPrice?.toFixed(2),
        totalAmount: +childrenPrices.toFixed(2)
      },
      freeChild: {
        minAge: freeChildAge?.ageFrom,
        maxAge: freeChildAge?.ageTo,
        amount: freeChildAge?.amount?.toFixed(2),
      },
      childAsAdult: {
        minAge: minAdultAge,
        amount: +adultPrice?.toFixed(2),
        totalAmount: +childsAsAdultPrices.toFixed(2)
      },
      total: +modilityRate?.totalAmount?.amount ||  total?.toFixed(2)

    };
  }
}