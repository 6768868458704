import { useSBSState } from "context/global";
import { useWebBuilderState } from "context/webBuilder";
import { logoURL } from "helpers/utils";
import { useHistory, Link } from "react-router-dom";
import Locale from "translations";

const currentYear = new Date().getFullYear();

export default function FooterTempTwo({ socialMediaIcons }) {
  const { webBuilder } = Locale;
  const history = useHistory();
  const { locale } = useSBSState();

  const { footer: footerDetails, hero, ourServices } = useWebBuilderState();
  const header = hero?.header;
  // const hasLogo = header?.layout?.hasLogo;
  const services = ourServices?.services?.filter((service) => service?.visible);

  const scrollToSection = (id) => {
    if (id) {
      const element = document.getElementById(id);
      element?.scrollIntoView({ behavior: "smooth" });
    }
  };

  const navFooter = (
    <>
      <div className="footer-col">
        <h4 className="footerLinks-header"> {webBuilder.home}</h4>
        <ul>
          <li
            onClick={() => {
              history.push({ pathname: `/about-us` });
              scrollToSection("about-us-section");
            }}
            className={"text-white-50 pointer"}
          >
            {webBuilder.aboutus}
          </li>

          <li
            onClick={() => {
              history.push({
                pathname: `/`,
                state: "our-service",
              });
              scrollToSection("our-service");
            }}
            className={"text-white-50 pointer"}
          >
            {webBuilder.ourServices}
          </li>
        </ul>
      </div>

      <div className="footer-col d-none-mob">
        <h4 className="footerLinks-header">{webBuilder.getHelp}</h4>
        <ul>
          <li
            onClick={() => {
              history.push({
                pathname: `/`,
                state: "faq-section",
              });
              scrollToSection("faq-section");
            }}
            className={"text-white-50 pointer"}
          >
            {webBuilder.FAQ}
          </li>

          <li
            onClick={() => {
              history.push({
                pathname: `/`,
                state: "contact-us-section",
              });
              scrollToSection("contact-us-section");
            }}
            className={"text-white-50 pointer"}
          >
            {webBuilder.contactUs}
          </li>
        </ul>
      </div>

      <div className="footer-col">
        <h4 className="footerLinks-header">{webBuilder.ourServices}</h4>
        <ul>
          {services &&
            services.length > 0 &&
            services?.map((service) => {
              return (
                <li
                  onClick={() => {
                    history.push({
                      pathname: `/`,
                      state: service?.id,
                    });
                    scrollToSection(service?.id);
                  }}
                  className={"text-white-50 pointer"}
                >
                  {webBuilder[service?.type] || service?.type}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );

  // const logo = header?.logo ? <img className='footer-photo' src={header?.logo} alt='Logo' /> : null

  return (
    <div className="w-100 px-md-5">
      <div className="footer-temp-two">
        <div className="footerTempTwo-logo">
          <Link to="/">
            <img
              className="footer-photo"
              src={logoURL(header?.logo, (header?.logo?.[locale?.toLowerCase()] || header?.logo?.en || header?.logo?.ar))}
              alt="Logo"
            />
          </Link>
          {footerDetails?.links?.filter((link) => link?.visible && link?.url)
            ?.length ? (
            <div className="footer-socialIcons">
              <p className="footer-text">{webBuilder.followUsOn}</p>
              <div className="d-flex align-items-center gap-10 py-2">
                {footerDetails?.links?.map((link) => {
                  if (link?.visible && link?.url) {
                    return (
                      <div key={link?.id}>
                        <a href={link?.url} target="_blank" rel="noreferrer">
                          {socialMediaIcons[link?.id]}
                        </a>
                      </div>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          ) : null}
        </div>

        <div className="footerTempTwo-links">{navFooter}</div>
      </div>
      <div className="footer-copyrights">
        <p>
          {webBuilder.allRightsReserved} {" - "}
          <a
            href="https://safasoft.com/"
            target="_blank"
            rel="noreferrer"
            className="text-white"
          >
            {webBuilder.safasoft}
          </a>{" "}
          © {currentYear}
        </p>
      </div>
    </div>
  );
}
